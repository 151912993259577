import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import peticionBack from "../../../../helpers/peticiones";
import createURL from "../../../../helpers/url";
import Datatable from "../../../datatable/Datatable";
import { Clientes, Expedientes, schedules } from "../types/CatClientes";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import Button from "../../../buttons/Button";
import FormExpediente from "./FormExpediente";
import { alerConfirmation } from "../../../../helpers/alertConfirmation";
import { Col, Label, Row } from "reactstrap";
import { format } from "date-fns";
import { es } from "date-fns/locale";

export default function Expediente({ setOpenClient, client, t }: { setOpenClient: any; client?: Clientes; t: any }) {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [expediente, setExpediente] = useState<Expedientes>();
    const [expReciente, setExpReciente] = useState<Expedientes>();
    let lastStatus = t("clientes.common.No stage");
    let lastExp = t("clientes.common.There is no file");
    let fechaFormateada = "";

    const fecha = expReciente?.schedules?.[0]?.start || "";

    if (fecha !== "") {
        fechaFormateada = format(new Date(fecha), "dd/MM/yyyy");
    } else {
        fechaFormateada = t("clientes.common.There is no appointment");
    }

    if (expReciente?.file_number) {
        lastExp = expReciente?.file_number;
    } else {
        lastExp = t("clientes.common.Does not have a file");
    }

    if (expReciente?.status) {
        lastStatus = expReciente?.status;
    } else {
        lastStatus = t("clientes.common.No stage");
    }

    async function getExpedienteClient() {
        const params: any = {
            pathname: `/expediente`,
            searchParams: [{ name: "client_id", value: client?._id }],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        const newData = { data: res };

        return newData || "";
    }

    async function getExpedienteReciente() {
        const params: any = {
            pathname: `/expediente/upcoming/get-one`,
            searchParams: [{ name: "client_id", value: client?._id }],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        setExpReciente(res);
        return res || "";
    }

    useEffect(() => {
        getExpedienteReciente();
        // eslint-disable-next-line
    }, [open]);

    const headers = [t("No."), t("clientes.common.Stages"), t("clientes.common.Next appointment"), t("clientes.common.Subject"), t("clientes.common.Judgment type"), t("Actions")];
    const columnsDef = [
        { orderable: false, targets: [5] },
        { className: "text-center", targets: [0, 1, 2, 3, 4, 5] },
    ];

    const columns = [
        { data: "file_number" },
        { data: "status" },
        { data: "schedules", render: (d: schedules[]) => (d[0] ? format(new Date(d[0].start), "PPPppp", { locale: es }).replace(" GMT-6", "") : t("clientes.common.There is no appointment")) },
        { data: "judgment_type_id.subject_id.name" },
        { data: "judgment_type_id.name" },
        {
            data: null,
            render: (row: Expedientes) => (
                <div className="d-flex justify-content-evenly">
                    <FontAwesomeIcon icon={faPencil} className="text-warning cursor-pointer" onClick={() => openToEdit(row)} />
                    <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />
                </div>
            ),
        },
    ];

    function openToEdit(row: Expedientes) {
        setExpediente(row);
    }

    useEffect(() => {
        if (!open) {
            setExpediente(undefined);
            setRefresh(false);
        }
    }, [open]);

    useEffect(() => {
        if (expediente) setOpen(true);
    }, [expediente]);

    async function deleteRow(row: Expedientes) {
        if (row && row._id) {
            await peticionBack({}, `/expediente/${row._id}`, "DELETE", true);
            setRefresh(true);
            getExpedienteReciente();
        }
    }

    function showAlert(row: Expedientes) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("Delete")}`,
            content: `${t("Are you sure you want to delete")} ${row.file_number}`,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    return (
        <>
            {!open ? (
                <div style={{ padding: "10px" }}>
                    <Button onClick={() => setOpenClient(false)} className="close-button">
                        {t("close")}
                    </Button>
                    <Row style={{ marginTop: "10px" }}>
                        <Label style={{ fontSize: "1.2em" }}>
                            <h5 style={{ marginBottom: "5px" }}> {t("clientes.common.Customer") + ": " + client?.name + " " + client?.lastname}</h5>
                            <h5 style={{ marginBottom: "5px" }}> {t("Phone") + ": " + client?.phone}</h5>
                            <h5 style={{ marginBottom: "5px" }}> {t("Email") + ": " + client?.email}</h5>
                            <h5 style={{ marginBottom: "5px" }}> {t("clientes.common.Last file") + ": " + lastExp}</h5>
                            <h5 style={{ marginBottom: "5px" }}> {t("clientes.common.Trial stage") + ": " + lastStatus}</h5>
                            <h5 style={{ marginBottom: "5px" }}> {t("clientes.common.Next appointment") + ": " + fechaFormateada}</h5>
                        </Label>
                    </Row>
                </div>
            ) : (
                ""
            )}
            <div>
                <h4> {open && (expediente ? `${t("Edit")}` : `${t("Add")}`)}</h4>
                <Col className="d-flex justify-content-end">
                    {!open && (
                        <Button onClick={() => setOpen(true)} className="my-1 my-md-0" color="success">
                            {t("Add")}
                        </Button>
                    )}
                </Col>
            </div>
            {open ? <FormExpediente setOpen={setOpen} expediente={expediente} t={t} clientId={client?._id} /> : <Datatable columns={columns} columnDefs={columnsDef} headers={headers} control="front" stateRefresh={[refresh, setRefresh]} petition={getExpedienteClient} />}
        </>
    );
}
