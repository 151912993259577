/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Dispatch, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { OnChangeParams } from "../../../inputs/InputProps";
import peticionBack from "../../../../helpers/peticiones";
import InputBasic from "../../../inputs/InputBasic";

const defaultForm = {
    name: "",
    folder_id: "",
};

export default function RenameFolders({ t, folder, openRenameFolder, setOpenRenameFolder, setRefreshContent, setIsFolderRoot, setCurrentFolder }: { t: (text: string) => string; folder: any; openRenameFolder: boolean; setOpenRenameFolder: Dispatch<boolean>; setRefreshContent: Dispatch<boolean>; setIsFolderRoot: Dispatch<boolean>; setCurrentFolder:any;}) {

    const formSchema = Yup.object().shape({
        name: Yup.string().required(t("Enter a name for the folder")),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue, setValues } = useFormik({
        initialValues: defaultForm,
        validationSchema: formSchema,
        onSubmit: () => {
            update();
        },
        onReset: () => {
            setOpenRenameFolder(false);
        },
    });

    useEffect(() => {
        if (folder) setValues({
          name: folder.name,
          folder_id: folder.folder_id
        }, true);
      }, []);

    async function update() {
        const params = { ...values, folder_id: folder.folder_id? folder.folder_id :"" };
        const res = await peticionBack(params, `/folders/${folder.id}` , "PUT", true);
        if (res) {
            if(res.folder_id){
                setCurrentFolder(res.folder_id)
                setRefreshContent(true);
            }else{
                setCurrentFolder(undefined)
            }
            setIsFolderRoot(res.folder_id? false : true);

        }
        setOpenRenameFolder(false);
        handleReset(null);
    }

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }
    return (
        <Modal id="RenameFolderModal" isOpen={openRenameFolder} modalClassName="zoomIn" centered tabIndex={1}>
            <ModalHeader className="p-3 bg-success-subtle">{t("Rename-folder")}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <InputBasic name="name" value={values.name} label={"Nombre de carpeta"} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                    <div className="mt-4">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="reset" className="btn btn-ghost-success" onClick={() => setOpenRenameFolder(false)}>
                                <i className="ri-close-line align-bottom"></i>  {t("cancel")}
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {t("Update-folder")}
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}
