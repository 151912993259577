import { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import toastMessage from "../../../../helpers/toastMessage";
import { Button, Spinner } from "reactstrap";
import { Row, Col } from "reactstrap";
import { getData } from "../../../layout/protected/components/BalanceHeader";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loginData } from "../../../../core/slices/auth/AuthSlicer";
import { alerConfirmation } from "../../../../helpers/alertConfirmation";

export default function CheckoutForm({ setStatus, t }: { setStatus: any; t: (f: string) => string }) {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const loginInformation = useSelector(loginData);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if (paymentIntent) {
                switch (paymentIntent.status) {
                    case "succeeded":
                        toastMessage("success", t("complete"));
                        break;
                    case "processing":
                        toastMessage("error", t("Your payment is processing"));
                        break;
                    case "requires_payment_method":
                        toastMessage("error", t("Your payment was not successful, please try again"));
                        break;
                    default:
                        toastMessage("error", "Something went wrong.");
                        break;
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        await stripe
            .confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: "https://www.miabogadoenlinea.mx/wallet",
                },

                redirect: "if_required",
            })
            .then((value: any) => {
                if (value.error) {
                    toastMessage("error", t("A processing error occurred"));
                } else {
                    const handleRefresh = () => {
                        window.location.reload();
                    };
                    if (value.paymentIntent.status === "succeeded") {
                        setStatus(true);
                        getData(loginInformation, dispatch);
                        alerConfirmation({
                            handleAction: handleRefresh,
                            title: `${t("reload page")}`,
                            content: `${t("reload page message")}`,
                            icon: "question",
                            position: "center",
                        });
                    }
                }
            })
            .catch((error: any) => {
                console.log("error", error);
                toastMessage("error", t("An unexpected error occurred"));
            });

        setIsLoading(false);
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
            <Row className="d-flex justify-content-center">
                <Col lg="8" md="8" xs="8" className="text-center">
                    <Button disabled={isLoading || !stripe || !elements} id="submit" className="mt-3 ">
                        <span id="button-text">{isLoading ? <Spinner></Spinner> : t("Pay now")}</span>
                    </Button>
                </Col>
            </Row>
        </form>
    );
}
