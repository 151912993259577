/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../buttons/Button";
import peticionBack from "../../../../helpers/peticiones";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../layout/protected/components/BalanceHeader";
import { loginData } from "../../../../core/slices/auth/AuthSlicer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { solutions } from "../../../../core/enums/enums";
import { getCostSolution } from "../../../../helpers/getCostsSolutions";
import { loaded, loading } from "../../../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../../../core/types/TypeLoader";
import toastMessage from "../../../../helpers/toastMessage";

export default function ModalPDF({
    t,
    id,
    stateImage,
    stateShow,
    title,
    src,
    setViewFile,
    isProcessed,
    setIsProcessed,
}: {
    t: (text: string) => string;
    id: string;
    stateImage: Array<any>;
    stateShow: Array<any>;
    title?: string;
    src: string;
    setViewFile: any;
    isProcessed: boolean;
    setIsProcessed: any;
}) {
    const [costSolution, setCostSolution] = useState<number | null>();
    const navigate = useNavigate();
    const loginInformation = useSelector(loginData);
    const dispatch = useDispatch();

    const handleClose = () => {
        setViewFile(false);
        stateShow[1](false);
        stateImage[1](false);
        setIsProcessed(false);
    };
    async function sendOCR() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ file_id: id, replace: false }, `/ocr`, "POST", true);
        setViewFile(false);
        stateShow[1](false);
        stateImage[1](false);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
            getData(loginInformation, dispatch);
            navigate("/ocr", { replace: true });
        }
    }

    useEffect(() => {
        getSolutionCost();
    }, []);

    async function getSolutionCost() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const costSolution = await getCostSolution(solutions.OCR);
        if (costSolution) {
            setCostSolution(costSolution);
        }
        dispatch(loaded());
    }
    return (
        <Modal isOpen={(stateShow[0] || stateImage[0]) && !isProcessed} size="xl" centered scrollable={false} fullscreen keyboard>
            <div className="mt-3 d-flex justify-content-end mx-3">
                <FontAwesomeIcon
                    className="text-danger"
                    style={{ cursor: "pointer" }}
                    size="2x"
                    icon={faXmark}
                    onClick={() => {
                        handleClose();
                    }}
                />
            </div>
            <ModalHeader className="border-bottom-0 pb-0">{title}</ModalHeader>
            <ModalBody className="pt-0">
                {stateImage[0] === true ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                        <img src={src} alt="Descripción de la imagen" />
                    </div>
                ) : (
                    <iframe title="Archivo PDF" src={src} width="100%" height="600px">
                        <p>{t("Unsupported-device")}</p>
                    </iframe>
                )}
            </ModalBody>
            <div className="m-4 d-flex justify-content-center">
                {costSolution ? (
                    <Button color="success" onClick={sendOCR}>
                        <i className="ri-money-dollar-circle-line" style={{ fontSize: "1em" }} />
                        {`${costSolution} ${t("Send to OCR")}`}
                    </Button>
                ) : (
                    <h4>${t("The price could not be consulted")}</h4>
                )}
            </div>
        </Modal>
    );
}
