import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./sidebar/Sidebar";
import withRouter from "../../common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { changeLayout, changeLayoutMode, changeSidebarVisibility } from "../../../core/slices/thunks";
import { loadingData } from "../../../core/slices/loader/LoaderSlicer";
import Loader from "../../loaders/Loader";
import { isUserInformationValidated } from "../../../helpers/userValidations";
import { loginData } from "../../../core/slices/auth/AuthSlicer";

function Layout(props: any) {
    const [headerClass, setHeaderClass] = useState<any>("");
    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.layout;
    const userInformation = useSelector(loginData);
    const selectLayoutProperties = createSelector(selectLayoutState, (layout) => {
        return {
            layoutType: "vertical",
            layoutModeType: layout.layoutModeType,
            preloader: layout.preloader,
            sidebarVisibilitytype: "HIDDEN",
            layoutPositionType: layout.layoutPositionType,
        };
    });

    const { layoutType, layoutModeType, sidebarVisibilitytype } = useSelector(selectLayoutProperties);
    const { loadingStack } = useSelector(loadingData);

    useEffect(() => {
        if (layoutType || layoutModeType || sidebarVisibilitytype) {
            window.dispatchEvent(new Event("resize"));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarVisibility(sidebarVisibilitytype));
        }
    }, [layoutType, layoutModeType, sidebarVisibilitytype, dispatch]);

    /* call dark/light mode */
    const onChangeLayoutMode = (value: any) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };

    // class add remove in header
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }

    useEffect(() => {
        const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
        if (sidebarVisibilitytype === "show" || layoutType === "vertical" || layoutType === "twocolumn") {
            humberIcon.classList.remove("open");
        } else {
            humberIcon && humberIcon.classList.add("open");
        }
    }, [sidebarVisibilitytype, layoutType]);

    return (
        <div id="layout-wrapper">
            <title> MIAbogado Suite</title>
            <Header headerClass={headerClass} layoutModeType={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} />
            <Sidebar layoutType={layoutType} />
            <div className="main-content">
                <div className="page-content position-relative" style={{ minHeight: "100vh" }}>
                    <div className="container-fluid px-0">{props.children}</div>
                    {loadingStack.length > 0 && <Loader show={loadingStack[loadingStack.length - 1]} />}
                </div>
                <Footer disableChatbot={isUserInformationValidated(userInformation)} />
            </div>
        </div>
    );
}

export default withRouter(Layout);
