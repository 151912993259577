import { useEffect, useState } from "react";
import peticionBack from "../../helpers/peticiones";
import createURL from "../../helpers/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Clientes } from "../../components/pages/admin-cat-clientes/types/CatClientes";
import { alerConfirmation } from "../../helpers/alertConfirmation";
import CardContainer from "../../components/cardContainer/CardContainer";
import FormClientes from "../../components/pages/admin-cat-clientes/components/FormClient";
import Datatable from "../../components/datatable/Datatable";
import { Col } from "reactstrap";
import Button from "../../components/buttons/Button";
import { withTranslation } from "react-i18next";
import Expediente from "../../components/pages/admin-cat-clientes/components/Expediente";

function MainClientes({ t }: { t: (f: string) => string }) {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selected, setSelected] = useState<Clientes>();
    const [client, setClient] = useState<Clientes>();
    const [open, setOpen] = useState<boolean>(false);
    const [openClient, setOpenClient] = useState<boolean>(false);

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: `/clientes`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        return res || "";
    }

    const headers = [t("Name"), t("Last name"), t("Phone"), t("Email"), t("clientes.common.See file"), t("Actions")];
    const columnsDef = [
        { orderable: false, targets: [4, 5] },
        { className: "text-center", targets: [0, 1, 2, 3] },
    ];

    const columns = [
        { data: "name"},
        { data: "lastname" },
        { data: "phone" },
        { data: "email" },
        {
            data: null,
            render: (row: Clientes) => (
                <div className="d-flex justify-content-evenly">
                    <FontAwesomeIcon icon={faEye} className="text-info cursor-pointer" title="Ver expediente" onClick={() => openExpediente(row)} />
                </div>
            ),
        },
        {
            data: null,
            render: (row: Clientes) => (
                <div className="d-flex justify-content-evenly">
                    <FontAwesomeIcon icon={faPencil} className="text-warning cursor-pointer" onClick={() => openToEdit(row)} />
                    <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />
                </div>
            ),
        },
    ];

    function openToEdit(row: Clientes) {
        setSelected(row);
    }

    function openExpediente(row: Clientes) {
        setClient(row);
    }

    useEffect(() => {
        if (!open) {
            setSelected(undefined);
            setRefresh(false);
        }
        if (!openClient) {
            setClient(undefined);
            setRefresh(false);
        }
    }, [open, openClient]);

    useEffect(() => {
        if (selected) setOpen(true);

        if (client) setOpenClient(true);
    }, [selected, client]);

    async function deleteRow(row: Clientes) {
        if (row && row._id) {
            await peticionBack({}, `/clientes/${row._id}`, "DELETE", true);
            setRefresh(true);
        }
    }

    function showAlert(row: Clientes) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("Delete")}`,
            content: `${t("Are you sure you want to delete")} ${row.name}`,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    return (
        <CardContainer title={openClient ? t("clientes.common.File") : t("clientes.common.Customers")}  tutorial="CLIENTS">
            {openClient ? (
                <Expediente setOpenClient={setOpenClient} client={client} t={t} />
            ) : (
                <>
                    <h4> {open && (selected ? `${t("Edit")}` : `${t("Add")}`)}</h4>
                    <Col className="d-flex justify-content-end">
                        {!open && (
                            <Button onClick={() => setOpen(true)} className="my-1 my-md-0" color="success">
                                {t("Add")}
                            </Button>
                        )}
                    </Col>

                    {open ? <FormClientes setOpen={setOpen} selected={selected} t={t} /> : <Datatable columns={columns} columnDefs={columnsDef} headers={headers} control="back" stateRefresh={[refresh, setRefresh]} petition={getData} />}
                </>
            )}
        </CardContainer>
    );
}

export default withTranslation()(MainClientes);
