/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { withTranslation } from "react-i18next";
import CardBalance from "../../../cardContainer/CardMini";
import { BalanceType } from "../types/chartType";

 function Clients({ t }: { t: (f: string) => string }) {
    const [data, setData] = useState<BalanceType|undefined>({
        id: "1",
        label: `${t("totalClients")}`,
        labelClass: "muted",
        percentage:`${t("Clients")}`,
        percentageClass: "info",
        percentageIcon: "ri-arrow-right-up-line",
        counter: "",
        caption: ``,
        icon: "bx bxs-user",
        iconClass: "primary-subtle",
        decimals: 2,
        prefix: "$",
        color:"primary"
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBalance();
    }, []);

    async function getBalance() {
setLoading(true)
        const res = await peticionBack({}, `/clients/count`, "GET", false);
        if (res) {
            const BalanceObj ={
                id: "1",
                label: `${t("totalClients")}`,
                labelClass: "muted",
                percentage:`${t("Clients")}`,
                percentageClass: "primary",
                percentageIcon: "ri-arrow-right-up-line",
                counter: res.clients,
                caption: ``,
                icon: "bx bxs-user",
                iconClass: "primary-subtle",
                decimals: 2,
                prefix: "$",
                color:"primary"
            }
            setData(BalanceObj); 
        }
        setLoading(false);
    }
    return (
        <>
       {data && <CardBalance datag={data} loading={loading}/>}
        </>
    );
}

export default withTranslation()(Clients);