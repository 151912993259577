import { Form } from "react-router-dom";
import InputBasic from "../../../inputs/InputBasic";
import InputPassword from "../../../inputs/InputPassword";
import { Spinner } from "reactstrap";
import Button from "../../../buttons/Button";
import InputCheck from "../../../inputs/InputCheck";

export const FormLogin = ({ errors, handleReset, values, touched, onChange, handleSubmit, handleBlur, loading, t, showConfirmPassword, labelSendButton }: any) => {
    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                <InputBasic name="email" type="email" value={values.email} label={t("Email")} touched={touched.email} error={errors.email} onChange={onChange} onBlur={handleBlur} />

                <div className="position-relative">
                    <InputPassword name="password" value={values.password} label={t("Password")} touched={touched.password} error={errors.password} onChange={onChange} onBlur={handleBlur} showPasswordMessageValidation={showConfirmPassword} />
                </div>

                {showConfirmPassword && (
                    <div className="position-relative">
                        <InputPassword name="Rpassword" value={values.Rpassword} label={t("Password")} touched={touched.Rpassword} error={errors.Rpassword} onChange={onChange} onBlur={handleBlur} />

                        <InputCheck type="checkbox" name="accept" value={values.accept} onChange={onChange} error={errors.accept} touched={touched.accept} label="acepte nuestros terminos y condiciones" />
                        {/* <span
                            className="fw-semibold text-primary text-decoration-underline ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate("/privacidad", { replace: true });
                            }}
                        >
                            {"Aqui"}
                        </span> */}
                    </div>
                )}

                <div className="mt-4">
                    <Button color="success" className="w-100 btn-load" type="submit">
                        <span className="">
                            {loading && (
                                <Spinner size="sm" style={{ height: "16px", width: "16px" }}>
                                    Loading...
                                </Spinner>
                            )}
                            <span className="ms-2">{labelSendButton}</span>
                        </span>
                    </Button>
                </div>
            </Form>
        </div>
    );
};
