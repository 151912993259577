import { withTranslation } from "react-i18next";
import ReportesMain from "../../components/pages/reportes/views/Main";
import { Reportes } from "../../core/enums/enums";

function UserReportes({ t }: { t: (f: string) => string }) {
    const reportes = [
        { value: 1, name: Reportes.TRANSCRIPCION, label: t("Transcoding Report") },
        { value: 2, name: Reportes.OCR, label: t("ocr Report") },
        { value: 3, name: Reportes.FOLDER, label: t("folder Report") },
        { value: 4, name: Reportes.PDF, label: t("pdf Report") },
        { value: 5, name: Reportes.VIDEO, label: t("video Report") },
        { value: 6, name: Reportes.IMAGE, label: t("image Report") },
        { value: 7, name: Reportes.STATUS, label: t("status report") },
        { value: 8, name: Reportes.STAGE, label: t("stage report") },
        { value: 9, name: Reportes.SCHEDULE, label: t("schedule report") },
        { value: 12, name: Reportes.CHATBOT, label: t("Chatbot Report") },
    ];

    return (
        <>
            <ReportesMain t={t} reportes={reportes} />
        </>
    );
}
export default withTranslation()(UserReportes);
