import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { TranscoIterInter } from '../../types/reportesTypes';
import { ConfigTable, generaTabla } from './generateTable';
import header from './Header';

export default async function IngresForAdmins(t: (text: string) => string, data: TranscoIterInter[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("use of tools")}`, 4);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                {
                    data: "date", 
                    title: `${t("date")}`,
                    width: 15, align: "tCenter"
                },
                { data: "ingress", title: `${t("Ingress")}`, width: 10, align: "tCenter" },
                { data: "solution.name", title: `${t("Solution")}`, width: 20 },
                { data: "tool_use", title: `${t("tool_use")}`, width: 20, align: "tCenter"},
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("ingress Report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("ingress Report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 4);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
