import { UncontrolledTooltip } from "reactstrap";
import CardContainer from "../../components/cardContainer/CardContainer";
import { useState } from "react";
import Datatable from "../../components/datatable/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faClosedCaptioning, faEye, faFileCode, faRedo } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/buttons/Button";
import peticionBack from "../../helpers/peticiones";
import { DataItem, ResultItem } from "./dto/TranscoderDto";
import createURL from "../../helpers/url";
import ViewTranscoder from "../../components/pages/transcoder/views/VistaTranscoder";
import { getColor } from "../../helpers/formats";
import axios from "axios";
import ModalView from "../../components/pages/transcoder/components/ModalView";
import { checkStatus } from "../../helpers/checkStatusOperation";
import { useTranslation } from "react-i18next";
import { StausQueue } from "../../core/enums/enums";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";
import toastMessage from "../../helpers/toastMessage";
import { alerConfirmation } from "../../helpers/alertConfirmation";
import Badge from "../../components/badge/Badge";
import SingleModalUpload from "../../components/modals/SingleModalUpload";
import { QueueWork } from "../../core/enums/enums";

export default function Principal() {
    const [refresh, setRefresh] = useState(false);
    const [isOpen, setIsOpen] = useState<any>(null);
    const [isOpenModalUpload, setIsOpenModalUpload] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: "/queues/TRANSCRIPCION",
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res) return res || [];
    }

    const DownloadJson = async (row: DataItem) => {
        const fileJson = row.result.find((f: ResultItem) => f.type === "json");
        if (!fileJson) {
            return;
        }
        try {
            const respuesta = await axios.get(fileJson.url, {
                responseType: "blob",
            });
            const url = window.URL.createObjectURL(new Blob([respuesta.data]));
            const enlace = document.createElement("a");
            enlace.href = url;
            enlace.setAttribute("download", "data.json");
            document.body.appendChild(enlace);
            enlace.click();
            enlace.remove();
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
        }
    };

    const DownloadSrt = async (row: DataItem) => {
        const fileJson = row.result.find((f: ResultItem) => f.type === "srt");
        if (!fileJson) {
            return;
        }
        try {
            const respuesta = await axios.get(fileJson.url, {
                responseType: "blob",
            });
            const url = window.URL.createObjectURL(new Blob([respuesta.data]));
            const enlace = document.createElement("a");
            enlace.href = url;
            enlace.setAttribute("download", "subtitles.srt");
            document.body.appendChild(enlace);
            enlace.click();
            enlace.remove();
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
        }
    };

    const ReproccessVideo = async (row: any) => {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ file_id: row._id, model: "large", replace: true }, `/transcripcion`, "POST", true);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
        }
    };

    function showAlert(row: any) {
        alerConfirmation({
            handleAction: ReproccessVideo,
            title: `${t("reprocess")}`,
            content: `${t("would you want convert to audio")} ${row.name}`,
            buttonOkMsg: `${t("common.accept")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    const columnsDef = [
        { orderable: false, targets: [4] },
        { className: "text-center", targets: [0, 1, 2, 3] },
    ];
    const headers: any[] = ["Nombre", "Tipo de tarea", "Estatus", "Última Actualización", "Acciones"];
    const columns = [
        { data: "name" },
        { data: "task" },
        {
            data: "status",
            render: (d: string) => (
                <Badge color={getColor(d)} soft>
                    {d}
                </Badge>
            ),
        },
        { data: "taskLastUpdate", format: "datetime" as "datetime" },
        {
            data: null,
            render: (row: DataItem) => (
                <div className={`d-flex justify-content-center`}>
                    <FontAwesomeIcon id="tooltipJSON" icon={faFileCode} size="xl" className="text-warning px-1 cursor-pointer" onClick={() => DownloadJson(row)} />
                    <FontAwesomeIcon id="tooltipSUB" icon={faClosedCaptioning} size="xl" className="text-danger px-1 cursor-pointer" onClick={() => DownloadSrt(row)} />
                    <ModalView row={row} />
                    <FontAwesomeIcon id="tooltipVconv" icon={faEye} size="xl" className="text-primary px-1 cursor-pointer" onClick={() => checkStatus({ row: row, t: t, funtionSuccess: setIsOpen })} />
                    <UncontrolledTooltip placement="top" target="tooltipJSON">
                        Descargar JSON
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="top" target="tooltipSUB">
                        Descargar Subtitulos
                    </UncontrolledTooltip>
                    <UncontrolledTooltip placement="top" target="tooltipVconv">
                        Reproducir video convertido
                    </UncontrolledTooltip>
                    {row.status === StausQueue.FAILURE && (
                        <>
                            <FontAwesomeIcon id="tooltipReporcess" icon={faRedo} size="xl" className="text-primary px-1 cursor-pointer" onClick={() => showAlert(row)} />{" "}
                            <UncontrolledTooltip placement="top" target="tooltipReporcess">
                                {t("reprocess")}{" "}
                            </UncontrolledTooltip>{" "}
                        </>
                    )}
                </div>
            ),
        },
    ];

    return (
        <CardContainer title="Transcodificador" tutorial="TRANSCRIPTION" defaultOpne={true}>
            <div className="mb-3">
                {isOpen ? (
                    <div className="my-2 mx-2 d-flex justify-content-md-between flex-column-reverse flex-md-row d-grid gap-2">
                        <Button color="danger" onClick={() => setIsOpen(null)}>
                            {" "}
                            Regresar{" "}
                        </Button>
                    </div>
                ) : (
                    <div className="d-flex justify-content-between">
                        <Button onClick={() => setRefresh(true)}>
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </Button>
                        <Button onClick={() => setIsOpenModalUpload(true)}>{t("Add")}</Button>
                    </div>
                )}
            </div>
            {isOpen ? <ViewTranscoder row={isOpen} /> : <Datatable headers={headers} petition={getData} control="back" columns={columns} columnDefs={columnsDef} stateRefresh={[refresh, setRefresh]} />}

            <SingleModalUpload open={isOpenModalUpload} setOpen={setIsOpenModalUpload} typeProcess={QueueWork.TRANSCRIPCION} setRefresh={setRefresh} />
        </CardContainer>
    );
}
