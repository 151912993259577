import Swal, { SweetAlertPosition } from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success fs-5 mb-2 m-md-0",
        cancelButton: "btn btn-danger fs-5 ",
        actions: "d-grid gap-2 d-flex flex-md-row flex-column-reverse justify-content-md-around mt-2",
        icon: "",
    },
    buttonsStyling: false,
});

export function alerConfirmation({ title, content, icon, handleAction, values, buttonOkMsg, buttonCancelMsg, hiddenCancel, hiddenOk, position }: propsAlertConfirmation) {
    swalWithBootstrapButtons
        .fire({
            position: position || "center",
            title: title,
            icon: icon || "question",
            text: content,
            showCancelButton: !hiddenCancel,
            showConfirmButton: !hiddenOk,
            confirmButtonText: buttonOkMsg || "Aceptar",
            cancelButtonText: buttonCancelMsg || "Cancelar",
            reverseButtons: true,
        })
        .then((result) => {
            if (result.isConfirmed && handleAction) {
                handleAction(values);
            }
        });
}

type propsAlertConfirmation = {
    title: string;
    content: string;
    icon?: "error" | "info" | "question" | "success" | "warning";
    handleAction?: Function;
    values?: any;
    buttonOkMsg?: string;
    buttonCancelMsg?: string;
    hiddenCancel?: boolean;
    hiddenOk?: boolean;
    position?: SweetAlertPosition;
};
