import "./assets/scss/themes.scss";

import Router from "./routes/Router";
import "./styles/App.css";
import { changeHTMLAttribute } from "./helpers/changeHTMLAttribute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react";

function App() {
    changeHTMLAttribute("data-sidebar", "dark");
    changeHTMLAttribute("data-topbar", "dark");
    return (
        <Fragment>
            <ToastContainer />
            <Router />
        </Fragment>
    );
}

export default App;
