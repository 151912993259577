import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/redux/store";
import ExploradorArchivos from "../../../pages/explorador-archivos/Main"
import { user_type } from "../../../core/enums/enums";
import AdminStarted from "./AdminStarted";

export const Started = ({ t }: { t: (text: string) => string }) => {
    const userType = useSelector((state: RootState) => state.auth.userInformation?.user_type);
    return (
        <div>
                {userType === user_type.ROOT || userType === user_type.ADMINISTRATOR ? <AdminStarted/>: <ExploradorArchivos/>}
        </div>
    );
};

export default withTranslation()(Started);
