export const generalEN = {
    "Unsupported-device": "Unsupported device.",
    titleE404: "SORRY, PAGE NOT FOUND",
    descE404: "The page you are looking for is not available!",
    myOnlineLawyer: "myOnlineLawyer",
    Welcome: "Welcome",
    "administrative site of": "Administrative site of",
    Day: "Day",
    Month: "Month",
    Year: "Year",
    "You are already close!": "You are already close!",
    PENDING: "PENDING",
    STARTED: "STARTED",
    RETRY: "RETRY",
    FAILURE: "FAILURE",
    SUCCESS: "SUCCESS",
    "Create your profile.": "Create your profile.",
    "Ask your legal advisor": "Ask your legal advisor",
    ChatBot: "ChatBot",
    Pending: "Pending",
    "Well Done!": "Well Done!",
    "Enter your data and discover how technology can revolutionize your legal practice": "Enter your data and discover how technology can revolutionize your legal practice",
    "A few steps away from simplifying your work and maximizing your efficiency": "A few steps away from simplifying your work and maximizing your efficiency",
    Finished: "Finished",
    ByStart: "By Start",
    InProcess: "In Process",
    commentsContact: "Enter comments",
    comments: "comments",
    filexp: "File",
    "Starting Process": "El procesamiento de datos ha comenzado",
    Processing: "Procesando los datos",
    preview: "Preview",
    reprocess: "Reprocess for free (free just is the status is FAILURE)",
    fileNotFound: "File not found",
    "File Processing Failure": "Error en el procesamiento del archivo",
    "Open doc": "Open Doc",
    "start chat": "start chat",
    "The price could not be consulted": "The price could not be consulted",
    "This processing has been free of charge by": "Este procesamiento ha sido gratis por parte de",
    "would you want convert to audio": "would you want convert to audio?",
    "Accept our terms and conditions": "Accept our terms and conditions",
    "You can see the progress in the file explorer": "You can see the progress in the file explorer",
    modules: {
        title: "Modules",
        module: "Module",
        route: "Route",
        icon: "Icon",
        parent: "Parent",
        "User type": "User type",
        administrative: "Administrative",
        form: {
            "save-module": "Save Module",
            "edit-module": "Edit Module",
            "want-save": "Do you want to register this module?",
            "want-edit": "Do you want to edit this module?",
        },
    },
    common: {
        yes: "Yes",
        no: "No",
        cancel: "Cancel",
        accept: "Accept",
        save: "Save",
        delete: "Delete",
        edit: "Edit",
        create: "Create",
        Continue: "Continue",
    },
    permissions: {
        common: {
            crud: {
                All: "All",
                Create: "Create",
                Read: "Read",
                Update: "Update",
                Delete: "Delete",
            },
        },
    },
    usuarios: {
        common: {
            crud: {
                User: "Users",
            },
        },
    },
};
