import { withTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import { Card, CardBody, UncontrolledTooltip } from "reactstrap";
import Button from "../../components/buttons/Button";
import { ModalSign } from "./complements/ModalSign";
import peticionBack, { uploadFile } from "../../helpers/peticiones";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";
import { useNavigate, useParams } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import SignPDFViewer from "./complements/SignPDFViewer";
import { DragElement, handleDragStart } from "../../helpers/sign-pdf/canvas-handles";
import { useSelector } from "react-redux";
import { loginData } from "../../core/slices/auth/AuthSlicer";
import { alerConfirmation } from "../../helpers/alertConfirmation";
import { checkObjectsInCanvas } from "../../helpers/sign-pdf/pdf-canvas";
import { signPDF } from "../../helpers/sign-pdf/sign-pdf";
import toastMessage from "../../helpers/toastMessage";
import { PageType } from "../../helpers/sign-pdf/PageType";

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.mjs`;

function FirmarDocumento({ t }: { t: (v: string) => string }) {
    const [pdfData, setPdfData] = useState<any>(null);
    const [sign, setSign] = useState<string | null>(null);
    const userInfo = useSelector(loginData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!id) exit();
        dispatch(loading(EnumLoader.SOLID));
        GetSign();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function GetSign() {
        const res = await peticionBack({}, "/me/sign", "POST", false);
        if (res.sign) {
            setSign(res.sign);
        }
        dispatch(loaded());
    }

    const exit = () => {
        navigate(-1);
    };

    const handlePdfData = (data: any) => {
        setPdfData(data);
    };

    async function save(page_objects: PageType[]) {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const file = await signPDF([pdfData.url_pdf, sign || ""], page_objects, t);
        if (file) {
            const res = await uploadFile({
                method: "PUT",
                endpoint: `/files/${encodeURIComponent(encodeURIComponent(id as string))}`,
                okMessage: t("sign.Document Signed"),
                file,
            });
            if (res) exit();
        }
        dispatch(loaded());
    }

    function saveConfirm() {
        if (!pdfData) {
            toastMessage("error", t("sign.Document not found"));
            return;
        }
        const { total, page_objects } = checkObjectsInCanvas(pdfData.pages, pdfData.fabric_canvas_refs);
        if (total > 0) {
            alerConfirmation({
                title: t("sign.Sign Document"),
                content: t("sign.Are you sure you want to sign this document? Your document will be overwritten"),
                buttonOkMsg: t("common.save"),
                handleAction: () => save(page_objects),
            });
        } else {
            toastMessage("error", t("sign.No elements were added to the document"));
        }
    }

    return (
        <Fragment>
            <Card className="mb-0 border">
                <CardBody className="p-2">
                    <div className="d-flex flex-row justify-content-between pdf-sign-topbar">
                        <div className="start-section d-none d-md-block">
                            <Button color="danger" onClick={exit}>
                                <IconBtn icon="mdi mdi-exit-to-app mdi-rotate-180" text="Regresar" />
                            </Button>
                        </div>
                        <div className="center-section flex-grow-1">
                            <div className="d-flex flex-row justify-content-center gap-2 gap-md-3">
                                <ModalSign setSign={setSign} exist={!!sign} t={t}>
                                    <Button color="info" soft>
                                        <IconBtn icon="mdi mdi-signature-freehand align-bottom me-1" text={t("sign.New Firm")} />
                                    </Button>
                                </ModalSign>
                                {sign && (
                                    <Fragment>
                                        <img src={sign} className="sign-img" alt="sign-user" draggable id="sign-img" onDragStart={(e) => handleDragStart(e, DragElement.SIGN)} />
                                        <img src={sign} className="sign-img" alt="half-sign-user" draggable id="half-sign-img" onDragStart={(e) => handleDragStart(e, DragElement.HALF_SIGN)} />
                                        <UncontrolledTooltip placement="top-start" target="sign-img">
                                            {t("sign.Signature")}
                                        </UncontrolledTooltip>
                                        <UncontrolledTooltip placement="top-start" target="half-sign-img">
                                            {t("sign.Half Signature")}
                                        </UncontrolledTooltip>
                                    </Fragment>
                                )}
                                <Button color="warning" soft draggable onDragStart={(e) => handleDragStart(e, DragElement.INITIALS, userInfo.userInformation)}>
                                    <IconBtn icon="mdi mdi-signature-text" text={t("sign.Initials")} />
                                </Button>
                                <Button color="primary" soft draggable onDragStart={(e) => handleDragStart(e, DragElement.DATE)}>
                                    <IconBtn icon="mdi mdi-calendar-month" text={t("sign.Date")} />
                                </Button>
                            </div>
                        </div>
                        <div className="end-section">
                            <Button color="success" soft onClick={() => saveConfirm()}>
                                <IconBtn icon="mdi mdi-file-sign" text={t("sign.Sign Document")} />
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <SignPDFViewer fileId={encodeURIComponent(id as string)} handleData={handlePdfData} />
        </Fragment>
    );
}

function IconBtn({ icon, text }: { icon: string; text: string }) {
    return (
        <Fragment>
            <i className={icon + " align-middle me-0 me-md-1 fs-5"} />
            <p className="d-none d-md-inline">{text}</p>
        </Fragment>
    );
}

export default withTranslation()(FirmarDocumento);
