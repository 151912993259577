
export const adminReportesEN = {
    "amount": "Amount",
    "quantity": "quantity",
    "NoDate": "No date",
    "lastUpdateDate": "Last Update Date",
    "File date": "File date",
    "dateCreation": "Creation date",
    "storage report": "Storage report",
    "allFiles": "All Files",
    "Report preview": "Report preview",
    "filterReport": "Filter",
    "NofilterReport": "Clear Filter",
    "deleted": "Deleted"
};