import ReactApexChart from "react-apexcharts";
import { getChartColorsArrayD, getChartColorsArrayf, } from "./ChartsDynamicColor";

const SimplePie = ({ dataColors, data }: { dataColors: any, data: any }) => {
    var chartPieBasicColors = getChartColorsArrayD(dataColors);

    const quantities = data.map((item: any) => item.amount);
    const soluciones = data.map((item: any) => item.solucion);

    var options: any = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: soluciones,
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartPieBasicColors
    };

    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={quantities}
            options={options}
            type="pie"
            height={270}
        />
    )
}

const SimpleDonut = ({ dataColors, data }: { dataColors: any, data: any }) => {
    var chartDonutBasicColors = getChartColorsArrayf(dataColors);
    const quantities = data.map((item: any) => item.quantity);
    const soluciones = data.map((item: any) => item.solucion);
    var options : any = {
        chart: {
            height: 280,
            type: 'donut',
        },
        legend: {
            position: 'bottom'
        },
        labels: soluciones,
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartDonutBasicColors
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={quantities}
            options={options}
            type="donut"
            height={250}
        />

    )
}

export {SimplePie, SimpleDonut}