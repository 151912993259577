/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import ModalVideoShow from "./ModalVideoShow";
import ModalPDF from "./ModalPDF";
import peticionBack from "../../../../helpers/peticiones";
import ModalTransco from "../../../transcodificacion/ModalTransco";
import toastMessage from "../../../../helpers/toastMessage";
import ModalOCR from "../../../ocr/ModalOcr";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../../../core/types/TypeLoader";

export default function DetermineFileViewer({ t, idFile, setViewFile }: { t: (text: string) => string; idFile: any; setViewFile: any }) {
    const [isProcessed, setIsProcessed] = useState<boolean>(false);
    const [isProcessedP, setIsProcessedP] = useState<boolean>(false);
    const [isVideo, setIsVideo] = useState<boolean>(false);
    const [isPDF, setIsPDF] = useState<boolean>(false);
    const [isImage, setImage] = useState<boolean>(false);
    const [file, setFile] = useState<any>(undefined);
    const [dataINeed, setDataINeed] = useState<any>(undefined);
    const [dataINeedP, setDataINeedP] = useState<any>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        if (idFile) {
            getFile();
        }
    }, [idFile]);

    async function getFile() {
        dispatch(loading(EnumLoader.SUBTLE));
        const res = await peticionBack({}, `/files/${encodeURIComponent(idFile.id)}`, "GET", false);
        if (res) {
            const lastObject1 = res.queues[res.queues.length - 1];
            const regex = /^image\/(png|jpe?g)$/i;
            if (regex.test(res.mimetype)) {
                if (res.queues.length > 0) {
                    setDataINeedP({
                        name: res.name,
                        file_id: lastObject1._id || lastObject1.file_id,
                        status: lastObject1.status,
                        task: lastObject1.task,
                        task_id: lastObject1.task_id,
                        queue_id: lastObject1._id,
                        result: lastObject1.result,
                        url: res.url,
                        mimetype: res.mimetype,
                        tts_task: lastObject1.tts_task,
                    });
                    switch (lastObject1.status) {
                        case "SUCCESS":
                            setIsProcessedP(true);
                            break;
                        case "FAILURE":
                            toastMessage("warning", `${t("File processing failed")}`);
                            break;
                        case "PENDING":
                            toastMessage("warning", `${t("Processing")}`);
                            break;
                        case "STARTED":
                            toastMessage("warning", `${t("Starting process")}`);
                            break;
                        default:
                            toastMessage("warning", `${t("Process the file")}`);
                    }
                }
                setImage(true);
            }
            setFile(res);

            const lastObject = res.queues[res.queues.length - 1];
            switch (res.mimetype) {
                case "video/mp4":
                    if (res.queues.length > 0) {
                        setDataINeed({
                            name: res.name,
                            file_id: lastObject.file_id,
                            status: lastObject.status,
                            task: lastObject.task,
                            task_id: lastObject.task_id,
                            queue_id: lastObject._id,
                            result: lastObject.result,
                            url: res.url,
                            tts_task: lastObject.tts_task,
                        });
                        switch (lastObject.status) {
                            case "SUCCESS":
                                setIsProcessed(true);
                                break;
                            case "FAILURE":
                                toastMessage("warning", `${t("File processing failed")}`);
                                break;
                            case "PENDING":
                                toastMessage("warning", `${t("Processing")}`);
                                break;
                            case "STARTED":
                                toastMessage("warning", `${t("Starting process")}`);
                                break;
                            default:
                                toastMessage("warning", `${t("Process the file")}`);
                        }
                    }
                    setIsVideo(true);
                    break;
                case "application/pdf":
                    if (res.queues.length > 0) {
                        setDataINeedP({
                            name: res.name,
                            file_id: lastObject.file_id,
                            status: lastObject.status,
                            task: lastObject.task,
                            task_id: lastObject.task_id,
                            queue_id: lastObject._id,
                            result: lastObject.result,
                            url: res.url,
                            mimetype: res.mimetype,
                            tts_task: lastObject.tts_task,
                        });
                        switch (lastObject.status) {
                            case "SUCCESS":
                                setIsProcessedP(true);
                                break;
                            case "FAILURE":
                                toastMessage("warning", `${t("File processing failed")}`);
                                break;
                            case "PENDING":
                                toastMessage("warning", `${t("Processing")}`);
                                break;
                            case "STARTED":
                                toastMessage("warning", `${t("Starting process")}`);
                                break;
                            default:
                                toastMessage("warning", `${t("Process the file")}`);
                        }
                    }
                    setIsPDF(true);
                    break;
                default:
            }
        }
        dispatch(loaded());
    }
    return (
        <>
            {isVideo && <ModalVideoShow t={t} id={file._id} stateShow={[isVideo, setIsVideo]} file={file} setViewFile={setViewFile} isProcessed={isProcessed} setIsProcessed={setIsProcessed} />}
            {(isPDF || isImage) && <ModalPDF t={t} id={file._id} stateImage={[isImage, setImage]} stateShow={[isPDF, setIsPDF]} title={file.name} src={file.url} setViewFile={setViewFile} isProcessed={isProcessedP} setIsProcessed={setIsProcessedP} />}
            <ModalTransco t={t} open={isProcessed && isVideo} setIsProcessed={setIsProcessed} setIsVideo={setIsVideo} setViewFile={setViewFile} row={dataINeed} />
            <ModalOCR open={isProcessedP && (isPDF || isImage)} setIsProcessed={setIsProcessedP} setIsVideo={setIsVideo} setViewFile={setViewFile} row={dataINeedP} />
        </>
    );
}
