import {  withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { userAuth, userInformation } from "../../core/slices/auth/interface/userinterface";
import { user_type } from "../../core/enums/enums";
import NonUserView from "./Views/NonUserView";
import UserOrganization from "./Views/UserOrganization";
import AdminOrganization from "./Views/AdminOrganization";

export function Organization() {
    const userInfo = useSelector(({ auth }: { auth: userAuth }) => auth.userInformation) as userInformation;
    if (!userInfo.organization) {
        return <NonUserView />;
    } else if (userInfo.user_type === user_type.NORMAL_USER && userInfo.organization) {
        return <UserOrganization />;
    } else {
        return <AdminOrganization />;
    }
}

export default withTranslation()(Organization);
