import { withTranslation } from "react-i18next";
import ReportesMain from "../../components/pages/reportes/views/Main";
import { Reportes } from "../../core/enums/enums";

function AdminReportes({ t }: { t: (f: string) => string }) {
    const reportes = [
        { value: 10, name: Reportes.CLIENTES, label: t("Clients report") },
        { value: 11, name: Reportes.INGRESOS, label: t("Income report") },
    ];
    return (
        <>
            <ReportesMain t={t} reportes={reportes} />
        </>
    );
}
export default withTranslation()(AdminReportes);
