import { eliminarTildes } from "../formats";

export type ParagraphSplited = { text: string; match: boolean; start: number; end: number };

export function findWordInText(text: string, wordToSearch: string): Array<ParagraphSplited> {
    let textCleaned = eliminarTildes(text.toLowerCase());
    let wordToSearchCleaned = eliminarTildes(wordToSearch.toLowerCase());

    let hits: Array<ParagraphSplited> = [];

    let start: number = 0;
    while (start < textCleaned.length) {
        let index = textCleaned.indexOf(wordToSearchCleaned, start);

        if (index >= 0) {
            hits.push({
                text: text.substring(start, index),
                match: false,
                start: start,
                end: index,
            });
            hits.push({
                text: text.substring(index, index + wordToSearchCleaned.length),
                match: true,
                start: index,
                end: index + wordToSearchCleaned.length,
            });
            start = index + wordToSearchCleaned.length;
        } else {
            hits.push({
                text: text.substring(start, textCleaned.length),
                match: false,
                start: start,
                end: textCleaned.length,
            });
            start = textCleaned.length;
        }
    }
    return hits;
}
