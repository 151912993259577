import { ButtonGroup } from "reactstrap";
import Button from "../../../buttons/Button";

export const Paggination = ({
    goToPreviousPage,
    currentPage,
    content,
    lastPageToShow,
    firstPageToShow,
    goToPage,
    goToNextPage,
    totalPages,
}: {
    goToPreviousPage: any;
    currentPage: number;
    content: any;
    lastPageToShow: number;
    firstPageToShow: number;
    goToPage: any;
    goToNextPage: any;
    totalPages: any;
}) => {
    return (
        <div>
            <ButtonGroup>
                <Button color="primary" onClick={goToPreviousPage} disabled={currentPage === 0 || !content}>
                    {"<"}
                </Button>
                {Array.from({ length: lastPageToShow - firstPageToShow + 1 }, (_, i) => i + firstPageToShow).map((page, index) => (
                    <Button key={`PagginationOCR${index}`} color={page === currentPage ? "primary" : "dark"} onClick={() => goToPage(page)} className={page === currentPage ? "bg-primary text-white" : "bg-transparent text-dark"}>
                        {/* {page + 1} */}
                        {content[page].page}
                    </Button>
                ))}
                <Button color="primary" onClick={goToNextPage} disabled={currentPage === (totalPages ? totalPages - 1 : 0) || !content}>
                    {">"}
                </Button>
            </ButtonGroup>
        </div>
    );
};
