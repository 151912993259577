export const styles: any = {
    font: {
        title: { name: 'Arial', size: 14, bold: true },
        subtitle: { name: 'Arial', size: 12, bold: true },
        text: { name: 'Arial', size: 11 },
        tBold: { name: 'Arial', size: 11, bold: true },

    },
    alignment: {
        tStart: { horizontal: 'left', vertical: 'middle' },
        tCenter: { horizontal: 'center', vertical: 'middle' },
        tEnd: { horizontal: 'right', vertical: 'middle' },
        wrap: { wrapText: true }
    }, 
    border:{
        full: {
            top: { style:'thin' },
            left: { style:'thin' },
            bottom: { style:'thin' },
            right: { style:'thin' }
        },
        top: { style:'thin' },
        left: { style:'thin' },
        bottom: { style:'thin' },
        right:  {style:'thin '}
    },
    fill: {
        header: {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'d1e0e0'},
            bgColor: { argb: '000000' }
        }
    }
}