import React from "react";
import { Row, Col } from "reactstrap";

export const FooterView = () => {
    return (
        <Row>
            <Col lg={12}>
                <div className="text-center">
                    <p className="mb-0">
                        {new Date().getFullYear()} &copy; MIAbogado Suite by <i className="las la-chess-queen text-danger" />
                    </p>
                </div>
            </Col>
        </Row>
    );
};
