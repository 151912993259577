import Select, { StylesConfig } from "react-select";
import { SelectInputProps } from "./InputProps";
import { FormGroup, Label } from "reactstrap";

export default function InputSelect({ name, label, placeholder, value, options, onChange, onBlur, error, touched, required, readOnly, readonlyPlain, disabled, opValue, opLabel, className, clearable }: SelectInputProps) {
    const clase = (className || "") + " form-control p-0" + (!!error && touched ? " is-invalid" : "");
    function findSelected() {
        if (!value) {
            return value;
        }
        return options.find((o) => o[opValue || "value"] === value);
    }

    const getStyle: StylesConfig = {
        option: (base, { isFocused, isSelected }) => ({ ...base, backgroundColor: isSelected ? "var(--vz-primary-text-emphasis)" : isFocused ? "var(--vz-primary-border-subtle)" : undefined }),
        control: (base, { isFocused }) => ({
            ...base,
            backgroundColor: disabled ? "var(--vz-tertiary-bg)" : "var(--vz-input-bg-custom)",
            border: touched && error !== undefined ? "var(--vz-border-width) solid var(--vz-form-invalid-border-color)" : isFocused ? "var(--vz-border-width) solid var(--vz-primary-border-subtle)" : "var(--vz-border-width) solid var(--vz-input-border-custom)",
            boxShadow: "none",
            ":hover": {
                border: touched && error !== undefined ? "var(--vz-border-width) solid var(--vz-form-invalid-border-color)" : isFocused ? "var(--vz-border-width) solid var(--vz-primary-border-subtle)" : "var(--vz-border-width) solid var(--vz-input-border-custom)",
            },
        }),
    };

    return (
        <FormGroup>
            {label && (
                <Label for={name}>
                    {label} {required && <span className="text-danger">*</span>}
                </Label>
            )}
            <Select
                id={name}
                name={name}
                options={options}
                value={findSelected()}
                onChange={(data) => (onChange ? onChange({ name, value: data ? data[opValue || "value"] : null }) : undefined)}
                onBlur={onBlur}
                isClearable={clearable ? clearable : false}
                isDisabled={disabled || readOnly || readonlyPlain}
                placeholder={placeholder || ""}
                isSearchable
                aria-errormessage={error}
                aria-invalid={!!error && touched}
                getOptionValue={(d) => d[opValue || "value"]}
                getOptionLabel={(d) => d[opLabel || "label"]}
                classNames={{
                    indicatorSeparator: () => "d-none",
                    input: () => "p-0 m-0",
                    control: () => clase,
                }}
                styles={getStyle}
            />
            <div className="invalid-feedback d-block">{touched ? error : null}</div>
        </FormGroup>
    );
}
