export const clientesES = {
    clientes: {
        common: {
            Customer: "Cliente",
            Customers: "Clientes",
            "Last file": "Último expediente",
            "Trial stage": "Etapa del juicio",
            "Next appointment": "Próxima cita",
            Stages: "Etapas",
            Subject: "Materia",
            "Judgment type": "Tipo de juicio",
            Status: "Estado",
            "File number": "Número de expediente",
            "See file": "Ver Expediente",
            File: "Expediente",
            "There is no appointment": "No hay cita",
            "Does not have a file": "No cuenta con un expediente",
            "No stage": "Sin etapas",
            "this file": "este expediente",
            "There is already a registered client with this data": "Ya existe un cliente registrado con estos datos",
            "There is already a file registered with this data":"Ya existe un expediente registrado con estos datos"
        },
    },
};
