import { Fragment } from "react";
import { Card, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import { FormView } from "../../components/pages/validateInformation/views/FormView";
import { changeLayoutMode } from "../../core/slices/thunks";
import { useDispatch } from "react-redux";
import Header from "../../components/layout/protected/Header";
import { isUserInformationValidated } from "../../helpers/userValidations";
import Footer from "../../components/layout/protected/Footer";
import { useSelector } from "react-redux";
import { loginData } from "../../core/slices/auth/AuthSlicer";

export const MainValidate = ({ t }: { t: (text: string) => string }) => {
    const dispatch = useDispatch();
    const onChangeLayoutMode = (value: any) => {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };
    const userInformation = useSelector(loginData);
    return (
        <Fragment>
            <div>
                <div className="auth-page-content overflow-hidden pt-lg-5 mt-5">
                    <Container>
                        <Header headerClass={""} layoutModeType={"horizontal"} onChangeLayoutMode={onChangeLayoutMode} />
                        <Card className="mt-5 mb-5">
                            <FormView t={t} />
                        </Card>
                        <Footer disableChatbot={isUserInformationValidated(userInformation)} />
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default withTranslation()(MainValidate);
