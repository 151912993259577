import { useEffect, useState } from "react";
import { Button, Carousel, CarouselControl, CarouselItem, Modal, ModalFooter } from "reactstrap";
import peticionBack from "../../../helpers/peticiones";
import toastMessage from "../../../helpers/toastMessage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../../../styles/components/carousel/modalCarousel.scss";
import loadImg from "../../../../src/assets/images/load-img.webp";
import { useSelector } from "react-redux";
import { userAuth } from "../../../core/slices/auth/interface/userinterface";
import { modalState } from "../../../core/slices/auth/AuthSlicer";
import { user_type } from "../../../core/enums/enums";
import { useDispatch } from "react-redux";

export function CarrouselModal() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [imagenes, setImagenes] = useState<any>([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const userInfo = useSelector(({ auth }: { auth: userAuth }) => auth);
    const dispatch = useDispatch();

    async function getCarrousel() {
        const res = await peticionBack({}, "/banners", "GET", false);
        if (res) {
            setImagenes(res);
        } else {
            toastMessage("error", "Ha ocurrido un error al obtener las imagenes");
        }
    }

    useEffect(() => {
        getCarrousel();
        if (userInfo.carousel) {
            setModal(true);
        }
        setAnimating(false);
    }, [userInfo.carousel]);

    const slides = imagenes.map((item: any, i: number) => {
        return (
            <CarouselItem key={i}>
                <LazyLoadImage src={item.url} effect="blur" placeholderSrc={loadImg} />
            </CarouselItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;

        setActiveIndex(nextIndex);
    };

    if (!slides || slides.length === 0) {
        return <></>;
    }

    function changeModalState() {
        dispatch(modalState(false));
    }

    if (userInfo.userInformation?.user_type === user_type.ROOT || userInfo.userInformation?.user_type === user_type.ADMINISTRATOR) {
        return null;
    }

    return (
        <div className="justify-content-center">
            <Modal isOpen={modal} toggle={toggle} centered scrollable={false} keyboard onClosed={changeModalState}>
                <Carousel activeIndex={activeIndex} next={next} previous={previous} autoPlay={true} interval={3000} className="modalCarrousel" dark>
                    {slides}
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Carousel>
                <ModalFooter className="mt-3 d-flex justify-content-center">
                    <Button color="primary" onClick={toggle}>
                        Aceptar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
