import { Control, FabricImage, InteractiveFabricObject, FabricText, Transform, util } from "fabric";
import imgDelete from "../../assets/images/sign-pdf/delete.svg";
import { imageConfig } from "../../core/constants/sign-pdf/image-configs";
import { textConfig } from "../../core/constants/sign-pdf/text-configs";

const img = new Image();
img.src = imgDelete;

type DragPosition = { left: number; top: number };

async function createImageObject(url_sign: string, position: DragPosition, canvasRef: any, format?: (i: FabricImage) => void) {
    const image = await FabricImage.fromURL(url_sign, {}, { ...imageConfig, ...position });
    image.controls.deleteControl = controlDelete();
    if (format) format(image);
    canvasRef.add(image);
    canvasRef.setActiveObject(image);
    return image;
}

export async function addImage(url_sign: string, position: DragPosition, canvasRef?: any) {
    if (!canvasRef) return;
    await createImageObject(url_sign, position, canvasRef);
}

export async function addHalfImage(url_sign: string, position: DragPosition, canvasRef?: any) {
    if (!canvasRef) return;
    await createImageObject(url_sign, position, canvasRef, (img) => (img.width = img.width / 2));
}

export async function addText(text: string, position: DragPosition, canvasRef?: any) {
    if (!canvasRef) return;
    const initials = text;
    const textbox = new FabricText(initials, { ...textConfig(initials, position) });
    canvasRef.add(textbox);
    textbox.controls.deleteControl = controlDelete();
    canvasRef.setActiveObject(textbox);
    textbox.setControlsVisibility(controls);
}

export function controlDelete() {
    return new Control({
        x: 0.5,
        y: -0.5,
        offsetX: 16,
        offsetY: -16,
        cursorStyle: "pointer",
        mouseUpHandler: deleteObject,
        render: renderButtonDelete,
        actionName: "delete",
        cornerSize: 24,
        sizeX: 24,
        sizeY: 24,
    } as Partial<Control>);
}

export function deleteObject(_eventData: Event, transform: Transform) {
    const target = transform.target;
    const canvas = target.canvas;
    if (canvas) {
        canvas.remove(target);
        canvas.requestRenderAll();
    }
    return true;
}

export function renderButtonDelete(ctx: CanvasRenderingContext2D, left: number, top: number, _styleOverride: any, fabricObject: InteractiveFabricObject) {
    const size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
}

const controls = {
    mt: false,
    mb: false,
    ml: false,
    mr: false,
    tl: true,
    tr: true,
    bl: true,
    br: true,
};
