import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { ConfigTable, generaTabla } from './generateTable';
import header from './Header';

export default async function CustomerByStage(t: (text: string) => string, data: any[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("stage")}`, 7);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                {
                    data: "cliente.name",
                    title: `${t("user name")}`,
                    width: 20,
                },
                {
                    data: "cliente.lastname",
                    title: `${t("user lastname")}`,
                    width: 20,
                },
                {
                    data: "cliente.phone",
                    title: `${t("Phone")}`,
                    width: 25,
                },   
                {
                    data: "cliente.email",
                    title: `${t("Email")}`,
                    width: 25,
                },
                { data: "createdAt", title: `${t("date")}`, width: 20, align: "tCenter", format: "date" },
                {
                    data: "file_number",
                    title: `${t("file_number")}`,
                    width: 25,
                },
                {
                    data: "status",
                    title: `${t("state")}`,
                    width: 10,
                }, 
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("stage report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("stage report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 7);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
