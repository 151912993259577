import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { TranscoIterInter } from '../../types/reportesTypes';
import { ConfigTable, generaTabla } from './generateTable';
import header from './Header';

export default async function Image(t: (text: string) => string, data: TranscoIterInter[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("file uploded")}`, 5);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                {
                    data: "owner_name", 
                    title: `${t("user name")}`,
                    width: 25
                },
                {
                    data: "name", 
                    title: `${t("folder-name")}`,
                    width: 20
                },
                {
                    data: "size", 
                    title: `${t("size")}`,
                    width: 10
                },
                { data: "createdAt", title: `${t("date")}`, width: 20, align: "tCenter", format: "date" },
                { data: null, title:`${t("Cost")}`, width: 15, render: (data:any)=>{return (data.costo)? data.costo.value : `${t("NoData")}`}, align: "tCenter"},
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("image Report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("image Report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 5);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
