export const walletES = {
    Ingress: "Ingresos",
    Solution: "Solución",
    tool_use: "Uso de la solución",
    Expense: "Distribución de gastos por créditos",
    "Expense - interactions": "Distribución de gastos por cantidad de iteraciones",
    totalCreditos: "Créditos totales",
    "buy credits": "Comprar créditos",
    balance: "Balance",
    Clients: "Clientes",
    totalClients: "Total de Clientes",
    Wallet: "Billetera",
    Credits: "Créditos",
    "Enter a credits": "Introduce el número de créditos",
    "Successfully copied content": "Contenido copiado correctamente",
    Outstanding: "Pendiente de pago",
    "Rejected due to general error": "Rechazado por error general",
    "Rejected with validation to authorize": "Rechazado con validación para autorizar",
    "Rejected due to insufficient amount": "Rechazado por importe insuficiente",
    "Rejected due to invalid security code": "Rechazado por código de seguridad inválido",
    "Rejected due to expiration date issue": "Rechazado debido a un problema de fecha de vencimiento",
    "Add credits to your account": "Añade créditos a tu cuenta",
    "payment processed, your wallet will be updated when it is processed": "pago procesado , su cartera se actualizará cuando sea procesado",
    "Payment processed incorrectly please try again": "Pago procesado incorrectamente, intenta de nuevo",
    "available credit": "Crédito disponible",
    "last transaction": "Última transacción",
    credits: " Crédito(s)",
    use: " Uso(s)",
    NoWallet: "Sin cartera",
    NoData: "Sin datos",
    Cost: "Costo",
    "Your payment is processing": "Su pago se está procesando",
    "Your payment was not successful, please try again": "Su pago no fue exitoso, por favor intente nuevamente",
    "Something went wrong": "Algo salió mal",
    "An unexpected error occurred": "ocurrió un error inesperado",
    "Pay now": "Pagar",
    "A processing error occurred": "Se produjo un error de procesamiento.",
    "Creation Date": "Fecha de creación",
    "you can check the status in the wallet section": "Puedes checar el estatus en la sección de billetera",
    "The minimum recharge is $": "La recarga mínima es de $",
    "your wallet will be updated when the payment is processed": "su billetera se actualizará cuando se procese el pago",
    "reload page": "Refrescar pagina",
    "reload page message": "Recargar la pagina le permite ver reflejados sus creditos en la parte superior derecha de la pagina",
};
