
export const adminReportesES = {
    "amount": "Ingresos",
    "quantity": "Cantidad",
    "NoDate": "Sin fecha",
    "lastUpdateDate": "Última Fecha de Actualización",
    "File date": "Fecha de archivo",
    "dateCreation": "Fecha de creación",
    "storage report": "Reporte de almacenamiento",
    "allFiles": "Todos los archivos",
    "Report preview": "Vista previa del reporte",
    "filterReport": "Filtrar",
    "NofilterReport": "Limpiar filtro",
    "deleted": "Eliminados"
};