export const fileManagerEN = {
    "folder-name": "Folder Name",
    "Are-you-sure": "Are you sure?",
    "Are-you-sure-complete": "Are you sure you want to delete the file?",
    "Are-you-sure-folder": "Are you sure you want to delete the folder?",
    "yes-delete-it": "Yes, delete it",
    "Delete-folder": "Delete folder",
    "Rename-folder": "Rename folder",
    "Update-folder": "Update",
    "Upload-file": "Upload file",
    file: "File",
    close: "Close",
    "Your-browser-does-not-support-video-playback": "Your browser does not support video playback",
    "home-file-manager": "Home",
    "create-folder": "Create folder",
    Folders: "Folders",
    Videos: "Videos",
    interactions: "Interactions",
    Files: "Files",
    OCR: "OCR",
    Transco: "Transcoder",
    Images: "Images",
    totalFolders: "Total folders",
    "Can't copy to clipboard": "Can't copy to clipboard",
    "No matches found": "No matches found",
    Content: "Content",
    "Send to Transcoder": "Send to Transcoder",
    "Send to OCR": "Send to OCR",
    "Files Processed in transcoder": "Files processed in transcoder",
    model: "Model",
    status: "Status",
    date: "Date",
    size: "Size",
    "The data is being processed to test": "The data is being processed to test",
    "hide names": "hide names",
    "open text editor": "Open text editor",
    "You want to continue with the action": "You want to continue with the action?",
    "Process files": "Process file",
    "reprocess file": "Reprocess file?",
    "Reprocess file": "Reprocess file",
    "Reprocess file no names": "No names were found to test. Reprocess file?",
};
