import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import ViewSection from "../../../../pages/landing/components/ViewSection";
import { useTranslation } from "react-i18next";
import Button from "../../../buttons/Button";
import ModalView from "../../transcoder/components/ModalView";

export default function Solutions() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const productData = [
        {
            id: 1,
            img: `/images/gsentencias.png`,
            title: t("PUBLIC SENTENCE GENERATOR"),
            category: t("Through the use of AI, we help judiciaries convert their private sentences into public sentences under intelligent testing of their document."),
            price: `$20 MXN / 2 ${t("free")}`,
            url: { name: "video texto", url: "/videos/editor.mp4" },
        },
        {
            id: 2,
            img: "/images/videoatexto.png",
            title: t("VIDEO TO TEXT CONVERSION"),
            category: t("Agilely manipulate a hearing of 2 hours or more, transforming it into text and requesting an intelligent legal point of view with our AI assistant."),
            price: `$20 MXN / 2 ${t("free")}`,
            url: { name: "video texto", url: "/videos/TRANSCODIFICADOR.mp4" },
        },
        {
            id: 3,
            img: "/images/pdftotext.png",
            title: t("PDF/IMAGE TO TEXT CONVERSION"),
            category: t("Convert your promotions, demands or any other legal writing to text and rely on our AI legal assistant trained in Mexican laws."),
            price: `$20 MXN / 2 ${t("free")}`,
            url: { name: "video texto", url: "/videos/OCR.mp4" },
        },
        {
            id: 4,
            img: "/images/asistente.png",
            title: t("INTELLIGENT ASSISTANT"),
            category: t("Our chatbot is an AI assistant that allows you to connect our video to text and PDF/image to text conversion services to manipulate content and request legal advice or content summaries in a single click."),
            price: `$5 MXN / 2 ${t("free")}`,
            url: { name: "video texto", url: "/videos/CHATBOT.mp4" },
        },
        {
            id: 5,
            img: "/images/tts.png",
            title: t("CONVERSIÓN DE  TEXTO A AUDIO"),
            category: t("Being inclusive, our text-to-audio tool allows visually impaired people to listen to any plain text extracted from hearings or lawsuits in PDF, etc."),

            price: `$20 MXN / 2 ${t("free")}`,
            url: { name: "video texto", url: "/videos/TEXTTOSPECH.mp4" },
        },
        {
            id: 6,
            img: "/images/expediente electronico.png",
            title: t("EXPEDIENTE ELECTRONICO"),
            category: t("Lleva un expediente electrónico organizado y accesible, da de alta a tus clientes y lleva una agenda electrónica de cada una de tus siguientes audiencias; asi como también poder manipular las etapas en las que se) encuentra cada caso."),
            price: "$0 MXN",
            url: { name: "video texto", url: "/videos/EXPELE.mp4" },
        },
    ];

    return (
        <ViewSection id="solutions">
            <div className="text-center m-5 mb-0">
                <h1 className="display-6">Soluciones</h1>
            </div>
            <Row className="p-5 pb-0 pt-3">
                {productData.map((item, key) => (
                    <Col key={key} lg={4} className={"product-item "}>
                        <div
                            className="card explore-box card-animate"
                            style={{ height: "500px" }}
                            onClick={() => {
                                navigate("/login", { replace: true });
                            }}
                        >
                            <div className="bookmark-icon position-absolute top-0 end-0 p-2"></div>
                            <div className="explore-place-bid-img">
                                <img src={item.img} alt="" className="card-img-top explore-img" style={{ maxHeight: "200px" }} />
                                <div className="bg-overlay"></div>
                            </div>
                            <div className="card-body ">
                                <h5 className="mb-3 text-center">
                                    <Link to="/apps-nft-item-details">{item.title}</Link>
                                </h5>
                                <p className="text-muted mb-0 fs-5">{item.category}</p>
                            </div>
                            <div className="card-footer border-top border-top-dashed">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="flex-shrink-0 fs-14 text-primary mb-0">{item.price}</h5>
                                    <ModalView row={item.url as any} autoPlay>
                                        <Button color="primary">{t("preview")} </Button>
                                    </ModalView>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </ViewSection>
    );
}
