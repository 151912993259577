import { solutions } from "../core/enums/enums";
import peticionBack from "./peticiones";

export async function getCostSolution(solution: solutions) {
    const res = await peticionBack({ name: solution }, "/soluciones", "GET", false);
    if (res) {
        const infoSolution = res.data[0];

        const resCost = await peticionBack({}, `/soluciones/${encodeURIComponent(infoSolution.id)}`, "GET", false);
        if (solution === solutions.TRANSCODIFICADOR) {
            const largeInfo = resCost.costos.find((cost: any) => cost.name === "large");

            return largeInfo.value;
        } else {
            return resCost.costos[0].value;
        }
    }
}
