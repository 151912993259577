import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { TranscoIterInter } from '../../types/reportesTypes';
import { ConfigTable, generaTabla } from './generateTable';
import header from './Header';

export default async function TranscoIte(t: (text: string) => string, data: TranscoIterInter[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("Files Processed in transcoder")}`, 6);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                {
                    data: "file.name", 
                    title: `${t("file name")}`,
                    width: 50
                },
                { data: "task", title: `${t("model")}`, width: 20, align: "tCenter" },
                { data: "status", title: `${t("status")}`, width: 30 },
                { data: "createdAt", title: `${t("date")}`, width: 20, align: "tCenter", format: "date" },
                { data: "file.mimetype", title: `${t("file type")}`, width: 30, align: "tCenter" },
                { data: null, title:`${t("Cost")}`, width: 15, render: (data:any)=>{return (data.costo)? data.costo.value : `${t("NoData")}`}, align: "tCenter"},
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("Transcoding Report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("Transcoding Report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 6);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
