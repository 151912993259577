/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Collapse, Row } from "reactstrap";
import Button from "../../../buttons/Button";
import InputSelect from "../../../inputs/InputSelect";
import { solutions, thirdValueStatus } from "../../../../core/enums/enums";
import { OnChangeParams } from "../../../inputs/InputProps";
import InputDate from "../../../inputs/InputDate";
import InputCheck from "../../../inputs/InputCheck";
import { useEffect } from "react";

export function FormReporte({ t, petition, task, handleExcelReport }: { t: (text: string) => string; petition: Function; task: string; handleExcelReport: Function }) {
    const filterTranscoOcr = [
        { value: thirdValueStatus.PENDING, label: t("PENDING") },
        { value: thirdValueStatus.STARTED, label: t("STARTED") },
        { value: thirdValueStatus.RETRY, label: t("RETRY") },
        { value: thirdValueStatus.FAILURE, label: t("FAILURE") },
        { value: thirdValueStatus.SUCCESS, label: t("SUCCESS") },
    ];

    useEffect(() => {}, [task]);

    const formSchema = Yup.object().shape({
        sdate: Yup.date().test("date-range", t("sdate"), function (value) {
            const { edate } = this.parent;
            const today = new Date();
            if (!value) return true;
            const sdate = new Date(value);
            if (sdate > today) {
                throw new Yup.ValidationError(t("Start date cannot be greater than today"), value, "sdate");
            }
            if (!edate) return true;
            const endDate = new Date(edate);
            return sdate < endDate;
        }),

        edate: Yup.date().test("date-range", t("edate"), function (value) {
            const { sdate } = this.parent;
            const today = new Date();
            if (!value) return true;
            const endDate = new Date(value);
            if (endDate > today) {
                throw new Yup.ValidationError(t("End date cannot be greater than today"), value, "edate");
            }
            if (!sdate) return true;
            const startDate = new Date(sdate);
            return endDate > startDate;
        }),
    });

    const { handleSubmit, handleReset, handleBlur, setFieldValue, values, errors, touched } = useFormik({
        initialValues: { deleted: false, third: "", sdate: "", edate: "" },
        validationSchema: formSchema,
        onSubmit: (values) => {},
        onReset: () => {},
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }
    return (
        <>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                    <Col xs={12} md={6}>
                        <InputDate name="sdate" value={values.sdate} label={t("sdatelabel")} touched={touched.sdate} error={errors.sdate} onChange={onChange} onBlur={handleBlur} type="single" wTime={false} clereable />
                    </Col>
                    <Col xs={12} md={6}>
                        <InputDate name="edate" value={values.edate} label={t("edatelabel")} touched={touched.edate} error={errors.edate} onChange={onChange} onBlur={handleBlur} type="single" wTime={false} clereable />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <div className="mt-4 p-3 mb-2">
                            <InputCheck label={t("deleted")} type={"switch"} name={"deleted"} value={values.deleted} onChange={onChange} />
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <Collapse isOpen={task === solutions.TRANSCRIPCION || task === solutions.OCR}>
                            <InputSelect opLabel="label" name={"third"} label={t("filter")} options={filterTranscoOcr} opValue="value" onChange={onChange} value={values.third} error={errors.third} touched={touched.third} onBlur={handleBlur} clearable />
                        </Collapse>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="d-flex justify-content-around">
                            <Button
                                onClick={() => {
                                    handleReset(true);
                                    petition();
                                }}
                                color="info"
                                type="submit"
                                soft
                            >
                                <i className="mdi mdi-filter-off-outline" />
                                {` ${t("NofilterReport")}`}
                            </Button>
                            <Button
                                onClick={() => {
                                    petition(values.third, values.sdate, values.edate, values.deleted);
                                }}
                                color="primary"
                                type="submit"
                                soft
                            >
                                <i className="bx bx-filter-alt" />
                                {` ${t("filterReport")}`}
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="d-flex justify-content-end mt-4 ml-2 mb-3">
                        <Button
                            color="success"
                            onClick={() => {
                                handleExcelReport();
                            }}
                            soft
                        >
                            <i className="mdi mdi-microsoft-excel" style={{ fontSize: "1.2em" }}></i>
                            {t(" Excel")}
                        </Button>
                    </div>
                </Row>
            </form>
        </>
    );
}
