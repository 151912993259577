import { Fragment, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import { LoginView } from "../../components/pages/authentication/views/LoginView";
import { FooterView } from "../../components/pages/authentication/views/FooterView";
import { useSelector } from "react-redux";
import { loginData } from "../../core/slices/auth/AuthSlicer";
import { SingUpView } from "../../components/pages/authentication/views/SingUpView";
import { WaitView } from "../../components/pages/validateInformation/views/WaitView";
import SignUpInformation from "../../components/pages/authentication/types/SingUpInformation";
import { CarouselAuth } from "../../components/pages/authentication/components/CarouselAuthView";

function Main({ t }: { t: (f: string) => string }) {
    const loginInfo = useSelector(loginData);

    const [isLogin, setIsLogin] = useState<boolean>(true);
    const [signupInformation, setSignupInformation] = useState<SignUpInformation>(null);
    const [waitingEmailValidation, setwaitingEmailValidation] = useState(false);

    return (
        <Fragment>
            <div className="auth-page-wrapper auth-bg-cover d-flex justify-content-center align-items-center min-vh-100 ">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Card className="overflow-hidden">
                            <Row>
                                <Col xl={6} lg={6} md={6}>
                                    <CarouselAuth t={t} />
                                </Col>
                                <Col xl={6} lg={6} md={6}>
                                    {waitingEmailValidation ? (
                                        <WaitView t={t} loginInfo={loginInfo} signupInformation={signupInformation} />
                                    ) : (
                                        <Row className="g-0">
                                            {isLogin ? (
                                                <Row>
                                                    <Col>
                                                        <LoginView t={t} isLogin={isLogin} setIsLogin={setIsLogin} />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Col>
                                                    <SingUpView isLogin={isLogin} t={t} setIsLogin={setIsLogin} setwaitingEmailValidation={setwaitingEmailValidation} setSignupInformation={setSignupInformation} />
                                                </Col>
                                            )}
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <FooterView />
                    </Container>
                </footer>
            </div>
        </Fragment>
    );
}

export default withTranslation()(Main);
