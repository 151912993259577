import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//import images
import logoSm from "../../../assets/images/miAbogadoNewIcon.png";
import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";
import { changeSidebarVisibility } from "../../../core/slices/thunks";
import { createSelector } from "@reduxjs/toolkit";
import LightDark from "../../common/LightDark";
import ProfileDropdown from "./components/ProfileDropdown";
import BalanceHeader from "./components/BalanceHeader";
import { loginData } from "../../../core/slices/auth/AuthSlicer";
import { isUserInformationValidated } from "../../../helpers/userValidations";
import "../../../styles/components/layout/header.scss";
interface propsHeader {
    headerClass?: string;
    layoutModeType: string;
    onChangeLayoutMode: Function;
}

const Header = ({ layoutModeType, onChangeLayoutMode, headerClass }: propsHeader) => {
    const dispatch: any = useDispatch();
    const userInformation = useSelector(loginData);
    const selectDashboardData = createSelector(
        (state: any) => state.layout,
        ({ sidebarVisibilitytype }) => sidebarVisibilitytype
    );
    const sidebarVisibilitytype = useSelector(selectDashboardData);
    const show = !isUserInformationValidated(userInformation);

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
        dispatch(changeSidebarVisibility("hidden"));

        if (windowSize >= 767) humberIcon.classList.toggle("open");

        //For collapse vertical
        if (sidebarVisibilitytype === "hidden" && document.documentElement.getAttribute("data-layout") === "vertical") {
            if (windowSize >= 767) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.getAttribute("data-sidebar-size") === "sm" ? document.documentElement.setAttribute("data-sidebar-size", "") : document.documentElement.setAttribute("data-sidebar-size", "sm");
            } else if (windowSize >= 1025) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.getAttribute("data-sidebar-size") === "lg" ? document.documentElement.setAttribute("data-sidebar-size", "sm") : document.documentElement.setAttribute("data-sidebar-size", "lg");
            } else if (windowSize <= 767) {
                document.body.classList.add("vertical-sidebar-enable");
                document.documentElement.setAttribute("data-sidebar-size", "lg");
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
            document.body.classList.contains("twocolumn-panel") ? document.body.classList.remove("twocolumn-panel") : document.body.classList.add("twocolumn-panel");
        }
    };

    return (
        <Fragment>
            <header id={show ? "page-topbar" : ""} className={show ? "" : "headerMiPerfil"}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <button onClick={toogleMenuBtn} type="button" className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon" style={{ display: isUserInformationValidated(userInformation) ? "none" : "initial" }}>
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>

                            <div className="navbar-brand-box horizontal-logo d-block d-md-none">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            {!isUserInformationValidated(userInformation) && (
                                <>
                                    <BalanceHeader />
                                    <LightDark layoutMode={layoutModeType} onChangeLayoutMode={onChangeLayoutMode} />
                                </>
                            )}
                            <ProfileDropdown prfileComplete={isUserInformationValidated(userInformation)} />
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    );
};

export default Header;
