export const walletEN = {
    Ingress: "Income",
    Solution: "Solution",
    tool_use: "Solution Usage",
    Expense: "Expense Distribution by Credits",
    "Expense - interactions": "Expense Distribution by Number of Interactions",
    totalCreditos: "Total Credits",
    "buy credits": "Buy Credits",
    balance: "Balance",
    Clients: "Clients",
    "Creation Date": "Creation Date",
    totalClients: "Total Clients",
    Wallet: "Wallet",
    Credits: "Credits",
    "Enter a credits": "Enter the number of credits",
    "Successfully copied content": "Content copied successfully",
    Outstanding: "Outstanding",
    "Rejected due to general error": "Rejected due to general error",
    "Rejected with validation to authorize": "Rejected with validation to authorize",
    "Rejected due to insufficient amount": "Rejected due to insufficient amount",
    "Rejected due to invalid security code": "Rejected due to invalid security code",
    "Rejected due to expiration date issue": "Rejected due to expiration date issue",
    "Add credits to your account": "Add credits to your account",
    "payment processed, your wallet will be updated when it is processed": "payment processed, your wallet will be updated when it is processed",
    "Payment processed incorrectly please try again": "Payment processed incorrectly, please try again",
    "available credit": "Available credit",
    "last transaction": "Last transaction",
    credits: " Credit(s)",
    use: " Use(s)",
    NoWallet: "No Wallet",
    NoData: "No Data",
    Cost: "Cost",
    "An unexpected error occurred": "An unexpected error occurred",
    "A processing error occurred": "A processing error occurred",
    "you can check the status in the wallet section": "You can check the status in the wallet section",
    "The minimum recharge is $": "The minimum recharge is $",
    "your wallet will be updated when the payment is processed": "your wallet will be updated when the payment is processed",
    "reload page": "Reload page",
    "reload page message": "Reloading the page allows you to see your credits reflected in the upper right part of the page.",
};
