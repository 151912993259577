import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import FormOrganizacion from "../Components/FormOrganizacion";
import { DeleteOrg } from "../Components/CardDelete";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { userAuth, userInformation } from "../../../core/slices/auth/interface/userinterface";
import { useState } from "react";
import AddUserFormulario from "../Components/FormUsuarios";
import TablaUsers from "../Components/AdminTableUsers";

export default function AdminOrganization() {

    const {t} = useTranslation();
    const userInfo = useSelector(({ auth }: { auth: userAuth }) => auth.userInformation) as userInformation;
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [refresh, setRefresh] = useState<boolean>(false);

    
    return (
        <div>
            {/* CARD MY ORGANIZATION */}
            <Row>
                <Col lg="12">
                    <Card className="ribbon-box border shadow-none mb-lg-0" color="primary" outline>
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape mb-0" style={{ fontSize: "18px" }}>
                                {t("organization.My organization")}
                            </div>
                            <h1 className="fs-14 text-end">MiAbogadoSuite</h1>
                            <div style={{ display: "flex", margin: "20px" }}>
                                <div className="big-words" style={{ fontSize: "30px", marginRight: "20px" }}>
                                    {userInfo.organization?.name}
                                </div>
                            </div>
                            <div className="ribbon-content mt-4 text-muted">
                                <p className="mb-0 " style={{ fontSize: "18px" }}>
                                    {t("organization.This is your new organization, here you can administrate the basic information and features.")}
                                </p>
                            </div>
                        </CardBody>

                        {/* COLAPSE EDIT MY ORG */}

                        <Row className="justify-content-end">
                            <Col lg="2" md="4" xs="5" xl="3">
                                <Button color="warning" onClick={toggle} style={{ margin: "1rem" }}>
                                    {t("organization.Edit my organization")}
                                </Button>
                            </Col>
                            <Col lg="12">
                                <Collapse isOpen={isOpen}>
                                    <Row>
                                        <Col lg="6" md="6" xs="12">
                                            <FormOrganizacion selected={userInfo.organization}></FormOrganizacion>
                                        </Col>
                                        <Col lg="6" md="6" xs="12">
                                            <DeleteOrg></DeleteOrg>
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* CARD MY ORGANIZATION */}
            <Card>
                <CardBody>
                    <Col>
                        <AddUserFormulario setRefresh={setRefresh} ></AddUserFormulario>
                    </Col>
                    <Row>
                        <Col>
                            <TablaUsers refresh={[refresh, setRefresh]}></TablaUsers>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}
