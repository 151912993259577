import { Canvas } from "fabric";
import { addHalfImage, addImage, addText } from "./fabric-objects";
import { fDate } from "../formats";
import { userInformation } from "../../core/slices/auth/interface/userinterface";
import { getInitials } from "./strings";

export enum DragElement {
    SIGN = "SIGN",
    HALF_SIGN = "HALF-SIGN",
    INITIALS = "INITIALS",
    DATE = "DATE",
}

export function handlePrevent(event: Event) {
    event.preventDefault();
}

export function handleDragOver(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
        event.dataTransfer.dropEffect = "copy";
    }
    return false;
}

export const handleDrop: any = async (event: DragEvent, fcanvas: Canvas) => {
    event.preventDefault();
    const data = event.dataTransfer?.getData("text/plain");
    if (!data) return;
    const pos = { left: event.offsetX, top: event.offsetY };
    const { type, object } = JSON.parse(data);
    switch (type) {
        case DragElement.SIGN:
            await addImage(object, pos, fcanvas);
            break;
        case DragElement.HALF_SIGN:
            await addHalfImage(object, pos, fcanvas);
            break;
        case DragElement.INITIALS:
        case DragElement.DATE:
            await addText(object, pos, fcanvas);
            break;
        default:
            console.warn("Nothing to add");
    }
};

export const handleDragStart = (event: React.DragEvent<HTMLButtonElement | HTMLImageElement>, type: DragElement, user_info?: userInformation) => {
    let object = "";
    switch (type) {
        case DragElement.INITIALS:
            object = getInitials(`${user_info?.name} ${user_info?.lastname}`);
            break;
        case DragElement.DATE:
            object = fDate(new Date());
            break;
        default:
            object = event.dataTransfer?.getData("text/uri-list");
    }
    event.dataTransfer.setData("text/plain", JSON.stringify({ type, object }));
};
