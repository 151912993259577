import { Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputDragAndDrop from "../inputs/inputDragAndDrop";
import peticionBack from "../../helpers/peticiones";
import toastMessage from "../../helpers/toastMessage";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";
import { useDispatch } from "react-redux";
import { getData } from "../layout/protected/components/BalanceHeader";
import { loginData } from "../../core/slices/auth/AuthSlicer";
import { useSelector } from "react-redux";
import { QueueWork } from "../../core/enums/enums";

const defaultForm = {
    files: null,
};

export default function UploadFileView({ t, currentFolder, cancelFunction, refreshContent, typeProcess, accepts }: { t: (text: string) => string; currentFolder: string; refreshContent: Function; cancelFunction?: Function; typeProcess: QueueWork; accepts: string }) {
    const dispatch = useDispatch();
    const loginInformation = useSelector(loginData);
    const formSchema = Yup.object().shape({
        files: Yup.mixed()
            .test("fileSizeInMB", t("File size cannot be larger than 200 MB"), (values: any) => {
                const file = values.size;
                const fileSizeInMB = file / (1024 * 1024);
                return values && fileSizeInMB <= 200;
            })
            .required(t("No file selected")),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formSchema,
        onSubmit: () => {
            upload();
        },
        onReset: () => {},
    });

    async function sendTransco(file_id: string) {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ file_id: file_id, model: "large", replace: false }, `/transcripcion`, "POST", true);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
            getData(loginInformation, dispatch);
        }
    }
    async function sendOCR(file_id: string) {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ file_id: file_id, replace: false }, `/ocr`, "POST", true);

        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
            getData(loginInformation, dispatch);
        }
    }

    async function upload() {
        if (values.files && currentFolder) {
            dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
            const params = new FormData();
            const archivo = values.files as File;
            params.append("file", archivo, (values.files as File).name);
            params.append("folder_id", currentFolder as string);
            const res = await peticionBack(params, "/files/upload", "POST", true);
            if (res) {
                switch (typeProcess) {
                    case QueueWork.TRANSCRIPCION:
                        sendTransco(res._id);
                        break;
                    case QueueWork.OCR:
                        sendOCR(res._id);
                        break;
                    case QueueWork.TTS:
                        break;

                    default:
                        break;
                }

                refreshContent();
            }

            handleReset(null);
            dispatch(loaded());
        }
    }

    function onChange(target: { target: { name: string; value: any } }) {
        if (target.target.name === "files" && target.target.value) {
            const file = target.target.value.size;

            const fileSizeInMB = file / (1024 * 1024);
            if (fileSizeInMB <= 200) {
                setFieldValue(target.target.name, target.target.value || "", true);
            } else {
                setFieldValue(target.target.name, target.target.value || "", true);
            }
        } else {
            setFieldValue(target.target.name, target.target.value || "", true);
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                <InputDragAndDrop
                    name="files"
                    label={t("file")}
                    value={values.files}
                    onBlurMethod={handleBlur}
                    touched={touched.files}
                    errors={errors.files}
                    onChangeMethod={onChange}
                    listType="picture-text"
                    accepts={accepts}
                    // accepts="application/pdf,image/*,video/*,.doc,.docx"
                    renderThumbnail={() => <div className="bg-white border-end w-100 h-100"></div>}
                    required
                />
                <div className="hstack gap-3 justify-content-end">
                    <button type="reset" className="btn btn-ghost-success" onClick={() => cancelFunction && cancelFunction()}>
                        <i className="ri-close-line align-bottom"></i> {t("cancel")}
                    </button>
                    <button type="submit" className="btn btn-primary" id="addNewFile" disabled={currentFolder && currentFolder !== "" ? false : true}>
                        {t("Upload-file")}
                    </button>
                </div>
            </Form>
        </div>
    );
}
