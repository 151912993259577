import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// Import Images
import { Carrousel } from "../../../../pages/admin_user/Banner/Banner";

export const CarouselAuth = ({ t }: { t: (f: string) => string }) => {
    return (
        <div>
            <React.Fragment>
                <div className="p-lg-5 p-4 auth-one-bg h-100">
                    <div className="bg-overlay"></div>
                    <div className="position-relative h-100 d-flex flex-column">
                        <div className="d-flex justify-content-center align-content-center">
                            <h2 className="text-white-50">MIABOGADO EN LINEA</h2>
                        </div>
                        <div className="d-flex justify-content-center align-content-center">
                            <h2 className="text-white-50">{t("Trayendo la IA hacia ti.")}</h2>
                        </div>
                        <div></div>
                    </div>
                    <div>
                        <Carrousel />
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
};
