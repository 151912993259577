import { Card, CardBody, CardHeader } from "reactstrap";
import { TypeColor } from "../../core/types/TypeColor";
import { Helmet } from "react-helmet-async";
import { Fragment } from "react";
import BtnTutorial from "../buttons/ButtonTutorial";
import { tutorials } from "../../core/constants/tutorials";

type PropsCardContainer = {
    children: any;
    title?: string;
    className?: string;
    color?: TypeColor;
    border?: TypeColor;
    shadow?: "none" | "sm" | "lg";
    header?: string;
    t?: Function;
    tutorial?: "OCR" | "TRANSCRIPTION" | "DASHBOARD" | "WALLET" | "REPORTS" | "FILEMANAGER" | "SCHEDULE" | "CLIENTS" | "TTS";
    defaultOpne?: boolean;
};
export default function CardContainer({ children, title, className, color, border, shadow, header, t, tutorial, defaultOpne }: PropsCardContainer) {
    const fullclass = (className || "") + (color ? ` card-${color}` : "") + (border ? ` border card-border-${border}` : "") + (shadow ? ` sw-${shadow}` : "");
    return (
        <Fragment>
            {header && (
                <Helmet>
                    <title>{t ? t(header) : header} | MIAbogado Suite</title>
                </Helmet>
            )}
            <Card className={fullclass}>
                {title && (
                    <CardHeader tag="h2" className="">
                        <div className="d-flex flex-row justify-content-between">
                            {title}
                            {tutorial && <BtnTutorial url={tutorials[tutorial]} defaultOpne={defaultOpne} />}
                        </div>
                    </CardHeader>
                )}
                <CardBody className="card-body">{children}</CardBody>
            </Card>
        </Fragment>
    );
}
