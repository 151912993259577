
import { FormFeedback, FormGroup, FormText, Label } from "reactstrap";
import { NumberInputProps } from "./InputProps";
import CurrencyInput from "react-currency-input-field";


export default function InputNumber(
    { name, label, placeholder, value, onChange, onBlur, error, touched, required, readOnly, disabled, readonlyPlain, formText, className, bsSize, iconLeft, iconRight, max, min, maxLength, prefix, suffix, noSeparator, noDecimals, noNegatives }: NumberInputProps
){
    let inputClass = (className || "form-control" )+
        // (size ? ` form-control-${size}` : "") +
        (readonlyPlain ? " form-control-plaintext" : "") +
        ((iconLeft || iconRight) ?" form-control-icon" : "") + 
        (!!error && touched ? " is-invalid" : "");
    
    const iconsClass = "" + 
    ((iconLeft || iconRight) ? " form-icon" : "") +
    (iconRight ? " right" : "");

    return(
        <FormGroup>
            { label &&<Label for={name}>{label} {required && <span className="text-danger">*</span>}</Label> }
        
            <div className={iconsClass}>
                <CurrencyInput
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onValueChange={(value) => onChange ? onChange({ name, value }): undefined}
                    onBlur={onBlur}
                    readOnly={readOnly || readonlyPlain}
                    disabled={disabled}
                    className={inputClass}
                    spellCheck={false}
                    max={max}
                    min={min}
                    maxLength={maxLength || 17}
                    prefix={prefix}
                    suffix={suffix}
                    allowDecimals={!noDecimals}
                    allowNegativeValue={!noNegatives}
                    groupSeparator=","
                    decimalSeparator="."
                    decimalsLimit={2}
                    decimalScale={!noDecimals ? +value % 1 === 0? 0 : 2 : undefined}
                    disableGroupSeparators={noSeparator}
                    
                />
                { iconLeft && <i className={iconLeft} /> }
                { iconRight && <i className={iconRight} /> }
                <FormFeedback>{error}</FormFeedback>
            </div>
            {formText && ((!touched && error) || !error ) && <FormText>{formText}</FormText>}
        </FormGroup>
    )
}
