import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import { clear } from "../../../../core/slices/auth/AuthSlicer";
import { useNavigate } from "react-router-dom";
import profile_pic from "../../../../../src/assets/images/users/user-dummy-img.jpg";
import { loginData } from "../../../../core/slices/auth/AuthSlicer";
import { useTranslation } from "react-i18next";

const ProfileDropdown = ({ prfileComplete }: { prfileComplete: boolean }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInformation = useSelector(loginData);
    const { t } = useTranslation();

    function logOut() {
        dispatch(clear());
        navigate("/login", { replace: true });
    }

    function MiPerfil() {
        navigate("/usuarios/mi-perfil", { replace: true });
    }

    function Organization() {
        navigate("/user/organization", { replace: true });
    }

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={userInformation.userInformation?.profile_photo || profile_pic} alt="Header Avatar" />
                        <span className="text-start ms-xl-2"></span>
                        <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userInformation.userInformation?.name}</span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {/* <h6 className="dropdown-header">Welcome {userInformation.email || ""}!</h6> */}
                    {!prfileComplete && (
                        <>
                            {" "}
                            <DropdownItem onClick={() => MiPerfil()}>
                                <i className="bx bx-user text-muted fs-16 align-middle me-1"></i>{" "}
                                <span className="align-middle" data-key="t-logout">
                                    {t("My Profile")}
                                </span>
                            </DropdownItem>
                            <DropdownItem onClick={() => Organization()}>
                                <i className=" ri-building-4-line text-muted fs-16 align-middle me-1"></i>{" "}
                                <span className="align-middle" data-key="t-logout">
                                    {t("organization.Organization")}
                                </span>
                            </DropdownItem>
                        </>
                    )}
                    <DropdownItem onClick={() => logOut()}>
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                        <span className="align-middle" data-key="t-logout">
                            {t("Log out")}
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
