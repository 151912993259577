/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { CardBody, CardHeader, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import { CustomDataLabel } from "../components/MixedLine";

function ChartState({ t }: { t: (f: string) => string }) {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getStatus("STATUS");
    }, []);

    async function getStatus(mimetype: string) {
        setLoading(true);
        const res = await peticionBack({}, `/dashboard/clients/${mimetype}`, "GET", false);
        if (res) {
            setData(res.data);
        }
        setLoading(false);
    }
    return (
        <>
            <CardHeader>
                <div className="card-header border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">{t("state")}</h4>
                </div>
            </CardHeader>
            <CardBody>
                {loading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <Spinner color="primary" />
                    </div>
                )}
                <div className="top-50 start-50" style={{ zIndex: "1" }}>
                    <CustomDataLabel data={data} dataColors='["--vz-danger", "--vz-info", "--vz-warning"]' />
                </div>
            </CardBody>
        </>
    );
}

export default withTranslation()(ChartState);
