import { useTranslation, withTranslation } from "react-i18next";
import { Form, Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import InputBasic from "../../components/inputs/InputBasic";
import peticionBack from "../../helpers/peticiones";
import { OnChangeParams } from "../../components/inputs/InputProps";
import * as Yup from "yup";
import { useFormik } from "formik";
import { alerConfirmation } from "../../helpers/alertConfirmation";
import InputPhone from "../../components/inputs/InputPhoneNumber";
import Button from "../../components/buttons/Button";
import { Contact } from "./type";
import LandingFooter from "../../components/pages/landing/footer/Index";

function Contacto() {
    const { t } = useTranslation();
    const esMobile = window.innerWidth < 950;

    const navigate = useNavigate();
    const defaultForm: Contact = {
        name: "",
        p_name: "",
        m_name: "",
        mail: "",
        phone: "",
        country_code: "+52",
        comments: "",
    };

    const formValidations = Yup.object().shape({
        name: Yup.string().required(t("Enter your name")),
        p_name: Yup.string().required(t("Enter your last name")),
        m_name: Yup.string().required(t("Enter your last name")),
        mail: Yup.string().required(t("Enter an email")),
        phone: Yup.string().required(t("Enter your phone number")).min(8, t("Min 8 characters")),
        comments: Yup.string().required(t("commentsContact")),
    });

    const { handleSubmit, handleReset, handleBlur, setFieldValue, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async (values) => {
            alerConfirmation({
                handleAction: save,
                title: `${t("Save")}`,
                content: `${t("sendComments")}`,
                buttonOkMsg: `${t("Save")}`,
                buttonCancelMsg: `${t("Cancel")}`,
                values: values,
            });
        },

        onReset: () => {
            navigate("/", { replace: true });
        },
    });

    async function save() {
        delete values.country_code;
        const params = { ...values, phone: `${values.country_code || "+52"}${values.phone}` };
        const res = await peticionBack(params, `/contact-info`, "POST", true);
        if (res) navigate("/", { replace: true });
    }

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }
    return (
        <>
            <div>
                <div className="m-5">
                    <nav className={"navbar   fixed-top d-flex justify-content-start align-items-end p-3"} id="navbar">
                        <div>
                            <Link to="/index">
                                <Link to="/" className="btn btn-info" style={{ background: "#4452EE" }}>
                                    Regresar
                                </Link>
                            </Link>
                        </div>
                    </nav>
                </div>

                <div className={esMobile ? "p-3" : "p-5"}>
                    <Card>
                        <Row className="g-0">
                            <Col md={4}>
                                <h2 className={esMobile ? "m-2" : "m-5"}>{t("Share your opinion or comments with us")}</h2>

                                <div className={`${esMobile ? "mt-2" : "mt-5"} p-3`}>
                                    <p className={`text-muted ${esMobile ? "fs-6" : "fs-5"}`} style={{ textAlign: "justify" }}>
                                        {t(
                                            "In this section, we invite you to express your experience using MiAbogado en Línea. Our goal is to provide you with a set of intuitive and efficient tools that facilitate your daily work, optimize your processes and support you in achieving professional excellence. We value your feedback, as it allows us to continue improving and ensure that you are equipped with the best solutions to be the most outstanding lawyer in Mexico"
                                        )}
                                    </p>
                                </div>
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <Form onSubmit={handleSubmit} onReset={handleReset} className={`p-3 ${esMobile ? "" : "mt-5"}`}>
                                        <Row className="d-flex justify-content-around">
                                            <Col lg="4">
                                                <InputBasic name="name" type="text" value={values.name} label={t("Name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                                            </Col>
                                            <Col lg="4">
                                                <InputBasic name="p_name" type="text" value={values.p_name} label={`P. ${t("Last name")}`} touched={touched.p_name} error={errors.p_name} onChange={onChange} onBlur={handleBlur} required />
                                            </Col>
                                            <Col lg="4">
                                                <InputBasic name="m_name" type="text" value={values.m_name} label={`M. ${t("Last name")}`} touched={touched.m_name} error={errors.m_name} onChange={onChange} onBlur={handleBlur} required />
                                            </Col>
                                        </Row>

                                        <Row className="d-flex justify-content-around">
                                            <Col lg="5" style={{ zIndex: 8 }}>
                                                <InputPhone name="phone" nameCountryCode="country_code" value={values.phone} label={t("Phone")} touched={touched.phone} error={errors.phone} onChange={onChange} onBlur={handleBlur} valueCountry={values.country_code} required />
                                            </Col>
                                            <Col lg="5">
                                                <InputBasic name="mail" type="text" value={values.mail} label={t("Email")} touched={touched.mail} error={errors.mail} onChange={onChange} onBlur={handleBlur} required />
                                            </Col>
                                        </Row>

                                        <Row className="d-flex justify-content-around">
                                            <Col>
                                                <InputBasic name="comments" type="textarea" value={values.comments} label={t("comments")} touched={touched.comments} error={errors.comments} onChange={onChange} onBlur={handleBlur} required />
                                            </Col>
                                        </Row>
                                        <Row className="d-flex justify-content-between ">
                                            <Col xl="5" lg="4" className="d-flex justify-content-center my-2">
                                                <Button color="danger" type="reset">
                                                    {t("Cancel")}
                                                </Button>
                                            </Col>
                                            <Col xl="5" lg="4" className="d-flex justify-content-center my-1">
                                                <Button color="success" type="submit">
                                                    {t("Save")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
            <LandingFooter />
        </>
    );
}

export default withTranslation()(Contacto);
