import { Modal, ModalBody } from "reactstrap";
import "../../../../styles/components/videoStylesModal.css";
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginData } from "../../../../core/slices/auth/AuthSlicer";
import { useDispatch } from "react-redux";
import { getData } from "../../../layout/protected/components/BalanceHeader";
import { solutions } from "../../../../core/enums/enums";
import { loaded, loading } from "../../../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../../../core/types/TypeLoader";
import { getCostSolution } from "../../../../helpers/getCostsSolutions";
import toastMessage from "../../../../helpers/toastMessage";

export default function ModVideoShow({ t, id, file, stateShow, setViewFile, isProcessed, setIsProcessed }: { t: (text: string) => string; file: any; id: string; stateShow: Array<any>; setViewFile: any; isProcessed: boolean; setIsProcessed: any }) {
    const navigate = useNavigate();
    const loginInformation = useSelector(loginData);
    const dispatch = useDispatch();
    const [costSolution, setCostSolution] = useState<number | null>();

    useEffect(() => {
        getSolutionCost();
        // eslint-disable-next-line
    }, []);

    async function getSolutionCost() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const costSolution = await getCostSolution(solutions.TRANSCODIFICADOR);
        if (costSolution) {
            setCostSolution(costSolution);
        }
        dispatch(loaded());
    }

    const handleClosed = () => {
        setViewFile(false);
        stateShow[1](false);
        setIsProcessed(false);
    };

    async function sendTransco() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ file_id: id, model: "large", replace: false }, `/transcripcion`, "POST", true);
        setViewFile(false);
        stateShow[1](false);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
            getData(loginInformation, dispatch);
            navigate("/transcodificador", { replace: true });
        }
    }

    return (
        <>
            <Modal isOpen={stateShow[0] && !isProcessed} centered size="lg">
                <div className="mt-3 d-flex justify-content-end mx-3">
                    <FontAwesomeIcon
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        size="2x"
                        icon={faXmark}
                        onClick={() => {
                            handleClosed();
                        }}
                    />
                </div>
                <h5 className="mx-3">{file.name}</h5>
                <ModalBody>
                    <div className="video-container">
                        <video controls className="video">
                            <source src={file.url} type="video/mp4" />
                            {t("Your-browser-does-not-support-video-playback")}
                        </video>
                    </div>
                    <div className="m-3 d-flex justify-content-center">
                        {costSolution ? (
                            <button type="submit" className="btn w-sm btn-success " id="delete-record" onClick={sendTransco}>
                                <i className="ri-money-dollar-circle-line" style={{ fontSize: "1em" }} />
                                {`${costSolution} ${t("Send to Transcoder")}`}
                            </button>
                        ) : (
                            <h4>${t("The price could not be consulted")}</h4>
                        )}
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}
