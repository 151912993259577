import { getChartColorsArrayf } from "./ChartsDynamicColor";
import ReactApexChart from "react-apexcharts";

export const ChartVertical = ({ dataColors, data} : {dataColors:any; data:any}) => {
    var barchartCountriesColors = getChartColorsArrayf(dataColors);

    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total
    }));

    const series = [{
        data: parsedData,
        name: 'Total',
    }];
    var options : any = {
        chart: {
            type: 'bar',
            height: 436,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        colors: barchartCountriesColors,
        dataLabels: {
            enabled: true,
            offsetX: 32,
            style: {
                fontSize: '12px',
                fontWeight: 400,
                colors: ['#adb5bd']
            }
        },

        legend: {
            show: false,
        },
        grid: {
            show: false,
        },
        xaxis: {
            type: 'category',
            categories: data.map((item: any) => item.label),
            labels: {
                show: true,
            }
        },
    };
    return (
        <div className="">
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="bar"
                className="apex-charts"
                height={350}
            />
        </div>
    );
};