import { withTranslation } from "react-i18next";
import MainView from "../../../components/pages/modulos-reportes-admin/views/MainView";
import { solutions } from "../../../core/enums/enums";

function ReportTransco({ t }: { t: (f: string) => string }) {
    const columns = [{ data: "file.name" }, { data: "size" },{ data: "file.mimetype" }, { data: "status" }, { data: "task" }, { data: "costo.value" }, { data: "createdAt", format: "date" }, { data: "userName"}];
    const headers = [`${t("file name")}`, `${t("size")}`, `${t("file type")}`, `${t("state")}`, `${t("model")}`, `${t("Cost")}`, `${t("date")}`, `${t("user name")}`];
    return (
        <>
            <MainView t={t} task={solutions.TRANSCRIPCION} columns={columns} headers={headers} />
        </>
    );
}
export default withTranslation()(ReportTransco);
