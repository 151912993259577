import { useLocation } from "react-router-dom";
import peticionBack from "../../helpers/peticiones";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess, clear } from "../../core/slices/auth/AuthSlicer";
import toastMessage from "../../helpers/toastMessage";
import { useEffect } from "react";

export const Confirm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    async function validateEmail() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.size === 0) {
            acessFromEmaelConfirmation();
        }
        if (urlParams.size === 1) {
            toastMessage("warning", "Error muchas sesiones abiertas");
            navigate("/login", { replace: true });
        } else if (urlParams.size === 3) {
            accessFromServices(urlParams);
        } else {
            toastMessage("warning", "Error encontrado con las claves");
            navigate("/login", { replace: true });
        }
    }

    function accessFromServices(urlParams: any) {
        const accessToken = urlParams.get("access_token");
        const refreshToken = urlParams.get("refresh_token");
        const email = urlParams.get("email");
        dispatch(
            loginSuccess({
                accessToken: accessToken,
                refreshToken: refreshToken,
                email: email,
            })
        );

        navigate("/explorador", { replace: true });
    }

    async function acessFromEmaelConfirmation() {
        const code = getCodeFromUrl();
        const res = await peticionBack({}, "/auth/confirm", "POST", true, { Authorization: `bearer ${code}` }, true);

        if (res.data && !res?.error) {
            dispatch(clear());
            dispatch(
                loginSuccess({
                    id: res?.data?.id,
                    accessToken: res?.data?.accessToken,
                    navigator_id: res?.data?.navigator_id,
                    refreshToken: res?.data?.refreshToken,
                    email: res?.data?.email,
                })
            );

            navigate("/explorador", { replace: true });
        } else {
            navigate("/login", { replace: true });
        }
    }

    useEffect(() => {
        validateEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getCodeFromUrl() {
        const urlParts = location.pathname.split("/");
        const codeIndex = urlParts.findIndex((part) => part === "confirm") + 1;
        return urlParts[codeIndex];
    }

    return (
        <div>
            {/* <Button
                onClick={() => {
                    validateEmail();
                }}
            >
                Validacion
            </Button> */}
        </div>
    );
};
