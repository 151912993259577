import { createSlice } from "@reduxjs/toolkit";
import { layoutModeTypes, layoutPositionTypes, layoutTypes, leftSidebarImageTypes, leftSidebarTypes, leftSidebarViewTypes, leftsidbarSizeTypes, preloaderTypes, sidebarVisibilitytypes, topbarThemeTypes } from "../../constants/layout";
import { RootState } from "../../redux/store";

export const initialState = {
    layoutType: document.documentElement.clientWidth >= 767 ? layoutTypes.TWOCOLUMN : layoutTypes.VERTICAL,
    leftSidebarType: leftSidebarTypes.DARK,
    layoutModeType: layoutModeTypes.LIGHTMODE,
    layoutPositionType: layoutPositionTypes.FIXED,
    topbarThemeType: topbarThemeTypes.DARK,
    leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
    leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
    leftSidebarImageType: leftSidebarImageTypes.NONE,
    preloader: preloaderTypes.DISABLE,
    sidebarVisibilitytype: sidebarVisibilitytypes.HIDDEN,
};

export const LayoutSlice = createSlice({
    name: "LayoutSlice",
    initialState,
    reducers: {
        changeLayoutAction(state: any, action: any) {
            state.layoutType = action.payload;
        },
        changeLayoutModeAction(state: any, action: any) {
            state.layoutModeType = action.payload;
        },
        changeSidebarThemeAction(state: any, action: any) {
            state.leftSidebarType = action.payload;
        },
        changeLayoutPositionAction(state: any, action: any) {
            state.layoutPositionType = action.payload;
        },
        changeTopbarThemeAction(state: any, action: any) {
            state.topbarThemeType = action.payload;
        },
        changeLeftsidebarSizeTypeAction(state: any, action: any) {
            state.leftsidbarSizeType = action.payload;
        },
        changeLeftsidebarViewTypeAction(state: any, action: any) {
            state.leftSidebarViewType = action.payload;
        },
        changeSidebarImageTypeAction(state: any, action: any) {
            state.leftSidebarImageType = action.payload;
        },
        changePreLoaderAction(state: any, action: any) {
            state.preloader = action.payload;
        },
        changeSidebarVisibilityAction(state: any, action: any) {
            state.sidebarVisibilitytype = action.payload;
        },
    },
});

export const { changeLayoutAction, changeLayoutModeAction, changeSidebarThemeAction, changeLayoutPositionAction, changeTopbarThemeAction, changeLeftsidebarSizeTypeAction, changeLeftsidebarViewTypeAction, changeSidebarImageTypeAction, changePreLoaderAction, changeSidebarVisibilityAction } =
    LayoutSlice.actions;

export default LayoutSlice.reducer;

export const LayoutData = (state: RootState) => state.layout;
