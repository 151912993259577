/**
 *  @author @luixeduard
 **/
export function validarCurp(CURP: string) {
    if (!CURP) {
        return false;
    }
    const curp = CURP.trim().replaceAll("\\s{2,}", " ").toUpperCase();
    if (curp.length === 18) {
        if (curpValida(curp)) {
            return true;
        } else {
            return false;
        }
    }
}

function curpValida(curp: string) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
        validado = curp.match(re);

    if (!validado)
        //Coincide con el formato general?
        return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17: string) {
        //Fuente https://consultas.curp.gob.mx/CurpSP/
        var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
            lngSuma = 0.0,
            lngDigito = 0.0;
        for (var i = 0; i < 17; i++) {
            lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
        }
        lngDigito = 10 - (lngSuma % 10);
        if (lngDigito === 10) return 0;
        return lngDigito;
    }
    const digVer = Number(validado[2]);
    if (digVer !== digitoVerificador(validado[1])) {
        return false;
    }
    return true; //Validado
}

export function esMayorDeEdad(curp: string): boolean {
    const regexCurp = /^([A-Z]{4})(\d{2})(\d{2})(\d{2})/;
    const match = curp.match(regexCurp);
    try {
        if (match) {
            let year = parseInt(match[2], 10);
            // Ajuste para el año dependiendo del siglo
            year += year < 100 ? 2000 : 1900;
            const month = parseInt(match[3], 10) - 1; // Los meses en JavaScript son 0-indexados
            const day = parseInt(match[4], 10);
            const birthdate = new Date(year, month, day);
            const today = new Date();
            const age = today.getFullYear() - birthdate.getFullYear();
            const m = today.getMonth() - birthdate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
                return age > 18;
            }
            return age >= 18;
        }
       
        return false;
    } catch (error) {
        throw new Error("CURP no válida");
    }  
}


export function validarRFC(RFC: string) {
    if (!RFC) {
        return false;
    }
    const rfc = RFC.trim().replaceAll("\\s{2,}", " ").toUpperCase();
    if (rfc.length > 11 && rfc.length < 14) {
        if (RFCValido(rfc)) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

function RFCValido(rfc: string, aceptarGenerico = true) {
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    const validado = rfc.match(re);
    if (!validado) {
        return false;
    }
    //Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop(),
        rfcSinDigito = validado.slice(1).join(""),
        len = rfcSinDigito.length,
        diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        indice = len + 1;
    let suma, digitoEsperado;
    suma = len === 12 ? 0 : 481; //Si es fisica o moral
    for (let i = 0; i < len; i++) {
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    }
    digitoEsperado = 11 - (suma % 11);
    if (digitoEsperado === 11) {
        digitoEsperado = 0;
    } else if (digitoEsperado === 10) {
        digitoEsperado = "A";
    }
    //El dígito verificador coincide con el esperado?
    // o es un RFC Genérico (ventas a público general)?
    if (digitoVerificador?.toString() !== digitoEsperado.toString() && (!aceptarGenerico || rfcSinDigito + digitoVerificador !== "XAXX010101000")) {
        return false;
    } else if (!aceptarGenerico && rfcSinDigito + digitoVerificador === "XEXX010101000") {
        return false;
    } else {
        return true;
    }
}
