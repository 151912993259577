import { SearchInputChats } from "../components/SearchInputChats";
import { TableChats } from "../components/TableChats";

export const ChatListView = ({ searchUsers, isLoading, setIsLoading, chats, userChatOpen }: { searchUsers: any; isLoading: boolean; setIsLoading: any; chats: Array<any>; userChatOpen: any }) => {
    return (
        <div>
            <SearchInputChats searchUsers={searchUsers} />

            <ul className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                <li className="nav-item">
                    <p className="nav-link">Conversaciones</p>
                </li>
            </ul>

            <TableChats isLoading={isLoading} setIsLoading={setIsLoading} chats={chats} userChatOpen={userChatOpen} />
        </div>
    );
};
