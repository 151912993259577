import { useEffect, useRef, useState } from "react";
import toastMessage from "../../helpers/toastMessage";
import peticionBack from "../../helpers/peticiones";
import "../../styles/components/editor/editor.scss";
import { useParams } from "react-router-dom";
import { ModalNames } from "../../components/pages/editor/ModalNames";
import { getData } from "../../components/layout/protected/components/BalanceHeader";
import { loginData } from "../../core/slices/auth/AuthSlicer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CardContainer from "../../components/cardContainer/CardContainer";
import { useTranslation } from "react-i18next";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";
import { thirdValueStatus } from "../../core/enums/enums";
import ModalCharge from "../../components/modals/modalCharge";
import { socket } from "../../soket";
interface nerTask {
    _id?: string;
    file_id?: FileNer;
    status?: string;
    task?: string;
    task_id?: string;
    result: string[];
    createdAt?: string;
    updatedAt?: string;
    __v?: number;
    actual?: string;
}
interface FileNer {
    _id: string;
    owner_id: string;
    folder_id: string;
    name: string;
    src: string;
    mimetype: string;
    size: number;
    shared: any[];
    createdAt: string;
    updatedAt: string;
    __v: number;
}
export default function Editor() {
    const { id } = useParams();
    const { t } = useTranslation();
    const [url, setUrl] = useState<string | undefined>();
    const loginInformation = useSelector(loginData);
    const dispatch = useDispatch();
    // const [names, setNames] = useState<string[]>([]);
    const [opened, setOpen] = useState(false);
    const refFrame = useRef<HTMLIFrameElement>(null);
    const refForm = useRef<HTMLFormElement>(null);
    const pathname = window.location.pathname;
    const segments = pathname.split("/");
    const lastSegment = segments.pop();
    const [statusNer, setStatusNer] = useState<nerTask>({ result: [] });
    const [modalChargeIn, setModalChargeIn] = useState<boolean>(false);

    useEffect(() => {
        getUrl();
        socket.on("status", (data: any) => {
            if (data.result) {
                setStatusNer({ ...statusNer, actual: data.actual, result: data.result });
                // markNames();
            } else {
                setStatusNer({ ...statusNer, actual: data.actual });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        markNames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusNer]);

    useEffect(() => {
        if (url) {
            submitForm();
            getNames(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    useEffect(() => {
        if (statusNer) {
            switch (statusNer.actual) {
                case thirdValueStatus.STARTED || thirdValueStatus.PENDING:
                    dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
                    break;
                case thirdValueStatus.SUCCESS:
                    toastMessage("success", t("SUCCESS"));
                    getData(loginInformation, dispatch);
                    if (statusNer.result) {
                        setStatusNer(statusNer);
                    }
                    if (statusNer.actual === thirdValueStatus.SUCCESS) {
                        dispatch(loaded());
                    }
                    break;
                case thirdValueStatus.FAILURE:
                    toastMessage("error", t("The file could not be processed"));
                    break;
                default:
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusNer]);

    async function getUrl() {
        dispatch(loading(EnumLoader.SUBTLE));
        if (id) {
            const encriptId = encodeURIComponent(id);
            const res = await peticionBack({ id: id }, `/files/wopi/${encriptId}`, "GET", false);
            if (res) {
                getData(loginInformation, dispatch);
                setUrl(res.url);
            }
        }
        dispatch(loaded());
    }

    /* proccess messages from editor */
    function receiveMessage(event: any) {
        if (!event || !event.data) return;
        let msg = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
        if (!msg) return;
        switch (msg.MessageId) {
            case "App_LoadingStatus":
                if (msg.Values && msg.Values.Status === "Document_Loaded") {
                    refFrame.current?.contentWindow?.postMessage(JSON.stringify({ MessageId: "Host_PostmessageReady" }), "*");
                    addButton();
                    if (statusNer && statusNer.result.length > 0) markNames();
                    else getNames();
                }
                break;
            case "Clicked_Button":
                if (msg.Values.Id === "TestarButton") {
                    saveDocument();
                    getNames();
                }
                if (msg.Values.Id === "TestarManualButton") {
                    testarManual();
                }
                break;

            default:
        }
    }

    /* listen messages from editor */
    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        return () => {
            window.removeEventListener("message", receiveMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, statusNer?.result]);

    async function getNames(isFirstLoad?: boolean) {
        if (!isFirstLoad) {
            if (statusNer && statusNer.result.length > 0) {
                setOpen(true);
                return;
            } else {
                //preguntar estatus
                setModalChargeIn(true);
            }
        }

        const res = await peticionBack({ file_id: id, replace: false }, "/ner", "POST", false);
        if (res) {
            switch (res.status) {
                case "STARTED":
                    if (!isFirstLoad) {
                        toastMessage("info", t("The data is being processed to test"));
                    }
                    break;
                case "SUCCESS":
                    setStatusNer(res);
                    break;
                case "PENDING":
                    if (!isFirstLoad) {
                        toastMessage("info", t("The data is being processed to test"));
                    }
                    break;
                case "error":
                    if (!isFirstLoad) {
                        toastMessage("error", t("The file could not be processed"));
                    }
                    break;
                default:
                    if (!isFirstLoad) {
                        toastMessage("warning", t("The data is being processed to test"));
                    }
            }
        }
    }

    function markNames() {
        if (statusNer && statusNer.result.length < 1) return;
        post({
            MessageId: "CallPythonScript",
            SendTime: Date.now(),
            ScriptFile: "TestarNamesTest.py",
            Function: "MarkNames",
            Values: { target_text: { type: "string", value: statusNer?.result.join("|") } },
        });
    }
    function testarManual() {
        post({
            MessageId: "CallPythonScript",
            SendTime: Date.now(),
            ScriptFile: "TestarNamesTest.py",
            Function: "TestarManual",
            Values: {},
        });
    }

    /* load editor */
    const submitForm = () => {
        if (refForm.current) {
            refForm.current.submit();
        }
    };

    /* send messages to editor */
    function post(msg: any) {
        if (refFrame.current !== null && refFrame.current.contentWindow !== null) {
            refFrame.current.contentWindow.postMessage(JSON.stringify(msg), "*");
        } else {
            console.error("No es posible enviar", msg.MessageId);
        }
    }

    function addButton() {
        post({
            MessageId: "Insert_Button",
            SendTime: Date.now(),
            Values: {
                id: "TestarButton",
                imgurl: document.location.origin + "/images/user-block3.svg",
                hint: "Testar",
                mobile: true,
                tablet: true,
                label: "Testar",
                insertBefore: "formatpaintbrush",
            },
        });
        post({
            MessageId: "Insert_Button",
            SendTime: Date.now(),
            Values: {
                id: "TestarManualButton",
                imgurl: document.location.origin + "/images/user-block4.svg",
                hint: "Testar seleccionado",
                mobile: true,
                tablet: true,
                label: "Testar seleccionado",
                insertBefore: "formatpaintbrush",
            },
        });
    }

    function testarNames(names: string[]) {
        if (names.length < 1) return;
        post({
            MessageId: "CallPythonScript",
            SendTime: Date.now(),
            ScriptFile: "TestarNamesTest.py",
            Function: "TestarNames",
            Values: { target_text: { type: "string", value: names.join("|") } },
        });
    }

    async function getUrlEditor() {
        // await saveDocument();
        if (socket) {
            socket.emit("join_room", lastSegment, (response: any) => {});
        } else {
            console.error("Socket no inicializado");
        }
        // socket.on("status", (data: any) => {
        //     if (data.result) {
        //         setStatusNer({ ...statusNer, actual: data.actual, result: data.result });
        //     } else {
        //         setStatusNer({ ...statusNer, actual: data.actual });
        //     }
        // });
        dispatch(loading(EnumLoader.SUBTLE));
        const res = await peticionBack({ file_id: lastSegment, replace: true }, "/ner", "POST", true);
        if (res) {
            switch (res.status) {
                case thirdValueStatus.STARTED:
                    toastMessage("info", t("The data is being processed to test"));
                    break;
                case thirdValueStatus.PENDING:
                    toastMessage("info", t("The data is being processed to test"));
                    break;
                case thirdValueStatus.FAILURE:
                    toastMessage("error", t("The file could not be processed"));
                    break;
                default:
            }
        }
        dispatch(loaded());
    }

    function saveDocument() {
        post({
            MessageId: "Action_Save",
            SendTime: Date.now(),
            DontSaveIfUnmodified: true,
            Notify: true,
        });
    }

    return (
        <div className="m-1">
            {url ? (
                <div className="d-flex flex-row justify-content-center ">
                    <form ref={refForm} action={url} encType="multipart/form-data" method="post" target="collabora-online-viewer" id="collabora-submit-form">
                        <input name="access_token" value="10" type="hidden" id="access-token" />
                        <input name="ui_defaults" value="UIMode=compact;TextRuler=false;" type="hidden" id="ui-defaults" />
                    </form>

                    <iframe ref={refFrame} title="Collabora Online Viewer" id="collabora-online-viewer" name="collabora-online-viewer" className="editor" />

                    <ModalNames state={[opened, setOpen]} testarNames={testarNames} getUrlEditor={getUrlEditor} statusNer={statusNer} />
                </div>
            ) : statusNer && statusNer.result ? (
                <CardContainer className="d-flex flex-Card justify-content-center my-5 ">Error al cargar el servicio</CardContainer>
            ) : (
                <></>
            )}
            <ModalCharge t={t} functionToExecut={getUrlEditor} message={t("Reprocess file no names")} modalChargeIn={modalChargeIn} setModalChargeIn={setModalChargeIn} iconRemix="ri-file-edit-line" />
        </div>
    );
}
