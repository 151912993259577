import * as fabric from "fabric";
import { objectConfig } from "./object-config";

export const imageConfig: fabric.ImageProps = {
    ...objectConfig(),
    minimumScaleTrigger: 0.5,
    lockScalingFlip: true,
    top: 100,
    left: 100,
} as fabric.ImageProps;
