import { expirationToken, loginSuccess } from "../../core/slices/auth/AuthSlicer";
import peticionBack from "../peticiones";
import ParseJwt from "../timer/parseJWT";
import { loading, loaded } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";

export async function renewToken(dispatch: any, refreshToken: string) {
    dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
    const token = { Authorization: `bearer ${refreshToken}` };

    const res = await peticionBack({}, "/auth/callback", "POST", false, token);
    if (res) {
        const getJWT = ParseJwt(res.accessToken);
        dispatch(
            loginSuccess({
                id: res.id,
                accessToken: res.accessToken,
                navigator_id: res.navigator_id,
                refreshToken: res.refreshToken,
            })
        );
        dispatch(
            expirationToken({
                exp: getJWT.exp,
                iat: getJWT.iat,
            })
        );
    }
    dispatch(loaded());
}
