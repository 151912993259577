import { useEffect, useState } from "react";
import Datatable from "../../../../datatable/Datatable";
import { convertirFormatoFecha } from "../../../../../helpers/formats";

export default function Video({t, data, openT }: { t: (text: string) => string; data: any ,openT: boolean }) {
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        setRefresh(true);
    }, [data]);

    /* TABLA */
    const columns = [
        { data: "owner_name"},
        { data: "name"},
        { data: "size"},
        { data: "createdAt", format: convertirFormatoFecha },
        { data: "costo.value" },
    ];

    const columnDefs = [
        { className: "text-center", targets: [1, 2, 3] },
    ];

    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
                <h4 className="">{t("video Report")}</h4>
            </div>
            <Datatable
                columns={columns}
                headers={[`${t("user name")}`,`${t("file name")}`,`${t("size")}`,`${t("date")}`,`${t("Cost")}`]}
                columnDefs={columnDefs}
                data={data}
                control="front"
                stateRefresh={[refresh, setRefresh]}
                className="p-0"
            />
        </>
    );
}