import { loginES } from "./traductions/es/login";
import { formsES } from "./traductions/es/forms";
import { fileManagerES } from "./traductions/es/fileManager";
import { generalES } from "./traductions/es/general";
import { dashboardEs } from "./traductions/es/dashboard";
import { walletES } from "./traductions/es/wallet";
import { organizationsES } from "./traductions/es/organizations";
import { clientesES } from "./traductions/es/clientes";
import { adminReportesES } from "./traductions/es/adminReportes";
import { bannerES } from "./traductions/es/banner";
import { tts } from "./traductions/es/tts";
import { sign } from "./traductions/es/sign";
import { landingEs } from "./traductions/es/landing";
import { privacidadEs } from "./traductions/es/privacidad";

export const traductionsES = {
    ...generalES,
    ...loginES,
    ...formsES,
    ...fileManagerES,
    ...dashboardEs,
    ...walletES,
    ...organizationsES,
    ...clientesES,
    ...adminReportesES,
    ...bannerES,
    ...landingEs,
    ...privacidadEs,
    tts,
    sign,
};
