import { useState } from "react";
import { FormFeedback, FormGroup, FormText, Input, Label, UncontrolledTooltip } from "reactstrap";
import { PasswordInputProps } from "./InputProps";
import { useTranslation } from "react-i18next";

export default function InputPassword({ name, label, placeholder, value, onChange, onBlur, error, touched, required, readOnly, disabled, readonlyPlain, icon, formText, className, bsSize, showPasswordMessageValidation = false }: PasswordInputProps) {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    let inputClass = (className || "") + (readonlyPlain ? " form-control-plaintext" : "") + (icon ? " form-control-icon" : "");

    const iconsClass = "" + (icon ? " form-icon" : "");

    return (
        <FormGroup className="password-input">
            {label && (
                <Label for={name}>
                    {label} {required && <span className="text-danger">*</span>}
                    {showPasswordMessageValidation && (
                        <>
                            <span>
                                <i className=" ri-question-line btn-tutorial" id="tutorial-shortcut" />
                            </span>
                            <UncontrolledTooltip placement="top-start" target="tutorial-shortcut">
                                {t("The password must have at least one uppercase letter, one special character, one number and be at least 6 characters")}
                            </UncontrolledTooltip>
                        </>
                    )}
                </Label>
            )}

            <div className={iconsClass}>
                <Input
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    type={show ? "text" : "password"}
                    value={value}
                    onChange={({ target }) => (onChange ? onChange(target) : undefined)}
                    onBlur={onBlur}
                    readOnly={readOnly}
                    disabled={disabled}
                    className={inputClass}
                    bsSize={bsSize}
                    invalid={!!error && touched}
                    spellCheck={false}
                />
                <div className="input-pass-icon">
                    <i className={"btn-pass c-pointer " + (show ? "ri-eye-off-line" : "ri-eye-line")} onClick={() => setShow(!show)} />
                </div>
                {icon && <i className={icon} />}
                <FormFeedback>{error}</FormFeedback>
            </div>
            {formText && ((!touched && error) || !error) && <FormText>{formText}</FormText>}
        </FormGroup>
    );
}
