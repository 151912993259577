export const loginES = {
    titleE404: "LO SENTIMOS, PÁGINA NO ENCONTRADA",
    descE404: "¡La página que buscas no está disponible!",
    "title-login": "Iniciar Sesión",
    "Welcome Back !": "¡Bienvenido de nuevo!",
    "Welcome to the application!": "¡Bienvenido a la aplicación!",
    "Sign in to continue to MIABOGADO EN LINEA.": "Inicie sesión para ingresar a MIABOGADO EN LINEA.",
    Email: "Correo electrónico",
    Password: "Contraseña",
    "Forgot password?": "¿Olvidaste tu contraseña?",
    "Remember me": "Recordarme",
    "Sign In": "Ingresar",
    "Sign In with": "Iniciar sesión con",
    Signup: "Registrarse",
    "Do you have an account?": "¿Ya tienes una cuenta?",
    "Don't have an account": "¿No tiene una cuenta?",
    "Give a new meaning to the organization": "Dale un nuevo sentido a la organización",
    "Bring your documents to life: our OCR system finds and converts text with astonishing accuracy": "Haz que tus documentos cobren vida: nuestro sistema OCR encuentra y convierte texto con precisión asombrosa",
    "Every word counts: With our video transcription, you can easily access key moments.": "Cada palabra cuenta: con nuestra transcripción de video, accede fácilmente a momentos clave.",
    "Bringing AI to you.": "Trayendo la IA hacia ti.",
    "solution file manager": "Administrador de archivos",
    "Close session": "Cerrar sesion",
    "Are you sure you want to close the session?": "¿Seguro que desea cerrar esta sesion?",
    "Open sessions": "Sesiones abiertas",
    "Forgot your password": "¿Olvidaste tu contraseña?",
    recover: "Recuperar contraseña",
    sendComments: "Enviar infomarción",
    sendMail: "Enviar Correo",
    resendMail: "Reenviar Correo",
    "password recovery": "Recuperación de contraseña",
    confirmPassword: "Confirmar contraseña",
    "Passwords do not match": "Las contraseñas no coinciden",
    "Please confirm your password": "Por favor confirma tu contraseña",
    "you haven't received an email": "¿No has recibido un correo electrónico?",
    "An email has been sent for password recovery. Please check your inbox": "Se ha enviado un correo electrónico para la recuperación de su contraseña. Por favor, verifique su bandeja de entrada.",
    "In this section, we invite you to express your experience using MiAbogado en Línea. Our goal is to provide you with a set of intuitive and efficient tools that facilitate your daily work, optimize your processes and support you in achieving professional excellence. We value your feedback, as it allows us to continue improving and ensure that you are equipped with the best solutions to be the most outstanding lawyer in Mexico":
        "En esta sección, te invitamos a expresar tu experiencia al utilizar MiAbogado en Línea. Nuestro objetivo es proporcionarte un conjunto de herramientas intuitivas y eficientes que faciliten tu trabajo diario, optimicen tus procesos y te apoyen en alcanzar la excelencia profesional. Valoramos tus comentarios, ya que nos permiten seguir mejorando y asegurarnos de que estás equipado con las mejores soluciones para ser el abogado más destacado de México.",
    "Share your opinion or comments with us": "Compártenos tu opinión o comentarios",
};
