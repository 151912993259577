import React from "react";

export const LoginSignUpMessage = ({ t, setIsLogin, isLogin, messageLink, messageLabel }: { t: (text: string) => string; setIsLogin: React.Dispatch<React.SetStateAction<boolean>>; isLogin: boolean; messageLink: string; messageLabel: string }) => {
    return (
        <div className=" text-center">
            <span className="mb-0">
                {t(messageLabel)}
                <p
                    className="fw-semibold text-primary text-decoration-underline ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setIsLogin(!isLogin);
                    }}
                >
                    {t(messageLink)}
                </p>
            </span>
        </div>
    );
};
