import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";
import { BasicInputProps } from "./InputProps";

export default function InputBasic({ name, type, label, placeholder, value, onChange, onBlur, error, touched, required, readOnly, disabled, readonlyPlain, iconLeft, iconRight, formText, className, bsSize, maxLength }: BasicInputProps) {
    let inputClass =
        (className || "") +
        // (size ? ` form-control-${size}` : "") +
        (readonlyPlain ? " form-control-plaintext" : "") +
        (iconLeft || iconRight ? " form-control-icon" : "") +
        (type === "color" ? " form-control-color w-100" : "");

    const iconsClass = "" + (iconLeft || iconRight ? " form-icon" : "") + (iconRight ? " right" : "");
    return (
        <FormGroup>
            {label && (
                <Label for={name}>
                    {label} {required && <span className="text-danger">*</span>}
                </Label>
            )}

            <div className={iconsClass}>
                <Input
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    type={(type as InputType) || ""}
                    value={value}
                    onChange={({ target }) => (onChange ? onChange(target) : undefined)}
                    onBlur={onBlur}
                    readOnly={readOnly || readonlyPlain}
                    disabled={disabled}
                    className={inputClass}
                    bsSize={bsSize}
                    invalid={!!error && touched}
                    spellCheck={false}
                    maxLength={maxLength}
                    style={{ maxHeight: type === "textarea" ? "100px" : "" }}
                />
                {iconLeft && <i className={iconLeft} />}
                {iconRight && <i className={iconRight} />}
                <FormFeedback>{error}</FormFeedback>
            </div>
            {formText && ((!touched && error) || !error) && <FormText>{formText}</FormText>}
        </FormGroup>
    );
}
