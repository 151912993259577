import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { traductionsES } from "./core/lang/es";
import { traductionsEN } from "./core/lang/en";

const resources = {
    es: { translation: traductionsES },
    en: { translation: traductionsEN },
};
i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "es",
        fallbackLng: "en",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
