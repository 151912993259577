import { Col, Form, Row } from "reactstrap";
import InputBasic from "../../../inputs/InputBasic";
import InputNumber from "../../../inputs/InputNumber";
import { FormForWalletProps } from "../types/Wallet";

export default function FormForWallet({ onChange, t, handleSubmit, handleReset, handleBlur, values, errors, touched }: FormForWalletProps) {
    return (
        <div>
            <div className="container-fluid py-4">
                <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                    <Row className="d-flex justify-content-around">
                        <Col xs={6}>
                            <InputBasic name="email" type="text" value={values.email} label={t("Enter an email")} onBlur={handleBlur} onChange={onChange} required touched={touched.email} error={errors.email} />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-around">
                        <Col xs={6}>
                            <InputNumber name="credits" value={values.credits} label={t("Credits")} onBlur={handleBlur} onChange={onChange} noDecimals noNegatives required iconLeft="las la-coins" touched={touched.credits} error={errors.credits} />
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}
