import { useEffect, useState } from "react";
import { FormPerfil } from "../components/FormPerfil";
import * as Yup from "yup";
import { useFormik } from "formik";
import { OnChangeParams } from "../../../inputs/InputProps";
import peticionBack from "../../../../helpers/peticiones";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginData, loginSuccess, fillUserInformation } from "../../../../core/slices/auth/AuthSlicer";
import { validarCurp, validarRFC } from "../../../../helpers/personalData";
import { changeLayout, changeSidebarVisibility } from "../../../../core/slices/thunks";
import { layoutTypes, sidebarVisibilitytypes } from "../../../../core/constants/layout";

export const FormView = ({ t }: { t: (text: string) => string }) => {
    const loginInformation = useSelector(loginData);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [carers, setCarers] = useState<[]>([]);

    useEffect(() => {
        getCarers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getCarers() {
        const token = { Authorization: `bearer ${loginInformation.accessToken}` };
        const res = await peticionBack({}, `/profesiones`, "GET", false, token);
        if (res) {
            setCarers(res.data);
        }
    }

    const defaultForm = {
        name: "",
        lastname: "",
        dateborn: "",
        phone: "",
        gender: "",
        country_code: "+52",
        curp: "",
        cp: "",
        rfc: "",
        career_id: "",
    };

    const formValidations = Yup.object().shape({
        name: Yup.string().required(t("Enter your name")),
        lastname: Yup.string().required(t("Enter your last name")),
        dateborn: Yup.date()
            .required(t("Choose your date born"))
            .test("is-18-or-older", t("Must be of legal age"), function (value) {
                const today = new Date();
                const birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                const m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                return age >= 18;
            }),

        gender: Yup.string().required(t("Choose your gender")),
        phone: Yup.string().required(t("Enter your phone")),
        curp: Yup.string().when("country_code", {
            is: "+52",
            then: () =>
                Yup.string()
                    .required(t("Enter your CURP"))
                    .test({
                        name: "valid_curp",
                        message: t("Invalid CURP"),
                        test: (value) => validarCurp(value),
                    }),
        }),
        cp: Yup.string().required(t("Enter your CP")).min(5, t("Min 5 digits")),
        rfc: Yup.string().when("country_code", {
            is: "+52",
            then: () =>
                Yup.string()
                    .required(t("Enter your RFC"))
                    .test({
                        name: "valid_curp",
                        message: t("Invalid RFC"),
                        test: (value) => validarRFC(value),
                    }),
        }),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,

        onSubmit: async () => {
            if (values.country_code.includes("+52")) {
                const res = await peticionBack(
                    {
                        name: values.name,
                        lastname: values.lastname,
                        date_born: new Date(values.dateborn),
                        phone: values.country_code + values.phone,
                        gender: values.gender,
                        country_code: values.country_code,
                        cp: values.cp,
                        curp: values.curp,
                        rfc: values.rfc,
                        career_id: values.career_id,
                    },
                    "/me",
                    "PUT",
                    true
                );

                if (res) {
                    dispatch(loginSuccess({ ...loginInformation, completed: true }));
                    dispatch(fillUserInformation(res.row));
                    dispatch(changeLayout(layoutTypes.VERTICAL));
                    dispatch(changeSidebarVisibility(sidebarVisibilitytypes.SHOW));
                    navigate("/explorador", { replace: true });
                }
            } else {
                const res = await peticionBack(
                    {
                        name: values.name,
                        lastname: values.lastname,
                        date_born: new Date(values.dateborn),
                        phone: values.country_code + values.phone,
                        gender: values.gender,
                        country_code: values.country_code,
                        cp: values.cp,

                        career_id: values.career_id,
                    },
                    "/me",
                    "PUT",
                    true
                );

                if (res) {
                    dispatch(loginSuccess({ ...loginInformation, completed: true }));
                    dispatch(fillUserInformation(res));
                    navigate("/explorador", { replace: true });
                }
            }

            setLoading(true);
        },
        enableReinitialize: true,
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }
    return (
        <div>
            <div className="text-center">
                <h1 className=" mt-2">{t("You are already close!")}</h1>
            </div>

            <div className="mt-4">
                <FormPerfil errors={errors} handleReset={handleReset} values={values} touched={touched} onChange={onChange} handleSubmit={handleSubmit} handleBlur={handleBlur} loading={loading} t={t} labelSendButton={t("Send")} careers={carers} />
            </div>
        </div>
    );
};
