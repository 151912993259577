import { Form } from "react-router-dom";
import InputBasic from "../../../inputs/InputBasic";
import InputDate from "../../../inputs/InputDate";
import InputSelect from "../../../inputs/InputSelect";
import { Button, Col, Row, Spinner } from "reactstrap";
import InputPhone from "../../../inputs/InputPhoneNumber";
import InputNumber from "../../../inputs/InputNumber";
import { Gender } from "../../../../core/enums/enums";

export const FormPerfil = ({ errors, handleReset, values, touched, onChange, handleSubmit, handleBlur, loading, t, labelSendButton, careers }: any) => {
    const generosArray = [
        { value: Gender.MASCULINO, label: t(Gender.MASCULINO) },
        { value: Gender.FEMENINO, label: t(Gender.FEMENINO) },
        { value: Gender.LGBT, label: t(Gender.LGBT) },
    ];

    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                <Row className="d-flex justify-content-around">
                    <Col lg="4">
                        <InputBasic name="name" type="text" value={values.name} label={t("Name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                    </Col>

                    <Col lg="4">
                        <InputBasic name="lastname" type="text" value={values.lastname} label={t("Last name")} touched={touched.lastname} error={errors.lastname} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                    <Col lg="4">
                        <InputSelect name="gender" options={generosArray} value={values.gender} label={t("Gender")} touched={touched["react-select-3-input"] || touched.gender} error={errors.gender} onChange={onChange} onBlur={handleBlur} required clearable />
                    </Col>
                    <Col lg="4">
                        <InputDate name="date_born" value={values.date_born} label={t("Date born")} touched={touched.date_born} error={errors.date_born} onChange={onChange} onBlur={handleBlur} required max={maxDate} />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around">
                    <Col lg="4">
                        <InputNumber name="cp" value={values.cp} label={t("CP")} touched={touched.cp} error={errors.cp} onChange={onChange} onBlur={handleBlur} maxLength={5} noSeparator={true} required />
                    </Col>
                    <Col lg="4">
                        <InputPhone name="phone" nameCountryCode="country_code" value={values.phone} label={t("Phone")} touched={touched.phone} error={errors.phone} onChange={onChange} onBlur={handleBlur} valueCountry={values.country_code} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                    <Col lg="4">
                        <InputBasic name="curp" type="text" value={values.curp} label={t("CURP")} touched={touched.curp} error={errors.curp} onChange={onChange} onBlur={handleBlur} maxLength={18} required={values.country_code === "+52"} />
                    </Col>

                    <Col lg="4">
                        <InputBasic name="rfc" type="text" value={values.rfc} label={t("RFC")} touched={touched.rfc} error={errors.rfc} onChange={onChange} onBlur={handleBlur} maxLength={13} required={values.country_code === "+52"} />
                    </Col>
                </Row>

                <Row className="d-flex  justify-content-around">
                    <Col lg="5">
                        <InputSelect name="career_id" options={careers} opValue="id" opLabel="name" value={values.career_id} label={t("Career")} touched={touched.career_id} error={errors.career_id} onChange={onChange} onBlur={handleBlur} required clearable />
                    </Col>
                </Row>

                <div className="d-flex justify-content-center my-5">
                    <Col lg="4">
                        <Button color="success" className="w-100 btn-load" type="submit">
                            <span className="">
                                {loading && (
                                    <Spinner size="sm" style={{ height: "16px", width: "16px" }}>
                                        Loading...
                                    </Spinner>
                                )}
                                <span className="ms-2">{t(labelSendButton)}</span>
                            </span>
                        </Button>
                    </Col>
                </div>
            </Form>
        </div>
    );
};
