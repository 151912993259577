import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { ConfigTable, generaTabla } from './generateTable';
import header from './Header';

export default async function UsersForAdmins(t: (text: string) => string, data: any[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("AllTimeUsers")}`, 5);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                { data: "createdAt", title: `${t("access date")}`, width: 15, align: "tCenter", format: "date"},
                { data: null, title:`${t("ClientName")}`, width: 25, render: (data:any)=>{return data.fullname? data.fullname :`${t("NoFullData")}`}},
                { data: null, title:`${t("Nationality")}`, width: 28, render: (data:any)=>{return data.country_code? data.country_code :`${t("NoData")}`}},
                { data: null, title:`${t("available credit")}`, width: 15, render: (data:any)=>{return data.wallet? data.wallet.value : `${t("NoWallet")}`}, align: "tCenter"},
                { data: null, title:`${t("last transaction")}`, width: 15, render: (data:any)=>{return (data.wallet && data.wallet.transacciones.length >0)? data.wallet.transacciones[0].value : `${t("NoData")}`}, align: "tCenter"},
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("Clients report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("Clients report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 5);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
