/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../../core/types/TypeLoader";
import toastMessage from "../../../helpers/toastMessage";
import { loadPdf } from "../../../helpers/sign-pdf/pdf-canvas";
import peticionBack from "../../../helpers/peticiones";
import SimpleBarReact from "simplebar-react";

export default function SignPDFViewer({ fileId, handleData }: { fileId: string; handleData: (d: any) => void }) {
    /* CANVAS */
    const fabric_canvas_refs = useRef<any[]>([]);
    const canvas_refs = useRef<any[]>([]);
    /* PDF */
    const [total_pages, set_total_pages] = useState<number>(0);

    const hasFetchedData = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!hasFetchedData.current) {
            dispatch(loading(EnumLoader.SOLID));
            getPDF();
        }
        return () => {
            if (fabric_canvas_refs.current) {
                fabric_canvas_refs.current.forEach((fcanvas) => {
                    if (fcanvas) {
                        fcanvas.dispose();
                    }
                });
            }
        };
    }, []);

    async function getPDF() {
        hasFetchedData.current = true;
        const res = await peticionBack({}, `/files/${encodeURIComponent(fileId)}`, "GET", false, null, false);
        if (res && res.url) {
            const pages = await loadPdf(res.url, canvas_refs, fabric_canvas_refs);
            handleData({ pages, fabric_canvas_refs, url_pdf: res.url });
            set_total_pages(pages);
        } else {
            toastMessage("error", "No fue posible cargar el archivo");
        }
        dispatch(loaded());
    }

    if (total_pages < 0) {
        return (
            <div className="text-center">
                <h4>No se puedo consultar el archivo</h4>
            </div>
        );
    }

    return (
        <section className="pdf-viewer-container">
            <SimpleBarReact className="pdf-viewer-body simplebar-track-secondary" autoHide={false}>
                <div className="pdf-page-container">
                    {Array.from({ length: total_pages }, (_, index) => (
                        <div className="pdf-page" key={`drag-page-miab-${index}`} id={`drag-page-miab-${index}`}>
                            <canvas ref={(el) => (canvas_refs.current[index] = el)} />
                        </div>
                    ))}
                </div>
            </SimpleBarReact>
        </section>
    );
}
