import { Link } from "react-router-dom";
import ViewSection from "../../../../pages/landing/components/ViewSection";
import { useTranslation } from "react-i18next";

export default function InvitationLanding() {
    const { t } = useTranslation();
    return (
        <ViewSection id="solutions">
            <div className="d-flex align-content-center justify-content-center mb-5 p-3" style={{ background: "#F0F0F0" }}>
                <div className="text-center">
                    <h1 className="mb-5 display-6">{t("What are you waiting for to use AI to your advantage?")}</h1>
                    <h3 className="mb-5 texts">
                        {t("Start your free trial at")} MIABOGADO EN LÍNEA {t("right now and discover how AI can help you be the best lawyer in Mexico")}
                    </h3>

                    <Link to="/login" className="btn btn-info poppings" style={{ background: "#4452EE" }}>
                        {t("Register")}
                        <i className="ri-arrow-right-line align-middle ms-1"></i>
                    </Link>
                </div>
            </div>
        </ViewSection>
    );
}
