import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

const RecursiveLinks = ({ item, t, onClick }: { item: any; t: any; onClick: any }) => {
    return (
        <React.Fragment>
            {/* Main Header */}
            {item["isHeader"] ? (
                <li className="menu-title">
                    <span data-key="t-menu">{t(item.label)}</span>
                </li>
            ) : item.subItems ? (
                <li className="nav-item">
                    <Link
                        onClick={(e: any) => {
                            onClick(e, item);
                        }}
                        className={`nav-link ${!item.parent_id && "menu-link"}`}
                        to={item.link && item.subItem ? item.link : "/#"}
                        data-bs-toggle="collapse"
                    >
                        <i className={item.icon}></i>
                        <span data-key="t-apps">{t(item.label)}</span>
                    </Link>

                    <Collapse className="menu-dropdown" isOpen={item.stateVariables} id="sidebarApps">
                        {/* LEVEL 1  */}
                        <ul className="nav nav-sm flex-column test">
                            {item.subItems.map((subItem: any) => (
                                <RecursiveLinks item={subItem} t={t} onClick={onClick} key={subItem.id} />
                            ))}
                        </ul>
                    </Collapse>
                </li>
            ) : (
                <li className="nav-item">
                    <Link className={`nav-link ${!item.parent_id && "menu-link"}`} to={item.link ? item.link : "/#"}>
                        <i className={item.icon}></i> <span>{t(item.label)}</span>
                    </Link>
                </li>
            )}
        </React.Fragment>
    );
};

export default RecursiveLinks;
