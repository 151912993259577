import { useState, useRef } from "react";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
import { DataItem } from "../../../../pages/transcodificador/dto/TranscoderDto";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ModalView({ row, children, autoPlay, defaultOpne }: { row: DataItem; children?: JSX.Element; autoPlay?: boolean; defaultOpne?: boolean }) {
    const [modalOpen, setModalOpen] = useState(defaultOpne ? defaultOpne : false);
    const videoRef = useRef(null);

    const toggleModal = (e: any) => {
        e.stopPropagation();
        setModalOpen(!modalOpen);
    };
    return (
        <div>
            {children ? (
                <div onClick={(e) => toggleModal(e)}>{children}</div>
            ) : (
                <>
                    <FontAwesomeIcon id="tooltipVideo" icon={faPlay} size="xl" className="text-info px-1 cursor-pointer" onClick={toggleModal} />
                    <UncontrolledTooltip placement="top" target="tooltipVideo">
                        Reproducir Video
                    </UncontrolledTooltip>
                </>
            )}
            <Modal isOpen={modalOpen} toggle={toggleModal} className="responsive-modal" centered>
                <ModalHeader toggle={toggleModal}>{row.name}</ModalHeader>
                <ModalBody>
                    <div className="m-3">
                        <div className="video-container">
                            <video ref={videoRef} className="video-player" controls controlsList="nodownload" crossOrigin="anonymous" id="video" autoPlay={autoPlay}>
                                <source src={row.url} type="video/mp4" />
                                {row.result && row.result[0] && <track src={row.result[0].url} kind="subtitles" label="Subtitulos" srcLang="es" />}
                                <p>Su navegador no admite el elemento de vídeo</p>
                            </video>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}
