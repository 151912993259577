import peticionBack from "../../../../helpers/peticiones";
import { useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import InputCheck from "../../../inputs/InputCheck";
import { ModalUsuarios } from "../components/modalUsuarios";
import { Usuario } from "../../../../pages/admin_user/Usuarios/dto/Usuario.dt";
import { PermisosModulo, Permission } from "../dto/perfil-modulo.dto";
import InputBasic from "../../../inputs/InputBasic";
import { useTranslation } from "react-i18next";

export const AdminPermissions = () => {
    const { t } = useTranslation();
    const [userPermissions, setUserPermissions] = useState<PermisosModulo[]>([]);
    const [selected, setSelected] = useState<Usuario>();
    type action = "all" | "c" | "r" | "u" | "d";

    useEffect(() => {
        if (selected) {
            getModulPerId(selected.id);
        }
    }, [selected]);

    async function getModulPerId(user_id: string) {
        const resP = await peticionBack({}, `${"/Permissions/"}${encodeURIComponent(user_id)}`, "GET", false);

        if (resP) {
            setUserPermissions(resP);
        }
    }

    async function onChange(value: boolean, idm: string, action: action, index: number) {
        let newPermisos = { module_id: idm, user_id: selected?.id as string, ...userPermissions[index].permission };
        if (action === "all") {
            newPermisos.c = !value;
            newPermisos.r = !value;
            newPermisos.u = !value;
            newPermisos.d = !value;
        } else {
            newPermisos[action] = value;
        }

        const res = await peticionBack(newPermisos, "/permissions", "POST", true);
        if (res) {
            const newData = [...userPermissions];
            newData[index] = {
                ...newData[index],
                permission: newPermisos,
            };
            setUserPermissions(newData);
        }
    }

    function itsAll(permisos: Permission) {
        return !Object.values(permisos).some((p) => p === false);
    }

    return (
        <div>
            {/* LISTA DE USUARIOS */}
            <Row className="justify-content-center">
                <Col lg="3" md="3" sm="3" className="align-items-center">
                    <ModalUsuarios setSelected={setSelected} />
                </Col>
                <Col lg="9" md="9" sm="9" className="text-center mb-3">
                    <InputBasic name={t("modules.modules")} value={`${selected?.name ? selected?.name : ""} ${selected?.lastname ? selected?.lastname : ""}`} disabled />
                </Col>
            </Row>

            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>{t("modules.module")}</th>
                        <th>{t("permissions.common.crud.All")}</th>
                        <th>{t("permissions.common.crud.Create")}</th>
                        <th>{t("permissions.common.crud.Read")}</th>
                        <th>{t("permissions.common.crud.Update")}</th>
                        <th>{t("permissions.common.crud.Delete")}</th>
                    </tr>
                </thead>
                <tbody>
                    {userPermissions.map((item: PermisosModulo, index: number) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>
                                <InputCheck type="switch" name="all" value={itsAll(item.permission)} onChange={(_x: any) => onChange(itsAll(item.permission), item.id, "all", index)} />
                            </td>
                            <td>
                                <InputCheck type="switch" name="c" value={item.permission.c} onChange={({ value }: any) => onChange(value, item.id, "c", index)} />
                            </td>
                            <td>
                                <InputCheck type="switch" name="r" value={item.permission.r} onChange={({ value }: any) => onChange(value, item.id, "r", index)} />
                            </td>
                            <td>
                                <InputCheck type="switch" name="u" value={item.permission.u} onChange={({ value }: any) => onChange(value, item.id, "u", index)} />
                            </td>
                            <td>
                                <InputCheck type="switch" name="d" value={item.permission.d} onChange={({ value }: any) => onChange(value, item.id, "d", index)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
