export const formsES = {
    Send: "Enviar",
    Actions: "Acciones",
    "Delete user": "Eliminar usuario",
    "Are you sure you want to delete": "Está seguro que desea eliminar",
    Edit: "Editar",
    "- Edit": "- Editar",
    "- Add": "- Agregar",
    Delete: "Eliminar",
    Cancel: "Cancelar",
    Save: "Guardar",
    ClientName: "Nombre del cliente",
    nationality: "Nacionalidad",
    cancel: "Cancelar",
    Search: "Buscar",
    Add: "Agregar",
    edit: "editar",
    save: "guardar",
    Name: "Nombre",
    Clean: "Limpiar",
    Date: "Fecha de naciemiento",
    Phone: "Teléfono",
    CURP: "CURP",
    "Last name": "Apellido",
    "Date born": "Fecha de nacimiento",
    Gender: "Género",
    Career: "Carrera",
    MALE: "MASCULINO",
    FEMALE: "FEMENINO",
    LGBT: "LGBT",
    UNDEFINED: "INDEFINIDO",
    Next: "Siguiente",
    Back: "Atrás",
    "Passwords must match": "Las contraseñas deben coincidir",
    NoFullData: "Registro sin terminar",
    "Country not found or not assigned": "País no encontrado o no asignado",
    "Do you want to": "¿Desea",
    "this user?": "este usuario?",
    "Enter an email": "Ingrese un correo",
    "Invalid email": "Correo inválido",
    "Enter a password": "Ingrese una contraseña",
    "Phone is not valid": "Teléfono inválido",
    "The password must have at least one uppercase letter, one special character, one number and be at least 6 characters": "La contraseña debe tener al menos una letra mayúscula, un carácter especial, un número y tener al menos 6 caracteres.",
    "Sign Up with": "Ingresar con",
    "Waiting for email confirmation": "Esperando la confirmación de correo",
    "In moments you will receive an email with a link to verify your email": "en momentos le llegará un correo con un link para verificar su correo electrónico",
    "Have you not received the email?": "¿No has recibido el correo electrónico?",
    "Send again": "Reenviar de nuevo",
    "Don't have an account?": "¿No tienes una cuenta?",
    "Repeat password": "Repita la contraseña",
    "must contain 10 digits": "debe contener 10 digitos",
    "Enter your name": "Escriba su nombre",
    "Enter your last name": "Escriba su apellido",
    "Choose your date born": "Escoja su fecha de nacimiento",
    "Enter your phone number": "Ingrese su número de teléfono",
    "Invalid phone": "Teléfono inválido",
    "Choose your gender": "Escoja su género",
    "Enter your CP": "Escriba su CP",
    "Max 5 digits": "Máximo 5 digitos",
    "CURP must be 18 characters": "El CURP debe tener 18 caracteres",
    "RFC must be 13 characters": "El RFC debe de tener 13 caracteres",
    ADMINISTRATOR: "ADMINISTRADOR",
    "ORGANIZATION ADMINISTRATOR": "ADMINISTRADOR DE ORGANIZACIÓN",
    "NORMAL USER": "USUARIO NORMAL",
    "Choose a role": "Escoja un rol",
    "Min 8 characters": "Mínimo 8 caracteres",
    "en-US": "es-MX",
    "credits have been credited to your account": "créditos han sido abonados a tu cuenta",
    "Payment method": "Método de pago",
    "Add credits": "Agregar créditos",
    "Select a month": "Selecciona un mes",
    "Select a year": "Selecciona un año",
    "The password must have at least one uppercase letter, one lowercase letter, one special character, one number and be at least 6 characters": "La contraseña debe tener al menos una letra mayúscula, una minúscula, un carácter especial, un número y tener al menos 6 caracteres",
    sdate: "La fecha de inicio debe ser anterior a la fecha de fin",
    edate: "La fecha de fin debe ser posterior a la fecha de inicio",
    "Start date cannot be greater than today": "La fecha de inicio no puede ser mayor que hoy.",
    "End date cannot be greater than today": "La fecha de fin no puede ser mayor que hoy.",
    sdatelabel: "Fecha inicio",
    edatelabel: "Fecha fin",
    filter: "Filtro",
    dateAssigned: "Fecha asignada",
    file_number: "número de archivo",
    stage: "Fase",
    state: "Estado",
    schedule: "Calendario",
    visit: "nombre de la cita",
    "user lastname": "Apellido de usuario",
    protection: "Amparo",
    Promotion: "Promoción",
    Waiting: "En espera",
    title: "Titulo",
    description: "Descripción",
    startDate: "Fecha de inicio",
    endDate: "Fecha de termino",
    startTime: "Hora de inicio",
    endTime: "Hora de termino",

    "form-validation": {
        common: {
            text: {
                requeired: "Ingrese un {{field}}",
                "min-length": "El {{field}} debe contener al menos {{minLength}} caracteres",
                "max-length": "El {{field}} debe contener menos de {{maxLength}} caracteres",
                invalid: "{{field}} no válido",
            },
            quantity: {
                requeired: "Ingrese una cantidad",
                "min-value": "El {{field}} debe ser igual o mayor que {{minValue}}",
                "max-value": "El {{field}} debe ser igual o menor que {{maxValue}}",
                integer: "El {{field}} debe ser un número entero",
                float: "El {{field}} debe ser un número decimal",
                positive: "El {{field}} debe ser un número positivo",
                "min-length": "El {{field}} debe contener al menos {{minLength}} dígitos",
                "max-length": "El {{field}} debe contener menos de {{maxLength}} dígitos",
                invalid: "Cantidad no válida",
            },
            phone: {
                requeired: "Ingrese un número telefónico",
                invalid: "Teléfono no válido",
            },
            date: {
                requeired: "Ingrese una fecha de {{field}}",
                "min-value": "La fecha de {{field}} debe ser igual o posterior a {{minValue}}",
                "max-value": "La fecha de {{field}} debe ser igual o anterior a {{maxValue}}",
                invalid: "Fecha no válida",
            },
        },
    },
};
