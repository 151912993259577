export const tts = {
    title: "Text to Audio Converter",
    "The browser cannot play this type of audio": "he browser cannot play this type of audio",
    table: {
        file: "file",
        status: "status",
        "Last Update": "Last Update",
        Play: "Play",
    },
    "Converting text to audio": "Converting text to audio",
    "It was not possible to convert the text to audio": "It was not possible to convert the text to audio",
    "Word processing": "Word processing",
    "Convert text to audio": "Convert text to audio",
};
