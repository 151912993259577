import { Form } from "react-router-dom";
import InputBasic from "../../../inputs/InputBasic";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import peticionBack from "../../../../helpers/peticiones";
import { OnChangeParams } from "../../../inputs/InputProps";
import { alerConfirmation } from "../../../../helpers/alertConfirmation";
import { useEffect, useState } from "react";
import { Expedientes, ID, Materias } from "../types/CatClientes";
import InputSelect from "../../../inputs/InputSelect";
import { status } from "../../../../core/enums/enums";

export default function FormExpediente({ setOpen, expediente, t, clientId }: { setOpen: any; expediente?: Expedientes; t: any; clientId?: string }) {
    const [materias, setMaterias] = useState<Materias[]>([]);

    const [juicios, setJuicios] = useState<any>({});

    useEffect(() => {
        if (expediente) {
            setValues(
                {
                    _id: expediente._id,
                    client_id: clientId || expediente.client_id,
                    file_number: expediente.file_number,
                    status: expediente.status,
                    judgment_type_id: (expediente.judgment_type_id as ID)._id,
                    materia: (expediente.judgment_type_id as ID).subject_id?._id as string,
                },
                true
            );
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getMaterias();
    }, []);

    async function getMaterias() {
        const res = await peticionBack({}, "/materias", "GET", false);
        if (res) setMaterias(res.data);
    }

    

    const statusArray = [
        { value: status.POR_INICIAR, label: t(status.POR_INICIAR) },
        { value: status.EN_PROCESO, label: t(status.EN_PROCESO) },
        { value: status.FINALIZADO, label: t(status.FINALIZADO) },
    ];
    //VALIDACIÓN FORM
    const defaultForm: Expedientes & { materia: string } = {
        client_id: "",
        file_number: "",
        status: "",
        judgment_type_id: "",
        materia: "",
    };

    const formValidations = Yup.object().shape({
        clientId: Yup.string(),
        status: Yup.string().required(t("Select status")),
        file_number: Yup.string().required(t("Enter your file number")),
        judgment_type_id: Yup.string().required(t("Select judgment type")),
        materia: Yup.string().required(t("Select subject")),
    });

    function onChange({ name, value }: OnChangeParams) {
        if(name==="materia") setFieldValue("judgment_type_id","")
        setFieldValue(name, value);
    }

    const { handleSubmit, handleReset, handleBlur, setFieldValue, setValues, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async (values) => {
            alerConfirmation({
                handleAction: save,
                title: `${expediente ? t("Edit") : t("Save")}`,
                content: `${t("¿Desea")} ${expediente ? t("edit") : t("save")} ${t("clientes.common.this file")}?`,
                buttonOkMsg: `${t("Save")}`,
                buttonCancelMsg: `${t("Cancel")}`,
                values: values,
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    async function save() {
        const method = expediente ? "PUT" : "POST";
        const rute = expediente ? `/${expediente._id}` : "";
        const params = expediente
            ? {
                  client_id: clientId || values.client_id,
                  file_number: values.file_number,
                  status: values.status,
                  judgment_type_id: values.judgment_type_id,
              }
            : {
                  client_id: clientId || values.client_id,
                  file_number: values.file_number,
                  status: values.status,
                  judgment_type_id: values.judgment_type_id,
              };

        const res = await peticionBack(params, `/expediente` + rute, method, t("clientes.common.There is already a file registered with this data"));
        if (res) setOpen(false);
    }

    useEffect(() => {
        if (values.materia) getTiposJuicios();
        // eslint-disable-next-line
    }, [values.materia]);

    async function getTiposJuicios() {
        if(!juicios[values.materia]){
        const res = await peticionBack({}, `/tipos-juicio?subject_id=${values.materia}`, "GET", false);
        if (res) setJuicios({...juicios,[values.materia]:res.data});
    }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                <Row className="d-flex justify-content-around">
                    <Col lg="5">
                        <InputBasic name="file_number" type="text" value={values.file_number} label={t("clientes.common.File number")} touched={touched.file_number} error={errors.file_number} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                    <Col lg="5">
                        <InputSelect name="status" options={statusArray} value={values.status} label={t("clientes.common.Status")} touched={touched.status} error={errors.status} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around">
                    <Col lg="5">
                        <InputSelect name="materia" options={materias} label={t("clientes.common.Subject")} opLabel="name" value={values.materia} opValue="_id" onChange={onChange} onBlur={handleBlur} error={errors.materia} touched={touched.materia} required />
                    </Col>
                    <Col lg="5">
                        <InputSelect
                            name="judgment_type_id"
                            opLabel="name"
                            options={juicios[values.materia]|| []} 
                            value={values.judgment_type_id as string}
                            label={t("clientes.common.Judgment type")}
                            opValue="_id"
                            touched={touched.judgment_type_id}
                            error={errors.judgment_type_id}
                            onChange={onChange}
                            onBlur={handleBlur}
                            required
                            disabled={!values.materia}
                        />
                    </Col>
                </Row>
                <Row className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
                    <Col xl="5" lg="4">
                        <Button className="mt-3" color="danger" type="reset">
                            {t("Cancel")}
                        </Button>
                    </Col>
                    <Col xl="5" lg="4" className="d-flex justify-content-end my-2">
                        <Button className="mt-2 mt-sm-3" color="success" type="submit">
                            {t("Save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
