import toastMessage from "./toastMessage";
import { thirdValueStatus } from "../core/enums/enums";

export function checkStatus({ row, t, funtionSuccess }: { row: any; t: (text: string) => string; funtionSuccess: Function }) {
    if (row) {
        switch (row.status) {
            case thirdValueStatus.STARTED:
                toastMessage("info", t("Starting process"));
                break;
            case thirdValueStatus.SUCCESS:
                funtionSuccess(row);
                break;
            case thirdValueStatus.PENDING:
                toastMessage("info", t("Processing"));
                break;
            case thirdValueStatus.FAILURE:
                toastMessage("error", t("File processing failed"));
                break;
            default:
                break;
        }
    }
}
