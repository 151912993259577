import { Worksheet, Cell } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { fCurrency, fDate, fDatetime, fPercent, getField } from "../../../../../helpers/formats";

export function generaTabla(data: any[], config: ConfigTable, sheet: Worksheet, sRow: number){
    config.columns.forEach((row: colConfig, ix: number)=>{
        const col = sheet.getCell(sRow, 1 + ix);
        col.value = row.title;
        col.alignment = sg.alignment.tCenter;
        col.fill = sg.fill.header;
        col.font = sg.font.tBold;
        col.border = sg.border.full;
    });
    data.forEach((rowD: any, ixR: number)=>{
        const row = sheet.getRow(sRow + ixR + 1);
        config.columns.forEach((rowC: colConfig, ixC: number)=>{
            const col = row.getCell(1 + ixC);
            col.font = sg.font.text;
            col.border = sg.border.full;
            if(rowC.align) col.alignment = sg.alignment[rowC.align];

            formatFields(col, rowD, rowC.data, rowC.format, rowC.render);
        });
    });

    config.columns.forEach((col: colConfig, ix) => {
        if(col.width){
            sheet.columns[ix].width = col.width
        }
    });

    return sRow + data.length;
}

function formatFields(col: Cell, data: any, field: string | string[] | null, format?: string, render?: any){
    let value = "";
    if(field){
        if(Array.isArray(field)){
            let cctFiled = "";
            field.forEach((d: string)=> cctFiled+= data[d]+" ");
            value = cctFiled;
        } else if(field.includes(".")){
            value = getField(field, data);
        }else{
            value = data[field]
        }
    }else{
       value = render(data);
    }
    
    col.value = formatter[format||"default"](value);
}

export interface ConfigTable {
    columns: colConfig[]
}

export interface colConfig {
    data: string | string[] | null,
    title: string,
    width?: number,
    format?: 'date' | 'datetime' | 'currency' | 'percent',
    align?: 'tStart' | 'tCenter' | 'tEnd'
    render?:Function;
}

const formatter: any = {
    date: (value: string) => fDate(value),
    datetime: (value: string) => fDatetime(value),
    currency: (value: string|number) => fCurrency(+value),
    percent: (value: string|number) => fPercent(value),
    default: (value: any) => value
}