import { useEffect, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import * as Yup from "yup";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { FormEvent } from "./FormEvent";
import { OnChangeParams } from "../../../inputs/InputProps";
import { Event } from "../../../../pages/Calendar/types/Calendar";
import peticionBack from "../../../../helpers/peticiones";
import { formCalendar } from "../../../../pages/Calendar/types/Calendar";

export const ModalNewEventCaldendar = ({ open, handleOnClose, addEvent, dayClicked, currentEvent, deleteEvent, updateEvent }: { open: boolean; handleOnClose: any; setEvents: any; events: any; addEvent: Function; dayClicked: any; currentEvent: Event | null; deleteEvent: any; updateEvent: any }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [files, setFiles] = useState([]);

    const defaultForm: formCalendar = {
        _id: "",
        title: "",
        client_id: "",
        file_id: "",
        start: "",
        end: "",
        startTime: "",
        endTime: "",
        description: "",
        allDay: false,
        id: "",
    };

    useEffect(() => {
        if (open) {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        getClients();
        if (currentEvent) {
            if (currentEvent.client_id && currentEvent.client_id !== "") {
                getExpedients(currentEvent.client_id);
            }

            let startDateTime0 = currentEvent.start;
            startDateTime0.setHours(0);
            startDateTime0.setMinutes(0);
            startDateTime0.setSeconds(0);

            let endDateTime0 = currentEvent.end;
            endDateTime0.setHours(0);
            endDateTime0.setMinutes(0);
            endDateTime0.setSeconds(0);

            setValues(
                {
                    _id: currentEvent._id,
                    title: currentEvent.title,
                    start: startDateTime0,
                    description: currentEvent.description,
                    end: endDateTime0,
                    allDay: currentEvent.allDay,
                    id: currentEvent.id,
                    client_id: currentEvent.client_id,
                    file_id: currentEvent.file_id,
                    startTime: currentEvent.start,
                    endTime: currentEvent.end,
                },
                true
            );
        } else {
            setFieldValue("start", dayClicked);
            setFieldValue("end", dayClicked);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClients = async () => {
        const res = await peticionBack({}, "/clientes", "GET", false);
        if (res && res.data) {
            setClients(
                res.data.map((client: any) => {
                    return { ...client, fullName: client.name + " " + client.lastname };
                })
            );
        }
    };
    const getExpedients = async (id: string) => {
        const res = await peticionBack({ client_id: id }, "/expediente", "GET", false);
        if (res) {
            setFiles(res);
        }
    };

    const formValidations = Yup.object().shape({
        title: Yup.string().required(t("form-validation.common.text.requeired", { field: t("title") })),
        description: Yup.string().required(t("form-validation.common.text.requeired", { field: t("decription") })),
        start: Yup.date().required(t("form-validation.common.text.requeired", { field: t("startDate") })),
        end: Yup.date().required(t("form-validation.common.text.requeired", { field: t("endDate") })),
        startTime: Yup.date().required(t("form-validation.common.text.requeired", { field: t("startTime") })),
        endTime: Yup.date().required(t("form-validation.common.text.requeired", { field: t("endTime") })),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue, resetForm, setValues } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,

        onSubmit: async () => {
            setLoading(true);

            if (values.id && values.id !== "") {
                updateEvent({
                    ...values,
                    start: values.start,
                    end: values.end,
                    startTime: values.startTime,
                    endTime: values.endTime,
                });
            } else {
                addEvent({
                    ...values,
                    start: values.start,
                    end: values.end,
                    startTime: values.startTime,
                    endTime: values.endTime,
                });
            }

            // resetForm();

            setLoading(false);
        },
        enableReinitialize: true,
    });

    function onChange({ name, value }: OnChangeParams) {
        if (name === "client_id") {
            getExpedients(value);
        }

        if (name === "start") {
            setFieldValue("end", "");
        }
        if (name === "startTime") {
            setFieldValue("endTime", "");
        }

        if (name === "start" || name === "end" || name === "endTime" || name === "startTime") {
            setFieldValue(name, value[0]);
        } else {
            setFieldValue(name, value);
        }
    }

    return (
        <div>
            <Modal isOpen={open} onClosed={handleOnClose} size="xl">
                <div className="mt-3 d-flex justify-content-end mx-3">
                    <FontAwesomeIcon
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        size="2x"
                        icon={faXmark}
                        onClick={() => {
                            resetForm();
                            handleOnClose();
                        }}
                    />
                </div>

                <Row>
                    <Col className="text-center">
                        <h1>{t("new event")}</h1>
                    </Col>
                </Row>

                <FormEvent errors={errors} handleReset={handleReset} values={values} touched={touched} onChange={onChange} handleSubmit={handleSubmit} handleBlur={handleBlur} loading={loading} t={t} handleOnClose={handleOnClose} deleteEvent={deleteEvent} clients={clients} files={files} />
            </Modal>
        </div>
    );
};
