import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Error404 from "../pages/errors/Error404";
import Main from "../pages/authentication/Main";
import Transco from "../pages/test/Transcripcion";
import MainValidate from "../pages/user/Main";
import { Confirm } from "../pages/authentication/Confirm";
import UserReportes from "../pages/reportes/Main";
import WalletMain from "../pages/wallet/Main";
import Ocr from "../pages/test/Ocr";
import MainUser from "../pages/admin_user/Usuarios/MainUser";
import MainClientes from "../pages/admin-cat-clientes/Main";
import Modulos from "../pages/admin_user/Modulos/Main";
import { Organization } from "../pages/admin_organizaciones/Main";
import { PerfilMain } from "../pages/perfil usuarios/Main";
import ReportTransco from "../pages/modulos-reportes-admin/transcodificador/Main";
import ReportOCR from "../pages/modulos-reportes-admin/ocr/Main";
import ReportChatbot from "../pages/modulos-reportes-admin/chatbot/Main";
import ReportAlmacenamiento from "../pages/modulos-reportes-admin/almacenamiento/Main";
import Permisos from "../pages/admin_user/Permisos/Main";
import Dashboard from "../pages/dashboard/Dashboard";
import AdminReportes from "../pages/admin-reportes/Main";
import MainStarted from "../pages/started/MainStarted";
import { Banner } from "../pages/admin_user/Banner/Main";
import Agenda from "../pages/Calendar/Agenda";
import ExploradorArchivos from "../pages/explorador-archivos/Main";
import Editor from "../pages/editor/Editor";
import TtsMain from "../pages/tts/Main";
import FirmarDocumento from "../pages/firmar-documento/Main";
import LandingPage from "../pages/landing/Index";
import Privacy from "../pages/privacidad/Index";
import FormRecoverPassword from "../components/pages/authentication/components/formRecoverPassword";
import SetNewPassword from "../components/pages/authentication/components/setNewPassword";
import Contacto from "../pages/contacto/Contacto";
import "../styles/components/privacy/privacy.css";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            {/* protected routes */}
            <Route element={<ProtectedRoutes />}>
                <Route path="/explorador" element={<MainStarted />} />
                <Route path="/editor/:id" element={<Editor />} />
                <Route path="/firmar/:id" element={<FirmarDocumento />} />
                <Route path="/transcodificador" element={<Transco />} />
                <Route path="/ocr" element={<Ocr />} />
                <Route path="/tts" element={<TtsMain />} />
                <Route path="/wallet" element={<WalletMain />} />
                <Route path="/agenda" element={<Agenda />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/clientes" element={<MainClientes />} />
                <Route path="banner" element={<Banner />} />
                <Route path="explorador-archivos" element={<ExploradorArchivos />} />
                <Route path="/usuarios/permisos" element={<Permisos />} />
                <Route path="/usuarios/reportes" element={<UserReportes />} />
                <Route path="/admin/reportes" element={<AdminReportes />} />
                <Route path="/reportes/transco" element={<ReportTransco />} />
                <Route path="/reportes/ocr" element={<ReportOCR />} />
                <Route path="/reportes/chatbot" element={<ReportChatbot />} />
                <Route path="user">
                    <Route path="validation" element={<MainValidate />} />
                    <Route path="organization" element={<Organization />} />
                </Route>
                <Route path="/reportes/almacenamiento" element={<ReportAlmacenamiento />} />
                <Route path="usuarios">
                    <Route path="administracion-usuarios" element={<MainUser />} />
                    <Route path="modulos" element={<Modulos />} />
                    <Route path="mi-perfil" element={<PerfilMain />} />
                    <Route path="permisions" element={<Permisos />} />
                </Route>
            </Route>
            {/* non protected routes */}

            <Route path="/" element={<LandingPage />} />
            <Route path="login" element={<Main />} />
            <Route path="auth/confirm/:token?" element={<Confirm />} />
            <Route path="validate" element={<MainValidate />} />
            <Route path="privacidad" element={<Privacy padding="responsive-padding" />} />
            <Route path="recoverPassword" element={<FormRecoverPassword />} />
            <Route path="auth/reset-password" element={<SetNewPassword />} />
            <Route path="contacto" element={<Contacto />} />

            <Route path="*" element={<Error404 />} />
        </Route>
    )
);
export default function Router() {
    return <RouterProvider router={router} />;
}
