import { useEffect, useState } from "react";
import { FormPerfil } from "../components/FormMiPerfil";
import * as Yup from "yup";
import { useFormik } from "formik";
import { OnChangeParams } from "../../../inputs/InputProps";
import peticionBack from "../../../../helpers/peticiones";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fillUserInformation } from "../../../../core/slices/auth/AuthSlicer";
import { validarCurp, validarRFC } from "../../../../helpers/personalData";
import { useTranslation } from "react-i18next";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import { Usuario } from "../Usuario.dt";
import profile_pic from "../../../../../src/assets/images/users/user-dummy-img.jpg";
import CardContainer from "../../../cardContainer/CardContainer";
import "../../../../../src/styles/components/file-manager/loader-center.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { loginData } from "../../../../core/slices/auth/AuthSlicer";

export const MiPerfilView = () => {
    const { t } = useTranslation();
    const loginInformation = useSelector(loginData);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [carers, setCarers] = useState<[]>([]);
    const [photo, setPhoto] = useState();

    useEffect(() => {
        getCarers();
        getMe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getCarers() {
        const res = await peticionBack({}, `/profesiones`, "GET", false);
        if (res) {
            setCarers(res.data);
        }
    }

    async function getMe() {
        setLoader(true);
        const res = await peticionBack({}, `/me`, "GET", false);

        if (res) {
            setValues(
                {
                    name: res.name,
                    lastname: res.lastname,
                    date_born: res.date_born,
                    phone: `${res.phone ? res.phone.substring(res.phone.length - 10) : ""}`,
                    gender: res.gender,
                    country_code: res.country_code,
                    cp: res.cp,
                    curp: res.curp,
                    rfc: res.rfc,
                    career_id: res.career_id,
                },
                true
            );
        }
        setFieldValue("date_born", res.date_born ? res.date_born.replace(/-/g, "/") : "");
        if (res) {
            setPhoto(res.profile_photo);
        }
        setLoader(false);
    }

    const defaultForm: Usuario = {
        name: "",
        lastname: "",
        date_born: "",
        phone: "",
        gender: "",
        country_code: "+52",
        curp: "",
        cp: "",
        rfc: "",
        career_id: "",
    };

    const formValidations = Yup.object().shape({
        name: Yup.string().required(t("Enter your name")),
        lastname: Yup.string().required(t("Enter your last name")),
        date_born: Yup.date()
            .required(t("Choose your date born"))
            .test("is-18-or-older", t("Must be of legal age"), function (value) {
                const today = new Date();
                const birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                const m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                return age >= 18;
            }),

        gender: Yup.string().required(t("Choose your gender")),
        phone: Yup.string().required(t("Enter your phone")),
        curp: Yup.string().when("country_code", {
            is: "+52",
            then: () =>
                Yup.string()
                    .required(t("Enter your CURP"))
                    .test({
                        name: "valid_curp",
                        message: t("Invalid CURP"),
                        test: (value) => validarCurp(value),
                    }),
        }),
        cp: Yup.string().required(t("Enter your CP")).min(5, t("Min 5 digits")),
        rfc: Yup.string().when("country_code", {
            is: "+52",
            then: () =>
                Yup.string()
                    .required(t("Enter your RFC"))
                    .test({
                        name: "valid_curp",
                        message: t("Invalid RFC"),
                        test: (value) => validarRFC(value),
                    }),
        }),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue, setValues } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,

        onSubmit: async () => {
            setLoading(true);
            if (values.country_code === "+52") {
                const res = await peticionBack(
                    {
                        name: values.name,
                        lastname: values.lastname,
                        date_born: new Date(values.date_born),
                        phone: `${values.country_code}${values.phone}`,
                        gender: values.gender,
                        country_code: values.country_code,
                        cp: values.cp,
                        curp: values.curp,
                        rfc: values.rfc,
                        career_id: values.career_id,
                    },
                    "/me",
                    "PUT",
                    true
                );

                if (res) {
                    dispatch(
                        fillUserInformation({
                            ...loginInformation.userInformation,
                            name: res.row.name,
                            lastname: res.row.lastname,
                            date_born: res.row.date_born,
                            phone: res.row.phone,
                            organization_id: res.row.organization_id,
                            wallet_id: res.row.wallet_id,
                            gender: res.row.gender,
                            career_id: res.row.career_id,
                            country_code: res.row.country_code,
                            cp: res.row.cp,
                            curp: res.row.curp,
                            rfc: res.row.rfc,
                            card: res.row.card,
                            user_type: res.row.user_type,
                        })
                    );
                    navigate("/explorador", { replace: true });
                }
            } else {
                const res = await peticionBack(
                    {
                        name: values.name,
                        lastname: values.lastname,
                        date_born: new Date(values.date_born),
                        phone: `${values.country_code}${values.phone}`,
                        gender: values.gender,
                        country_code: values.country_code,
                        cp: values.cp,
                        career_id: values.career_id,
                    },
                    "/me",
                    "PUT",
                    true
                );
                if (res) {
                    navigate("/explorador", { replace: true });
                }
            }

            setLoading(false);
        },
        enableReinitialize: true,
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    async function upload(value: FileList | null) {
        if (value === null || value.length < 1) {
            return;
        }
        setLoader(true);
        const maxSizeFile = 2 * 1024 * 1024;
        const params = new FormData();
        const archivo = value[0];

        if (archivo.size >= maxSizeFile) {
            setLoader(false);
            toast.error(t("The image must be 2MB or less."), { autoClose: 4000 });
        } else {
            params.append("file", archivo, values.profile_photo);
            const res = await peticionBack(params, "/me/profile-image", "POST", true);
            setLoader(false);
            if (res) {
                setPhoto(res.profile_photo);
                dispatch(fillUserInformation({ ...loginInformation.userInformation, profile_photo: res.profile_photo }));
            }
        }
    }

    return (
        <Row>
            <Col xs={12} sm={6} md={4} lg={3}>
                <CardContainer>
                    <h4>{t("Profile photo")}</h4>
                    <div className="text-center">
                        <div className="profile-user position-relative d-inline-block mx-auto">
                            <img src={values === null ? profile_pic : photo} className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="" />
                            {/* Loader */}
                            {loader && (
                                <div className="loader-container">
                                    <Spinner color="success" />
                                    {t("Cargando...")}
                                </div>
                            )}
                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                <Input id="profile-img-file-input" type="file" accept=".jpg" className="profile-img-file-input" onChange={({ target }) => upload(target.files)} />
                                <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                                    <span className="avatar-title rounded-circle bg-light text-body">
                                        <i className="ri-camera-fill"></i>
                                    </span>
                                </Label>
                            </div>
                        </div>
                    </div>
                </CardContainer>
            </Col>
            <Col xs={12} sm={6} md={8} lg={9}>
                <CardContainer>
                    <FormPerfil errors={errors} handleReset={handleReset} values={values} touched={touched} onChange={onChange} handleSubmit={handleSubmit} handleBlur={handleBlur} loading={loading} t={t} labelSendButton={t("Send")} careers={carers} />
                </CardContainer>
            </Col>
        </Row>
    );
};
