import { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { MiPerfilView } from "../../components/pages/miPerfil/views/UserView";
export const PerfilMain = () => {
    return (
        <Fragment>
            <MiPerfilView />
        </Fragment>
    );
};

export default withTranslation()(PerfilMain);
