import { DropdownItem, DropdownMenu, DropdownToggle, Label, Input, FormFeedback, UncontrolledDropdown } from "reactstrap";
import { phoneNumber } from "./InputProps";
import SimpleBar from "simplebar-react";
import { country } from "./country";
import { useState } from "react";

import { defaultCountryValue, Country } from "./country";

export default function InputPhone({ name, label, value, required, error, touched, onChange, onBlur, nameCountryCode }: phoneNumber) {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [seletedCountry, setseletedCountry] = useState<Country>(defaultCountryValue);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    function maxSize({ value, countryCode }: { value: string; countryCode: string }) {
        if (value.length > 10) {
            return { name, value: value.substring(0, 10), countryCode };
        }
        return { name, value, countryCode };
    }

    function onChangeCountry(item: any) {
        setseletedCountry(item);
        if (onChange) {
            onChange({ name: nameCountryCode, value: item.countryCode });
        }
    }

    return (
        <div>
            {label && (
                <Label for={name}>
                    {label} {required && <span className="text-danger">*</span>}
                </Label>
            )}

            <UncontrolledDropdown direction="down" className="input-group" isOpen={dropdownOpen} toggle={toggle} style={{ zIndex: 5 }}>
                <DropdownToggle as="button" className="btn btn-light border arrow-none" style={{ zIndex: 3 }}>
                    <img src={seletedCountry.flagImg} alt="country flag" className="options-flagimg" height="20" />
                    <span className="countrylist-codeno text-muted">{seletedCountry.countryCode}</span>
                </DropdownToggle>

                <Input type="number" name={name} id={name} className="form-control rounded-end flag-input" invalid={!!error && touched} value={value} onChange={({ target }) => (onChange ? onChange(maxSize({ ...target, countryCode: seletedCountry.countryCode })) : undefined)} onBlur={onBlur} />
                <FormFeedback>{error}</FormFeedback>

                <DropdownMenu as="ul" className="list-unstyled w-100 dropdown-menu-list mb-0">
                    <SimpleBar style={{ maxHeight: "220px" }} className="px-3">
                        {(country || []).map((item, key) => (
                            <DropdownItem
                                as="li"
                                onClick={() => {
                                    onChangeCountry(item);
                                }}
                                key={key}
                                className="dropdown-item d-flex"
                            >
                                <div className="flex-shrink-0 me-2">
                                    <img src={item.flagImg} alt="country flag" className="options-flagimg" height="20" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex">
                                        <div className="country-name me-1">{item.countryName}</div>
                                        <span className="countrylist-codeno text-muted">{item.countryCode}</span>
                                    </div>
                                </div>
                            </DropdownItem>
                        ))}
                    </SimpleBar>
                </DropdownMenu>
            </UncontrolledDropdown>

            {/* <Dropdown className="input-group" isOpen={dropdownOpen} toggle={toggle} style={{ zIndex: 5 }}></Dropdown> */}
        </div>
    );
}
