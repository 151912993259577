import { useEffect, useState } from "react";
import Datatable from "../../../../datatable/Datatable";
import { convertirFormatoFecha } from "../../../../../helpers/formats";

export default function TranscoIter({t, data, openT }: { t: (text: string) => string; data: any ,openT: boolean }) {
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        setRefresh(true);
    }, [data]);

    /* TABLA */
    const columns = [
        { data: "file.name"},
        { data: "task" },
        { data: "status"},
        { data: "createdAt", format: convertirFormatoFecha },
        { data: "file.mimetype"},
        { data: "costo.value"},
    ];

    const columnDefs = [
        { className: "text-center", targets: [ 1, 2, 3, 4] },
    ];
    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
                <h4 className="">{t("Transcoding Report")}</h4>
            </div>
            <Datatable
                columns={columns}
                headers={[`${t("file name")}`, `${t("model")}`, `${t("status")}`, `${t("date")}`, `${t("file type")}`, `${t("Cost")}`]}
                columnDefs={columnDefs}
                data={data}
                control="front"
                stateRefresh={[refresh, setRefresh]}
                className="p-0"
            />
        </>
    );
}