export const privacidadEn = {
    "PRIVACY NOTICE AND PRIVACY POLICY": "PRIVACY NOTICE AND PRIVACY POLICY",
    "Identity and Address of the Responsible": "Identity and Address of the Responsible",
    "recognizes the importance of protecting the privacy of its users' personal information and undertakes to take all necessary measures to ensure its confidentiality and security. This Privacy Notice and Privacy Policy":
        "recognizes the importance of protecting the privacy of its users' personal information and undertakes to take all necessary measures to ensure its confidentiality and security. This Privacy Notice and Privacy Policy",
    "hereinafter, the Notice": "hereinafter, the Notice",
    "describes the practices that MIABOGADO ONLINEA follows for the collection, use, storage, and protection of personal data provided by its users.:": "describes the practices that MIABOGADO ONLINEA follows for the collection, use, storage, and protection of personal data provided by its users.",
    "MIABOGADO ONLINEA is responsible for collecting, using and protecting the personal data that you provide us through our website and any other means related to our services.":
        "MIABOGADO ONLINEA is responsible for collecting, using and protecting the personal data that you provide us through our website and any other means related to our services.",
    "Personal Data We Collect": "Personal Data We Collect",
    "In compliance with the Federal Law on Protection of Personal Data Held by Private Parties (LFPDPPP), we inform you that the personal data we collect from you are the following":
        "In compliance with the Federal Law on Protection of Personal Data Held by Private Parties (LFPDPPP), we inform you that the personal data we collect from you are the following",
    "Full name": "Full name",
    "Last name": "Last name",
    "Address and geographical locationAddress and geographical location": "Address and geographical location",
    "Federal Taxpayer Registry (RFC)": "Federal Taxpayer Registry (RFC)",
    "Unique Population Registry Code (CURP)": "Unique Population Registry Code (CURP)",
    Nationality: "Nationality",
    Gender: "Gender",
    "Phone number": "Phone number",
    "Email address": "Email address",
    "Academic information (bachelor's degree)": "Academic information (bachelor's degree)",
    "Data from your file or judicial processes (private sentences, lawsuits, recorded hearings, among other related documents)": "Data from your file or judicial processes (private sentences, lawsuits, recorded hearings, among other related documents)",
    "Purposes of Data Processing": "Purposes of Data Processing",
    "The personal data we collect will be used for the following purposes, necessary for the provision of our services nes the text formatted in lists using HTML":
        "The personal data we collect will be used for the following purposes, necessary for the provision of our services: nes the text formatted in lists using HTML",
    "Primary Purposes": "Primary Purposes",
    "Processing and analysis of documents and judicial content using artificial intelligence": "Processing and analysis of documents and judicial content using artificial intelligence",
    "Direct communication with the user regarding the requested services.": "Direct communication with the user regarding the requested services.",
    "Creation of a digital file for the management of your legal information": "Creation of a digital file for the management of your legal information",
    "Administration of the contractual relationship and service provision": "Administration of the contractual relationship and service provision",
    "Generation of anonymous global statistics for internal analysis and continuous improvement of services.": "Generation of anonymous global statistics for internal analysis and continuous improvement of services.",
    "Secondary Purposes": "Secondary Purposes",
    "Use of your data for commercial purposes, such as sending offers and promotions, provided the user has expressly authorized it.": "Use of your data for commercial purposes, such as sending offers and promotions, provided the user has expressly authorized it.",
    "Security and Confidentiality Measures": "Security and Confidentiality Measures",
    "MIABOGADO ONLINEA implements strict security measures to protect the confidentiality and integrity of the personal data and documents you provide us. These measures include, but are not limited to:":
        "MIABOGADO ONLINEA implements strict security measures to protect the confidentiality and integrity of the personal data and documents you provide us. These measures include, but are not limited to",

    "Data Encryption All personal data and documents uploaded to the platform will be encrypted during transmission and storage.": "Data Encryption: All personal data and documents uploaded to the platform will be encrypted during transmission and storage.",
    "Access Control Only authorized and qualified personnel will have access to your data and documents, under strict confidentiality policies": "Access Control: Only authorized and qualified personnel will have access to your data and documents, under strict confidentiality policies.",
    "Continuous Monitoring The platform is subject to constant monitoring to detect and mitigate any potential security vulnerabilities.": "Continuous Monitoring: The platform is subject to constant monitoring to detect and mitigate any potential security vulnerabilities.",

    "User Responsibility": "User Responsibility",
    "The user is responsible for the veracity and accuracy of the information and documents uploaded to the MIABOGADO ONLINEA platform. It is your obligation to ensure that you are not providing data or documents that could compromise the privacy of third parties or violate any law.":
        "The user is responsible for the veracity and accuracy of the information and documents uploaded to the MIABOGADO ONLINEA platform. It is your obligation to ensure that you are not providing data or documents that could compromise the privacy of third parties or violate any law.",
    "Use of Third Party Tools": "Use of Third Party Tools",
    "MIABOGADO ONLINEA uses open source software tools and third-party services to provide its document processing and analysis services. The user acknowledges and accepts that these third parties may also have access to the data and documents uploaded to the":
        "MIABOGADO ONLINEA uses open source software tools and third-party services to provide its document processing and analysis services. The user acknowledges and accepts that these third parties may also have access to the data and documents uploaded to the",

    "Data Sharing": "Data Sharing",

    "MIABOGADO EN LINEA will not share, sell, or transfer your personal data or case information with any private or governmental entity, except in the following cases":
        "MIABOGADO EN LINEA will not share, sell, or transfer your personal data or case information with any private or governmental entity, except in the following cases",
    "When required by law, regulations, or in compliance with a judicial mandate.": "When required by law, regulations, or in compliance with a judicial mandate.",

    "To protect the rights, property, or safety of MIABOGADO EN LINEA, our users, or others.": "To protect the rights, property, or safety of MIABOGADO EN LINEA, our users, or others.",

    "Use of Data for Global Metrics": "Uso de datos para métricas globales",

    "The information provided by users may be used by MIABOGADO EN LINEA in an aggregated and anonymous manner to generate global metrics and statistics. These statistics will include data such as the number of users, number of cases processed, number of iterations with the tool, among others. This information will be used solely to improve our services and will not involve the individual identification of users or the disclosure of specific case details.":
        "La información proporcionada por los usuarios podrá ser utilizada por MIABOGADO EN LINEA de forma agregada y anónima para generar métricas y estadísticas globales. Estas estadísticas incluirán datos como el número de usuarios, número de casos procesados, número de iteraciones con la herramienta, entre otros. Esta información se utilizará únicamente para mejorar nuestros servicios y no implicará la identificación individual de los usuarios ni la divulgación de detalles de casos específicos.",
    "Data Subject Rights": "Derechos del interesado",
    "The data subject has the right to access, rectify, cancel, or oppose (ARCO Rights) the processing of their personal data. To exercise these rights, the data subject must submit a written request via the following email address contacto@miabogadoenlinea.mx. The request must contain":
        "The data subject has the right to access, rectify, cancel, or oppose (ARCO Rights) the processing of their personal data. To exercise these rights, the data subject must submit a written request via the following email address: contacto@miabogadoenlinea.mx. The request must contain:",
    "The name of the data subject and address or other means to communicate the response.": "The name of the data subject and address or other means to communicate the response.",
    "The documents that prove the identity of the data subject or, where appropriate, legal representation.": "The documents that prove the identity of the data subject or, where appropriate, legal representation.",
    "A clear and precise description of the personal data for which the data subject seeks to exercise any of the ARCO rights.": "A clear and precise description of the personal data for which the data subject seeks to exercise any of the ARCO rights.",
    "Any other element or document that facilitates the location of the personal data.": "Any other element or document that facilitates the location of the personal data.",
    "Modifications to the Privacy Notice": "Modifications to the Privacy Notice",
    "MIABOGADO EN LINEA reserves the right to modify this Notice at any time. The modifications will be available through our website, and if significant, they will be notified to the user via the registered email address.":
        "MIABOGADO EN LINEA reserves the right to modify this Notice at any time. The modifications will be available through our website, and if significant, they will be notified to the user via the registered email address.",
    Consent: "Consent",
    "By using the services of MIABOGADO EN LINEA and providing your personal data, the user agrees to the terms and conditions set forth in this Notice. If the user does not agree with these terms, they must refrain from using the platform and its services.":
        "By using the services of MIABOGADO EN LINEA and providing your personal data, the user agrees to the terms and conditions set forth in this Notice. If the user does not agree with these terms, they must refrain from using the platform and its services.",
    "If you have any questions or comments regarding this Privacy Notice, please contact us at the following email address: contacto@miabogadoenlinea.mx.":
        "If you have any questions or comments regarding this Privacy Notice, please contact us at the following email address: contacto@miabogadoenlinea.mx.",
    "This privacy notice is a detailed example and should be reviewed by a lawyer specialized in data protection to ensure it complies with all applicable regulations and adequately protects the company.":
        "This privacy notice is a detailed example and should be reviewed by a lawyer specialized in data protection to ensure it complies with all applicable regulations and adequately protects the company.",
};
