import React from "react";
import { withTranslation } from "react-i18next";
import { Card, Col, Row } from "reactstrap";

export const AdminStarted = ({ t }: { t: (text: string) => string }) => {
    return (
        <div>
        <Row>
            <Col>
            <h1 className="d-flex justify-content-center">{t("Welcome")}</h1>
            <Card className={"p-3"}>
            <h3 className="d-flex justify-content-center">{`${t("administrative site of")} miAgogadoEnLinea`}</h3>
           
            <Row className="icon-demo-content d-flex justify-content-center">
            <div className="col-lg-4 d-flex justify-content-center">
                      <i className="bx bx-home"></i>
                  
                    </div>
                    </Row>
                </Card>
            </Col>
        </Row>
    </div>
               
    );
};

export default withTranslation()(AdminStarted);
