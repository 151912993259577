import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toastMessage from "../../../../helpers/toastMessage";
import { isValid } from "../../../../helpers/formats";
import peticionBack from "../../../../helpers/peticiones";
import { DataItem, ResultItem } from "../../../../pages/ocr/dto/OcrDto";
import { FormSearch } from "../components/FormSearch";
import { Paggination } from "../components/Paggination";
import { ContentOcr } from "../components/ContentOcr";
import { Col, Row } from "reactstrap";
import AudioPlayer from "../../../audio-player/AudioPlayer";
import { StausQueue } from "../../../../core/enums/enums";

type Content = {
    page: number;
    text: string[];
};

export default function ViewOcr({ row }: { row: DataItem }) {
    const { t } = useTranslation();
    const [content, setContent] = useState<Content[]>([]); //Todo el contenido separado
    const [coincidencias, setCoincidencias] = useState<Content[]>([]); // coincidencias de filtro
    const [descripction, setDescription] = useState<string | null>(null); //Todo el contenido junto
    const [textInput, setTextInput] = useState<string>("");
    const [textSearched, setTextSearched] = useState<string>("");
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = coincidencias.length > 0 ? coincidencias.length : content.length;
    const pagesToShow = 5;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    const [isPc, setIsPc] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsPc(window.innerWidth >= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const goToPage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1));
    };

    const firstPageToShow = Math.max(currentPage - halfPagesToShow, 0);
    const lastPageToShow = Math.min(firstPageToShow + pagesToShow - 1, totalPages - 1);

    useEffect(() => {
        DowJson();
        // eslint-disable-next-line
    }, []);

    const DowJson = async () => {
        const fileOcr = row.result.find((f: ResultItem) => f.type === "ocr");
        if (!fileOcr) {
            return;
        }
        try {
            const res = await axios.get(fileOcr.url, { responseType: "text" });
            const json = JSON.parse(res.data);

            let concatContent = "";
            if (json) {
                const jsonClean = json.map((s: Content) => {
                    concatContent += s.text; //arrelgo //join
                    return { page: s.page, text: s.text };
                });

                setContent(jsonClean);
                setDescription(concatContent);
            } else {
                console.error("El objeto JSON o sus segmentos son indefinidos.");
            }
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
        }
    };

    /* =================== Boton copiar ==================== */
    async function copyClipboard() {
        try {
            if (descripction) {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(descripction).then((a) => {});
                } else {
                    commandCopyClipboard();
                }
                toastMessage("info", t("Successfully copied content"));
            }
        } catch (error) {
            toastMessage("error", t("No se puede copiar al portapapeles"));
        }
    }

    function commandCopyClipboard() {
        const textArea = document.createElement("textarea");
        textArea.value = descripction as string;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }

    function onChange({ target }: { target: any }) {
        if (target.value === "") {
            setTextSearched("");
            setCoincidencias([]);
        }
        setTextInput(target.value);
    }

    //Realizar busqueda
    async function search(evento: any) {
        evento.preventDefault();
        if (!isValid(textInput)) return;
        const res = await peticionBack({}, `/queues/OCR/search/${[encodeURIComponent(row.queue_id)]}?search=${textInput}`, "GET", false);
        if (res && res.concidences && res.concidences.length > 0) {
            const data: Content[] = res.concidences.map((el: Content) => {
                return {
                    text: el.text,
                    page: el.page,
                };
            });
            setCoincidencias(data);
            setTextSearched(textInput);
            setCurrentPage(0);
        } else {
            setCoincidencias([]);
            toastMessage("info", t("No matches found"));
        }
    }

    return (
        <div className="mx-3 text-center mt-3">
            <h4>{row.name}</h4>
            <Row style={{ flex: 1 }}>
                <Col xs="12" md="6">
                    <div className="align-self-center" style={{ display: "flex", flexDirection: "column", height: isPc ? "90vh" : "auto", minHeight: isPc ? "90vh" : "auto", justifyContent: "center" }}>
                        <div>
                            <FormSearch copyClipboard={copyClipboard} fieldValue={textInput} onChange={onChange} search={search} />
                        </div>
                        <div className="px-5 mt-3">
                            {(row.mimetype === "image/jpeg" || row.mimetype === "image/png" || row.mimetype === "image/jpg") && <img src={row.url} alt="document" style={{ maxWidth: "100%", height: "auto" }} />}
                            {row.mimetype === "application/pdf" && <iframe src={row.url} title="PDF Viewer" style={{ width: "100%", height: "75vh", border: "none" }} />}
                        </div>
                    </div>
                </Col>
                <Col xs="12" md="6">
                    <div className="mt-5" style={{ display: "flex", flexDirection: "column", height: isPc ? "75vh" : "auto", minHeight: isPc ? "75vh" : "auto", justifyContent: "center" }}>
                        <div className="text-center my-3 mt-5">
                            {row.status === StausQueue.SUCCESS && (
                                <div className="mb-2">
                                    <AudioPlayer queue={row} />
                                </div>
                            )}
                            <Paggination
                                content={coincidencias.length > 0 ? coincidencias : content}
                                currentPage={currentPage}
                                firstPageToShow={firstPageToShow}
                                goToNextPage={goToNextPage}
                                goToPage={goToPage}
                                totalPages={coincidencias.length > 0 ? coincidencias.length : content.length}
                                goToPreviousPage={goToPreviousPage}
                                lastPageToShow={lastPageToShow}
                            />
                        </div>
                        <div>{content.length > 0 || coincidencias.length > 0 ? <ContentOcr content={coincidencias.length > 0 ? coincidencias : content} currentPage={currentPage} textSearched={textSearched} /> : <div></div>}</div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
