import { BadgeProps } from "./BadgeProps";

export default function Badge({ children, color, outline, soft, pill, capitalize, className }: BadgeProps){
    const theColor = color || "dark";
    const textColor = (" text-" + (["light", "dark"].includes(color as any)  ? "body" : color));

    const clase = "badge " + 
    (
        (soft || outline) ?
        (
            (outline ? (` border border-${theColor}`) : "") + 
            (soft ? ` bg-${theColor}-subtle` : "") 
        )+ textColor : 
        "bg-"+theColor
    ) +
    (pill ? " rounded-pill": "");
    return(
        <span color={theColor} className={clase}>
            { children }
        </span>
    )
}