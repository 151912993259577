import { Form } from "react-router-dom";
import InputBasic from "../../../inputs/InputBasic";
import { Button, Col, Row } from "reactstrap";
import InputPhone from "../../../inputs/InputPhoneNumber";
import * as Yup from "yup";
import { useFormik } from "formik";
import peticionBack from "../../../../helpers/peticiones";
import { OnChangeParams } from "../../../inputs/InputProps";
import { alerConfirmation } from "../../../../helpers/alertConfirmation";
import { useEffect } from "react";
import { Clientes } from "../types/CatClientes";

export default function FormClientes({ setOpen, selected, t }: { setOpen: any; selected?: Clientes; t: any }) {
    //VALIDACIÓN FORM
    const defaultForm: Clientes = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        country_code: "+52",
        _id: "",
    };

    const formValidations = Yup.object().shape({
        name: Yup.string().required(t("Enter your name")),
        lastname: Yup.string().required(t("Enter your last name")),
        email: selected ? Yup.string() : Yup.string().required(t("Enter an email")),
        phone: Yup.string().required(t("Enter your phone number")).min(8, t("Min 8 characters")),
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    const { handleSubmit, handleReset, handleBlur, setFieldValue, setValues, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async (values) => {
            alerConfirmation({
                handleAction: save,
                title: `${selected ? t("Edit") : t("Save")}`,
                content: `${t("¿Desea")} ${selected ? t("edit") : t("guardar")} ${t("este cliente?")}`,
                buttonOkMsg: `${t("Save")}`,
                buttonCancelMsg: `${t("Cancel")}`,
                values: values,
            });
        },

        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (selected) {
            setValues(
                {
                    name: selected.name,
                    lastname: selected.lastname,
                    email: selected.email,
                    phone: selected.phone.substring(selected.phone.length - 10),
                    country_code: selected.country_code,
                    _id: selected._id,
                },
                true
            );
        }
        // eslint-disable-next-line
    }, []);

    async function save() {
        const method = selected ? "PUT" : "POST";
        const rute = selected ? `/${selected._id}` : "";
        const params = selected
            ? {
                  name: values.name,
                  lastname: values.lastname,
                  email: values.email,
                  phone: `${values.country_code || "+52"} ${values.phone}`,
              }
            : {
                  name: values.name,
                  lastname: values.lastname,
                  email: values.email,
                  phone: `${values.country_code || "+52"} ${values.phone}`,
              };

        const res = await peticionBack(params, `/clientes` + rute, method, t("clientes.common.There is already a registered client with this data"));
        if (res) setOpen(false);
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                <Row className="d-flex justify-content-around">
                    <Col lg="5">
                        <InputBasic name="name" type="text" value={values.name} label={t("Name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                    <Col lg="5">
                        <InputBasic name="lastname" type="text" value={values.lastname} label={t("Last name")} touched={touched.lastname} error={errors.lastname} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                    <Col lg="5" style={{ zIndex: 8 }}>
                        <InputPhone
                            name="phone"
                            nameCountryCode="country_code"
                            value={values.phone}
                            label={t("Phone")}
                            touched={touched.phone}
                            error={errors.phone}
                            onChange={onChange}
                            onBlur={handleBlur}
                            required
                            valueCountry={{
                                id: 0,
                                flagImg: "",
                                countryPrefix: "",
                                countryName: "",
                                countryCode: "+52",
                            }}
                        />
                    </Col>
                    <Col lg="5">
                        <InputBasic name="email" type="text" value={values.email} label={t("Email")} touched={touched.email} error={errors.email} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>

                <Row className="d-grid gap-2 d-md-flex justify-content-md-around mt-2">
                    <Col xl="5" lg="4">
                        <Button className="mt-3" color="danger" type="reset">
                            {t("Cancel")}
                        </Button>
                    </Col>
                    <Col xl="5" lg="4" className="d-flex justify-content-end my-2">
                        <Button className="mt-2 mt-sm-3" color="success" type="submit">
                            {t("Save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
