import React, { useState } from "react";
import { FormLogin } from "../components/FormLogin";
import * as Yup from "yup";
import { OnChangeParams } from "../../../inputs/InputProps";
import { useFormik } from "formik";
import { Col, Row } from "reactstrap";
import { LoginSignUpMessage } from "../components/LoginSignUpMessage";
import { RedesSocialesIcons } from "../components/RedesSocialesIcons";
import peticionBack from "../../../../helpers/peticiones";
import SignUpInformation from "../types/SingUpInformation";
import ModalPrivacy from "../../../modals/ModalPolicy";

export const SingUpView = ({
    t,
    setIsLogin,
    isLogin,
    setwaitingEmailValidation,
    setSignupInformation,
}: {
    t: (text: string) => string;
    setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
    isLogin: boolean;
    setwaitingEmailValidation: React.Dispatch<React.SetStateAction<boolean>>;
    setSignupInformation: React.Dispatch<React.SetStateAction<SignUpInformation>>;
}) => {
    const [loading, setLoading] = useState(false);
    const [modalPrivacy, setModalPrivacy] = useState(true);

    const defaultForm = {
        email: "",
        password: "",
        Rpassword: "",
        accept: "",
    };

    const formValidations = Yup.object().shape({
        email: Yup.string().required(t("Enter an email")).email(t("Invalid email")),
        accept: Yup.boolean().test("accepted", t("Accept our terms and conditions"), function (value) {
            return this.parent.accept === true;
        }),
        password: Yup.string()
            .required("Enter a password")
            .matches(/^(?=.*[!@#$%^&*()\-_=+{};:,<.>/?`~])(?=.*[0-9])(?=.*[A-Z]).{6,}$/, t("The password must have at least one uppercase letter, one special character, one number and be at least 6 characters")),
        Rpassword: Yup.string()
            .required(t("Repeat password"))
            .test("passwords-match", t("Passwords must match"), function (value) {
                return this.parent.password === value;
            }),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async () => {
            setLoading(true);
            const res = await peticionBack({ email: values.email, pwd: values.password }, "/auth/signup", "POST", true, {}, true);

            if (!res?.error && res.status === 201) {
                setSignupInformation({ email: values.email, recoveryToken: res.data.recoveryToken });

                setwaitingEmailValidation(true);
            }
            setLoading(false);
        },
        enableReinitialize: true,
    });
    async function googleAuth() {
        window.location.href = process.env.REACT_APP_BACK_URI + "/auth/google";
    }

    async function outlookAuth() {
        window.location.href = process.env.REACT_APP_BACK_URI + "/auth/microsoft";
    }

    function onChange({ name, value }: OnChangeParams) {
        if (loading) return;

        if (name === "accept" && value) {
            setModalPrivacy(true);
        } else {
            setFieldValue(name, value);
        }
    }
    function policyAccepted() {
        setFieldValue("accept", true);
        setModalPrivacy(false);
    }

    return (
        <div className="p-lg-5 p-4">
            <Row>
                <Col>
                    <div className="d-flex justify-content-center align-content-center">
                        <h2>MIABOGADO EN LINEA</h2>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-flex justify-content-center align-content-center my-5 text-center text-bold">
                        <h3 className=""> {t("A few steps away from simplifying your work and maximizing your efficiency")}</h3>
                    </div>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col lg={8}>
                    <FormLogin errors={errors} handleReset={handleReset} values={values} touched={touched} onChange={onChange} handleSubmit={handleSubmit} handleBlur={handleBlur} loading={loading} t={t} showConfirmPassword={true} labelSendButton={t("Signup")} showPasswordMessageValidation />
                </Col>
            </Row>

            <div className="signin-other-title text-center mt-4">
                <h5 className="fs-13 mb-4 title">{t("Sign Up with")}</h5>
            </div>

            <Row>
                <Col>
                    <RedesSocialesIcons t={t} googleAuth={googleAuth} outlookAut={outlookAuth} />
                </Col>
            </Row>

            <Row>
                <LoginSignUpMessage isLogin={isLogin} t={t} setIsLogin={setIsLogin} messageLink={t("Sign In")} messageLabel={t("Do you have an account?")} />
            </Row>

            <ModalPrivacy open={modalPrivacy} setOpen={setModalPrivacy} accepted={policyAccepted} />
        </div>
    );
};
