import { useState } from "react";
import { DataItem } from "../ocr/dto/OcrDto";
import CardContainer from "../../components/cardContainer/CardContainer";
import Datatable from "../../components/datatable/Datatable";
import createURL from "../../helpers/url";
import peticionBack from "../../helpers/peticiones";
import Badge from "../../components/badge/Badge";
import { QueueWork, StausQueue } from "../../core/enums/enums";
import { getColor } from "../../helpers/formats";
import Button from "../../components/buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faRedo } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";
import toastMessage from "../../helpers/toastMessage";
import { alerConfirmation } from "../../helpers/alertConfirmation";
import SingleModalUpload from "../../components/modals/SingleModalUpload";

function TtsMain({ t }: { t: (f: string) => string }) {
    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();
    const [isOpenModalUpload, setIsOpenModalUpload] = useState(false);

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: "/queues/TTS",
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res) return res || [];
    }

    async function getFile(row: any) {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        let queue = undefined;
        if (row.mimetype === "video/mp4") {
            const resTransco = await peticionBack({}, "/queues/TRANSCRIPCION", "GET", false);
            if (resTransco) {
                queue = resTransco.data.find((e: any) => e._id === row._id);
            }
        } else {
            const resOCR = await peticionBack({}, "/queues/OCR", "GET", false);
            if (resOCR) {
                queue = resOCR.data.find((e: any) => e._id === row._id);
            }
        }
        dispatch(loaded());
        return queue;
    }

    const ReproccessText = async (row: any) => {
        const ocr = await getFile(row);
        if (!ocr) {
            toastMessage("info", t("fileNotFound"));
            return;
        }
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ queue_id: ocr.queue_id, replace: true }, `/tts`, "POST", true);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
        }
    };

    function showAlert(row: any) {
        alerConfirmation({
            handleAction: ReproccessText,
            title: `${t("reprocess")}`,
            content: `${t("would you want convert to audio")} ${row.name}`,
            buttonOkMsg: `${t("common.accept")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    const columns = [
        { data: "name" },
        {
            data: "status",
            render: (d: string) => (
                <Badge color={getColor(d)} soft>
                    {d}
                </Badge>
            ),
        },
        { data: "taskLastUpdate", format: "datetime" as "datetime" },
        {
            data: null,
            render: (row: DataItem) => (
                <div>
                    {row.status === StausQueue.SUCCESS && (
                        <div style={{ minWidth: "500px" }}>
                            <audio src={row.result[0].url} controls>
                                <p>{t("tts.The browser cannot play this type of audio")}</p>
                            </audio>
                        </div>
                    )}
                    {row.status === StausQueue.FAILURE && (
                        <div className="">
                            <FontAwesomeIcon id="tooltipVconv" icon={faRedo} size="xl" className="text-primary px-1 cursor-pointer" onClick={() => showAlert(row)} />{" "}
                            <UncontrolledTooltip placement="top" target="tooltipVconv">
                                {t("reprocess")}{" "}
                            </UncontrolledTooltip>{" "}
                        </div>
                    )}
                </div>
            ),
        },
    ];

    const headers: any[] = [t("tts.table.file"), t("tts.table.status"), t("tts.table.Last Update"), t("tts.table.Play")];

    return (
        <CardContainer title={t("tts.title")} tutorial="TTS" defaultOpne={true}>
            <div className="mb-3 mx-2 d-flex justify-content-md-between flex-column-reverse flex-md-row d-grid gap-2">
                <Button onClick={() => setRefresh(true)}>
                    <FontAwesomeIcon icon={faArrowsRotate} />
                </Button>
            </div>
            <Datatable headers={headers} petition={getData} control="back" columns={columns} columnDefs={columnsDef} stateRefresh={[refresh, setRefresh]} />
            <SingleModalUpload open={isOpenModalUpload} setOpen={setIsOpenModalUpload} setRefresh={setRefresh} typeProcess={QueueWork.TTS} />
        </CardContainer>
    );
}

export default withTranslation()(TtsMain);

const columnsDef = [
    { orderable: false, targets: [3] },
    { className: "text-center", targets: [1, 2, 3] },
];
