import { useEffect, useState } from "react";

import Datatable from "../../../components/datatable/Datatable";
import CardContainer from "../../../components/cardContainer/CardContainer";
import { Button, Col } from "reactstrap";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createURL from "../../../helpers/url";
import peticionBack from "../../../helpers/peticiones";
import FormUser from "./Form";
import { withTranslation } from "react-i18next";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import { Usuario } from "./dto/Usuario.dt";

export function Users({ t }: any) {
    function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: `/usuarios`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "rows", value: "name,lastname,phone,gender,id,user_type" },
            ],
        };
        const url = createURL(params);
        return peticionBack({}, url, "GET", false);
    }

    const [open, setOpen] = useState<boolean>(false);
    const headers = [t("Name"), t("Last name"), t("Phone"), t("Gender"), t("Actions")];
    const [selected, setSelected] = useState<Usuario | undefined>();
    const columnsDef = [
        { orderable: false, targets: [4] },
        { className: "text-center", targets: [0, 1, 2, 3, 4] },
    ];

    const [refresh, setRefresh] = useState<boolean>(false);
    const columns = [
        { data: "name" },
        { data: "lastname" },
        { data: "phone" },
        { data: "gender" },
        {
            data: null,
            render: (row: Usuario) => (
                <div className="d-flex justify-content-evenly">
                    <FontAwesomeIcon icon={faPencil} className="text-warning cursor-pointer" onClick={() => openToEdit(row)} />

                    <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />
                </div>
            ),
        },
    ];

    function openToEdit(row: Usuario) {
        setSelected(row);
    }

    useEffect(() => {
        if (!open) {
            setSelected(undefined);
            setRefresh(false);
        }
    }, [open]);

    useEffect(() => {
        if (selected) {
            setOpen(true);
        }
    }, [selected]);

    async function deleteRow(row: Usuario) {
        if (row && row.id) {
            await peticionBack({}, `/usuarios/${row.id}`, "DELETE", true);
            setRefresh(true);
        }
    }

    function showAlert(row: Usuario) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("Delete user")}`,
            content: `${t("Are you sure you want to delete")} ${row.name}`,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    return (
        <CardContainer>
            <h4 className=""> Usuarios {open && (selected ? t("- Edit") : t("- Add"))}</h4>

            {open ? <FormUser setOpen={setOpen} selected={selected} /> : <Datatable columns={columns} columnDefs={columnsDef} headers={headers} control="back" stateRefresh={[refresh, setRefresh]} petition={getData} />}
            <br />

            <Col lg="" className="d-flex justify-content-end">
                {!open && (
                    <Button onClick={() => setOpen(true)} className="my-1 my-md-0" color="success">
                        {t("Add")}
                    </Button>
                )}
            </Col>
        </CardContainer>
    );
}

export default withTranslation()(Users);
