/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { CardBody, CardHeader, Spinner } from "reactstrap";
import Button from "../../../buttons/Button";
import { BasicLineCharts } from "../../dashboard/components/MixedLine";
import { ChartDataPure } from "../types/types";

export default function ChartGlobal({ t, task, type, dataColors}: { t: (f: string) => string; task:string; type:boolean; dataColors: string }) {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>("Filter");

    useEffect(() => {
        getPDF(task);
    }, [selectedDate]);

    const handleFilter = (valDate: string) => {
        setSelectedDate(valDate);
    };

    async function getPDF(mimetype: string) {
        setLoading(true);
        let pan = "";
        if (selectedDate === "D") {
            pan = "?day=true";
        } else if (selectedDate === "M") {
            pan = "?month=true";
        } else if (selectedDate === "A") {
            pan = "?year=true";
        }

        const res = await peticionBack({}, `/administrative/expense-distribution/${mimetype}${pan}` , "GET", false);
        if (res) {
            const ChartQuantity = res[task].map((e: ChartDataPure) => {
                return { 
                    label: e.date || e.month || e.year || t("NoDate"),
                    total: e.quantity };
            }); 
            const ChartAmount = res[task].map((e: ChartDataPure) => {
                return { 
                    label: e.date || e.month || e.year || t("NoDate"),
                    total: e.amount };
            });
            setData(type? ChartQuantity : ChartAmount);
        }
        setLoading(false);
    }

    return (
        <>
            <CardHeader>
                <div className="card-header border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">{`${task} ${type? t("quantity"):t("amount")}`}</h4>
                    <div className="d-flex gap-1">
                        <Button
                            type="button"
                            className={`btn btn-sm`}
                            onClick={() => {
                                handleFilter("D");
                            }}
                            soft={selectedDate === "D"}
                            outline={selectedDate !== "D"}
                            color={`${selectedDate === "D" ? "success" : "primary"}`}
                        >
                            {t("Day")}
                        </Button>
                        <Button
                            type="button"
                            className={`btn btn-sm`}
                            onClick={() => {
                                handleFilter("M");
                            }}
                            soft={selectedDate === "M"}
                            outline={selectedDate !== "M"}
                            color={`${selectedDate === "M" ? "success" : "primary"}`}
                        >
                            {t("Month")}
                        </Button>
                        <Button
                            type="button"
                            className={`btn btn-sm`}
                            onClick={() => {
                                handleFilter("A");
                            }}
                            soft={selectedDate === "A"}
                            outline={selectedDate !== "A"}
                            color={`${selectedDate === "A" ? "success" : "primary"}`}
                        >
                            {t("Year")}
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                {loading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <Spinner color="primary" />
                    </div>
                )}
                <div className="top-50 start-50" style={{ zIndex: "1" }}>
                    <BasicLineCharts data={data} dataColors={dataColors}/>
                </div>
            </CardBody>
        </>
    );
}

