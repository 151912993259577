import { isValid } from "./formats";
interface ParamsCreateURL {
    pathname: string;
    searchParams: Array<{ name: string; value: string }>;
}
export default function createURL(obj: ParamsCreateURL) {
    const url = new URL(process.env.REACT_APP_BACK_URI || "");

    url.pathname += obj.pathname;

    if (obj.searchParams) {
        obj.searchParams.forEach(({ name, value }) => {
            if (isValid(value)) url.searchParams.append(name, value.toString());
        });
    }

    return url.toString();
}

export function orderSQL2NoSQL(order: string) {
    let direc = 0;
    if (order.toUpperCase().endsWith("ASC")) direc = 1;
    else direc = -1;
    return `{"${order.split(" ")[0]}": ${direc}}`;
}
