import { Dispatch, SetStateAction } from "react";

import Datatable from "../../../components/datatable/Datatable";
import CardContainer from "../../../components/cardContainer/CardContainer";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createURL from "../../../helpers/url";
import peticionBack from "../../../helpers/peticiones";
import { useTranslation, withTranslation } from "react-i18next";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import { Usuario } from "../../admin_user/Usuarios/dto/Usuario.dt";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/redux/store";
import { user_type } from "../../../core/enums/enums";

export function AdminTableUsers({ refresh }: { refresh?: [boolean, Dispatch<SetStateAction<boolean>>] }) {
    const { t } = useTranslation();
    const userInfo = useSelector((state: RootState) => state.auth.userInformation);

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: `/usuarios`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "rows", value: "name,lastname,phone,gender,id,user_type" },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        return res;
    }

    const headers = [t("Name"), t("Last name"), t("Phone"), t("User_Types.User type"), t("Actions")];
    const columnsDef = [
        { orderable: false, targets: [4] },
        { className: "text-center", targets: [0, 1, 2, 3, 4] },
    ];

    const columns = [
        { data: "name" },
        { data: "lastname" },
        { data: "phone" },
        {
            data: "user_type",
            render: (data: user_type) => {
                return data === user_type.NORMAL_USER ? `${t("User_Types.Normal user")}` : `${t("User_Types.Organization administrator")}`;
            },
        },
        {
            data: null,
            render: (row: Usuario) => <div className="d-flex justify-content-evenly">{userInfo?.user_type === user_type.ORGANIZATION_ADMINISTRATOR && row.id !== userInfo.id && <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />}</div>,
        },
    ];

    async function deleteRow(row: Usuario) {
        if (row.user_type === user_type.ORGANIZATION_ADMINISTRATOR) {
            isAdminWarning(row);
        } else {
            const res = await peticionBack({}, `/usuarios/organization/unset/${row.id}`, "PUT", true);
            if (res) if (refresh) refresh[1](true);
        }
    }

    function showAlert(row: Usuario) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("organization.Exclude user")}`,
            content: `${t("organization.Are you sure you want to take out")} ${row.name}? ${t("organization.This user will no longer be linked to your organization")}`,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    function isAdminWarning(row: Usuario) {
        alerConfirmation({
            title: `${t("organization.STOP")}`,
            icon: "warning",
            content: `${t("organization.You can't delete yourself")}`,
            buttonOkMsg: `${t("organization.Accept")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    
  

    return (
        <CardContainer>
            <h3> {t("organization.Organization users")}</h3>

            <Datatable columns={columns} columnDefs={columnsDef} headers={headers} control="back" stateRefresh={refresh} petition={getData} />
            <br />
        </CardContainer>
    );
}

export default withTranslation()(AdminTableUsers);
