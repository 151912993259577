import { ModuleSideBar } from "../components/layout/protected/types/ModuleSideBar";
import { controlSideBar } from "../components/layout/protected/types/Control";

export function toRecursive(modules: any) {
    const copy = modules as ModuleSideBar[];
    copy.forEach((modulo) => {
        if (Object.hasOwn(modulo, "parent_id")) {
            const finded = copy.find((module) => {
                return module.id === modulo.parent_id;
            });
            if (finded) {
                if (finded.subItems === undefined) {
                    finded.subItems = [];
                }
                finded.subItems.push(modulo);
            }
        }
    });
    return copy.filter((modulo) => !modulo.parent_id);
}

export function toRecursiveVarControls(modules: any) {
    const copy = modules.map((mod: ModuleSideBar) => {
        return { id: mod.id, parent_id: mod.parent_id, isActive: false, subItems: mod.subItems };
    });
    copy.forEach((modulo: controlSideBar) => {
        if (Object.hasOwn(modulo, "parent_id")) {
            const finded = copy.find((module: controlSideBar) => {
                return module.id === modulo.parent_id;
            });
            if (finded) {
                if (finded.subItems === undefined) {
                    finded.subItems = [];
                }
                finded.subItems.push(modulo);
            }
        }
    });
    return copy.filter((modulo: any) => !modulo.parent_id);
}
