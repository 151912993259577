import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BalanceState } from "./interface/balanceinterface";

const initialState: BalanceState = {
    balance: null,
};

const balanceSlice = createSlice({
    name: "balance",
    initialState,
    reducers: {
        setBalance(state, action: PayloadAction<number | null>) {
            state.balance = action.payload;
        },
    },
});

export const { setBalance } = balanceSlice.actions;
export default balanceSlice.reducer;
