/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../buttons/Button";
import { Col, Form, Row } from "reactstrap";
import { OnChangeParams } from "../../../inputs/InputProps";
import InputBasic from "../../../inputs/InputBasic";
import peticionBack from "../../../../helpers/peticiones";
import { userAuth } from "../../../../core/slices/auth/interface/userinterface";
import SignUpInformation from "../../authentication/types/SingUpInformation";

export const WaitView = ({ t, loginInfo, signupInformation }: { t: (text: string) => string; loginInfo?: userAuth; signupInformation: SignUpInformation }) => {
    const defaultForm = {
        emael: "",
    };

    const formValidations = Yup.object().shape({
        emael: Yup.string().required(t("Enter an email")).email(t("Invalid email")),
    });

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async () => {
            if (signupInformation?.email === values.emael) {
                sendAgain();
            } else {
                changeEmail();
            }
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (signupInformation) {
            setFieldValue("emael", signupInformation.email);
        }
    }, []);

    async function sendAgain() {
        await peticionBack({ email: values.emael }, "/auth/resend-email", "POST", true, { Authorization: `bearer ${signupInformation?.recoveryToken}` });
    }

    async function changeEmail() {
        await peticionBack({ email: values.emael }, "/auth/change-email", "POST", true, { Authorization: `bearer ${signupInformation?.recoveryToken}` });
    }

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }
    return (
        <div className=" text-center m-5">
            <Row className="d-flex flex-column justify-content-center align-content-center my-5 ">
                <h1> {t("Waiting for email confirmation")}</h1>

                <div>
                    <h2>{t("In moments you will receive an email with a link to verify your email")}</h2>
                </div>

                <FontAwesomeIcon icon={faClock} size="5x" className="text-primary px-1 mt-3" spin={true} />
            </Row>

            <Row className="d-flex flex-column justify-content-center align-content-center my-5 ">
                <div>
                    <h2>{t("Have you not received the email?")}</h2>
                </div>
            </Row>

            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                <Row className="d-flex justify-content-around">
                    <Col xs={6}>
                        <InputBasic name={t("Email")} type="text" value={values.emael} label={t("Email")} touched={touched.emael} error={errors.emael} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>
                <Row className="mt-5  justify-content-center align-content-center">
                    <Col>
                        <div className="d-flex justify-content-around">
                            <Button className="bg-success" type="submit">
                                {t("Send again")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
