import { Canvas, FabricImage } from "fabric";
import { pageConfig } from "../../core/constants/sign-pdf/pdf-page-config";
import { handleDragOver, handleDrop } from "./canvas-handles";
import { PageType } from "./PageType";
import { getDocument, PDFPageProxy } from "pdfjs-dist";

/**
 * Cargar archivo pdf en canvas
 * @returns {number}
 */
export async function loadPdf(url_pdf: string, canvas_refs: any, fabric_canvas_refs: any): Promise<number> {
    try {
        const loading_task = getDocument(url_pdf);
        const pdf = await loading_task.promise;
        // let pdf_size = 0; // control scroll page
        if (pdf) {
            // set_total_pages(pdf.numPages);
            for (let p = 1; p <= pdf.numPages; p++) {
                const page = await pdf.getPage(p);
                const scale = getScale(page);
                // pdf_size += await createCanvasImage(page, p, scale);
                createCanvasImage(page, p, scale, canvas_refs, fabric_canvas_refs);
            }
            // set_pdf_size(pdf_size);
        }
        return pdf.numPages;
    } catch (error) {
        console.error("Error loading PDF:", error);
    }
    return -1;
}

/**
 * Pagina de pdf a canvas
 */
export async function createCanvasImage(page: PDFPageProxy, no: number, scale: number, canvas_refs: any, fabric_canvas_refs: any) {
    const viewport = page.getViewport({ scale });
    const pdfCanvas = document.createElement("canvas");
    const context = pdfCanvas.getContext("2d");
    pdfCanvas.height = viewport.height;
    pdfCanvas.width = viewport.width;
    if (context) {
        const renderContext = {
            canvasContext: context,
            viewport: viewport,
        };
        await page.render(renderContext).promise;

        const image = new Image();
        image.src = pdfCanvas.toDataURL();
        image.onload = async () => {
            const fcanvas = new Canvas(canvas_refs.current[no - 1], { width: viewport.width * scale, height: viewport.height * scale, selection: true });
            const fImage = new FabricImage(image, pageConfig(scale));
            fcanvas.add(fImage);
            fabric_canvas_refs.current[no - 1] = fcanvas;
            let cv = document.getElementById(`drag-page-miab-${no - 1}`);
            if (cv) {
                cv.addEventListener("dragover", handleDragOver);
                cv.addEventListener("drop", (event: DragEvent) => handleDrop(event, fcanvas));
            }
        };
    }
    return viewport.height;
}

/**
 * Verificar firmas en pdf
 */
export function checkObjectsInCanvas(total_pages: number, fabric_canvas_refs: any) {
    const page_objects: PageType[] = Array.from({ length: total_pages }, () => []);
    let total = 0;
    Array.from(Array(total_pages)).forEach((_, ix) => {
        const fabric_canvas = fabric_canvas_refs.current[ix];
        if (fabric_canvas) {
            page_objects[ix] = fabric_canvas.getObjects().slice(1);
            total += page_objects[ix].length;
        }
    });
    return { total, page_objects };
}

export function getScale(page: PDFPageProxy) {
    // const viewport = page.getViewport({ scale: 1 });
    // return Math.min(window.innerWidth / viewport.width, window.innerHeight / viewport.height);
    const mobile = window.innerWidth < window.innerHeight;
    return Math.max(1 / window.devicePixelRatio, mobile ? 0.7 : 1);
}
