import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { chat, chatState, currentChat, chatHistory } from "./interface/chatInterface";
import { RootState } from "../../redux/store";

const initialState: chatState = {
    chatList: [],
    isChatsOpen: false,
    currentChat: {
        _id: null,
        chat_history: [],
        file_id: null,
        name: null,
        user_id: null,
    },
};

export const chatBotSlice = createSlice({
    name: "chatbot",
    initialState,
    reducers: {
        changeAllInfo: (state, action: PayloadAction<chatState>) => {
            state.chatList = action.payload.chatList;
            state.currentChat = action.payload.currentChat;
            state.isChatsOpen = action.payload.isChatsOpen;
        },
        changeChatList: (state, action: PayloadAction<Array<chat>>) => {
            state.chatList = action.payload;
        },
        toggleChat: (state, action: PayloadAction<boolean>) => {
            state.isChatsOpen = action.payload;
        },
        changeCurrentChat: (state, action: PayloadAction<currentChat>) => {
            state.currentChat._id = action.payload._id;
            state.currentChat.file_id = action.payload.file_id;
            state.currentChat.name = action.payload.name;
            state.currentChat.user_id = action.payload.user_id;
            state.currentChat.chat_history = action.payload.chat_history;
        },
        addMessageHistory: (state, action: PayloadAction<chatHistory>) => {
            const newMessages = [...(state.currentChat.chat_history || []), action.payload];
            state.currentChat.chat_history = newMessages;
        },
        changeHistoryMessages: (state, action: PayloadAction<Array<any>>) => {
            const newMessages = action.payload || [];
            state.currentChat.chat_history = newMessages;
        },
        clear: (state) => {
            state.chatList = [];
            state.isChatsOpen = false;
            state.currentChat = {
                _id: null,
                chat_history: [],
                file_id: null,
                name: null,
                user_id: null,
            };
        },
    },
});
export const { changeChatList, toggleChat, changeCurrentChat, addMessageHistory, changeHistoryMessages, changeAllInfo, clear } = chatBotSlice.actions;
export default chatBotSlice.reducer;

export const chatBotData = (state: RootState) => state.chatbot;
