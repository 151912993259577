export type Event = {
    id: string;
    _id: string;
    title: string;
    start: Date;
    end: Date;
    startTime: Date;
    endTime: Date;
    description: string;
    allDay: boolean;
    client_id: string;
    file_id: string;
};

export type paramsGetEvent = {
    name: string;
    value: any;
};

export enum ActionsCalendar {
    MONTH = "MONTH",
    DAY = "DAY",
    WEEK = "WEEK",
    PREV = "PREV",
    NEXT = "NEXT",
    TODAY = "TODAY",
}

export type formCalendar = {
    _id: string;
    title: string;
    client_id: string;
    file_id: string;
    start: any;
    end: any;
    startTime: any;
    endTime: any;
    description: string;
    allDay: boolean;
    id: string;
};
