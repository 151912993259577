export const generalES = {
    "Unsupported-device": "Dispositivo no compatible.",
    titleE404: "LO SENTIMOS, PÁGINA NO ENCONTRADA",
    descE404: "¡La página que buscas no está disponible!",
    myOnlineLawyer: "mIAbogadoEnLinea",
    Welcome: "Bienvenido",
    "administrative site of": "Sitio administrativo de",
    Day: "Día",
    Month: "Mes",
    Year: "Año",
    "You are already close!": "Ya estás cerca!",
    PENDING: "PENDIENTE",
    STARTED: "INICIADO",
    RETRY: "REINTENTANDO",
    FAILURE: "FALLÓ",
    SUCCESS: "COMPLETADO",
    "Create your profile.": "Cree su perfil",
    "Ask your legal advisor": "Pregúntale a tu asesor jurídico",
    ChatBot: "ChatBot",
    Pending: "No encontrado",
    "Well Done!": "¡Bien hecho!",
    "Enter your data and discover how technology can revolutionize your legal practice": "Ingresa tus datos y descubre cómo la tecnología puede revolucionar tu práctica legal",
    "A few steps away from simplifying your work and maximizing your efficiency": "A unos pasos de simplificar tu trabajo y maximizar tu eficiencia",
    Finished: "Finalizado",
    ByStart: "Por iniciar",
    InProcess: "En proceso",
    commentsContact: "Ingrese comentarios",
    comments: "comentarios",
    filexp: "Expediente",
    "Starting process": "El procesamiento de datos ha comenzado",
    Processing: "Procesando los datos",
    preview: "Vista previa",
    reprocess: "Reprocesar gratis (gratis solo si el estatus es FAULIRE es decir fallido)",
    fileNotFound: "Archivo no encontrado",
    "File processing failed": "Error en el procesamiento del archivo",
    "Open doc": "Abrir documento",
    "start chat": "iniciar chat",
    "The price could not be consulted": "No se ha podido consultar el precio",
    "would you want convert to audio": "¿Desea convertir a audio?",
    "Accept our terms and conditions": "Acepta nuestros términos y condiciones",
    "You can see the progress in the file explorer": "Podra ver el progreso en el explorador de archivos",
    modules: {
        title: "Módulos",
        module: "Módulo",
        route: "Ruta",
        icon: "Icono",
        parent: "Padre",
        "User type": "Tipo de usuario",
        administrative: "Administrativo",
        form: {
            "save-module": "Guardar Módulo",
            "edit-module": "Editar Módulo",
            "want-save": "¿Desea registrar este módulo?",
            "want-edit": "¿Desea editar este módulo?",
        },
    },
    common: {
        yes: "Sí",
        no: "No",
        cancel: "Cancelar",
        accept: "Aceptar",
        save: "Guardar",
        delete: "Eliminar",
        edit: "Editar",
        create: "Crear",
        close: "Cerrar",
        clean: "Limpiar",
        Continue: "Continuar",
    },
    permissions: {
        common: {
            crud: {
                All: "Todos",
                Create: "Crear",
                Read: "Leer",
                Update: "Actualizar",
                Delete: "Eliminar",
            },
        },
    },
    usuarios: {
        common: {
            crud: {
                User: "Usuarios",
            },
        },
    },
};
