import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import peticionBack from "../../../../helpers/peticiones";
import { useState, useEffect } from "react";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../buttons/Button";
import { setBalance } from "../../../../core/slices/balance_header/BalanceHeader";
import { RootState } from "../../../../core/redux/store";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { loginData } from "../../../../core/slices/auth/AuthSlicer";

export async function getData(loginInformation: any, dispatch: Dispatch<UnknownAction>, setLoading?: React.Dispatch<React.SetStateAction<boolean>>) {
    if (loginInformation.accessToken) {
        const token = { Authorization: `bearer ${loginInformation.accessToken}` };
        const res = await peticionBack({}, "/wallet/balance", "GET", false, token);
        if (res && res.balance) {
            dispatch(setBalance(res.balance));
        }
        setLoading && setLoading(false);
        return res;
    }
}

export default function BalanceHeader() {
    const [loading, setLoading] = useState(true);
    const balance = useSelector((state: RootState) => state.balance.balance);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginInformation = useSelector(loginData);

    useEffect(() => {
        getData(loginInformation, dispatch, setLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ms-1 header-item d-none d-sm-flex">
            <Button className="btn btn-ghost-secondary fs-5 text-muted 2 rounded-4 light-dark-mode shadow-none" onClick={() => navigate("/wallet")}>
                <FontAwesomeIcon icon={faWallet} />
                <span className="">{!loading ? (balance !== null ? ` ${balance}` : "") : "0"}</span>
            </Button>
        </div>
    );
}
