import { faFileSignature } from "@fortawesome/free-solid-svg-icons";
import { defineFileAction } from "chonky";

export const SignFileAction = defineFileAction({
    id: "sign_file_action",
    button: {
        name: "Firmar documento",
        toolbar: true,
        contextMenu: true,
        icon: faFileSignature,
    },
    contextMenu: {
        name: "Firmar documento",
        group: "Default",
        icon: faFileSignature,
        fileFilter: (file: any) => {
            return file && !file.isDir && file.name.endsWith(".pdf");
        },
    },
});
