import { withTranslation } from "react-i18next";
import MainView from "../../../components/pages/modulos-reportes-admin/views/MainView";
import { solutions } from "../../../core/enums/enums";

function ReportAlmacenamiento({ t }: { t: (f: string) => string }) {
    const columns = [ { data: "userName" },{ data: "name" }, { data: "size" }, { data: "mimetype" }, { data: "createdAt", format: "date" }];
    const headers = [`${t("user name")}`, `${t("file name")}`, `${t("size")}`, `${t("file type")}`, `${t("dateCreation")}`];
    return (
        <>
            <MainView t={t} task={solutions.FILEMANAGER} columns={columns} headers={headers} />
        </>
    );
}
export default withTranslation()(ReportAlmacenamiento);
