import { Form, Input, InputGroup } from "reactstrap";
import Button from "../../../buttons/Button";
import { useTranslation } from "react-i18next";


export const FormSearch = ({ search, onChange, fieldValue, copyClipboard }: { search: any; onChange: any; fieldValue: any; copyClipboard: any }) => {
    const { t } = useTranslation();
    return (
        <Form role="search" onSubmit={search}>
            <InputGroup className="mt-3 mb-3">
                <Input name="busqueda" onChange={onChange} placeholder={`${t("Search")}...`} onCopy={(e) => e.preventDefault()} value={fieldValue} />
                <Button outline color="primary" type="submit" /* onClick={searchF} */>
                    <i className="ri-search-line" />
                </Button>
                <Button outline color="secondary" type="button" onClick={() => copyClipboard()}>
                    <i className="ri-file-copy-2-line" />
                </Button>
            </InputGroup>
        </Form>
    );
};
