import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Row, Col, Pagination, FormGroup, Input, PaginationItem, PaginationLink } from "reactstrap";

export default function Paging({
    currentPageItems = [],
    itemsPerPage = 0,
    currentPage = 0,
    setCurrentPage,
    items = 0,
    setItemsPerPage,
    lengthMenu,
    paging,
    offset = 0,
    setOffset,
    info,
}: {
    currentPageItems: Array<object>;
    itemsPerPage: number;
    currentPage: number;
    setCurrentPage: Dispatch<SetStateAction<number>>;
    items: number;
    setItemsPerPage: Dispatch<SetStateAction<number>>;
    lengthMenu: Array<{ l: number; v: number }>;
    paging: boolean;
    offset: number;
    setOffset: Dispatch<SetStateAction<number>>;
    info: boolean;
}) {
    const [pagination, setPagination] = useState<Array<number>>([]);

    useEffect(() => {
        const newOffset = currentPage * itemsPerPage;
        if (newOffset !== offset) {
            setOffset(newOffset);
        }

        const pages = Math.ceil(items / itemsPerPage);
        const maxPages = Math.min(pages, 5);
        const startPage = Math.max(Math.min(pages - maxPages, currentPage - Math.floor(maxPages / 2)), 0);
        const endPage = Math.min(startPage + maxPages, pages);
        const tempPages = Array.from({ length: endPage - startPage }, (_, i) => i + startPage);
        setPagination(tempPages);
    }, [setOffset, currentPage, itemsPerPage, offset, items]);

    return (
        <Row className="mt-3">
            <Col sm={6} className="info-paging d-flex justify-content-start align-items-center mt-3">
                {info && (
                    <div className="mx-2">
                        Mostrando <b>{itemsPerPage * currentPage === 0 ? (items > 0 ? 1 : 0) : itemsPerPage * currentPage + 1}</b> a <b>{paging ? (itemsPerPage * (currentPage + 1) <= items ? itemsPerPage * (currentPage + 1) : items) : items}</b> de <b>{items}</b> registros
                    </div>
                )}
                {paging && (
                    <FormGroup>
                        <Input
                            type="select"
                            bsSize="sm"
                            style={{ width: "78px" }}
                            value={itemsPerPage}
                            onChange={(e) => {
                                setItemsPerPage(Number(e.currentTarget.value));
                                setCurrentPage(0);
                            }}
                        >
                            {lengthMenu.map((value, index) => (
                                <option value={value.v} key={`op${index}pg`}>
                                    {value.l}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
            </Col>
            <Col sm={6} className="d-flex justify-content-center align-items-center justify-content-sm-end">
                {paging && (
                    <Pagination className="pagination-borderless d-flex mt-3 ">
                        {pagination.length !== 0 ? (
                            <PaginationLink
                                previous
                                disabled={currentPage <= 0}
                                onClick={(e) => {
                                    setCurrentPage(currentPage - 1);
                                    e.preventDefault();
                                }}
                            />
                        ) : null}
                        {pagination.map((page, i) => (
                            <PaginationItem key={`pagItemKey-${i}`} active={page === currentPage}>
                                <PaginationLink
                                    key={`pagLinkKey-${i}`}
                                    disabled={page === currentPage}
                                    onClick={(e) => {
                                        setCurrentPage(page);
                                        e.preventDefault();
                                    }}
                                >
                                    {page + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        {pagination.length !== 0 && (
                            <PaginationLink
                                next
                                disabled={currentPage + 1 >= Math.floor(Number(items) / Number(itemsPerPage))}
                                onClick={(e) => {
                                    setCurrentPage(currentPage + 1);
                                    e.preventDefault();
                                }}
                            />
                        )}
                    </Pagination>
                )}
            </Col>
        </Row>
    );
}
