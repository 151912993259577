import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";

import peticionBack from "../../../../helpers/peticiones";
import toastMessage from "../../../../helpers/toastMessage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET || "");

export default function WalletStripe({ t, email, credits, setStatus }: { t: (f: string) => string; email: string; credits: number; setStatus: any }) {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        getSecret();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSecret() {
        const res = await peticionBack({ quantity: credits }, "/proccess-payment", "POST", false);
        if (res) {
            setClientSecret(res.clientSecret || "");
        } else {
            toastMessage("warning", "Existe un problema con el servicio, intetentelo mas tarde");
        }
    }

    return (
        <div>
            {clientSecret && clientSecret !== "" && (
                <Elements options={{ clientSecret: clientSecret }} stripe={stripePromise}>
                    <CheckoutForm setStatus={setStatus} t={t} />
                </Elements>
            )}
        </div>
    );
}
