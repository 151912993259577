import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { LoadingState } from "./interface/loadingInterface";
import { EnumLoader } from "../../types/TypeLoader";

const initialState: LoadingState = {
  loadingStack: []
}

export const LoaderSlicer = createSlice({
  name: "loader",
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<EnumLoader>) => {
      state.loadingStack.push(action.payload);
    },
    loaded: (state) => {
      state.loadingStack.pop()
    },
  },

});
export const { loading, loaded } = LoaderSlicer.actions
export default LoaderSlicer.reducer

export const loadingData = (state: RootState) => state.loader