import { useTranslation } from "react-i18next";

export default function PrivacyView() {
    const { t } = useTranslation();
    return (
        <div style={{ lineHeight: 1.5, padding: "10%" }} className="p-3">
            <div className="text-center">
                {t("PRIVACY NOTICE AND PRIVACY POLICY")}
                <h2>MIABOGADO EN LINEA</h2>
            </div>

            <div className="text-left mt-5 mb-5 ">
                <strong>
                    "MIABOGADO EN LINEA", {t("recognizes the importance of protecting the privacy of its users' personal information and undertakes to take all necessary measures to ensure its confidentiality and security. This Privacy Notice and Privacy Policy")} ({t("hereinafter, the Notice")})
                    {` ${t("describes the practices that MIABOGADO ONLINEA follows for the collection, use, storage, and protection of personal data provided by its users.")}`}
                </strong>
            </div>

            <h3> 1. {t("Identity and Address of the Responsible")} Identidad y Domicilio del Responsable</h3>

            <p>{t("MIABOGADO ONLINEA is responsible for collecting, using and protecting the personal data that you provide us through our website and any other means related to our services.")}</p>

            <h3> 2.{t("Personal Data We Collect")} </h3>

            <p>
                {t("In compliance with the Federal Law on Protection of Personal Data Held by Private Parties (LFPDPPP), we inform you that the personal data we collect from you are the following")}
                <ul>
                    <li>{t("Full name")}</li>
                    <li>{t("Last name")}</li>
                    <li>{t("Address and geographical location")}</li>
                    <li>{t("Federal Taxpayer Registry (RFC)")}</li>
                    <li>{t("Unique Population Registry Code (CURP)")}</li>
                    <li>{t("Nationality")}</li>
                    <li>{t("Gender")}</li>
                    <li>{t("Phone number")}</li>
                    <li>{t("Email address")}</li>
                    <li>{t("Academic information (bachelor's degree)")}</li>
                    <li>{t("Data from your file or judicial processes (private sentences, lawsuits, recorded hearings, among other related documents)")}</li>
                </ul>
            </p>
            <h3> 3.{t("Purposes of Data Processing")}</h3>
            <p>
                {t("The personal data we collect will be used for the following purposes, necessary for the provision of our services nes the text formatted in lists using HTML")}

                <ol type="a">
                    <li>
                        {t("Primary Purposes")}:
                        <ul>
                            <li>{t("Processing and analysis of documents and judicial content using artificial intelligence.")}</li>
                            <li>{t("Direct communication with the user regarding the requested services.")}</li>
                            <li>{t("Creation of a digital file for the management of your legal information.")}</li>
                            <li>{t("Administration of the contractual relationship and service provision.")}</li>
                            <li>{t("Generation of anonymous global statistics for internal analysis and continuous improvement of services.")}</li>
                        </ul>
                    </li>
                    <li>
                        {t("Secondary Purposes")}:
                        <ul>
                            <li>{t("Use of your data for commercial purposes, such as sending offers and promotions, provided the user has expressly authorized it.")}</li>
                        </ul>
                    </li>
                </ol>
            </p>
            <h3> 4. {t("Security and Confidentiality Measures")} </h3>
            <p>
                {t("MIABOGADO ONLINEA implements strict security measures to protect the confidentiality and integrity of the personal data and documents you provide us. These measures include, but are not limited to")}:
                <ul>
                    <li>{t("Data Encryption All personal data and documents uploaded to the platform will be encrypted during transmission and storage.")}</li>
                    <li>{t("Access Control Only authorized and qualified personnel will have access to your data and documents, under strict confidentiality policies.")}</li>
                    <li>{t("Continuous Monitoring The platform is subject to constant monitoring to detect and mitigate any potential security vulnerabilities.")}</li>
                </ul>
            </p>
            <h3> 5. {t("User Responsibility")}</h3>
            <p>
                {t(
                    "The user is responsible for the veracity and accuracy of the information and documents uploaded to the MIABOGADO ONLINEA platform. It is your obligation to ensure that you are not providing data or documents that could compromise the privacy of third parties or violate any law."
                )}
            </p>
            <h3> 6. {t("Use of Third Party Tools")}</h3>
            <p>{t("MIABOGADO ONLINEA uses open source software tools and third-party services to provide its document processing and analysis services. The user acknowledges and accepts that these third parties may also have access to the data and documents uploaded to the")}</p>
            <h3> 7. {t("Data Sharing")} </h3>
            <p>
                {t("MIABOGADO EN LINEA will not share, sell, or transfer your personal data or case information with any private or governmental entity, except in the following cases:")}
                <ul>
                    <li>{t("When required by law, regulations, or in compliance with a judicial mandate.")}</li>
                    <li>{t("To protect the rights, property, or safety of MIABOGADO EN LINEA, our users, or others.")}</li>
                </ul>
            </p>

            <h3>8. {t("Use of Data for Global Metrics")}</h3>
            <p>
                {t(
                    "The information provided by users may be used by MIABOGADO EN LINEA in an aggregated and anonymous manner to generate global metrics and statistics. These statistics will include data such as the number of users, number of cases processed, number of iterations with the tool, among others. This information will be used solely to improve our services and will not involve the individual identification of users or the disclosure of specific case details."
                )}
            </p>

            <h3>9. {t("Data Subject Rights")}</h3>
            <p>
                {t(
                    "The data subject has the right to access, rectify, cancel, or oppose (ARCO Rights) the processing of their personal data. To exercise these rights, the data subject must submit a written request via the following email address contacto@miabogadoenlinea.mx. The request must contain"
                )}
                :
                <ul>
                    <li>{t("The name of the data subject and address or other means to communicate the response.")}</li>
                    <li>{t("The documents that prove the identity of the data subject or, where appropriate, legal representation.")}</li>
                    <li>{t("A clear and precise description of the personal data for which the data subject seeks to exercise any of the ARCO rights.")}</li>
                    <li>{t("Any other element or document that facilitates the location of the personal data.")}</li>
                </ul>
            </p>

            <h3>10. {t("Modifications to the Privacy Notice")}</h3>
            <p>{t("MIABOGADO EN LINEA reserves the right to modify this Notice at any time. The modifications will be available through our website, and if significant, they will be notified to the user via the registered email address.")}</p>

            <h3>11. {t("Consent")}</h3>
            <p>
                {t("By using the services of MIABOGADO EN LINEA and providing your personal data, the user agrees to the terms and conditions set forth in this Notice. If the user does not agree with these terms, they must refrain from using the platform and its services.")}
                <strong>{t("Contact")}:</strong> {t("If you have any questions or comments regarding this Privacy Notice, please contact us at the following email address: contacto@miabogadoenlinea.mx.")}
            </p>

            <p>{t("This privacy notice is a detailed example and should be reviewed by a lawyer specialized in data protection to ensure it complies with all applicable regulations and adequately protects the company.")}</p>
        </div>
    );
}
