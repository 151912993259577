/* eslint-disable react-hooks/exhaustive-deps */
import Datatable from "../datatable/Datatable";
import { useEffect, useState } from "react";
import { PropsModalDatatable } from "./ModalProps";
import peticionBack from "../../helpers/peticiones";
import createURL from "../../helpers/url";
import { Modal, ModalBody } from "reactstrap";

export default function ModalDatatable({ state, title, tableProps }: PropsModalDatatable) {
    const [refresh, setRefresh] = useState(false);

    async function getData(order: string, records: number, page: number, search: string) {
        let params: any = {
            pathname: tableProps.endpoint,
            searchParams:
                tableProps.petitionProps?.whitParams !== false
                    ? [
                          { name: "order", value: order },
                          { name: "records", value: records },
                          { name: "page", value: page },
                          { name: "search", value: search },
                      ]
                    : [],
        };
        if (tableProps.petitionProps?.filters) params.searchParams = params.searchParams.concat(tableProps.petitionProps.filters);
        const url = createURL(params);
        let res = await peticionBack({}, url, "GET", false);
        if (tableProps.petitionProps?.procesData) tableProps.petitionProps.procesData(res);
        return res;
    }

    function selectRow(row: any) {
        tableProps.onSelect(row);
        if (tableProps.autoClose !== false) onClose();
    }

    function onClose() {
        state[1](false);
    }

    useEffect(() => {
        if (state[0] && tableProps.control === "front") {
            setRefresh(true);
        }
    }, [state[0]]);

    return (
        <>
            <Modal isOpen={state[0]} toggle={() => onClose()} centered>
                <ModalBody>
                    {title && <h5 className="mb-2">{title}</h5>}

                    <h6 className="mb-2">Seleccione un Usuario</h6>
                    <Datatable
                        columns={tableProps.columns}
                        headers={tableProps.headers}
                        columnDefs={tableProps.columnDefs}
                        data={tableProps.data}
                        control={tableProps.control}
                        petition={tableProps.control === "back" ? getData : undefined}
                        stateRefresh={[refresh, setRefresh]}
                        className="p-0"
                        eventsRow={{
                            onClick: tableProps.onClick ? (row: any) => selectRow(row) : undefined,
                            onDoubleClick: tableProps.onDoubleClick ? (row: any) => selectRow(row) : undefined,
                        }}
                        order={tableProps.order}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}
