import * as fabric from "fabric";
import { objectConfig } from "./object-config";

export const FONT_SIZE = 20;
export const FONT_FAMILY = "Helvetica";
export const FONT_WEIGHT = "Bold";
export const FONT_FAMILY_WEIGHT = `${FONT_FAMILY}${FONT_WEIGHT}`;

export function textConfig(text: string, position?: { top?: number; left?: number }) {
    return {
        ...objectConfig(7),
        ...position,
        textAlign: "center",
        originX: "left",
        minScaleLimit: 0.5,
        width: calcWidth(text),
        fontFamily: FONT_FAMILY,
        fontWeight: FONT_WEIGHT,
        fontSize: FONT_SIZE,
        lineHeight: 1,
    } as Partial<fabric.TextboxProps>;
}

function calcWidth(text: string) {
    const canvas2d = document.createElement("canvas").getContext("2d");
    if (canvas2d instanceof CanvasRenderingContext2D) {
        canvas2d.font = `${FONT_SIZE}px ${FONT_FAMILY}`;
        return canvas2d.measureText(text).width;
    }
}
