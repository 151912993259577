import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import logoSm from "../../../../assets/images/miAbogadoNewIcon.png";
import miAbogadoLight from "../../../../assets/images/miAbogadoNewLight.png";
import miAbogadoDark from "../../../../assets/images/miAbogadoNew.png";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import VerticalLayouts from "./components/VerticalLayouts";
import peticionBack from "../../../../helpers/peticiones";
import { toRecursive } from "../../../../helpers/permissions";
import { useSelector } from "react-redux";
import { clear, loginData } from "../../../../core/slices/auth/AuthSlicer";
import { RootState } from "../../../../core/redux/store";
import { loadModules } from "../../../../core/slices/permisos/permissionsSlicer";
import { useDispatch } from "react-redux";

const Sidebar = ({ layoutType }: any) => {
    const loginInformation = useSelector(loginData);
    const dispatch = useDispatch();

    const t = useTranslation();
    const navigate = useNavigate();
    const [modules, setModules] = useState<Array<any>>([]);

    let modulesRedux = useSelector((state: RootState) => state.modules.modules);

    useEffect(() => {
        var verticalOverlay = document.getElementsByClassName("vertical-overlay");
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener("click", function () {
                document.body.classList.remove("vertical-sidebar-enable");
            });
        }
    });

    const addEventListenerOnSmHoverMenu = () => {
        // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute("data-sidebar-size") === "sm-hover") {
            document.documentElement.setAttribute("data-sidebar-size", "sm-hover-active");
        } else if (document.documentElement.getAttribute("data-sidebar-size") === "sm-hover-active") {
            document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
        } else {
            document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
        }
    };
    function formatModules(modules: any[]) {
        return modules.map((module) => {
            return {
                id: module.id,
                label: module.name,
                icon: module.icon,
                link: module.route,
                stateVariables: false,
                parent_id: module.parent_id,
            };
        });
    }

    function changeCollapse(modules: any, id: string) {
        modules.forEach((mood: any, key: any) => {
            if (mood.id === id) {
                mood.stateVariables = !mood.stateVariables;
            } else {
                if (mood.subItems) {
                    changeCollapse(mood.subItems, id);
                }
            }
        });
    }

    function onClick(e: any, item: any, key: any) {
        e.preventDefault();
        let tempModules = modules.slice();
        changeCollapse(tempModules, item.id);
        setModules(tempModules);
        updateIconSidebar(e);
    }

    useEffect(() => {
        getModules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const recursiveMenu = toRecursive(formatModules((modulesRedux ? modulesRedux : []).filter((module: any) => module.permission.r === true)));
        setModules(recursiveMenu);
    }, [modulesRedux]);

    async function getModules() {
        const token = { Authorization: `bearer ${loginInformation.accessToken}` };
        const res = await peticionBack({}, `/me/permissions`, "GET", false, token, true);

        if (res && res.data && res.status === 200) {
            dispatch(loadModules({ modules: res.data }));
        } else if (res.statusCode === 401) {
            dispatch(clear());
            navigate("/login", { replace: true });
        }
    }

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement;
                if (getID) getID.classList.remove("show");
            });
        }
    }

    return (
        <React.Fragment>
            <div className="app-menu navbar-menu">
                <div className="navbar-brand-box">
                    <Link to="explorador-archivos" className="logo logo-dark">
                        <span className="logo-sm">
                            <img src={logoSm} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={miAbogadoDark} alt="" height="17" />
                        </span>
                    </Link>

                    <Link to="explorador-archivos" className="logo logo-light">
                        <span className="logo-sm">
                            <img src={logoSm} alt="" height="22" />
                        </span>
                        <span className="logo-lg">
                            <img src={miAbogadoLight} alt="" height="29" />
                        </span>
                    </Link>
                    <button onClick={addEventListenerOnSmHoverMenu} type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                <React.Fragment>
                    <SimpleBar id="scrollbar" className="h-100">
                        <Container fluid>
                            <div id="two-column-menu"></div>
                            <ul className="navbar-nav" id="navbar-nav">
                                <VerticalLayouts layoutType={layoutType} modules={modules} onClick={onClick} t={t} />
                            </ul>
                        </Container>
                    </SimpleBar>
                    <div className="sidebar-background"></div>
                </React.Fragment>
            </div>
            <div className="vertical-overlay"></div>
        </React.Fragment>
    );
};

export default Sidebar;
