export const tutorials = {
    OCR: "OCR/OCR.mp4",
    TRANSCRIPTION: "TRANSCRIPTION/TRANSCRIPTION.mp4",
    DASHBOARD: "DASHBOARD/DASHBOARD.mp4",
    WALLET: "WALLET/WALLET.mp4",
    REPORTS: "REPORTS/REPORTS.mp4",
    FILEMANAGER: "FILEMANAGER/FILEMANAGER.mp4",
    SCHEDULE: "SCHEDULE/SCHEDULE.mp4",
    CLIENTS: "CLIENTS/CLIENTS.mp4",
    TTS: "/videos/TEXTTOSPECH.mp4",
};
