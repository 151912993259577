import { Alert, Offcanvas, OffcanvasBody, Tooltip } from "reactstrap";
import { useState } from "react";
import Chat from "./Chat";
import React from "react";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { chatBotData, toggleChat, clear } from "../../../core/slices/chatbot/ChatbotSlice";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";

export const ChatBot = ({ t }: { t: (text: string) => string }) => {
    const chatInfo = useSelector(chatBotData);
    const dispatch = useDispatch();
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const toggleChatbot = () => {
        if (chatInfo.isChatsOpen === true) {
            dispatch(clear());
        } else {
            dispatch(toggleChat(!chatInfo.isChatsOpen));
        }
    };
    const toggleTooltip = () => {
        setTooltipOpen(!tooltipOpen);
    };

    return (
        <React.Fragment>
            <div style={{ position: "absolute", bottom: 12, right: 15, width: "60px", height:"60px"}} >
                <div id="tooltipButton" onClick={toggleChatbot} className="btn btn-icon btn-lg p-2" style={{ position: "relative", width: "100%", height: "100%" }}>
                    <img alt="Chatbot" src="/images/ChatbotImg.jpg" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }} />
                </div>
               <Alert isOpen={!tooltipOpen} color="primary" className="fixed-bubble p-1" style={{width:"100px", position: "absolute", top: "5px", left: "-5%", transform: "translateX(-110%)" }}>
                {t("Ask your legal advisor")}
                </Alert>
                <Tooltip placement="top" target="tooltipButton" isOpen={tooltipOpen} toggle={toggleTooltip}>
                    {t("IA")}
                </Tooltip>
            </div>

            <Offcanvas isOpen={chatInfo.isChatsOpen} toggle={toggleChatbot} id="offcanvasScrolling" direction="end">
                <OffcanvasBody className="p-0">
                    <div className="text-center mt-3 mx-3 ">
                        <div className="d-flex justify-content-end ">
                            <FontAwesomeIcon
                                icon={faX}
                                size="xl"
                                color="red "
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    toggleChatbot();
                                }}
                            />
                        </div>
                    </div>
                    <Chat t={t} />
                </OffcanvasBody>
            </Offcanvas>
        </React.Fragment>
    );
};

export default withTranslation()(ChatBot);
