import Folders from "./Folders";
import TranscoIte from "./TranscoIte";
import chatBotIter from "./chatBotIter";
import CustomerBySchedule from "./customerBySchedule";
import CustomerByStatus from "./customerByStatus";
import CustomerByStage from "./custumerByStage";
import Image from "./image";
import IngresForAdmins from "./ingressForAdmin";
import ocrIte from "./ocrIter";
import PDF from "./pdf";
import UsersForAdmins from "./userForAdmin";
import Video from "./video";


export default async function getReportExcel(t: (text: string) => string, report:number, data:any){
    switch(report){    
        case 1: return TranscoIte(t, data.data);
        case 2: return ocrIte(t, data.data);
        case 3: return Folders(t, data.data);
        case 4: return PDF(t, data.data);
        case 5: return Video(t, data.data);
        case 6: return Image(t, data.data);
        case 7: return CustomerByStatus(t, data.data);
        case 8: return CustomerByStage(t, data.data);
        case 9: return CustomerBySchedule(t, data.data);
        case 10: return UsersForAdmins(t, data);
        case 11: return IngresForAdmins(t, data.data);
        case 12: return chatBotIter(t, data.data);
        default: throw new Error(t("The selected report does not exist"), {cause: 404});
    }
}