import { Placeholder, Spinner } from "reactstrap";
import { TypeColor } from "../../core/types/TypeColor";
import { EnumLoader } from "../../core/types/TypeLoader";

type LoderProps = {
    show?: EnumLoader;
    color?: TypeColor;
    bgColor?: TypeColor;
    backdrop?: boolean;
};

export default function Loader({ show, color, bgColor }: LoderProps) {
    if (!show) return null;
    const clase = "position-" + (show.includes("FULLSCREEN") ? "fixed" : "absolute") +
    " top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" + 
    (" bg-" + (bgColor ? bgColor : "light")) + 
    (!show.includes("SOLID") ? ` bg-opacity-75` : "");
    return (
        <div className={clase} style={{ zIndex: "2000" }}>
            {
                !show.includes("SOLID") ?
                <Spinner color={color || "primary"} className="position-fixed" /> :
                <div className="w-100 placeholder-glow p-3 h-100">
                    <Placeholder xs={3} className="rounded" />
                    <Placeholder xs={11} className="rounded" />
                    <Placeholder xs={8} className="rounded" />
                    <Placeholder xs={5} className="rounded" />
                </div>
            }
        </div>
    );
}
