/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useCallback, useEffect, useState } from "react";
import CardContainer from "../../components/cardContainer/CardContainer";
import { ChonkyActions, FileBrowser, FileList, FileToolbar, FileArray, ChonkyFileActionData, FileContextMenu } from "chonky";
import RecursiveFolders from "../../components/pages/explorador-archivos/views/Recursive-capretas";
import UploadFile from "../../components/pages/explorador-archivos/views/UploadFile";
import CreateFolder from "../../components/pages/explorador-archivos/views/CreateFolder";
import peticionBack from "../../helpers/peticiones";
import DeleteFiles from "../../components/pages/explorador-archivos/views/DeleteFiles";
import { withTranslation } from "react-i18next";
import DetermineFileViewer from "../../components/pages/explorador-archivos/views/DetermineFileViwer";
import "../../styles/components/file-manager/recursive-folder.scss";
import SimpleBar from "simplebar-react";
import Button from "../../components/buttons/Button";
import toastMessage from "../../helpers/toastMessage";
import { useSelector, useDispatch } from "react-redux";
import { chatBotData, changeAllInfo } from "../../core/slices/chatbot/ChatbotSlice";
import "../../styles/components/file-manager/loader-center.css";
import { loginData } from "../../core/slices/auth/AuthSlicer";
import { CarrouselModal } from "../admin_user/Banner/BannerModal";
import { getData } from "../../components/layout/protected/components/BalanceHeader";
import { useNavigate } from "react-router-dom";
import { SignFileAction } from "../../components/pages/explorador-archivos/views/SignFileAction";
import { EnumLoader } from "../../core/types/TypeLoader";
import Loader from "../../components/loaders/Loader";
import ModalCharge from "../../components/modals/modalCharge";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { getCostSolution } from "../../helpers/getCostsSolutions";
import { solutions } from "../../core/enums/enums";

function ExploradorArchivos({ t }: { t: (f: string) => string }) {
    const [loadingLOcal, setLoading] = useState<boolean>(false);
    const [currentFolder, setCurrentFolder] = useState<string | undefined>();
    const [open, setOpen] = useState<boolean>(false);
    const [openCreateFolder, setOpenCreateFolder] = useState<boolean>(false);
    const [openDeleteFiles, setOpenDeleteFiles] = useState<boolean>(false);
    const [folders, setFolders] = useState<any>([]);
    const [files, setFiles] = useState<{ id: string; name: string; modDate: Date; isDir: boolean }[]>([]);
    const [fileExplorer, setFileExplorer] = useState<FileArray>([]);
    const [showFiles, setShowFiles] = useState<any>([]);
    const [fileSelected, setFileSelected] = useState<any>(undefined);
    const [refreshContent, setRefreshContent] = useState<boolean>(false);
    const [isFolderRoot, setIsFolderRoot] = useState<boolean>(false);
    const [viewFile, setViewFile] = useState<boolean>(false);
    const [fileActions, setFileActions] = useState<any[]>([]);
    const chatInformation = useSelector(chatBotData);
    const dispatch = useDispatch();
    const esMobile = window.innerWidth < 950;
    const loginInformation = useSelector(loginData);
    const navigate = useNavigate();
    const [modalChargeIn, setModalChargeIn] = useState<boolean>(false);
    const [costSolution, setCostSolution] = useState<number | null>();

    const messages = {
        "chonky.toolbar.searchPlaceholder": "Buscar",
        "chonky.toolbar.visibleFileCount": "Elemento",
        "chonky.toolbar.selectedFileCount": "Seleccionado",
        "chonky.actions.upload_files.button.tooltip": "Subir archivos",
        "chonky.toolbar.hiddenFileCount": "Ocultos",
        "chonky.fileList.nothingToShow": "¡Nada por mostrar!",
        "chonky.contextMenu.browserMenuShortcut": "Menú del navegador: {shortcut}",
        "chonky.actionGroups.Actions": "Acciones",
        "chonky.actionGroups.Options": "Opciones",
        [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: "Abrir selección",
        [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: "Abrir la carpeta principal",
        [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: "Nueva carpeta",
        [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: "Crear una nueva carpeta",
        [`chonky.actions.${ChonkyActions.UploadFiles.id}.button.name`]: "Añadir archivo",
        [`chonky.actions.${ChonkyActions.DownloadFiles.id}.button.name`]: "Descargar archivo",
        [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: "Eliminar archivos",
        [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: "Seleccionar todo",
        [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: "Restablecer selección",
        [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: "Mostrar lista",
        [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: "Mostrar iconos",
        [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: "Ordenar por nombre",
        [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: "Ordenar por tamaño",
        [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: "Ordenar por fecha",
        [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: "Archivos ocultos",
        [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: "Carpetas al principio",
        "chonky.actions.sign_file_action.button.name": t("sign.Sign Document"),
    };

    const browserLanguage = navigator.language.split("-")[0];

    useEffect(() => {
        getFolders();
    }, []);

    async function getChatCost() {
        setLoading(true);
        const costSolution = await getCostSolution(solutions.CHATBOT);
        if (costSolution) {
            setCostSolution(costSolution);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (isFolderRoot) {
            setFiles([]);
            setFolders([]);
            getFolders();
        }
    }, [isFolderRoot]);

    useEffect(() => {
        if (currentFolder) {
            if (refreshContent) {
                setFiles([]);
                getContent(currentFolder);
            }
        }
    }, [refreshContent]);

    useEffect(() => {
        if (fileSelected) {
            const actions: any[] = [ChonkyActions.UploadFiles, ChonkyActions.OpenSelection, ChonkyActions.DeleteFiles];
            if (fileSelected.mimetype?.includes("pdf") && !esMobile) {
                actions.push(SignFileAction);
            }
            setFileActions(actions);
        } else {
            setFileActions([ChonkyActions.UploadFiles, ChonkyActions.OpenSelection, ChonkyActions.DeleteFiles]);
        }
    }, [fileSelected]);

    useEffect(() => {
        setFileSelected(undefined);
        if (currentFolder) {
            setFiles([]);
            getContent(currentFolder);
            setFileActions([ChonkyActions.UploadFiles, ChonkyActions.OpenSelection, ChonkyActions.DeleteFiles]);
        } else {
            setFileActions([ChonkyActions.OpenSelection, ChonkyActions.DeleteFiles]);
        }
    }, [currentFolder]);

    async function getFolders() {
        if (loginInformation.accessToken) {
            setLoading(true);
            const token = { Authorization: `bearer ${loginInformation.accessToken}` };
            const res = await peticionBack({}, `/folders/init-folders`, "GET", false, token);
            if (res) {
                if (res.length > 0) {
                    res.forEach((fol: any) => {
                        fol.isDir = true;
                        fol.id = fol._id;
                    });
                    setFolders(res);
                    setShowFiles(res);
                } else {
                    setShowFiles([]);
                    setCurrentFolder(undefined);
                }
            } else {
                setFolders([]);
            }
            if (isFolderRoot) {
                setIsFolderRoot(false);
            }
            setLoading(false);
        }
    }

    async function getContent(currentFolder: string) {
        setLoading(true);
        if (currentFolder && !costSolution) {
            getChatCost();
        }
        const res = await peticionBack({}, `/folders/content/${encodeURIComponent(currentFolder)}`, "GET", false);
        if (res) {
            if (res.length > 0) {
                res.forEach((fol: any) => {
                    fol.id = fol._id;
                });
            }
            const newContent = res.map((folder: any) => ({
                ...folder,
                id: folder._id,
                subFolders: [],
            }));
            setFolders(res.find((folder: any) => folder._id === currentFolder)?.folders || []);
            setFiles(
                res[0].files.map((file: any) => ({
                    id: file._id,
                    name: file.name,
                    modDate: file.updatedAt,
                    isDir: false,
                    mimetype: file.mimetype,
                }))
            );
            setShowFiles(updateShowFilesWithContent(showFiles, newContent));
        }
        setLoading(false);
        if (refreshContent) {
            setRefreshContent(false);
        }
    }
    function updateShowFilesWithContent(showFiles: any[], content: any): any[] {
        const updatedShowFiles = showFiles.map((folder: any) => {
            const matchedContent = content.find((c: any) => c.id === folder._id);
            if (matchedContent) {
                return {
                    ...folder,
                    subFolders: matchedContent.folders.map((subfolder: any) => ({
                        ...subfolder,
                        id: subfolder._id,
                    })),
                };
            } else if (folder.subFolders) {
                return {
                    ...folder,
                    subFolders: updateShowFilesWithContent(folder.subFolders, content),
                };
            } else {
                return folder;
            }
        });

        return updatedShowFiles;
    }
    const handleFileOptions = useCallback(
        (data: ChonkyFileActionData) => {
            if (ChonkyActions.MouseClickFile.id === data.id) {
                if (data.payload.file.isDir) {
                    setFileSelected(undefined);
                } else {
                    const dataFile = data.payload.file;
                    if (data.payload.clickType === "double") {
                        setFileSelected(dataFile);
                        if (dataFile.name.endsWith(".docx")) {
                            setModalChargeIn(true);
                        } else {
                            setViewFile(true);
                        }
                    } else if (data.payload.clickType === "single") {
                        if (fileSelected && fileSelected.id === dataFile.id) {
                            setFileSelected(undefined);
                        } else {
                            setFileSelected(dataFile);
                        }
                    }
                }
            }
            if (data.id === ChonkyActions.OpenFiles.id) {
                if (data.payload.files && data.payload.files.length !== 1) return;
                if (!data.payload.targetFile) return;
                if (data.payload.targetFile.isDir) {
                    if (data.payload.targetFile.id !== "home") {
                        setCurrentFolder(data.payload.targetFile.id);
                    } else {
                        setCurrentFolder(undefined);
                    }
                } else {
                    setFileSelected(data.payload.targetFile);
                }
            } else if (data.id === ChonkyActions.DownloadFiles.id) {
                data.state.selectedFiles.forEach(async (file) => {});
            } else if (data.id === ChonkyActions.UploadFiles.id) {
                setOpen(true);
            } else if (data.id === ChonkyActions.CreateFolder.id) {
                setOpenCreateFolder(true);
            } else if (data.id === ChonkyActions.DeleteFiles.id) {
                setOpenDeleteFiles(true);
            } else if (data.id === ChonkyActions.OpenSelection.id) {
                if (data.state.selectedFiles[0].isDir) {
                    setCurrentFolder(data.state.selectedFiles[0].id);
                } else {
                    setViewFile(true);
                }
            } else if (data.id === ChonkyActions.ChangeSelection.id) {
            } else if (data.id === SignFileAction.id) {
                if (data.state.selectedFiles.length === 1) {
                    navigate("firmar/" + data.state.selectedFiles[0].id);
                }
            }
        },
        [currentFolder, fileSelected]
    );

    useEffect(() => {
        if (currentFolder) {
            const fileExplorer = [];
            if (folders) {
                fileExplorer.push(
                    ...folders
                        .filter((folder: any) => folder.folder_id === currentFolder)
                        .map((folder: any) => {
                            return {
                                id: folder._id || "",
                                name: folder.name,
                                isDir: true,
                            };
                        })
                );
                fileExplorer.push(...files);
                setFileExplorer(fileExplorer);
            }
        } else {
            const fileExplorer = folders
                .filter((folder: any) => folder.folder_id === undefined)
                .map((folder: any) => {
                    return {
                        id: folder._id || "",
                        name: folder.name,
                        isDir: true,
                    };
                });
            fileExplorer.push(...files);
            setFileExplorer(fileExplorer);
        }
    }, [folders, files, currentFolder]);

    async function handleInicio() {
        setCurrentFolder(undefined);
        setFolders([]);
        setFiles([]);
        await getFolders();
    }

    async function sendToChat() {
        dispatch(loading(EnumLoader.SUBTLE));
        const res = await peticionBack({}, `/files/${encodeURIComponent(fileSelected.id)}`, "GET", false);
        if (res && res.queues.length > 0) {
            const lastObject = res.queues[res.queues.length - 1];
            switch (lastObject.status) {
                case "SUCCESS":
                    const resChat = await peticionBack({ file_id: fileSelected.id }, `/chat/new`, "POST", false);
                    dispatch(changeAllInfo({ ...chatInformation, currentChat: resChat, isChatsOpen: true }));
                    getData(loginInformation, dispatch);
                    break;
                case "FAILURE":
                    toastMessage("warning", `${t("File processing failed")}`);
                    break;
                case "PENDING":
                    toastMessage("warning", `${t("Processing")}`);
                    break;
                case "STARTED":
                    toastMessage("warning", `${t("Starting process")}`);
                    break;
                default:
                    toastMessage("warning", `${t("Process the file")}`);
            }
        } else {
            toastMessage("warning", `${t("Process the file")}`);
        }
        dispatch(loaded());
    }

    async function getUrlEditor(id?: string, replace?: boolean) {
        dispatch(loading(EnumLoader.SUBTLE));
        const res = await peticionBack({ file_id: id ? id : fileSelected.id, replace: replace ? replace : false }, "/ner", "POST", true);
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + "MIAbogadoEnLinea");
            switch (res.status) {
                case "STARTED":
                    toastMessage("info", t("The data is being processed to test"));
                    break;
                case "SUCCESS":
                    navigate(`/editor/${fileSelected.id}`, { replace: true });
                    break;
                case "PENDING":
                    toastMessage("info", t("The data is being processed to test"));
                    break;
                case "FAILURE":
                    toastMessage("error", t("The file could not be processed"));
                    break;
                default:
                    toastMessage("warning", t("The data is being processed to test"));
            }
        }
        dispatch(loaded());
    }

    return (
        <Fragment>
            <h1 className="text-md-center mb-4">{t("solution file manager")}</h1>
            <CardContainer title={t("myOnlineLawyer")} tutorial="FILEMANAGER" className="mb-5">
                <CarrouselModal />
                <div id="menu-container45" className="d-flex flex-column flex-md-row" style={{ height: "450px" }}>
                    <div className="tree-folder position-relative p-2">
                        <p className="text-muted mb-2">{t("Folders")}</p>
                        {loadingLOcal && <Loader show={EnumLoader.SUBTLE} />}
                        <SimpleBar data-simplebar-direction="rtl" style={{ maxHeight: "450px" }}>
                            {showFiles?.map((m: any, ix: number) => (
                                <RecursiveFolders t={t} folder={m} setCurrentFolder={setCurrentFolder} currentFolder={currentFolder as string} key={"mr" + m.name + ix} setRefreshContent={setRefreshContent} setIsFolderRoot={setIsFolderRoot} />
                            ))}
                        </SimpleBar>
                    </div>
                    <div className="p-3 py-0" style={{ width: "100%", height: "100%" }}>
                        <FileBrowser files={fileExplorer} onFileAction={handleFileOptions} i18n={{ locale: browserLanguage, messages: browserLanguage === "es" ? messages : {} }} fileActions={fileActions} disableDefaultFileActions={esMobile}>
                            <div className="d-flex">
                                <div className="p-2">
                                    <Button onClick={handleInicio} color="success" capitalize className="btn-sm" outline soft>
                                        {t("home-file-manager")}
                                    </Button>
                                </div>
                                <div className="p-2">
                                    <Button onClick={() => setOpenCreateFolder(true)} color="success" capitalize className="btn-sm" outline soft>
                                        {t("create-folder")}
                                    </Button>
                                </div>
                                {fileSelected !== undefined && fileSelected.isDir === false && fileSelected?.name?.includes("docx") && (
                                    <div className="p-2">
                                        <Button onClick={() => setModalChargeIn(true)} color="success" capitalize className="btn-sm" outline soft>
                                            {t("Open doc")}
                                        </Button>
                                    </div>
                                )}
                                {fileSelected !== undefined && fileSelected.isDir === false && fileSelected.name && !fileSelected.name.includes("docx") && (
                                    <div className="p-2">
                                        <Button onClick={() => sendToChat()} color="info" capitalize className="btn-sm" outline soft>
                                            {`${t("start chat")} ${costSolution} `}
                                            <i className="ri-money-dollar-circle-line" style={{ fontSize: "1em" }} />
                                        </Button>
                                    </div>
                                )}
                                <div className="ms-auto p-2">
                                    <Button onClick={() => setRefreshContent(true)} color="primary" className="btn-icon btn-sm" outline soft>
                                        {" "}
                                        <i className="bx bx-refresh bx-sm" />{" "}
                                    </Button>
                                </div>
                            </div>
                            <div className="mb-3 mt-1">
                                <FileToolbar />
                            </div>
                            <FileList />
                            <FileContextMenu />
                        </FileBrowser>
                    </div>
                </div>
                <CreateFolder t={t} currentFolder={currentFolder} openCreateFolder={openCreateFolder} setOpenCreateFolder={setOpenCreateFolder} setRefreshContent={setRefreshContent} setIsFolderRoot={setIsFolderRoot} />
                <UploadFile t={t} currentFolder={currentFolder as string} open={open} setOpen={setOpen} setRefreshContent={setRefreshContent} />
                {fileSelected && <DeleteFiles t={t} idFolder={fileSelected} openDeleteFiles={openDeleteFiles} setOpenDeleteFiles={setOpenDeleteFiles} setRefreshContent={setRefreshContent} />}
                {viewFile === true && <DetermineFileViewer t={t} idFile={fileSelected} setViewFile={setViewFile} />}
            </CardContainer>
            <ModalCharge t={t} functionToExecut={getUrlEditor} message={t("open text editor")} showCost={false} modalChargeIn={modalChargeIn} setModalChargeIn={setModalChargeIn} iconRemix="ri-file-edit-line" />
        </Fragment>
    );
}

export default withTranslation()(ExploradorArchivos);
