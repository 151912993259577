const colors = ['solid', 'subtle'] as const;
type TypeLoader = typeof colors[number];

export type { TypeLoader }

export enum EnumLoader {
    SOLID = "SOLID",
    SUBTLE = "SUBTLE",
    FULLSCREEN_SOLID = "FULLSCREEN_SOLID",
    FULLSCREEN_SUBTLE = "FULLSCREEN_SUBTLE"
}