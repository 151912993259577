import ReactApexChart from "react-apexcharts";
import { getChartColorsArrayD, getChartColorsArrayf } from "./ChartsDynamicColor";

const Line = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var chartLineColumnColors = getChartColorsArrayf(dataColors);

    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total,
    }));

    const series = [
        {
            name: "Total videos",
            type: "column",
            data: parsedData,
        },
        {
            name: "Total videos",
            type: "line",
            data: parsedData,
        },
    ];
    const options: any = {
        chart: {
            toolbar: {
                show: !1,
            },
        },
        stroke: {
            width: [0, 4],
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1],
        },
        labels: ["01 Jan 2001", "02 Jan 2001", "03 Jan 2001", "04 Jan 2001", "05 Jan 2001", "06 Jan 2001", "07 Jan 2001", "08 Jan 2001", "09 Jan 2001", "10 Jan 2001", "11 Jan 2001", "12 Jan 2001"],
        xaxis: {
            type: "datetime",
        },
        yaxis: [
            {

            },
            {
                opposite: true,
            },
        ],
        colors: chartLineColumnColors,
    };
    return (
        <>
            <ReactApexChart dir="ltr" className="apex-charts" options={options} series={series} type="line" height={350} />
        </>
    );
};

const BasicLineCharts = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var linechartBasicColors = getChartColorsArrayf(dataColors);
    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total,
    }));
    const series = [
        {
            data: parsedData,
            name: "Total",
        },
    ];
    var options: any = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        colors: linechartBasicColors,

        xaxis: {
            categories: data.map((item: any) => item.label),
        },
    };
    return (
        <>
            <ReactApexChart dir="ltr" options={options} series={series} type="line" height="350" className="apex-charts" />
        </>
    );
};

const SteplineChart = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var SteplineChartColors = getChartColorsArrayD(dataColors);

    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total,
    }));

    const series = [
        {
            data: parsedData,
            name: "Total",
        },
    ];

    const options: any = {
        stroke: {
            curve: "stepline",
        },
        dataLabels: {
            enabled: !1,
        },
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: !1
            }
        },
        markers: {
            hover: {
                sizeOffset: 4,
            },
        },
        colors: SteplineChartColors,
    };

    return (
        <>
            <ReactApexChart dir="ltr" options={options} series={series} type="line" height={350} className="apex-charts" />
        </>
    );
};

const GradientCharts = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var GradientChartsColors = getChartColorsArrayf(dataColors);
    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total,
    }));

    const series = [
        {
            data: parsedData,
            name: "Total",
        },
    ];
    var options : any = {
        chart: {
            height: 350,
            type: 'line',
            toolbar: {
                show: false
            },
        },
        stroke: {
            width: 7,
            curve: 'smooth'
        },
        xaxis: {
            type: 'category',
            categories:data.map((item: any) => item.label),
            tickAmount: 10,
        },
        title: {
            text: 'Social Media',
            align: 'left',
            style: {
                fontWeight: 500,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                gradientToColors: ["#0ab39c"],
                shadeIntensity: 1,
                type: 'horizontal',
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100, 100, 100]
            },
        },
        markers: {
            size: 4,
            colors: GradientChartsColors,
            strokeColors: "#fff",
            strokeWidth: 2,
            hover: {
                size: 7,
            }
        },
        yaxis: {
            min: -10,
            max: 40,
            title: {
                text: 'Engagement',
            },
        }
    };

    return (
        <>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="line"
                height={350}
                className="apex-charts"
            />
        </>
    );
};

const CustomDataLabel = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var chartDatalabelsBarColors = getChartColorsArrayf(dataColors);
    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total,
    }));

    const series = [
        {
            data: parsedData,
            name: "Total",
        },
    ];
    var options :any = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: chartDatalabelsBarColors,
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val :any, opt :any) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: data.map((item: any) => item.label),
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return '';
                    }
                }
            }
        }
    };
    return (
        <>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </>
    );
};

export { BasicLineCharts, Line, SteplineChart, GradientCharts, CustomDataLabel };
