import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faVideo, faImage } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { chat } from "../types/Chat";

export const ContentList = ({ chat, userChatOpen }: { chat: chat; userChatOpen: any }) => {
    function getIconByFormat(nameFile: string) {
        if (nameFile.includes(".pdf")) {
            return <FontAwesomeIcon icon={faFilePdf} color="red" size="2x" />;
        } else if (nameFile.includes(".mp4")) {
            return <FontAwesomeIcon icon={faVideo} color="blue" size="2x" />;
        } else if (nameFile.includes(".mp4")) {
            return <FontAwesomeIcon icon={faVideo} color="blue" size="2x" />;
        } else if (nameFile.includes(".jpg") || nameFile.includes(".jpg") || nameFile.includes(".jpeg") || nameFile.includes(".png")) {
            return <FontAwesomeIcon icon={faImage} color="green" size="2x" />;
        }
    }
    return (
        <div className="d-flex align-items-center justify-content-start m-3" onClick={() => userChatOpen(chat)}>
            {getIconByFormat(chat.name)}
            <Link to={"#"}>{chat.name}</Link>
        </div>
    );
};
