import { loginEN } from "./traductions/en/login";
import { formsEN } from "./traductions/en/forms";
import { fileManagerEN } from "./traductions/en/fileManager";
import { generalEN } from "./traductions/en/general";
import { dashboardEN } from "./traductions/en/dashboard";
import { walletEN } from "./traductions/en/wallet";
import { organizationsEN } from "./traductions/en/organizations";
import { clientesEN } from "./traductions/en/clientes";
import { adminReportesEN } from "./traductions/en/adminReportes";
import { bannerEN } from "./traductions/en/banner";
import { agendaEn } from "./traductions/en/agenda";
import { tts } from "./traductions/en/tts";
import { sign } from "./traductions/en/sign";
import { landingUS } from "./traductions/en/landing";
import { privacidadEn } from "./traductions/en/privacidad";

export const traductionsEN = {
    ...generalEN,
    ...loginEN,
    ...formsEN,
    ...fileManagerEN,
    ...dashboardEN,
    ...walletEN,
    ...organizationsEN,
    ...clientesEN,
    ...adminReportesEN,
    ...bannerEN,
    ...agendaEn,
    ...landingUS,
    ...privacidadEn,
    tts,
    sign,
};
