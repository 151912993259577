import { useEffect, useState } from "react";
import { eliminarTildes } from "../../../../helpers/formats";
import { useTranslation } from "react-i18next";
import { findWordInText } from "../../../../helpers/searchWord/searchWord";
import SimpleBar from "simplebar-react";
type Content = {
    page: number;
    text: string[];
};

export const ContentOcr = ({ content, currentPage, textSearched }: { content: Array<Content>; currentPage: number; textSearched: string }) => {
    const { t } = useTranslation();
    const [text, setText] = useState<Array<any>>([]);

    useEffect(() => {
        changeContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textSearched]);

    function changeContent() {
        if (textSearched !== "") {
            markTexts(content[currentPage].text);
        } else {
            setText(content[currentPage].text);
        }
    }

    useEffect(() => {
        changeContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    function markTexts(element: any) {
        let textSearchedCleaned = eliminarTildes(textSearched.toLowerCase());
        let filter = findWordInText(element.join(), textSearchedCleaned);
        let texts = filter.map((hit, index) => {
            return getContentElement(hit, index);
        });
        setText(texts);
        return texts;
    }

    function getContentElement(hit: { text: string; match: boolean }, index: any) {
        return (
            <span>
                <span key={index} className={hit.match ? "bg-warning" : ""}>
                    {hit.text}
                </span>
            </span>
        );
    }
    return (
        <div className="text-justify mt-3 border border-2 rounded py-1 px-2 mb-5">
            <strong>{t("Content")}:</strong>
            {content.length >= 0 && (
                <SimpleBar autoHide={false} style={{ maxHeight: "75vh" }} className="px-3 t-justify mb-2">
                    {text}
                </SimpleBar>
            )}
        </div>
    );
};
