import Flatpickr from "react-flatpickr";
import { DateInputProps } from "./InputProps";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function InputDate({ name, label, placeholder, value, onChange, onBlur, error, touched, required, readOnly, disabled, readonlyPlain, type, wTime, min, max, maxTime, minTime, disabledDates, enabledDates, inline, weeks, renderDay, className, clereable, disabledLocal }: DateInputProps) {
    const isTime = type === "time";
    const isMulti = type === "multiple";

    const options = {
        altInput: true,
        altFormat: isTime ? "H:i" : wTime ? "d/m/Y H:i" : "d/m/Y",
        dateFormat: isTime ? "H:i" : wTime ? "Y-m-d H:i" : "Y-m-d",
        minDate: min,
        maxDate: max,
        disable: disabledDates || [],
        enable: enabledDates || undefined,
        mode: type || "single",
        noCalendar: isTime,
        enableTime: wTime || isTime,
        minTime: minTime,
        maxTime: maxTime,
        conjunction: isMulti ? ", " : undefined,
        inline: inline,
        weekNumbers: weeks,
        allowInput: true,
        locale: disabledLocal && disabledLocal === true ? undefined : Spanish,
    };
    if (!enabledDates) delete options.enable;
    const clase = "form-control " + (className || "") + (!!error && touched ? " is-invalid" : "");

    const handleClear = () => {
        onChange && onChange({ name, value: "" });
    };
    
    return (
        <FormGroup>
            {label && (
                <Label for={name}>
                    {label} {required && <span className="text-danger">*</span>}
                </Label>
            )}

            <Flatpickr
                data-enable-time
                id={name}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(selectedDates: Date[]) => (onChange ? onChange({ name, value: selectedDates }) : undefined)}
                className={clase}
                options={options}
                onDayCreate={(sd, cd, _ref, d: HTMLElement) => (renderDay ? renderDay(sd, cd, d) : undefined)}
                disabled={disabled}
            />
            {clereable && (
                <span className=" cursor-pointer" onClick={handleClear}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            )}
            <FormFeedback>{error}</FormFeedback>
        </FormGroup>
    );
}
