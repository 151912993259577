export const loginEN = {
    titleE404: "SORRY, PAGE NOT FOUND",
    descE404: "The page you are looking for is not available!",
    "title-login": "Sign In",
    "Welcome Back !": "Welcome back!",
    "Welcome to the application!": "Welcome to the application!",
    "Sign in to continue to MIABOGADO EN LINEA.": "Sign in to continue to MIABOGADO EN LINEA.",
    Email: "Email",
    Password: "Password",
    "Forgot password?": "Forgot password?",
    "Remember me": "Remember me",
    "Sign In": "Sign In",
    "Sign In with": "Sign In with",
    Signup: "Sign Up",
    "Do you have an account?": "Do you have an account?",
    "Don't have an account": "Don't have an account?",
    "Give a new meaning to the organization": "Give a new meaning to the organization",
    "Bring your documents to life: our OCR system finds and converts text with astonishing accuracy": "Bring your documents to life: our OCR system finds and converts text with astonishing accuracy",
    "Every word counts: With our video transcription, you can easily access key moments.": "Every word counts: With our video transcription, you can easily access key moments.",
    "Bringing AI to you.": "Bringing AI to you.",
    "solution file manager": "File Manager",
    "Close session": "Close session",
    "Are you sure you want to close the session?": "Are you sure you want to close this session?",
    "Open sessions": "Open sessions",
    "Forgot your password": "Forgot your password?",
    recover: "Recover password",
    sendComments: "Send information",
    sendMail: "Send Mail",
    resendMail: "Send another Mail",
    "password recovery": "Password recovery",
    confirmPassword: "Confirm Password",
    "Passwords do not match": "Passwords do not match",
    "Please confirm your password": "Please confirm your password",
    "you haven't received an email": "you haven't received an email?",
    "An email has been sent for password recovery. Please check your inbox": "An email has been sent for password recovery. Please check your inbox.",
    "In this section, we invite you to express your experience using MiAbogado en Línea. Our goal is to provide you with a set of intuitive and efficient tools that facilitate your daily work, optimize your processes and support you in achieving professional excellence. We value your feedback, as it allows us to continue improving and ensure that you are equipped with the best solutions to be the most outstanding lawyer in Mexico":
        "In this section, we invite you to express your experience using MiAbogado en Línea. Our goal is to provide you with a set of intuitive and efficient tools that facilitate your daily work, optimize your processes and support you in achieving professional excellence. We value your feedback, as it allows us to continue improving and ensure that you are equipped with the best solutions to be the most outstanding lawyer in Mexico.",
    "Share your opinion or comments with us": "Share your opinion or comments with us",
};
