import { withTranslation } from "react-i18next";
import { Form, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../buttons/Button";
import { Card, Col, Container, Row, Spinner } from "reactstrap";
import { Fragment, useState } from "react";
import * as Yup from "yup";
import peticionBack from "../../../../helpers/peticiones";
import { useFormik } from "formik";
import { OnChangeParams } from "../../../inputs/InputProps";
import { FooterView } from "../views/FooterView";
import { CarouselAuth } from "./CarouselAuthView";
import InputPassword from "../../../inputs/InputPassword";

function SetNewPassword({ t }: { t: (f: string) => string }) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tokenUrl = [searchParams.get("header") || "", searchParams.get("payload") || "", searchParams.get("sign") || ""].join(".");

    const formValidations = Yup.object().shape({
        password: Yup.string().required(t("Enter a password")),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")], t("Passwords do not match"))
            .required(t("Please confirm your password")),
    });
    const defaultForm = {
        password: "",
        confirmPassword: "",
    };

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async () => {
            setLoading(true);
            const token = { Authorization: `bearer ${tokenUrl}` };
            const res = await peticionBack({ password: values.password }, "/auth/change-password", "POST", true, token, true);

            if ((res.status === 201 || res.status === 200) && !res?.erro) {
                setLoading(false);
                goToLOgin();
            }
            setLoading(false);
        },
        enableReinitialize: true,
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    function goToLOgin() {
        navigate("/login", { replace: true });
    }
    return (
        <>
            <Fragment>
                <div className="auth-page-wrapper auth-bg-cover d-flex justify-content-center align-items-center min-vh-100">
                    <div className="bg-overlay"></div>
                    <div className="auth-page-content overflow-hidden pt-lg-5">
                        <Container>
                            <Card className="overflow-hidden">
                                <Row>
                                    <Col xl={6} lg={6} md={6}>
                                        <CarouselAuth t={t} />
                                    </Col>
                                    <Col xl={6} lg={6} md={6}>
                                        <div className="p-5 m-5">
                                            <h3 className="mb-5">{t("password recovery")}</h3>
                                            <Form onSubmit={handleSubmit} onReset={handleReset}>
                                                <div className="position-relative">
                                                    <InputPassword name="password" value={values.password} label={t("Password")} touched={touched.password} error={errors.password} onChange={onChange} onBlur={handleBlur} showPasswordMessageValidation={true} />
                                                </div>
                                                <div className="position-relative">
                                                    <InputPassword name="confirmPassword" value={values.confirmPassword} label={t("confirmPassword")} touched={touched.confirmPassword} error={errors.confirmPassword} onChange={onChange} onBlur={handleBlur} showPasswordMessageValidation={true} />
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="w-100 btn-load" type="submit">
                                                        <span className="">
                                                            {loading && (
                                                                <Spinner size="sm" style={{ height: "16px", width: "16px" }}>
                                                                    Loading...
                                                                </Spinner>
                                                            )}
                                                            <span className="ms-2">{t("common.accept")}</span>
                                                        </span>
                                                    </Button>
                                                </div>
                                            </Form>
                                            <div className=" text-center mt-5">
                                                <span className="mb-0">
                                                    {t("Do you have an account?")}
                                                    <p className="fw-semibold text-primary text-decoration-underline ms-2" style={{ cursor: "pointer" }} onClick={goToLOgin}>
                                                        {t("Sign In")}
                                                    </p>
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Container>
                    </div>

                    <footer className="footer">
                        <Container>
                            <FooterView />
                        </Container>
                    </footer>
                </div>
            </Fragment>
        </>
    );
}

export default withTranslation()(SetNewPassword);
