import { Modal } from "reactstrap";
import ViewTranscoder from "../pages/transcoder/views/VistaTranscoder";
import { DataItem } from "../../pages/transcodificador/dto/TranscoderDto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SimpleBar from "simplebar-react";

export default function ModalTransco({ t, open, row, setIsVideo, setViewFile, setIsProcessed }: { t: (text: string) => string; open: boolean; row: DataItem; setIsVideo?: any; setViewFile?: any; setIsProcessed?: any }) {
    const handleOnClose = () => {
        setIsProcessed(false);
        setIsVideo(false);
        setViewFile(false);
    };
    return (
        <>
            <Modal isOpen={open} onClosed={handleOnClose} size="xl" centered scrollable={false} fullscreen keyboard>
                <div className="mt-3 d-flex justify-content-end mx-3">
                    <FontAwesomeIcon
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        size="2x"
                        icon={faXmark}
                        onClick={() => {
                            handleOnClose();
                        }}
                    />
                </div>
                <SimpleBar autoHide={false} style={{ maxHeight: "100vh" }} className="px-3 t-justify">
                    <ViewTranscoder row={row} />
                </SimpleBar>
            </Modal>
        </>
    );
}
