/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { withTranslation } from "react-i18next";
import CardBalance from "../../../cardContainer/CardMini";
import { BalanceType } from "../types/chartType";
import { formatCurrencyMX } from "../../../../helpers/formats";

function Balance({ t }: { t: (f: string) => string }) {
    const [data, setData] = useState<BalanceType | undefined>({
        id: "1",
        label: `${t("totalCreditos")}`,
        labelClass: "muted",
        percentage: `${t("balance")}`,
        percentageClass: "success",
        percentageIcon: "ri-arrow-right-up-line",
        counter:"",
        caption: `${t("buy credits")}`,
        icon: "bx bx-dollar-circle",
        iconClass: "success-subtle",
        decimals: 2,
        prefix: "$",
        color: "success",
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBalance();
    }, []);

    async function getBalance() {
        setLoading(true);
        const res = await peticionBack({}, `/wallet/balance`, "GET", false);
        if (res) {
            const credits = formatCurrencyMX(res.balance);
            const BalanceObj = {
                id: "1",
                label: `${t("totalCreditos")}`,
                labelClass: "muted",
                percentage: `${t("balance")}`,
                percentageClass: "success",
                percentageIcon: "ri-arrow-right-up-line",
                counter: credits,
                caption: `${t("buy credits")}`,
                icon: "bx bx-dollar-circle",
                iconClass: "success-subtle",
                decimals: 2,
                prefix: "$",
                color: "success",
            };
            setData(BalanceObj);
        }
        setLoading(false);
    }
    return (
        <>
            {data && <CardBalance datag={data} loading={loading}/>}
        </>
    );
}

export default withTranslation()(Balance);
