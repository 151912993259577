import { Button as ButtonDef } from "reactstrap";
import { ButtonProps } from "./ButtonProps";

export default function Button({ type, children, color, outline, soft, ghost, border, capitalize, size, className, w, ...props }: ButtonProps) {
    const clase = (className || "") + (capitalize ? " text-capitalize" : "") + (border ? " btn-border" : "") + (soft ? ` btn-soft-${color || "primary"}` : "") + (ghost ? ` btn-ghost-${color || "primary"}` : "") + (outline || soft || ghost ? " shadow-none" : "") + (w ? ` w-${w}` : "");

    return (
        <ButtonDef color={color} outline={outline} className={clase} size={size} type={type || "button"} {...props}>
            {children}
        </ButtonDef>
    );
}
