import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Dispatch, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputDragAndDrop from "../../../inputs/inputDragAndDrop";
import peticionBack from "../../../../helpers/peticiones";

const defaultForm = {
    files: null,
};

export default function UploadFile({ t, currentFolder, open, setOpen, setRefreshContent }: { t: (text: string) => string; currentFolder: string; open: boolean; setOpen: Dispatch<boolean>; setRefreshContent: Dispatch<boolean> }) {
    const formSchema = Yup.object().shape({
        files: Yup.mixed()
            .test("fileSizeInMB", t("File size cannot be larger than 200 MB"), (values: any) => {
                const file = values.size;
                const fileSizeInMB = file / (1024 * 1024);
                return values && fileSizeInMB <= 200;
            })
            .required(t("No file selected")),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formSchema,
        onSubmit: () => {
            upload();
        },
        onReset: () => {},
    });

    async function upload() {
        if (values.files && currentFolder) {
            setLoading(true);
            const params = new FormData();
            const archivo = values.files as File;
            params.append("file", archivo, (values.files as File).name);
            params.append("folder_id", currentFolder as string);
            const res = await peticionBack(params, "/files/upload", "POST", true);
            if (res) {
                setRefreshContent(true);
            }
            setLoading(false);
            setOpen(false);
            handleReset(null);
        }
    }

    function onChange(target: { target: { name: string; value: any } }) {
        if (target.target.name === "files" && target.target.value) {
            const file = target.target.value.size;

            const fileSizeInMB = file / (1024 * 1024);
            if (fileSizeInMB <= 200) {
                setFieldValue(target.target.name, target.target.value || "", true);
            } else {
                setFieldValue(target.target.name, target.target.value || "", true);
            }
        } else {
            setFieldValue(target.target.name, target.target.value || "", true);
        }
    }

    return (
        <>
            <Modal id="createFileModal" isOpen={open} modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader className="p-3 bg-success-subtle">{t("Upload-file")}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} onReset={handleReset}>
                        <InputDragAndDrop
                            name="files"
                            label={t("file")}
                            value={values.files}
                            onBlurMethod={handleBlur}
                            touched={touched.files}
                            errors={errors.files}
                            onChangeMethod={onChange}
                            listType="picture-text"
                            accepts="application/pdf,image/*,video/*,.doc,.docx"
                            renderThumbnail={() => <div className="bg-white border-end w-100 h-100"></div>}
                            required
                        />
                        {loading && (
                            <div className="d-flex justify-content-center ">
                                <Spinner color="primary"></Spinner>
                            </div>
                        )}
                        <div className="hstack gap-3 justify-content-end">
                            <button type="reset" className="btn btn-ghost-success" onClick={() => setOpen(false)}>
                                <i className="ri-close-line align-bottom"></i> {t("cancel")}
                            </button>
                            <button type="submit" className="btn btn-primary" id="addNewFile">
                                {t("Upload-file")}
                            </button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
            {loading && <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999 }}></div>}
        </>
    );
}
