import { useEffect, useRef, useState } from "react";
import { Carousel, CarouselControl, CarouselItem } from "reactstrap";
import peticionBack from "../../../helpers/peticiones";
import toastMessage from "../../../helpers/toastMessage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "../../../styles/components/carousel/styles.scss";
import loadImg from "../../../../src/assets/images/load-img.webp";

export function Carrousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [imagenes, setImagenes] = useState<any>([]);

    async function getCarrousel() {
        const res = await peticionBack({}, "/banners", "GET", false);
        if (res) {
            setImagenes(res);
        } else {
            toastMessage("error", "Ha ocurrido un error al obtener las imagenes");
        }
    }

    useEffect(() => {
        getCarrousel();
        setAnimating(false);
    }, []);

    const inputRef = useRef(null);

    const slides = imagenes.map((item: any, i: number) => {
        return (
            <CarouselItem key={i} ref={inputRef}>
                <LazyLoadImage src={item.url} effect="blur" placeholderSrc={loadImg} />
            </CarouselItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;

        setActiveIndex(nextIndex);
    };

    if (!slides || slides.length === 0) {
        return <></>;
    }

    return (
        <div className="d-flex justify-content-center mt-3">
            <Carousel activeIndex={activeIndex} next={next} previous={previous} autoPlay={true} interval={3000} >
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
        </div>
    );
}
