import CardBalance from "../../../cardContainer/CardMini";
import peticionBack from "../../../../helpers/peticiones";
import createURL from "../../../../helpers/url";
import { useEffect, useState } from "react";
import Datatable from "../../../datatable/Datatable";
import { Card, Col, Form, FormGroup, Label, Row } from "reactstrap";
import Button from "../../../buttons/Button";

export default function BalanceWallet({ t, setIsOpen }: { t: (text: string) => string; setIsOpen: any }) {
    const [refresh, setRefresh] = useState(false);
    const [flag, setFlag] = useState(false);
    const [balance, setBalance] = useState<string>("");
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth();
    const anioActual = fechaActual.getFullYear();
    const [mesSeleccionado, setMesSeleccionado] = useState<number>(mesActual + 1);
    const [anioSeleccionado, setAnioSeleccionado] = useState<number>(anioActual);

    const handleMesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setMesSeleccionado(parseInt(event.target.value));
    };

    const handleAnioChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setAnioSeleccionado(parseInt(event.target.value));
    };

    const handleLimpiarSelects = () => {
        setMesSeleccionado(new Date().getMonth() + 1);
        setAnioSeleccionado(new Date().getFullYear());
    };

    async function getBalance() {
        const res = await peticionBack({}, "/wallet/balance", "GET", false);
        if (res && res.balance) setBalance(res.balance);
        return res;
    }

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: "/me/wallet/charges",
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "month", value: mesSeleccionado - 1 },
                { name: "year", value: anioSeleccionado },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        return res || "";
    }

    useEffect(() => {
        if (flag) {
            setRefresh(true);
        } else {
            setFlag(true);
        }
        // eslint-disable-next-line
    }, [mesSeleccionado, anioSeleccionado]);

    useEffect(() => {
        getBalance();
    }, []);

    const columnsDef = [
        { orderable: false, targets: [3] },
        { className: "text-center", targets: [0, 1, 2] },
    ];

    const headers: any[] = [`${t("Payment method")}`, `${t("Credits")}`, `${t("Creation Date")}`, `${t("status")}`];
    const columns = [{ data: "metodo_pago" }, { data: "balance" }, { data: "updatedAt", format: "datetime" as "datetime" }, { data: "status" }];

    return (
        <Card>
            <Row>
                <Col className="order-md-first order-last" xs={12} md={6}>
                    <CardBalance
                        datag={{
                            id: "1",
                            label: `${t("total Creditos")}`,
                            labelClass: "muted",
                            percentage: `${t("balance")}`,
                            percentageClass: "success",
                            percentageIcon: "ri-arrow-right-up-line",
                            counter: balance || "0",
                            icon: "bx bx-dollar-circle",
                            iconClass: "success-subtle",
                            decimals: 2,
                            prefix: "$",
                            color: "success",
                        }}
                    />
                </Col>
                <Col xs={12} md={6} className="d-grid d-md-flex justify-content-md-end align-items-md-center">
                    <Button className="mb-4 mb-md-0" onClick={() => setIsOpen(true)}>
                        {t("Add credits")}
                    </Button>
                </Col>
            </Row>
            <Row className="gx-3">
                <Form>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label for="mes">{t("Select a month")}:</Label>
                                <select id="mes" value={mesSeleccionado} onChange={handleMesChange} className="form-select">
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map((mes) => (
                                        <option key={mes} value={mes}>
                                            {mes}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormGroup>
                                <Label for="anio">{t("Select a year")}:</Label>
                                <select id="anio" value={anioSeleccionado} onChange={handleAnioChange} className="form-select">
                                    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((anio) => (
                                        <option key={anio} value={anio}>
                                            {anio}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col xs={12} md={4} className="d-grid d-md-flex justify-content-md-end align-items-md-center">
                            <Button color="secondary" className="mb-4 mb-md-0" onClick={handleLimpiarSelects}>
                                {t("Clean")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
            <Row>
                <Col>
                    <Datatable headers={headers} petition={getData} control="back" columns={columns} columnDefs={columnsDef} stateRefresh={[refresh, setRefresh]} searching={false} />
                </Col>
            </Row>
        </Card>
    );
}
