import { Col, Row, Card, CardHeader, CardBody } from "reactstrap";
import ViewSection from "../../../../pages/landing/components/ViewSection";
import { useTranslation } from "react-i18next";

export default function LandingIntroduction() {
    const { t } = useTranslation();
    return (
        <ViewSection id="public">
            <div className="mt-3">
                <div className="text-center">
                    <h1 className="display-6">{t("Our suite of digital tools contains services for 2 sectors")}</h1>
                </div>
                <div className="p-5 pb-0 text-card-introduction ">
                    <Row>
                        <Col xl={8}>
                            <Card>
                                <Row className="g-0 ">
                                    <Col md={4}>
                                        <picture>
                                            <img className="rounded-start img-fluid h-100 object-fit-cover" src="/images/abogado.jpg" alt="Card" />
                                        </picture>
                                    </Col>
                                    <Col md={8}>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">{t("Trial lawyers and educational sector of the branch of law")}:</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <p className="card-text mb-2">
                                                <ul>
                                                    <li>{t("Online text editor tested with AI to generate")}</li>
                                                    <ul>
                                                        <li>{t("Promotions")}</li>
                                                        <li>{t("Protections")}</li>
                                                    </ul>
                                                    <li>{t("Converting video audiences to text")}</li>
                                                    <li>{t("Conversion of sentences, jurisprudence and agreements to text")}</li>
                                                    <li>{t("Converting text to audio")}</li>
                                                    <li>{t("AI Legal Assistant")}</li>
                                                    <li>{t("Electronic file")}</li>
                                                    <li>{t("Electronic agenda")}</li>
                                                </ul>
                                            </p>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-end align-content-end">
                        <Col xl={8}>
                            <Card>
                                <Row className="g-0">
                                    <Col md={8}>
                                        <CardHeader>
                                            <h5 className="card-title mb-0">{t("Poderes judiciales")}:</h5>
                                        </CardHeader>
                                        <CardBody>
                                            <p className="card-text mb-2">
                                                <ul>
                                                    <li>{t("Public statement generator")}</li>
                                                    <li>{t("Converting video audiences to text")}</li>
                                                    <li>{t("Conversion of sentences, jurisprudence and agreements in PDF format to text")}</li>
                                                    <li>{t("Converting text to audio")}</li>
                                                    <li>{t("AI Legal Assistant")}</li>
                                                </ul>
                                            </p>
                                        </CardBody>
                                    </Col>
                                    <div className="col-md-4">
                                        <img className="rounded-end img-fluid h-100 object-fit-cover" src="/images/jueces.jpg" alt="Card" />
                                    </div>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </ViewSection>
    );
}
