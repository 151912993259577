export const privacidadEs = {
    "PRIVACY NOTICE AND PRIVACY POLICY": "AVISO DE PRIVACIDAD Y POLÍTICA DE PRIVACIDAD DE",
    "Identity and Address of the Responsible": "Identidad y Domicilio del Responsable",
    "recognizes the importance of protecting the privacy of its users' personal information and undertakes to take all necessary measures to ensure its confidentiality and security. This Privacy Notice and Privacy Policy":
        "reconoce la importancia de proteger la privacidad de la información personal de sus usuarios y se compromete a tomar todas las medidas necesarias para asegurar la confidencialidad y seguridad de la misma. Este Aviso de Privacidad y Política de Privacidad",
    "hereinafter, the Notice": "en adelante, el Aviso",
    "describes the practices that MIABOGADO ONLINEA follows for the collection, use, storage, and protection of personal data provided by its users.":
        "describe las prácticas que MIABOGADO EN LINEA sigue para la recolección, uso, almacenamiento, y protección de los datos personales proporcionados por sus usuarios.",
    "MIABOGADO ONLINEA is responsible for collecting, using and protecting the personal data that you provide us through our website and any other means related to our services.":
        "MIABOGADO EN LINEA, es responsable de recabar, utilizar y proteger los datos personales que usted nos proporciona a través de nuestro sitio web y cualquier otro medio relacionado con nuestros servicios.",
    "Personal Data We Collect": "Datos Personales que Recopilamos",
    "In compliance with the Federal Law on Protection of Personal Data Held by Private Parties (LFPDPPP), we inform you that the personal data we collect from you are the following":
        "En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), le informamos que los datos personales que recabamos de usted son los siguientes",
    "Full name": "Nombre completo",
    "Last name": "Apellidos",
    "Address and geographical locationAddress and geographical location": "Domicilio y ubicación geográfica",
    "Federal Taxpayer Registry (RFC)": "Registro Federal de Contribuyentes (RFC)",
    "Unique Population Registry Code (CURP)": "Clave Única de Registro de Población (CURP)",
    Nationality: "Nacionalidad",
    Gender: "Género",
    "Phone number": "Número de teléfono",
    "Email address": "Correo electrónico",
    "Academic information (bachelor's degree)": "Información académica (licenciatura)",
    "Data from your file or judicial processes (private sentences, lawsuits, recorded hearings, among other related documents)": "Datos de su expediente o procesos judiciales (sentencias privadas, demandas, audiencias grabadas, entre otros documentos relacionados)",
    "Purposes of Data Processing": "Finalidades del Tratamiento de Datos",
    "The personal data we collect will be used for the following purposes, necessary for the provision of our services nes the text formatted in lists using HTML":
        "Los datos personales que recabamos serán utilizados para las siguientes finalidades, necesarias para la prestación de nuestros servicios nes el texto formateado en listas usando HTML",

    "Primary Purposes": "Finalidades Primarias",

    "Processing and analysis of documents and judicial content using artificial intelligence.": "Procesamiento y análisis de documentos y contenido judicial utilizando inteligencia artificial.",
    "Direct communication with the user regarding the requested services.": "Comunicación directa con el usuario respecto a los servicios solicitados.",
    "Creation of a digital file for the management of your legal information.": "Creación de un expediente digital para la gestión de su información legal.",
    "Administration of the contractual relationship and service provision.": "Administración de la relación contractual y prestación de servicios.",
    "Generation of anonymous global statistics for internal analysis and continuous improvement of services.": "Generación de estadísticas globales anónimas para análisis interno y mejora continua de los servicios.",
    "Secondary Purposes": "Finalidades Secundarias",
    "Use of your data for commercial purposes, such as sending offers and promotions, provided the user has expressly authorized it.": "Uso de sus datos para propósitos comerciales, tales como el envío de ofertas y promociones, siempre y cuando el usuario lo haya autorizado expresamente.",
    "Security and Confidentiality Measures": "Medidas de Seguridad y Confidencialidad",
    "MIABOGADO ONLINEA implements strict security measures to protect the confidentiality and integrity of the personal data and documents you provide us. These measures include, but are not limited to":
        "MIABOGADO ONLINEA implementa estrictas medidas de seguridad para proteger la confidencialidad e integridad de los datos personales y documentos que nos facilitas. Estas medidas incluyen, pero no se limitan a",

    "Data Encryption All personal data and documents uploaded to the platform will be encrypted during transmission and storage.": "Encriptación de Datos: Todos los datos personales y documentos subidos a la plataforma serán encriptados durante su transmisión y almacenamiento.",
    "Access Control Only authorized and qualified personnel will have access to your data and documents, under strict confidentiality policies.": "Control de Acceso: Solo el personal autorizado y calificado tendrá acceso a sus datos y documentos, bajo estrictas políticas de confidencialidad.",
    "Continuous Monitoring The platform is subject to constant monitoring to detect and mitigate any potential security vulnerabilities.": "Monitoreo Continuo: La plataforma está sujeta a monitoreos constantes para detectar y mitigar cualquier posible vulnerabilidad de seguridad.",

    "User Responsibility": "Responsabilidad del Usuario",
    "The user is responsible for the veracity and accuracy of the information and documents uploaded to the MIABOGADO ONLINEA platform. It is your obligation to ensure that you are not providing data or documents that could compromise the privacy of third parties or violate any law.":
        "El usuario es responsable de la veracidad y precisión de la información y documentos que sube a la plataforma de MIABOGADO EN LINEA. Es su obligación asegurarse de que no está proporcionando datos o documentos que puedan comprometer la privacidad de terceros o violar alguna ley aplicable. MIABOGADO EN LINEA no se hace responsable por las consecuencias legales derivadas de la información que el usuario decida compartir o subir a la plataforma.",

    "Use of Third Party Tools": "Uso de Herramientas de Terceros",
    "MIABOGADO ONLINEA uses open source software tools and third-party services to provide its document processing and analysis services. The user acknowledges and accepts that these third parties may also have access to the data and documents uploaded to the":
        "MIABOGADO EN LINEA utiliza herramientas de software de código abierto y servicios de terceros para proporcionar sus servicios de procesamiento y análisis de documentos. El usuario reconoce y acepta que estos terceros también pueden tener acceso a los datos y documentos subidos a la plataforma. MIABOGADO EN LINEA no es responsable de cualquier filtración de información, mal uso o incumplimiento de la seguridad por parte de estos terceros. El usuario acepta que, al utilizar nuestros servicios, comprende y asume los riesgos inherentes al uso de herramientas de terceros.",

    "Data Sharing": "Compartición de Datos",
    "MIABOGADO EN LINEA will not share, sell, or transfer your personal data or case information with any private or governmental entity, except in the following cases":
        "MIABOGADO EN LINEA no compartirá, venderá ni transferirá sus datos personales o información de su caso con ninguna entidad privada o gubernamental, excepto en los siguientes casos",
    "When required by law, regulations, or in compliance with a judicial mandate.": "Cuando así lo requiera la ley, los reglamentos o en cumplimiento de un mandato judicial.",

    "To protect the rights, property, or safety of MIABOGADO EN LINEA, our users, or others.": "Para proteger los derechos, la propiedad o la seguridad de MIABOGADO EN LINEA, nuestros usuarios u otros.",

    "Use of Data for Global Metrics": "Uso de datos para métricas globales",

    "The information provided by users may be used by MIABOGADO EN LINEA in an aggregated and anonymous manner to generate global metrics and statistics. These statistics will include data such as the number of users, number of cases processed, number of iterations with the tool, among others. This information will be used solely to improve our services and will not involve the individual identification of users or the disclosure of specific case details.":
        "La información proporcionada por los usuarios podrá ser utilizada por MIABOGADO EN LINEA de forma agregada y anónima para generar métricas y estadísticas globales. Estas estadísticas incluirán datos como el número de usuarios, número de casos procesados, número de iteraciones con la herramienta, entre otros. Esta información se utilizará únicamente para mejorar nuestros servicios y no implicará la identificación individual de los usuarios ni la divulgación de detalles de casos específicos.",

    "Data Subject Rights": "Derechos del interesado",
    "The data subject has the right to access, rectify, cancel, or oppose (ARCO Rights) the processing of their personal data. To exercise these rights, the data subject must submit a written request via the following email address contacto@miabogadoenlinea.mx. The request must contain":
        "El titular de los datos tiene derecho a acceder, rectificar, cancelar u oponerse (Derechos ARCO) al tratamiento de sus datos personales. Para ejercer estos derechos, el titular deberá presentar una solicitud por escrito a través de la siguiente dirección de correo electrónico: contacto@miabogadoenlinea.mx. La solicitud debe contener",
    "The name of the data subject and address or other means to communicate the response.": "El nombre del interesado y domicilio u otro medio para comunicar la respuesta",
    "The documents that prove the identity of the data subject or, where appropriate, legal representation.": "Los documentos que acrediten la identidad del interesado o, en su caso, la representación legal.",
    "A clear and precise description of the personal data for which the data subject seeks to exercise any of the ARCO rights.": "Una descripción clara y precisa de los datos personales respecto de los cuales el titular busca ejercer cualquiera de los derechos ARCO.",
    "Any other element or document that facilitates the location of the personal data.": "Cualquier otro elemento o documento que facilite la localización de los datos personales.",
    "Modifications to the Privacy Notice": "Modificaciones al Aviso de Privacidad",
    "MIABOGADO EN LINEA reserves the right to modify this Notice at any time. The modifications will be available through our website, and if significant, they will be notified to the user via the registered email address.":
        "MIABOGADO EN LINEA se reserva el derecho de modificar el presente Aviso en cualquier momento. Las modificaciones estarán disponibles a través de nuestro sitio web, y de ser significativas, serán notificadas al usuario a través de la dirección de correo electrónico registrada.",
    Consent: "Consentimiento",
    "By using the services of MIABOGADO EN LINEA and providing your personal data, the user agrees to the terms and conditions set forth in this Notice. If the user does not agree with these terms, they must refrain from using the platform and its services.":
        "Al utilizar los servicios de MIABOGADO EN LINEA y proporcionar sus datos personales, el usuario acepta los términos y condiciones establecidos en el presente Aviso. Si el usuario no está de acuerdo con estos términos deberá abstenerse de utilizar la plataforma y sus servicios.",
    "If you have any questions or comments regarding this Privacy Notice, please contact us at the following email address: contacto@miabogadoenlinea.mx.":
        "Si tiene alguna pregunta o comentario respecto a este Aviso de Privacidad, por favor contáctenos a la siguiente dirección de correo electrónico: contacto@miabogadoenlinea.mx.",
    "This privacy notice is a detailed example and should be reviewed by a lawyer specialized in data protection to ensure it complies with all applicable regulations and adequately protects the company.":
        "Este aviso de privacidad es un ejemplo detallado y debe ser revisado por un abogado especializado en protección de datos para garantizar que cumple con todas las regulaciones aplicables y protege adecuadamente a la empresa.",
};
