import { FormFeedback, Input, Label } from "reactstrap";
import { CheckProps } from "./InputProps";

export default function InputCheck({ name, label, value, onChange, onBlur, disabled, bsSize, className, color, outline, type, error, touched }: CheckProps) {
    const which = type === "switch";
    const tipo = which ? "switch" : "check";
    const clase = "" + (!bsSize ? ` form-${tipo}-md` : bsSize !== "sm" ? ` form-${tipo}-` + bsSize : "") + (outline ? ` form-${tipo}-${which ? "custom" : "outline"}` : "") + ` form-${tipo}-` + (color || "primary");
    return (
        <div className={`form-${tipo} pb-2 ` + clase}>
            <Input
                className={`form-${tipo}-input cursor-pointer ` + (className || "")}
                type={type}
                id={name}
                name={name}
                checked={value}
                onChange={({ target }) => (onChange ? onChange({ name, value: target.checked }) : null)}
                disabled={disabled}
                dir="ltr"
                onBlur={onBlur}
                invalid={!!error && touched}
            />
            {label && (
                <Label className="form-check-label user-select-none" for={name}>
                    {label}
                </Label>
            )}
            <FormFeedback>{error}</FormFeedback>
        </div>
    );
}
