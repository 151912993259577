import { format } from "date-fns";
import { es } from "date-fns/locale";
import { country } from "../components/inputs/country";
import { StausQueue } from "../core/enums/enums";
import { TypeColor } from "../core/types/TypeColor";
const CurrencyFormatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
});
const DecimSeparatorFormatter = new Intl.NumberFormat("en-US");

var PercentageFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const tiposReporte = [{ name: "EXCEL" }, { name: "HTML" }, { name: "PDF" }];

export function FechaEstandar(fechaStr: string): string {
    const partes = fechaStr.split("-");

    if (partes.length !== 3) {
        throw new Error("Formato de fecha incorrecto. Debe ser YYYY-MM-DD");
    }

    const [anio, mes, dia] = partes;
    const fecha = new Date(`${mes}/${dia}/${anio}`);

    const diaFormat = String(fecha.getDate()).padStart(2, "0");
    const mesFormat = String(fecha.getMonth() + 1).padStart(2, "0"); // Se suma 1 porque en JavaScript los meses van de 0 a 11
    const anioFormat = fecha.getFullYear();

    return `${diaFormat}/${mesFormat}/${anioFormat}`;
}

export function generatePassword() {
    const wishlist = "~!@-#$0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnñopqrstuvwxyz~!@-#$";
    var randPassword = new Array(12)
        .fill(0)
        .map((x) =>
            (function (chars) {
                let umax = Math.pow(2, 32),
                    r = new Uint32Array(1),
                    max = umax - (umax % chars.length);
                do {
                    crypto.getRandomValues(r);
                } while (r[0] > max);
                return chars[r[0] % chars.length];
            })(wishlist)
        )
        .join("");
    return randPassword;
}

export function isValid(value: any) {
    return value !== undefined && value !== null && value.toString() !== "" && value.toString().trim() !== "";
}

export function fDate(value: number | string | Date) {
    if (isValid(value)) return format(new Date(value), "dd/MM/yyyy");
    return "Invalid Format";
}

export function convertirFormatoFecha(fechaISO: string): string {
    const fecha = new Date(fechaISO);
    const dia = fecha.getDate().toString().padStart(2, "0");
    const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const año = fecha.getFullYear().toString();
    return `${dia}/${mes}/${año}`;
}

export function convertirAMegas(numero: number): string {
    const megas: number = numero / (1024 * 1024);
    const megasRedondeados: string = megas.toFixed(2);
    return `${megasRedondeados} MB`;
}

export function convertirFecha(cadena: string): Date | null {
    const partes = cadena.split("/");

    if (partes.length === 3) {
        const anio = parseInt(partes[0], 10);
        const mes = parseInt(partes[1], 10) - 1;
        const dia = parseInt(partes[2], 10);

        if (!isNaN(anio) && !isNaN(mes) && !isNaN(dia)) {
            return new Date(dia, mes, anio);
        }
    }

    return null;
}

export const DateToString = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}/${month}/${day}`;
};

export function secondsToMinutes(seg: number | string) {
    const hrs = ~~(+seg / 3600);
    const mins = ~~((+seg % 3600) / 60);
    const secs = ~~+seg % 60;
    let time = "";
    if (hrs > 0) time = String(hrs).padStart(2, "0");
    time += String(mins).padStart(2, "0") + ":" + String(secs).padStart(2, "0");
    return time;
}

export function fDatetime(value: string | Date | number) {
    if (isValid(value)) return format(new Date(value), "PPPppp", { locale: es }).replace(" GMT-6", "");
    return value.toString();
}

export function fDateCustom(value: string, formato: string) {
    if (isValid(value)) return format(new Date(value), formato, { locale: es }).replace(" GMT-6", "");
    return value;
}

export function fCurrency(value: number) {
    if (isValid(value)) return CurrencyFormatter.format(value);
    return value;
}

export function fDecim(value: number | string) {
    if (isValid(value)) return DecimSeparatorFormatter.format(Number(value));
    return value;
}

export function fPercent(value: number | string) {
    if (isValid(value)) return PercentageFormatter.format(Number(value));
    return value;
}

export function formatCurrencyMX(num: number) {
    return num.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
    });
}

export function fBytes(bytes: number) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
}

export function sortObjects(data: Array<any>, field: string | null | undefined, direction?: string): Array<any> {
    if (data.length === 0) return [];
    if (field) {
        if (field.includes(".")) {
            return data.slice().sort((a: any, b: any) => {
                return compare(getField(field, a), getField(field, b)) * (direction === "desc" ? -1 : 1);
            });
        } else {
            return data.slice().sort((a: any, b: any) => compare(a[field], b[field]) * (direction === "desc" ? -1 : 1));
        }
    } else {
        return data.slice().sort((a: any, b: any) => compare(a, b) * (direction === "desc" ? -1 : 1));
    }
}

export function getField(field: string, object: any) {
    if (!object) return "";
    const keys: string[] = field.split(".");
    let objeto: any = object,
        index = 0;
    while (!!objeto && Object.hasOwn(objeto, keys[index]) && index < keys.length) {
        objeto = objeto[keys[index]];
        index++;
    }
    return objeto || "";
}

export function getFileNameFromSrc(src: string): string {
    const partsSrc = src.split("/");
    const name = partsSrc[partsSrc.length - 1];
    return name;
}

export function specialTrim(str: string) {
    return str.trim().replace(/\s\s+/g, " ");
}

export function eliminarTildes(str: string) {
    return str
        .normalize("NFD")
        .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1")
        .normalize();
}

export function getColor(edo: string): TypeColor {
    switch (edo.toUpperCase()) {
        case StausQueue.STARTED:
            return "info";
        case StausQueue.SUCCESS:
            return "success";
        case StausQueue.RETRY:
            return "warning";
        case StausQueue.FAILURE:
            return "danger";
        case StausQueue.PENDING:
        default:
            return "dark";
    }
}

export function formatDate(dateString: string) {
    if (!dateString) return "";
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export function obtenerNombrePaisPorCodigo(t: (text: string) => string, codigo: any) {
    for (let i = 0; i < country.length; i++) {
        if (country[i].countryCode === codigo) {
            return country[i].countryName;
        }
    }
    return `${t("Country not found or not assigned")}`;
}

export const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
