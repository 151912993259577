import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import header from '../../../reportes/components/excel/Header';
import { ConfigTable, generaTabla } from '../../../reportes/components/excel/generateTable';
import { DataAlmacenamiento } from '../../types/types';

export default async function AdminAlmacenamiento(t: (text: string) => string, data: DataAlmacenamiento[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("allFiles")}`, 5);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                { data: "userName", title: `${t("user name")}`, width: 35},
                {
                    data: "name", 
                    title: `${t("file name")}`,
                    width: 50
                },
                { data: "size", title: `${t("size")}`, width: 20, align: "tCenter" },          
                { data: "mimetype", title: `${t("file type")}`, width: 15, align: "tCenter" },
                { data: "createdAt", title: `${t("dateCreation")}`, width: 20, align: "tCenter", format: "date" },
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("storage report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `Admin ${t("storage report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 5);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
