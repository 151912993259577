/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { CardBody, CardHeader, Spinner } from "reactstrap";
import Button from "../../../buttons/Button";
import { withTranslation } from "react-i18next";
import { ChartVertical } from "../components/ChartVertical";

function ChartSchedule({ t }: { t: (f: string) => string }) {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>("Filter");

    useEffect(() => {
        getSchedule("SCHEDULE");
    }, [selectedDate]);

    const handleFilter = (valDate: string) => {
        setSelectedDate(valDate);
    };

    async function getSchedule(mimetype: string) {
        setLoading(true);
        let pan = "";
        if (selectedDate === "D") {
            pan = "?day=true";
        } else if (selectedDate === "M") {
            pan = "?month=true";
        } else if (selectedDate === "A") {
            pan = "?year=true";
        }

        const res = await peticionBack({}, `/dashboard/clients/${mimetype}${pan}`, "GET", false);
        if (res) {
            res.data.forEach((e: any) => {
                e.label = e.label ? e.label : "data";
            });
            setData(res.data);
        }
        setLoading(false);
    }
    return (
        <>
            <CardHeader>
                <div className="card-header border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">{t("schedule")}</h4>
                    <div className="d-flex gap-1">
                        <Button
                            type="button"
                            className={`btn btn-sm`}
                            onClick={() => {
                                handleFilter("D");
                            }}
                            soft={selectedDate === "D"}
                            outline={selectedDate !== "D"}
                            color={`${selectedDate === "D" ? "success" : "primary"}`}
                        >
                            {t("Day")}
                        </Button>
                        <Button
                            type="button"
                            className={`btn btn-sm`}
                            onClick={() => {
                                handleFilter("M");
                            }}
                            soft={selectedDate === "M"}
                            outline={selectedDate !== "M"}
                            color={`${selectedDate === "M" ? "success" : "primary"}`}
                        >
                            {t("Month")}
                        </Button>
                        <Button
                            type="button"
                            className={`btn btn-sm`}
                            onClick={() => {
                                handleFilter("A");
                            }}
                            soft={selectedDate === "A"}
                            outline={selectedDate !== "A"}
                            color={`${selectedDate === "A" ? "success" : "primary"}`}
                        >
                            {t("Year")}
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                {loading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <Spinner color="primary" />
                    </div>
                )}
                <div className="top-50 start-50" style={{ zIndex: "1" }}>
                    <ChartVertical data={data} dataColors='["--vz-success", "--vz-primary", "--vz-success"]' />
                </div>
            </CardBody>
        </>
    );
}

export default withTranslation()(ChartSchedule);
