import ChatBotIter from "../../views/html/ChatBotIter";
import CustomerByScheduleHTML from "../../views/html/CustomerBySchedule";
import CustomerByStagerHTML from "../../views/html/CustomerByStage";
import CustomerByStatusHTML from "../../views/html/CustomerByStatus";
import Folders from "../../views/html/Folders";
import Images from "../../views/html/Images";
import IngresForAdminsHTML from "../../views/html/IngresForAdmin";
import Pdf from "../../views/html/PDF";
import TranscoIter from "../../views/html/TranscoIter";
import Video from "../../views/html/Video";
import OcrIter from "../../views/html/ocrIter";
import UsersForAdminsHTML from "../../views/html/userForAdmin";

export default function getReportHTML(t: (text: string) => string, data: any, report: number, openT:boolean){
    switch(report){
         case 1: return <TranscoIter t={t} data={data.data} openT={openT} />;
         case 2: return <OcrIter t={t} data={data.data} openT={openT} />;
         case 3: return <Folders t={t} data={data.data} openT={openT} />;
         case 4: return <Pdf t={t} data={data.data} openT={openT} />;
         case 5: return <Video t={t} data={data.data} openT={openT} />;
         case 6: return <Images t={t} data={data.data} openT={openT} />;
         case 7: return <CustomerByStatusHTML t={t} data={data.data} openT={openT} />;
         case 8: return <CustomerByStagerHTML t={t} data={data.data} openT={openT} />;
         case 9: return <CustomerByScheduleHTML t={t} data={data.data} openT={openT} />;
         case 10: return <UsersForAdminsHTML t={t} data={data} openT={openT} />;
         case 11: return <IngresForAdminsHTML t={t} data={data.data} openT={openT} />;
         case 12: return <ChatBotIter t={t} data={data.data} openT={openT} />;
        default: return <h5>El reporte seleccionado no existe</h5>
    } 
}