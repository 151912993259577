import ModalView from "../pages/transcoder/components/ModalView";
import { DataItem } from "../../pages/transcodificador/dto/TranscoderDto";
import { UncontrolledTooltip } from "reactstrap";

export default function BtnTutorial({ url, defaultOpne }: { url: string; defaultOpne?: boolean }) {
    return (
        <ModalView row={url.includes("TEXTTOSPECH") ? ({ url: url } as DataItem) : ({ url: process.env.REACT_APP_BACK_TUTORIALS_URI + url } as DataItem)} autoPlay defaultOpne={defaultOpne}>
            <>
                <h2>
                    <i className=" ri-question-line btn-tutorial" id="tutorial-shortcut" />
                </h2>
                <UncontrolledTooltip placement="top-start" target="tutorial-shortcut">
                    Video Tutorial
                </UncontrolledTooltip>
            </>
        </ModalView>
    );
}
