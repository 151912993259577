import { toast } from "react-toastify";

export default function toastMessage(type: "success" | "info" | "warning" | "error", message: string) {
    let colorAlert = "";

    if (type === "success") {
        colorAlert = "bg-success";
    } else if (type === "info") {
        colorAlert = "bg-info";
    } else if (type === "warning") {
        colorAlert = "bg-warning";
    } else if (type === "error") {
        colorAlert = "bg-danger";
    }

    toast(message, { position: "top-right", hideProgressBar: true, className: `${colorAlert} text-white` });
}
