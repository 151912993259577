/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from "react-router-dom";
import InputBasic from "../../../components/inputs/InputBasic";
import { Button, Col, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import peticionBack from "../../../helpers/peticiones";
import { OnChangeParams } from "../../../components/inputs/InputProps";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import { useEffect, useState } from "react";
import { Modulo } from "./dto/Modulo.dt";
import InputSelect from "../../../components/inputs/InputSelect";
import Loader from "../../../components/loaders/Loader";
import { EnumLoader } from "../../../core/types/TypeLoader";
import Select, { MultiValue } from "react-select";
import makeAnimated from "react-select/animated";
import { UserTypes } from "../../../core/enums/enums";

interface UserType {
    label: string;
    value: string;
}

export default function FormModulos({ setOpen, selected, modulos, t, getModules }: { setOpen: any; selected?: Modulo; modulos: Modulo[]; t: Function; getModules: Function }) {
    const animatedComponents = makeAnimated();
    const [loader, setLoader] = useState<EnumLoader | undefined>();
    const [options, setOptions] = useState<UserType[] | any>();

    const userType = [
        { value: UserTypes.ROOT, label: t("ROOT") },
        { value: UserTypes.ADMINISTRATOR, label: t("ADMINISTRATOR") },
        { value: UserTypes.ORGANIZATION_ADMINISTRATOR, label: t("ORGANIZATION ADMINISTRATOR") },
        { value: UserTypes.NORMAL_USER, label: t("NORMAL USER") },
    ];

    function mapUserType(optionsEdit: string[] | null): { value: string; label: string }[] {
        let newUserType: { value: string; label: string }[] = [];

        if (optionsEdit) {
            newUserType = optionsEdit.map((value: string) => {
                let label = "";
                switch (value) {
                    case "-1":
                        label = t("ROOT");
                        break;
                    case "0":
                        label = t("ADMINISTRATOR");
                        break;
                    case "1":
                        label = t("ORGANIZATION ADMINISTRATOR");
                        break;
                    case "2":
                        label = t("NORMAL USER");
                        break;
                    default:
                        break;
                }
                return { value, label };
            });
        }
        return newUserType;
    }

    const result = mapUserType(options);

    const defaultForm: Modulo = {
        id: "",
        name: "",
        route: "",
        admin: false,
        parent_id: "",
        icon: "",
        user_type: "",
    };

    function getValidations(t: Function) {
        return Yup.object().shape({
            name: Yup.string()
                .required(t("form-validation.common.text.requeired", { field: t("Name") }))
                .min(3, t("form-validation.common.text.min-length", { field: t("Name"), minLength: "3" })),
            route: Yup.string().required(t("form-validation.common.text.requeired", { field: t("Route") })),
            user_type: Yup.array().min(1, "Selecciona al menos una opción"),
        });
    }

    /* FORM */
    const { handleSubmit, handleReset, handleBlur, setFieldValue, setValues, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: getValidations(t),
        onSubmit: async (values) => {
            alerConfirmation({
                handleAction: save,
                title: t(selected ? "modules.form.edit-module" : "modules.form.save-module"),
                content: t(selected ? "modules.form.want-edit" : "modules.form.want-save"),
                buttonOkMsg: t("Save"),
                buttonCancelMsg: t("Cancel"),
                values: values,
            });
        },
        onReset: () => setOpen(false),
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    function onChangeSelect(opt: MultiValue<any>) {
        const valores = opt.map((item) => item.value);
        setOptions(valores);
    }

    async function save() {
        const method = selected ? "PUT" : "POST";
        const rute = selected ? `/${selected.id}` : "";
        const params = { ...values, user_type: options };
        if (!selected) delete params.id;
        setLoader(EnumLoader.SUBTLE);
        const res = await peticionBack(params, `/modulos` + rute, method, true);
        if (res) {
            setOpen(false);
            getModules();
        } else {
            setLoader(undefined);
        }
    }

    /* END FORM */

    useEffect(() => {
        if (selected) {
            setOptions(selected.user_type);
            setValues(
                {
                    name: selected.name,
                    route: selected.route,
                    admin: selected.admin,
                    id: selected.id,
                    parent_id: selected.parent_id,
                    icon: selected.icon,
                    user_type: selected.user_type,
                },
                true
            );
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Form onSubmit={handleSubmit} onReset={handleReset} className="p-1">
            <Row>
                <Col xs={12} md={6}>
                    <InputBasic name="name" value={values.name} label={t("Name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                </Col>
                <Col xs={12} md={6}>
                    <InputBasic name="route" type="text" value={values.route} label={t("modules.route")} touched={touched.route} error={errors.route} onChange={onChange} onBlur={handleBlur} required />
                </Col>

                <Col xs={12} md={6}>
                    <InputSelect name="parent_id" value={values.parent_id} label={t("modules.parent")} touched={touched.parent_id} error={errors.parent_id} onChange={onChange} onBlur={handleBlur} options={modulos} opLabel="name" opValue="id" clearable />
                </Col>

                <Col xs={12} md={6}>
                    <InputBasic name="icon" value={values.icon} label={t("Icon")} touched={touched.icon} error={errors.icon} onChange={onChange} onBlur={handleBlur} />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12} md={6} className="text-center mb-4">
                    <Label>{t("modules.User type")}</Label>
                    <Select isMulti name="user_type" onChange={onChangeSelect} options={userType} components={animatedComponents} closeMenuOnSelect={false} value={result} isClearable required />
                    {errors.user_type && <div className="d-flex justify-content-start text-danger">{errors.user_type}</div>}
                </Col>
            </Row>

            <div className="d-grid gap-2 d-flex flex-md-row flex-column-reverse justify-content-md-around mt-2">
                <Button color="danger" type="reset">
                    {t("Cancel")}
                </Button>
                <Button color="success" type="submit">
                    {t("Save")}
                </Button>
            </div>
            <Loader show={loader} />
        </Form>
    );
}
