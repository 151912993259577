import styles from "./ErrorDatatable.module.css"

export default function ErrorDatatable({ message }: { message: string }) {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.textContainer}>
                    <h1 className={styles.title}>Lo sentimos.</h1>
                    <p className={styles.subtitle}>Se produjo un error inesperado.</p>
                    {message && <p className={styles.message}>{message}</p>}
                </div>
            </div>
        </div>
    );
}