import { Form } from "react-router-dom";
import { Col, Row } from "reactstrap";
import InputDate from "../../../inputs/InputDate";
import InputBasic from "../../../inputs/InputBasic";
import Button from "../../../buttons/Button";
import InputSelect from "../../../inputs/InputSelect";

export const FormEvent = ({ errors, handleReset, values, touched, onChange, handleSubmit, handleBlur, loading, t, deleteEvent, clients, files }: any) => {
    function validateSameDay() {
        if (values.start !== "" && values.end !== "") {
            if (values.start?.getDay() === values.end?.getDay() && values.start?.getMonth() === values.end?.getMonth() && values.start?.getFullYear() === values.end?.getFullYear() && values?.startTime) {
                return values.startTime;
            }
        }

        return undefined;
    }
    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset} className="py-4">
                <Row className="d-flex justify-content-around">
                    <Col lg={10} xs={10} md={10}>
                        <InputBasic name="title" type="text" value={values.title} label={t("title")} touched={touched.title} error={errors.title} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around">
                    <Col lg={10} xs={10} md={10}>
                        <InputBasic name="description" type="textarea" value={values.description} label={t("description")} touched={touched.description} error={errors.description} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                    <Col lg={5} xs={5} md={5}>
                        <InputDate name="start" type="single" value={values.start} label={t("startDate")} touched={touched.start} error={errors.start} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                    <Col lg={5} xs={5} md={5}>
                        <InputDate name="end" type="single" value={values.end} label={t("endDate")} touched={touched.end} error={errors.end} onChange={onChange} onBlur={handleBlur} min={values.start} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                    <Col lg={5} xs={5} md={5}>
                        <InputDate name="startTime" type="time" value={values.startTime} label={t("startTime")} touched={touched.startTime} error={errors.startTime} onChange={onChange} onBlur={handleBlur} required disabledLocal />
                    </Col>
                    <Col lg={5} xs={5} md={5}>
                        <InputDate name="endTime" type="time" value={values.endTime} label={t("endTime")} touched={touched.endTime} error={errors.endTime} onChange={onChange} minTime={validateSameDay()} onBlur={handleBlur} required disabledLocal />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center">
                    <Col lg={5} xs={5} md={5}>
                        <InputSelect name="client_id" options={clients} value={values.client_id} label={t("Client")} touched={touched.client_id} error={errors.client_id} onChange={onChange} onBlur={handleBlur} opValue="_id" opLabel="fullName" />
                    </Col>
                    <Col lg={5} xs={5} md={5}>
                        <InputSelect
                            name="file_id"
                            options={files}
                            value={values.file_id}
                            label={t("filexp")}
                            touched={touched.file_id}
                            error={errors.file_id}
                            onChange={onChange}
                            onBlur={handleBlur}
                            opValue="_id"
                            opLabel="file_number"
                            disabled={!(values.client_id && values.client_id !== "" && files.length > 0)}
                        />
                    </Col>
                </Row>

                <Row className="justify-content-center mb-3">
                    {values.id && values.id !== "" && (
                        <Col lg={5} xs={5} md={5}>
                            <Button
                                color="danger"
                                className="w-100 btn-load"
                                onClick={() => {
                                    deleteEvent(values);
                                }}
                            >
                                {t("Delete")}
                            </Button>
                        </Col>
                    )}
                    <Col lg={5} xs={5} md={5}>
                        <Button color="success" className="w-100 btn-load" type="submit">
                            {values.id ? t("Edit") : t("Add")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
