export const sign = {
    "Document Signed": "Documento firmado",
    "Document not found": "No se encontró el documento",
    "Sign Document": "Firmar Documento",
    "Are you sure you want to sign this document? Your document will be overwritten": "¿Está seguro de firmar este documneto? Su documento será sobreescrito",
    "No elements were added to the document": "No se agregó ningun elemento al documento",
    "New Firm": "Nueva Firma",
    Signature: "Firma",
    "Half Signature": "Media Firma",
    Initials: "Iniciales",
    Date: "Fecha",
    "Signature created": "Firma creada",
    "An error occurred while creating the signature": "Ocurrio un error al crear la firma",
    "Create signature": "Crear firma",
    "Draw your new signature without exiting the box": "Dibuje su nueva firma sin salir del recuadro",
    "Save Signature": "Guardar Firma",
    "Are you sure you want to keep this signature?": "¿Está seguro de guardar esta firma?",
    "Your previous signature will be deleted": "Su anterior firma será eliminada",
    "It was not possible to sign the document": "No fue posible firmar el documento",
    "It was not possible to obtain the file": "No fue posible obtener el archivo",
};
