import { Modal, ModalBody } from "reactstrap";
import { Dispatch } from "react";
import peticionBack from "../../../../helpers/peticiones";

export default function DeleteFiles({ t, idFolder, openDeleteFiles, setOpenDeleteFiles, setRefreshContent }: { t: (text: string) => string; idFolder: any; openDeleteFiles: boolean; setOpenDeleteFiles: Dispatch<boolean>; setRefreshContent: Dispatch<boolean> }) {
    async function handleSubmit() {
        await Delete();
        setOpenDeleteFiles(false);
    }
    async function Delete() {
        const res = await peticionBack({}, `/files/${encodeURIComponent(idFolder.id as string)}`, "DELETE", true);
        if (res) {
            setRefreshContent(true);
        }
    }
    return (
        <Modal id="createFileModal" isOpen={openDeleteFiles && (idFolder ? true : false)} modalClassName="zoomIn" centered tabIndex={1}>
            <ModalBody>
                <div className="mt-2 text-center">
                    <i className="ri-delete-bin-line display-5 text-danger"></i>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h5>{t("Are-you-sure")}</h5>
                        <p className="text-muted mx-4 mb-0">
                            {t("Are-you-sure-complete")}
                            <strong>{idFolder.name}</strong>
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="reset" className="btn w-sm btn-light" onClick={() => setOpenDeleteFiles(false)} data-bs-dismiss="modal">
                        <i className="ri-close-line align-bottom"></i> {t("cancel")}
                    </button>
                    <button type="submit" className="btn w-sm btn-danger " id="delete-record" onClick={handleSubmit}>
                        {t("yes-delete-it")}
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
}
