import { Workbook } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";

export default function header(workbook: Workbook, title: string, cols?: number){
    workbook.creator = 'miAbogadoEnLinea';
    workbook.lastModifiedBy = 'miAbogadoEnLinea';
    workbook.created = new Date();
    workbook.modified = new Date();

    const sheet = workbook.addWorksheet(title);
    sheet.mergeCells(1, 1, 1, cols || 4);

    const cellTitle = sheet.getCell(1, 1);
    cellTitle.value = "miAbogadoEnLinea";
    cellTitle.alignment = sg.alignment.tCenter;
    cellTitle.font = sg.font.title
    sheet.getRow(1).height = 60;

    sheet.mergeCells(2, 1, 2 , cols || 4);
    const cellSubtitle = sheet.getCell(2, 1)
    cellSubtitle.value = title;
    cellSubtitle.alignment = sg.alignment.tCenter;
    cellSubtitle.font = sg.font.subtitle

    return sheet;
}