import React from "react";
import { Row } from "reactstrap";

export const SendMessageView = ({ curMessage, onKeyPress, setcurMessage, addMessage }: { curMessage: any; onKeyPress: any; setcurMessage: any; addMessage: any }) => {
    return (
        <div>
            <form id="chatinput-form">
                <Row className="g-0 align-items-center">
                    <div className="col">
                        <input type="text" value={curMessage} onKeyDown={onKeyPress} onChange={(e) => setcurMessage(e.target.value)} className="form-control chat-input bg-light border-light" id="chat-input" placeholder="Escribe tu mensaje..." />
                    </div>
                    <div className="col-auto">
                        <div className="chat-input-links ms-2">
                            <div className="links-list-item">
                                <button
                                    type="button"
                                    disabled={curMessage === ""}
                                    onClick={() => {
                                        addMessage();
                                    }}
                                    className="btn btn-success chat-send waves-effect waves-light disable"
                                >
                                    <i className="ri-send-plane-2-fill align-bottom"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </Row>
            </form>
        </div>
    );
};
