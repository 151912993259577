export const dashboardEs = {
    Dashboard: "Graficas",
    "schedule report": "Reporte  de clientes por cronograma",
    "status report": "Reporte  de clientes por estado",
    "stage report": "Reporte  de clientes por escenario",
    "Transcoding Report": "Reporte de Transcodificacion",
    "Chatbot Report": "Reporte de chatbot",
    "ingress Report": "Reporte de ingresos",
    "use of tools": "Uso de las soluciones e ingresos",
    "Income graph": "Gráfico de ingresos",
    "Clients report": "Reporte  de clientes",
    AllTimeUsers: "Usuarios totales",
    "access date": "Fecha de ingreso",
    "Income report": "Reporte de ingresos",
    "ocr Report": "Reporte de OCR",
    "pdf Report": "Reporte archivos PDF",
    "video Report": "Reporte archivos de video",
    "image Report": "Reporte archivos de imagen",
    "folder Report": "Reporte de carpetas",
    "Generate report": "Generar reporte",
    Active: "Activos",
    Inactive: "Inactivos",
    "Files Processed in ocr": "Archivos Procesados en OCR",
    "Folders created": "Carpetas creadas",
    "file name": "Nombre del archivo",
    "chat interaction per file": "Interacción de chat por archivo",
    total_chat_history: "historial total de chats",
    "user name": "Nombre de usuario",
    "file uploded": "Archivos subidos",
    report: "Reporte",
    format: "Formato",
    "Choose a report": "Selecciona un reporte",
    "Choose a date e": "Selecciona una fecha final",
    "Choose a date s": "Selecciona una fecha inicial",
    "Choose a option": "Selecciona una opcion",
    "Choose a format": "Selecciona un formato",
    "file type": "Tipo de archivo",
    "Getting information": "Consiguiendo información...",
    "The selected report does not exist": "El reporte seleccionado no existe",
};
