import ReactApexChart from "react-apexcharts";
import { getChartColorsArray } from "./ChartsDynamicColor";

const BasicColumn = ({ dataColors, data, customlabel }: { dataColors: any; data: any; customlabel?:string }) => {
    var chartColumnColors = getChartColorsArray(dataColors);

    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total
    }));

    const series = [{
        name: customlabel|| "Total",
        data: parsedData
    }];

    var options: any = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: chartColumnColors,
        xaxis: {
            type: 'category',
            categories: data.map((item: any) => item.label),
            labels: {
                show: true,
            }
        },
        yaxis: {
            title: {
                text: 'Total'
            }
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        fill: {
            opacity: 1

        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return val;
                }
            }
        }
    };

    return (
        <ReactApexChart
            dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="bar"
            height={350}
        />
    );
};

const ColumnWithLable = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var chartColumnDatatalabelColors = getChartColorsArray(dataColors);

    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total
    }));

    const series = [{
        name: "Total",
        data: parsedData
    }];

    const options : any = {
        chart: {
            toolbar: {
                show: !1,
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", 
                },
            },
        },
        dataLabels: {
            enabled: !0,
            formatter: function (val : any) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#adb5bd"],
            },
        },
        colors: chartColumnDatatalabelColors,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: data.map((item: any) => item.label),
            position: "top",
            labels: {
                offsetY: -10,
            },
            axisBorder: {
                show: !1,
            },
            axisTicks: {
                show: !1,
            },
            crosshairs: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            tooltip: {
                enabled: !0,
                offsetY: -35,
            },
        },
        fill: {
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: !0,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100],
            },
        },
        yaxis: {
            axisBorder: {
                show: !1,
            },
            axisTicks: {
                show: !1,
            },
            labels: {
                show: !1,
                formatter: function (val : any) {
                    return val + "%";
                },
            },
        },
    };

    return (
        <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="bar" height={340} />
    );
};

const RotateLable = ({ dataColors, data }: { dataColors: any; data: any }) => {
    var chartColumnRotateLabelsColors = getChartColorsArray(dataColors);


    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total 
    }));

    const series = [{
        name: "Total",
        data: parsedData
    }];

    var options : any = {
        annotations: {
            points: [{
                x: 'Bananas',
                seriesIndex: 0,
                label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                        color: '#fff',
                        background: '#775DD0',
                    },
                    text: 'Bananas are good',
                }
            }]
        },
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '50%',
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 2
        },
        colors: chartColumnRotateLabelsColors,
        xaxis: {
            labels: {
                rotate: -45
            },
            categories: data.map((item: any) => item.label),
            tickPlacement: 'on'
        },
        yaxis: {
            title: {
                text: 'Total',
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
            },
        }
    };

    return (
        <ReactApexChart dir="ltr" className="apex-charts" options={options} series={series} type="bar" height={350} />
    );
};
const StackedColumn = ({ data, t }: { data: any;t: (text: string) => string; }) => {
    const chartData: any = {};
    data.forEach((item: any) => {
        if (!chartData[item.label]) {
            chartData[item.label] = {};
        }
        chartData[item.label][item.solution] = item.total;
    });

    const dates = Object.keys(chartData).sort();

    const series: any = [];
    const solutions: Set<string> = new Set();
    Object.values(chartData).forEach((value: any) => {
        Object.keys(value).forEach((solution: string) => {
            solutions.add(solution);
        });
    });
    solutions.forEach((solution: string) => {
        const dataPoints = dates.map((date: string) => chartData[date][solution] || 0);
        series.push({ name: solution, data: dataPoints });
    });

    // Configuración de la gráfica
    const options: any = {
        chart: {
            stacked: true,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: true,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
            },
        },
        xaxis: {
            type: "category",
            categories: dates,
        },
        yaxis: {
            title: {
                text: `${t("credits")}`
            }
        },
        legend: {
            position: "right",
            offsetY: 40,
        },
        fill: {
            opacity: 1,
        },
    };

    return <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="bar" height={350} />;
};



export {
    BasicColumn, ColumnWithLable, RotateLable, StackedColumn
};
