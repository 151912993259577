/* eslint-disable react-hooks/exhaustive-deps */
import CardContainer from "../../../components/cardContainer/CardContainer";
import Button from "../../../components/buttons/Button";
import { useEffect, useState } from "react";
import peticionBack from "../../../helpers/peticiones";
import Datatable from "../../../components/datatable/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modulo } from "./dto/Modulo.dt";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import FormModulos from "./FormModulos";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/loaders/Loader";
import { EnumLoader } from "../../../core/types/TypeLoader";
import { useDispatch } from "react-redux";
import { loadModules } from "../../../core/slices/permisos/permissionsSlicer";

function Modulos({ t }: { t: Function }) {
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Modulo | undefined>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [allModules, setAllModules] = useState<Modulo[]>([]);
    const [loader, setLoader] = useState<EnumLoader | undefined>(EnumLoader.SOLID);
    const dispatch = useDispatch();

    useEffect(() => {
        if (loader !== EnumLoader.SOLID) {
            setRefresh(true);
        }
    }, [allModules]);

    const renderUserType = (userTypes: string[]) => {
        return userTypes
            .map((type: string) => {
                if (type === "-1") {
                    return t("ROOT");
                } else if (type === "0") {
                    return t("ADMINISTRATOR");
                } else if (type === "1") {
                    return t("ORGANIZATION ADMINISTRATOR");
                } else if (type === "2") {
                    return t("NORMAL USER");
                } else {
                    return t("UNKNOWN");
                }
            })
            .join(", "); // Si se quiere mostrar múltiples tipos de usuario, se pueden unir con una coma (o cualquier otro separador)
    };

    async function getModules() {
        const res = await peticionBack({}, `/me/permissions`, "GET", false);
        if (res) {
            dispatch(loadModules({ modules: res }));
        }
    }

    /* TABLE */
    const headers = [t("modules.module"), t("modules.route"), t("modules.parent"), t("modules.User type"), t("Actions")];
    const columnsDef = [{ orderable: false, targets: [5] }];

    const columns = [
        { data: "name" },
        { data: "route" },
        { data: "parent", render: (p?: Modulo) => p?.name, orderValue: "parent.name" },
        {
            data: "user_type",
            render: (userTypes: string[]) => renderUserType(userTypes),
        },
        {
            data: null,
            render: (row: Modulo) => (
                <div className="d-flex justify-content-evenly">
                    <FontAwesomeIcon icon={faPencil} className="text-warning cursor-pointer" onClick={() => openToEdit(row)} />
                    <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />
                </div>
            ),
        },
    ];

    async function getData() {
        if (!loader) setLoader(undefined);
        const res = await peticionBack({}, "/modulos?populate=parent_id&admin=true", "GET", false);
        if (res) {
            (res.data as Modulo[]).forEach((m) => m.parent_id);
            setAllModules(res.data);
        }
        setLoader(undefined);
    }

    function openToEdit(row: Modulo) {
        setSelected(row);
    }

    function showAlert(row: Modulo) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("Delete")} ${t("modules.module")}`,
            content: `${t("Are you sure you want to delete")} ${row.name}`,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    async function deleteRow(row: Modulo) {
        if (!row || !row.id) return;
        setLoader(EnumLoader.SUBTLE);
        const res = await peticionBack({}, `/modulos/${row.id}`, "DELETE", true);
        if (res) {
            getData();
            getModules();
        } else {
            setLoader(undefined);
        }
    }

    /* END TABLE */

    useEffect(() => {
        if (!open) {
            setSelected(undefined);
            getData();
        }
    }, [open]);

    useEffect(() => {
        if (selected) {
            setOpen(true);
        }
    }, [selected]);

    function getTitle() {
        if (!open) return "";
        return " - " + t(selected ? "common.edit" : "common.create");
    }

    return (
        <CardContainer title={`${t("modules.title")}` + getTitle()}>
            {!open && (
                <div className="d-grid d-md-block text-end">
                    <Button onClick={() => setOpen(true)} className="mb-2" color="success">
                        {t("Add")}
                    </Button>
                </div>
            )}
            {open ? (
                <FormModulos setOpen={setOpen} selected={selected} modulos={allModules.filter((m) => m.id !== selected?.id || "")} t={t} getModules={getModules} />
            ) : (
                <Datatable columns={columns} columnDefs={columnsDef} headers={headers} control="front" data={allModules} stateRefresh={[refresh, setRefresh]} className="p-0" />
            )}
            <br />
            <Loader show={loader} />
        </CardContainer>
    );
}

export default withTranslation()(Modulos);
