import { degrees, PDFDocument, PDFFont, PDFImage, PDFPage, PDFPageDrawImageOptions, PDFPageDrawTextOptions, rgb, StandardFonts } from "pdf-lib";
import { FabricImage, Textbox } from "fabric";
import { PageType } from "./PageType";
import { saveAs } from "file-saver";
import axios from "axios";
import { FONT_FAMILY_WEIGHT, FONT_SIZE } from "../../core/constants/sign-pdf/text-configs";
import toastMessage from "../toastMessage";

export async function getFileArraybuffer(url: string): Promise<ArrayBuffer> {
    let res = await axios.get(url, { responseType: "arraybuffer" });
    if (!res) throw new Error("It was not possible to obtain the file");
    return res.data;
}

export async function signPDF([url_pdf, url_sign]: [string, string], pages_objects: PageType[], t: (t: string) => string, download?: boolean) {
    try {
        const [pdfArraybuffer, signArraybuffer] = await Promise.all([getFileArraybuffer(url_pdf), getFileArraybuffer(url_sign)]);
        const old_PDF = await PDFDocument.load(pdfArraybuffer);

        const [font_family, png_image] = await Promise.all([old_PDF.embedFont(StandardFonts[FONT_FAMILY_WEIGHT as keyof typeof StandardFonts]), old_PDF.embedPng(signArraybuffer)]);

        const half_image = await cropImage(url_sign);
        const png_half_image = half_image ? await old_PDF.embedPng(half_image) : png_image;

        const pages_pdf = old_PDF.getPages();
        for (let ix = 0; ix < pages_pdf.length; ix++) {
            signPage(pages_pdf[ix], pages_objects[ix], [png_image, png_half_image], font_family);
        }

        const new_pdf_bytes = await old_PDF.save();
        const bytes = new Uint8Array(new_pdf_bytes);
        const blobFile = new Blob([bytes], { type: "application/pdf" });
        if (download) creteNewDF(blobFile);
        else return blobFile;
    } catch (error: any) {
        console.error(error);
        toastMessage("error", t("sign.It was not possible to sign the document"));
    }
    return null;
}

function signPage(page: PDFPage, objects: Array<FabricImage | Textbox>, pngImages: PDFImage[], font: PDFFont) {
    if (objects.length < 1) return;
    for (let obj of objects) {
        if (obj.type === "image") {
            const image = obj.width < pngImages[0].width ? pngImages[1] : pngImages[0];
            addImage(page, obj as FabricImage, image);
        } else {
            addText(page, obj as Textbox, font);
        }
    }
}

function addImage(page: PDFPage, obj: FabricImage, pngImage: PDFImage) {
    const { width, height, x, y, angle } = getSize(obj, page.getHeight());
    page.drawImage(pngImage, { x, y, width, height, rotate: degrees(angle) } as PDFPageDrawImageOptions);
}

function addText(page: PDFPage, obj: Textbox, font: PDFFont) {
    const { x, y, angle, scaleY, width, height } = getSize(obj, page.getHeight());
    const size = scaleY * FONT_SIZE;
    const t = calcMove(obj.text, width, height, size, font);
    page.drawText(obj.text, {
        x: x + 0.43 * scaleY,
        y: y + t.ty + 1.5 * scaleY,
        size,
        font,

        rotate: degrees(angle),
        color: rgb(0, 0, 0),
    } as PDFPageDrawTextOptions);
}

function creteNewDF(pdf_blob: Blob) {
    saveAs(pdf_blob, `sig_pdf_${Date.now()}`);
}

function getSize(obj: FabricImage | Textbox, page_height: number) {
    const y = page_height - obj.top;
    return { width: obj.width * obj.scaleX, height: obj.height * obj.scaleY, x: obj.left, y, angle: obj.angle * -1, scaleX: obj.scaleX, scaleY: obj.scaleY };
}

function calcMove(text: string, width: number, height: number, font_size: number, font: PDFFont) {
    const width_pdf = font.widthOfTextAtSize(text, font_size);
    const height_pdf = font.heightAtSize(font_size);
    return { tx: width - width_pdf, ty: height - height_pdf };
}

async function cropImage(url: string): Promise<ArrayBuffer | null> {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    return new Promise((resolve) => {
        img.onload = async function () {
            try {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                const width = img.width / 2;
                const height = img.height;
                canvas.width = width;
                canvas.height = height;
                if (context) {
                    context.drawImage(img, 0, 0, width, height, 0, 0, width, height);
                    const blob: Blob | null = await new Promise((resolve) => canvas.toBlob(resolve, "image/png"));
                    if (blob) {
                        const arrayBuffer = await blob.arrayBuffer();
                        resolve(arrayBuffer);
                    }
                }
                resolve(null);
            } catch (error) {
                resolve(null);
            }
        };
        img.src = url;
    });
}
