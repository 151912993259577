import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";
import CardContainer from "../../components/cardContainer/CardContainer";
import { useEffect, useState } from "react";
import { Button, Nav, NavItem, NavLink, Progress, TabContent, TabPane } from "reactstrap";

import FormForWallet from "../../components/pages/wallet/views/Form";
import { withTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import BalanceWallet from "../../components/pages/wallet/views/BalanceWallet";
import WalletStripe from "../../components/pages/wallet/components/WalletStripe";

function WalletMain({ t }: { t: (f: string) => string }) {
    const emailRedux = useSelector((state: RootState) => state.auth.email) || "";
    const [activeTab, setactiveTab] = useState<number>(1);
    const [progressbarvalue, setprogressbarvalue] = useState<number>(0);
    const [passedSteps, setPassedSteps] = useState<any>([1]);
    const [pagoStatus, setPagoStatus] = useState<boolean>();
    const [isOpen, setIsOpen] = useState<any>(null);
    const minCredits = 100;

    const defaultForm = {
        email: emailRedux,
        credits: 0,
    };

    const formValidations = Yup.object().shape({
        email: Yup.string()
            .required(`${t("Enter an email")}`)
            .email(`${t("Invalid email")}`),
        credits: Yup.number()
            .required(`${t("Enter a credits")}`)
            .min(minCredits, `${t("The minimum recharge is $")}${minCredits}`),
    });

    const { handleSubmit, resetForm, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async () => {},
        enableReinitialize: true,
    });

    function onChange(target: any) {
        setFieldValue(target.name, target.value);
    }

    function toggleTab(tab: any, value: any) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        setprogressbarvalue(value);
    }
    if (pagoStatus && pagoStatus === true) {
        toggleTab(activeTab + 1, 100);
        setPagoStatus(undefined);
    }

    const disabledButton = () => {
        return !(values.email && values.credits && Number(values.credits) >= minCredits);
    };

    useEffect(() => {
        if (isOpen === null) resetForm();
        toggleTab(activeTab - 1, 0);
        // eslint-disable-next-line
    }, [isOpen]);

    return (
        <div>
            <CardContainer title={t("Wallet")} tutorial="WALLET">
                <div className="my-2 mx-2 d-flex justify-content-md-between flex-column-reverse flex-md-row d-grid gap-2">
                    {isOpen ? (
                        <Button className="ms-auto" color="danger" onClick={() => setIsOpen(null)}>
                            {" "}
                            {t("Regresar")}{" "}
                        </Button>
                    ) : (
                        ""
                    )}
                </div>
                {!isOpen ? (
                    <BalanceWallet t={t} setIsOpen={setIsOpen} />
                ) : (
                    <div>
                        <div className="text-center pt-3 pb-4 mb-1">
                            <h5>{t("Add credits to your account")}</h5>
                        </div>

                        <div className="progress-nav mb-4">
                            <Progress value={progressbarvalue} style={{ height: "2px", zIndex: 10 }} />

                            <Nav className="nav-pills progress-bar-tab custom-nav" role="tablist" style={{ zIndex: 15 }}>
                                <NavItem style={{ zIndex: 15 }}>
                                    <NavLink style={{ cursor: "default" }} id="pills-gen-info-tab" className="rounded-pill" tag="button">
                                        1
                                    </NavLink>
                                </NavItem>
                                <div style={{ zIndex: 15 }}>
                                    <NavLink style={{ cursor: "default" }} id="pills-gen-info-tab" className="rounded-pill" tag="button">
                                        2
                                    </NavLink>
                                </div>
                                <NavItem style={{ zIndex: 15 }}>
                                    <NavLink style={{ cursor: "default" }} id="pills-gen-info-tab" className="rounded-pill" tag="button">
                                        3
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                                <div>
                                    <FormForWallet t={t} handleSubmit={handleSubmit} handleReset={resetForm} handleBlur={handleBlur} values={values} errors={errors} touched={touched} onChange={onChange} />
                                </div>

                                <div className="d-flex align-items-start gap-3 mt-4">
                                    <Button
                                        type="button"
                                        className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                        onClick={() => {
                                            toggleTab(activeTab + 1, 50);
                                        }}
                                        disabled={disabledButton()}
                                    >
                                        <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                        {t("Next")}
                                    </Button>
                                </div>
                            </TabPane>

                            <TabPane tabId={2}>
                                <div>{activeTab === 2 && values.credits && Number(values.credits) >= minCredits && <WalletStripe t={t} email={values.email} credits={values.credits} setStatus={setPagoStatus} />}</div>
                            </TabPane>
                            <TabPane tabId={3}>
                                <div>
                                    <div className="text-center">
                                        <div className="mb-4">
                                            <i className="bx bx-party display-4 text-success"></i>
                                        </div>
                                        <h5>{t("Well Done!")}</h5>
                                        <p className="text-muted">{t("your wallet will be updated when the payment is processed")}</p>
                                        <h5>{t("you can check the status in the wallet section")}</h5>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                )}
            </CardContainer>
        </div>
    );
}

export default withTranslation()(WalletMain);
