export const clientesEN = {
    clientes: {
        common: {
            Customer: "Customer",
            Customers: "Customers",
            "Last file": "Last file",
            "Trial stage": "Trial stage",
            "Next appointment": "Next appointment",
            Stages: "Stages",
            Subject: "Subject",
            "Judgment type": "Judgment type",
            Status: "Status",
            "File number": "File number",
            "See file": "See File",
            File: "File",
            "There is no appointment": "There is no appointment",
            "Does not have a file": "Does not have a file",
            "No stage": "No stage",
            "this file": "this file",
            "There is already a registered client with this data": "There is already a registered client with this data",
            "There is already a file registered with this data":"There is already a file registered with this data"

        },
    },
}
