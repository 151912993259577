/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { CardBody, CardHeader, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import { SimpleDonut } from "../components/simplePayChart";

function ChartExpenseInteractions({ t }: { t: (f: string) => string }) {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getFolder("FOLDER");
    }, []);

    async function getFolder(mimetype: string) {
        setLoading(true);
        const res = await peticionBack({}, `/dashboard/wallet/expense-distribution`, "GET", false);
        if (res) {
            setData(res);
        }
        setLoading(false);
    }
    return (
        <>
            <CardHeader>
                <div className="card-header border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">{t("Expense - interactions")}</h4>
                </div>
            </CardHeader>
            <CardBody>
                {loading && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <Spinner color="primary" />
                    </div>
                )}
                <div className="top-50 start-50" style={{ zIndex: "1" }}>
                    <SimpleDonut data={data} dataColors='["--vz-success", "--vz-primary", "--vz-info"]' />
                </div>
            </CardBody>
        </>
    );
}

export default withTranslation()(ChartExpenseInteractions);
