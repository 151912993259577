import { Fragment, useEffect, useState } from "react";
import { QueueDto } from "../../core/interfaces/Queue.dto";
import Button from "../buttons/Button";
import { solutions, StausQueue } from "../../core/enums/enums";
import peticionBack from "../../helpers/peticiones";
import { DataItem } from "../../pages/transcodificador/dto/TranscoderDto";
import { useDispatch } from "react-redux";
import { EnumLoader } from "../../core/types/TypeLoader";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { useNavigate } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getCostSolution } from "../../helpers/getCostsSolutions";
import toastMessage from "../../helpers/toastMessage";
import { getData } from "../layout/protected/components/BalanceHeader";
import { useSelector } from "react-redux";
import { loginData } from "../../core/slices/auth/AuthSlicer";

export default function AudioPlayer({ queue }: { queue: DataItem }) {
    const [tts, setTts] = useState<QueueDto | undefined>(queue.tts_task?.[0]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [costSolution, setCostSolution] = useState<number | null>();
    const loginInformation = useSelector(loginData);

    useEffect(() => {
        getSolutionCost();
        // eslint-disable-next-line
    }, []);

    async function getSolutionCost() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const costSolution = await getCostSolution(solutions.TEXTO_VOZ);
        if (costSolution) {
            setCostSolution(costSolution);
        }
        dispatch(loaded());
    }

    const proccessText = async () => {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ queue_id: queue.queue_id, replace: false }, `/tts`, "POST", true);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");

            setTts(res);
            navigate("/tts", { replace: true });
            getData(loginInformation, dispatch);
        }
    };

    return (
        <Fragment>
            {tts ? (
                tts.status === StausQueue.FAILURE ? (
                    <p className="text-danger">{t("tts.It was not possible to convert the text to audio")}</p>
                ) : tts.status === StausQueue.SUCCESS ? (
                    <audio src={tts.result[0].url} controls style={{ minWidth: "60%" }}>
                        <p>{t("tts.The browser cannot play this type of audio")}</p>
                    </audio>
                ) : (
                    <p className="text-muted">{t("tts.Converting text to audio")}...</p>
                )
            ) : costSolution ? (
                <>
                    <h4>{t("would you want convert to audio")}</h4>
                    <Button onClick={proccessText} id="btn-proccess-audio">
                        <i className="ri-money-dollar-circle-line" style={{ fontSize: "1em" }} />
                        {`${costSolution} ${t("tts.Word processing")}`}
                    </Button>
                    <UncontrolledTooltip target="btn-proccess-audio">{t("tts.Convert text to audio")}</UncontrolledTooltip>
                </>
            ) : (
                <h4>${t("The price could not be consulted")}</h4>
            )}
        </Fragment>
    );
}
