import React, { useEffect, useRef, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ModalNewEventCaldendar } from "../../components/pages/calendar/Components/ModalNewEventCaldendar";
import peticionBack from "../../helpers/peticiones";
import createURL from "../../helpers/url";
import { es } from "date-fns/locale";
import { Event, ActionsCalendar, paramsGetEvent } from "./types/Calendar";
import { useTranslation } from "react-i18next";
import CardContainer from "../../components/cardContainer/CardContainer";

const Agenda = () => {
    const [modalNewEvent, setModalNewEvent] = useState(false);
    const [dayClicked, setDayClicked] = useState();
    const [currentEvent, setCurrentEvent] = useState<Event | null>(null);
    const calendarRef = useRef<FullCalendar | null>(null);
    const [events, setEvents] = useState<Array<any>>([]);
    const [dateCalendar] = useState(new Date());
    const { t } = useTranslation();

    useEffect(() => {
        getEvents(ActionsCalendar.MONTH);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEvents = async (action: ActionsCalendar) => {
        const searchParams = getParamsEvent(action);
        const params: any = {
            pathname: "/agenda",
            searchParams,
        };

        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res) {
            const events = res.map((event: any) => {
                return {
                    ...event,
                    id: event._id,
                    start: new Date(new Date(event.start).getTime()),
                    end: new Date(new Date(event.end).getTime()),
                };
            });
            setEvents(events);
        }
    };

    function getParamsEvent(action: ActionsCalendar): Array<paramsGetEvent> {
        let calendarApi = calendarRef.current?.getApi();
        let typeView = calendarRef.current?.getApi().view.type === "dayGridMonth" ? "month" : calendarRef.current?.getApi().view.type === "dayGridDay" ? "day" : "week";
        let valueParam = 0;
        switch (typeView) {
            case "month":
                if (action === ActionsCalendar.NEXT) {
                    valueParam = dateCalendar.getMonth() + 1;
                    dateCalendar.setMonth(dateCalendar.getMonth() + 1);
                }
                if (action === ActionsCalendar.PREV) {
                    valueParam = dateCalendar.getMonth() - 1;
                    dateCalendar.setMonth(dateCalendar.getMonth() - 1);
                }
                if (action === ActionsCalendar.MONTH) {
                    let actualDate = new Date();
                    dateCalendar.setDate(actualDate.getDate());
                    dateCalendar.setMonth(actualDate.getMonth());
                    dateCalendar.setFullYear(actualDate.getFullYear());
                    calendarApi?.gotoDate(actualDate);
                }
                if (action === ActionsCalendar.TODAY) {
                    let actualDate = new Date();
                    dateCalendar.setDate(actualDate.getDate());
                    dateCalendar.setMonth(actualDate.getMonth());
                    dateCalendar.setFullYear(actualDate.getFullYear());
                    calendarApi?.gotoDate(actualDate);
                }
                valueParam = dateCalendar.getMonth();
                break;

            case "week":
                let actualDate = new Date();
                if (action === ActionsCalendar.NEXT) {
                    dateCalendar.setDate(dateCalendar.getDate() + 7);
                }
                if (action === ActionsCalendar.PREV) {
                    dateCalendar.setDate(dateCalendar.getDate() - 7);
                }
                if (action === ActionsCalendar.TODAY) {
                    dateCalendar.setDate(actualDate.getDate());
                    dateCalendar.setMonth(actualDate.getMonth());
                    dateCalendar.setFullYear(actualDate.getFullYear());
                    calendarApi?.gotoDate(actualDate);
                }
                if (action === ActionsCalendar.WEEK) {
                    dateCalendar.setDate(actualDate.getDate());
                    dateCalendar.setMonth(actualDate.getMonth());
                    dateCalendar.setFullYear(actualDate.getFullYear());
                }

                valueParam = getWeekYear();

                break;
            case "day":
                if (action === ActionsCalendar.NEXT) {
                    dateCalendar.setDate(dateCalendar.getDate() + 1);
                }
                if (action === ActionsCalendar.PREV) {
                    dateCalendar.setDate(dateCalendar.getDate() - 1);
                }
                if (action === ActionsCalendar.DAY || action === ActionsCalendar.TODAY) {
                    let actualDate = new Date();
                    dateCalendar.setDate(actualDate.getDate());
                    dateCalendar.setMonth(actualDate.getMonth());
                    dateCalendar.setFullYear(actualDate.getFullYear());
                    calendarApi?.gotoDate(actualDate);
                }

                valueParam = getDayOfYear();

                break;
        }

        return [
            { name: "type_calendar", value: typeView },
            { name: "value", value: valueParam },
            { name: "year", value: dateCalendar.getFullYear() },
        ];
    }

    function getWeekYear(): number {
        let fecha = dateCalendar;
        const day = 1000 * 60 * 60 * 24,
            daysWeek = 7,
            thursday = 4;
        fecha = new Date(Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate()));
        let dayWeek = fecha.getUTCDay(); // Domingo es 0, sábado es 6
        if (dayWeek === 0) {
            dayWeek = 7;
        }
        fecha.setUTCDate(fecha.getUTCDate() - dayWeek + thursday);
        const inicioDelAño = new Date(Date.UTC(fecha.getUTCFullYear(), 0, 1)) as any;
        const diferenciaDeFechasEnMilisegundos = (fecha as any) - inicioDelAño;
        return Math.ceil((diferenciaDeFechasEnMilisegundos / day + 1) / daysWeek);
    }

    function getDayOfYear(): number {
        var now = dateCalendar as any;
        var start = new Date(now.getFullYear(), 0, 0) as any;
        var diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
        var oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }

    function handleDateClick(arg: any): void {
        setDayClicked(arg.date);
        setModalNewEvent(true);
    }

    async function onDrop(arg: any) {
        const eventChanded = {
            title: arg.event._def.title,
            allDay: arg.event._def.allDay,
            id: arg.event._def.publicId,
            _id: arg.event._def.extendedProps._id,
            description: arg.event._def.extendedProps.description,
            start: new Date(new Date(arg.event._instance.range.start).getTime() + 6 * 60 * 60 * 1000),
            end: new Date(new Date(arg.event._instance.range.end).getTime() + 6 * 60 * 60 * 1000),
        };

        const res = await peticionBack(eventChanded, `/agenda/${encodeURIComponent(eventChanded._id)}`, "PUT", true);
        if (!res) {
            arg.revert();
        }
    }

    function renderEventContent(eventInfo: any) {
        return (
            <div style={{ fontSize: "10px" }} className="text-center">
                <div className="text-center">
                    <span>
                        <b>
                            {eventInfo.event._instance.range.start.getHours() - 6}:{eventInfo.event._instance.range.end.getMinutes() < 9 ? 0 : ""}
                            {eventInfo.event._instance.range.start.getMinutes()} - {eventInfo.event._instance.range.end.getHours() - 6}:{eventInfo.event._instance.range.end.getMinutes() < 9 ? 0 : ""}
                            {eventInfo.event._instance.range.end.getMinutes()}
                        </b>
                    </span>
                </div>
                <span>
                    <span>{eventInfo.event.title}</span>
                </span>
            </div>
        );
    }

    async function addEvent(event: Event) {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);
        startDate.setHours(event.startTime.getHours());
        startDate.setMinutes(event.startTime.getMinutes());
        startDate.setSeconds(event.startTime.getSeconds());

        endDate.setHours(event.endTime.getHours());
        endDate.setMinutes(event.endTime.getMinutes());
        endDate.setSeconds(event.endTime.getSeconds());

        const res = await peticionBack({ ...event, start: startDate, end: endDate }, "/agenda", "POST", true);
        if (res) {
            setEvents([
                ...events,
                {
                    ...res,
                    id: res._id,
                    _id: res._id,
                    start: new Date(new Date(res.start).getTime()),
                    end: new Date(new Date(res.end).getTime()),
                },
            ]);
            setModalNewEvent(false);
        }
    }
    async function updateEvent(event: Event) {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);

        startDate.setHours(event.startTime.getHours());
        startDate.setMinutes(event.startTime.getMinutes());
        startDate.setSeconds(event.startTime.getSeconds());

        endDate.setHours(event.endTime.getHours());
        endDate.setMinutes(event.endTime.getMinutes());
        endDate.setSeconds(event.endTime.getSeconds());

        const res = await peticionBack({ ...event, start: startDate, end: endDate }, `/agenda/${encodeURIComponent(event.id)}`, "PUT", true);
        if (res) {
            const newEvents = events.map((element) => {
                if (element._id === event.id) {
                    return { ...res, id: event._id, start: new Date(new Date(res.start).getTime()), end: new Date(new Date(res.end).getTime()) };
                } else {
                    return element;
                }
            });

            setEvents(newEvents);
            setModalNewEvent(false);
        }
    }

    async function deleteEvent(eventDelete: Event) {
        const res = await peticionBack({}, `/agenda/${eventDelete._id}`, "DELETE", true);
        if (res) {
            setEvents([...events].filter((ev) => ev.id !== eventDelete.id));
            handleOnCloseModal();
        }
    }

    useEffect(() => {
        if (currentEvent) {
            setModalNewEvent(true);
        }
    }, [currentEvent]);

    function handleEventClick(args: any) {
        let eventClicked = events.find((ev) => ev._id === args.event._def.publicId) as Event;
        setCurrentEvent(eventClicked);
    }

    function handleOnCloseModal() {
        setModalNewEvent(false);
        setCurrentEvent(null);
    }

    function changeCalendarValues(typeChange: ActionsCalendar) {
        let calendarApi = calendarRef.current?.getApi();

        switch (typeChange) {
            case ActionsCalendar.NEXT:
                getEvents(typeChange);
                calendarApi?.next();
                break;
            case ActionsCalendar.PREV:
                getEvents(typeChange);
                calendarApi?.prev();
                break;
            case ActionsCalendar.MONTH:
                calendarRef.current?.getApi().changeView("dayGridMonth");
                getEvents(typeChange);
                break;
            case ActionsCalendar.WEEK:
                calendarRef.current?.getApi().changeView("dayGridWeek");
                getEvents(typeChange);
                break;
            case ActionsCalendar.DAY:
                calendarRef.current?.getApi().changeView("dayGridDay");
                getEvents(typeChange);
                break;
            case ActionsCalendar.TODAY:
                getEvents(typeChange);
                break;
        }
    }

    return (
        <CardContainer title="Agenda" tutorial="SCHEDULE">
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                eventContent={renderEventContent}
                headerToolbar={{
                    left: "preButton todayButton nextButton",
                    center: "title",
                    // right: "dayGridMonth,dayGridWeek,dayGridDay",
                    right: "monthButton,weekButton,dayButton",
                }}
                customButtons={{
                    preButton: {
                        text: t("Prev"),
                        icon: " ri-arrow-left-s-fill",
                        click: () => {
                            changeCalendarValues(ActionsCalendar.PREV);
                        },
                    },
                    nextButton: {
                        text: t("Next"),
                        icon: " ri-arrow-right-s-fill",
                        click: () => {
                            changeCalendarValues(ActionsCalendar.NEXT);
                        },
                    },
                    todayButton: {
                        text: t("today"),
                        click: () => {
                            changeCalendarValues(ActionsCalendar.TODAY);
                        },
                    },
                    monthButton: {
                        text: t("Month"),
                        click: () => {
                            changeCalendarValues(ActionsCalendar.MONTH);
                        },
                    },
                    weekButton: {
                        text: t("week"),
                        click: () => {
                            changeCalendarValues(ActionsCalendar.WEEK);
                        },
                    },
                    dayButton: {
                        text: t("Day"),
                        click: () => {
                            changeCalendarValues(ActionsCalendar.DAY);
                        },
                    },
                }}
                editable={true}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                drop={onDrop}
                eventDrop={onDrop}
                locale={es}
            />

            {modalNewEvent && <ModalNewEventCaldendar open={modalNewEvent} handleOnClose={handleOnCloseModal} setEvents={setEvents} events={events} addEvent={addEvent} dayClicked={dayClicked} currentEvent={currentEvent} deleteEvent={deleteEvent} updateEvent={updateEvent} />}
        </CardContainer>
    );
};

export default Agenda;
