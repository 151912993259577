import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { renewToken } from "../renewToken/RenewToken";
// import { swalAction } from "../ModalConfirm/modalConfirm";
import toastMessage from "../toastMessage";
import { useSelector } from "react-redux";
import { loadingData } from "../../core/slices/loader/LoaderSlicer";

export default function Timer({ children, onFinished, loginInformation }: { children: any; onFinished: Function; loginInformation: any }) {
    const [count, setCount] = useState(0);
    const [remaining, setRemaining] = useState(300);
    const { loadingStack } = useSelector(loadingData);
    const dispatch = useDispatch();

    const onIdle = () => {};

    const onActive = () => {};

    const onAction = () => {
        setCount(count + 1);
    };

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        // timeout: 1000 * 60 * 30,
        timeout: 1000 * 60 * 30,
        throttle: 500,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (remaining === 0) {
            onFinished();
            toastMessage("error", "Session cerrada por inactividad");
        }

        veryfyNewToken();
        return;
        // eslint-disable-next-line
    }, [remaining]);

    function veryfyNewToken() {
        const currentTime = Math.floor(Date.now() / 1000);

        const oneHourInSeconds = 60 * 60;

        if (currentTime > loginInformation.exp - oneHourInSeconds && loadingStack.length === 0 && currentTime % 20 === 0) {
            renewToken(dispatch, loginInformation.refreshToken);
        }
    }

    return <>{children}</>;
}
