import { combineReducers } from "@reduxjs/toolkit";
import balanceReducer from "../slices/balance_header/BalanceHeader";
import LayoutReducer from "./layout/reducer";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import AuthSlicer from "./auth/AuthSlicer";
import ChatbotSlicer from "./chatbot/ChatbotSlice";
import  moduleSlicer  from "./permisos/permissionsSlicer";
import LoaderSlicer from "./loader/LoaderSlicer";

const persistConfig = {
    key: "auth_zeeIAB",
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, AuthSlicer);

const rootReducer = combineReducers({
    auth: persistedAuthReducer,
    chatbot: ChatbotSlicer,
    modules: moduleSlicer,
    layout: LayoutReducer,
    balance: balanceReducer,
    loader: LoaderSlicer
    //modulos: moduleSlice,
});

export default rootReducer;
