import react, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OnChangeParams } from "../../../inputs/InputProps";
import { RedesSocialesIcons } from "../components/RedesSocialesIcons";
import { FormLogin } from "../components/FormLogin";
import { LoginSignUpMessage } from "../components/LoginSignUpMessage";
import peticionBack from "../../../../helpers/peticiones";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../../core/slices/auth/AuthSlicer";
import { useNavigate } from "react-router-dom";
import ModalCloseSessions from "../components/ModalCloseSessions";
import { Col } from "reactstrap";
import { RecoverPassword } from "../components/recoverPassword";

export const LoginView = ({ t, setIsLogin, isLogin }: { t: (text: string) => string; setIsLogin: React.Dispatch<react.SetStateAction<boolean>>; isLogin: boolean }) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [recoveryToken, setRecoveryToken] = useState<string | null>();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const defaultForm = {
        email: "",
        password: "",
    };

    const formValidations = Yup.object().shape({
        email: Yup.string().required("Enter an email").email("Invalid email"),
        password: Yup.string().required("Enter a password"),
    });

    async function googleAuth() {
        window.location.href = process.env.REACT_APP_BACK_URI + "/auth/google";
    }

    async function outlookAuth() {
        window.location.href = process.env.REACT_APP_BACK_URI + "/auth/microsoft";
    }

    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async () => {
            setLoading(true);
            const res = await peticionBack({ username: values.email, password: values.password }, "/auth/login", "POST", true, {}, true);

            if ((res.status === 201 || res.status === 200) && !res?.erro) {
                dispatch(
                    loginSuccess({
                        id: res.data.id,
                        accessToken: res.data.accessToken,
                        navigator_id: res.data.navigator_id,
                        refreshToken: res.data.refreshToken,
                        email: values.email,
                    })
                );
                navigate("/explorador", { replace: true });
                setLoading(false);
            } else if (res.statusCode === 401 && res.message === "El usuario tiene muchas sesiones abiertas, necesita cerrar una" && res.recoveryToken) {
                setModalOpen(true);
                setRecoveryToken(res.recoveryToken);
            }
            setLoading(false);
        },
        enableReinitialize: true,
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    return (
        <div className="p-lg-3 p-3">
            <div>
                <div>
                    <h5 className={"text-primary text-center"}>{isLogin ? t("Welcome Back !") : t("Welcome to the application!")}</h5>
                    <p className="text-muted text-center">{t("Sign in to continue to MIABOGADO EN LINEA.")}</p>
                </div>

                <div className="mb-3">
                    <FormLogin errors={errors} handleReset={handleReset} values={values} touched={touched} onChange={onChange} handleSubmit={handleSubmit} handleBlu r={handleBlur} loading={loading} t={t} showRememberMe={true} showConfirmPassword={false} labelSendButton={t("Sign In")} />
                </div>

                <div className="signin-other-title text-center ">
                    <h5 className="fs-13  title">{t("Sign In with")}</h5>
                </div>

                <div className="mt-3">
                    <RedesSocialesIcons t={t} googleAuth={googleAuth} outlookAut={outlookAuth} />
                </div>
                <div className="d-flex justify-content-between mt-4">
                    <Col>
                        <LoginSignUpMessage isLogin={isLogin} t={t} setIsLogin={setIsLogin} messageLink="Signup" messageLabel={t("Don't have an account")} />
                    </Col>
                    <Col>
                        <RecoverPassword t={t} messageLink="recover" messageLabel={t("Forgot your password")} />
                    </Col>
                </div>

                {modalOpen && recoveryToken && <ModalCloseSessions modalOpen={modalOpen} setModalOpen={setModalOpen} recoveryToken={recoveryToken} t={t} />}
            </div>
        </div>
    );
};
