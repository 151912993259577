import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { DeleteOrg } from "../Components/CardDelete";
import { useSelector } from "react-redux";
import { userAuth, userInformation } from "../../../core/slices/auth/interface/userinterface";
import { useState } from "react";
import AdminTableUsers from "../Components/AdminTableUsers";

export default function UserOrganization() {
    const { t } = useTranslation();
    const userInfo = useSelector(({ auth }: { auth: userAuth }) => auth.userInformation) as userInformation;
    // {Control del colapse editar mi org}
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);


    return (
        <div>
            {/* CARD MY ORGANIZATION */}
            <Row>
                <Col lg="12">
                    <Card className="ribbon-box border shadow-none mb-lg-0" color="primary" outline>
                        <CardBody>
                            <div className="ribbon ribbon-primary round-shape mb-0" style={{ fontSize: "18px" }}>
                                {t("organization.My organization")}
                            </div>
                            <h1 className="fs-14 text-end">MiAbogadoSuite</h1>
                            <div style={{ display: "flex", margin: "20px" }}>
                                <div className="big-words" style={{ fontSize: "30px", marginRight: "20px" }}>
                                    {userInfo.organization?.name}
                                </div>
                            </div>
                            <div className="ribbon-content mt-4 text-muted">
                                <p className="mb-0 " style={{ fontSize: "18px" }}>
                                    {t("organization.This is your organization, here you can visualize general information about it and leve the organization.")}
                                </p>
                            </div>
                        </CardBody>

                        {/* COLAPSE EDIT MY ORG */}

                        <Row className="justify-content-end">
                            <Col lg="2" md="4" xs="5" xl="3">
                                <Button color="danger" onClick={toggle} style={{ margin: "1rem" }}>
                                    {t("organization.Leave organization")}
                                </Button>
                            </Col>
                            <Col lg="12">
                                <Collapse isOpen={isOpen}>
                                    <Row>
                                        <Col lg="12" md="6" xs="12">
                                            <DeleteOrg/>
                                        </Col>
                                    </Row>
                                </Collapse>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* CARD MY ORGANIZATION */}
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <AdminTableUsers/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}
