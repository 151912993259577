import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthStates } from "./states";
import { userAuth } from "./interface/userinterface";
import { RootState } from "../../redux/store";
import Swal from "sweetalert2";
import { userInformation } from "./interface/userinterface";
import { user_type } from "../../enums/enums";
import { Organizacion } from "../../../pages/admin_organizaciones/dto/organization.dto";

const initialState: userAuth = {};

export const authSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<userAuth>) => {
            state.state = AuthStates.LOGIN_SUCCESS;
            state.id = action.payload.id;
            state.refreshToken = action.payload.refreshToken;
            state.accessToken = action.payload.accessToken;
            state.navigator_id = action.payload.navigator_id;
            state.verified = action.payload.verified;
            state.email = action.payload.email;
            state.completed = action.payload.completed;
            state.carousel = true;
        },

        modalState: (state, action: PayloadAction<boolean>) => {
            state.carousel = action.payload;
        },

        fillUserInformation: (state, action: PayloadAction<userInformation>) => {
            state.userInformation = action.payload;
        },

        changeUserType: (state, action: PayloadAction<user_type>) => {
            if (state.userInformation && action.payload) {
                state.userInformation.user_type = action.payload;
            }
        },

        changeOrganization: (state, action: PayloadAction<Organizacion | null>) => {
            if (state.userInformation) {
                if (action.payload) {
                    state.userInformation.organization = {
                        name: action.payload.name,
                        description: action.payload.description,
                        id: action.payload.id,
                    };
                    state.userInformation.organization_id = action.payload.id;
                } else {
                    state.userInformation.organization = undefined;
                    state.userInformation.organization_id = undefined;
                }
            }
        },

        loginFailure: (state) => {
            state.state = AuthStates.LOGIN_FAILURE;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Usuario y/o contraseña incorrectos",
                showConfirmButton: false,
                timer: 3000,
            });
        },
        expiration: (state, action: PayloadAction<{ iat: number; exp: number }>) => {
            state.exp = action.payload.exp;
            state.iat = action.payload.iat;
        },
        clear: (state) => {
            delete state.state;
            delete state.id;
            delete state.refreshToken;
            delete state.accessToken;
            delete state.navigator_id;
            delete state.verified;
            delete state.email;
            delete state.completed;
            delete state.userInformation;
            delete state.carousel;
        },
        expirationToken: (state, action: PayloadAction<{ iat: number; exp: number }>) => {
            state.exp = action.payload.exp;
            state.iat = action.payload.iat;
        },
    },
});
export const { loginSuccess, loginFailure, clear, expiration, fillUserInformation, changeUserType, changeOrganization, modalState,expirationToken } = authSlice.actions;
export default authSlice.reducer;

export const loginData = (state: RootState) => state.auth;
