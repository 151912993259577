import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import * as Yup from "yup";
import InputBasic from "../../../components/inputs/InputBasic";
import peticionBack from "../../../helpers/peticiones";
import { OnChangeParams } from "../../../components/inputs/InputProps";
import { useFormik } from "formik";
import toastMessage from "../../../helpers/toastMessage";
import { alerConfirmation } from "../../../helpers/alertConfirmation";

export default function AddUserFormulario({ setRefresh }: { setRefresh: Function }) {
    const { t } = useTranslation();
    const defaultForm = {
        email: "",
    };

    const formValidations = Yup.object().shape({
        email: Yup.string(),
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    async function addUser() {
        const params = {
            email: values.email,
        };
        if (values.email === "") {
            return (toastMessage("error",`${t("Invalid email")}`));
        } else {
            const res = await peticionBack(params, `/usuarios/organization/set`, "PUT", true);
            if (res) {
                setRefresh(true);
                setFieldValue("email", "");
            } else {
                setFieldValue("email", "");
            }
        }
    }

    function showAlert(email: string) {
        alerConfirmation({
            handleAction: addUser,
            title: `${t("organization.Add user")}`,
            content: `${t("organization.Are you sure you want add this user? This user will have access to all the modules")}`,
            buttonOkMsg: `${t("Add")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: email,
        });
    }

    const { handleSubmit, handleReset, handleBlur, setFieldValue, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: () => {
            showAlert(values.email);
        },
    });

    return (
        <div>
            <Row>
                <Col lg="6" md="6">
                    <Container>
                        <CardBody>
                            <div className="text-muted">
                                <CardHeader tag={"h1"} className="text-primary card-header">
                                    {t("organization.Users")}
                                </CardHeader>
                                <p className="mb-3" style={{ fontSize: "18px" }}>
                                    {t("organization.To start sharing all the solutions with your users, you should first add them to your organization. Just enter an email of an existan user and it will be automatically form part of your organization.")}

                                    <span className="text-primary"> {t("organization.Don't make them wait!")}</span>
                                </p>
                            </div>
                        </CardBody>
                    </Container>
                </Col>
                <Col lg="6" md="6">
                    <Card>
                        <CardBody>
                            <CardHeader tag={"h1"} className="text-success card-header">
                                {t("organization.Add user")}
                            </CardHeader>

                            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-1">
                                <Row className="d-flex justify-content-around">
                                    <Col lg="6">
                                        <InputBasic name="email" type="text" value={values.email} label={t("Email")} touched={touched.email} error={errors.email} onChange={onChange} onBlur={handleBlur} required />
                                    </Col>
                                </Row>

                                <Row className="d-flex justify-content-between ">
                                    <Col xl="12" lg="12" className="d-flex justify-content-end my-2">
                                        <Button color="success" type="submit">
                                            Guardar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
