import React from "react";
import Button from "../../../buttons/Button";

export const RedesSocialesIcons = ({ t, googleAuth, outlookAut }: { t: (f: string) => string; googleAuth: any; outlookAut: any }) => {
    return (
        <div className=" text-center">
            <div>
                <Button
                    onClick={() => {
                        googleAuth();
                    }}
                    color="danger"
                    outline
                    className="btn-icon me-1"
                >
                    <i className="ri-google-fill fs-16" />
                </Button>

                <Button
                    onClick={() => {
                        outlookAut();
                    }}
                    color="info"
                    outline
                    className="btn-icon me-1"
                >
                    <i className="mdi mdi-microsoft-outlook" style={{ fontSize: '1.5em' }}></i>
                </Button>
            </div>
        </div>
    );
};
