import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModulesStates } from "./states";
import { permissionState } from "./interfaces/permisosInterface";
import { RootState } from "../../redux/store";

const initialState: permissionState = {};

export const moduleSlicer = createSlice({
    name: "modules",
    initialState,
    reducers: {
        loadModules: (state, action: PayloadAction<permissionState>) => {
            if (action.payload.modules && action.payload.modules.length > 0) {
                state.state = ModulesStates.MODULES_LOADED;
                state.modules = action.payload.modules;
            }
        },
        setActualModule: (state, action: PayloadAction<string>) => {
            state.actual = action.payload;
        },
    },
});
export const { loadModules, setActualModule } = moduleSlicer.actions;
export default moduleSlicer.reducer;

export const moduleData = (state: RootState) => state.modules;
