export const fileManagerES = {
    "folder-name": "Nombre de la carpeta",
    "Are-you-sure": "¿Estás seguro?",
    "Are-you-sure-complete": "¿Estás seguro de eliminar el archivo?",
    "Are-you-sure-folder": "¿Estás seguro de eliminar la carpeta?",
    "yes-delete-it": "Sí bórralo",
    "Delete-folder": "Eliminar carpeta",
    "Rename-folder": "Renombrar folder",
    "Update-folder": "Actualizar",
    "Upload-file": "Subir archivo",
    file: "Archivo",
    close: "Cerrar",
    "Your-browser-does-not-support-video-playback": "Su navegador no admite la reproducción de vídeo",
    "home-file-manager": "Inicio",
    "create-folder": "Crear carpeta",
    Folders: "Carpetas",
    Videos: "Vídeos",
    interactions: "Interacciones",
    Files: "Archivos",
    OCR: "OCR",
    Transco: "Transcodificador",
    Images: "Imágenes",
    totalFolders: "Total carpetas",
    "Can't copy to clipboard": "No se pudo copiar al portapapeles",
    "No matches found": "No se encontraron concidencias",
    Content: "Contenido",
    "Send to Transcoder": "Enviar a transcodificar",
    "Send to OCR": "Enviar a OCR",
    "Files Processed in transcoder": "Archivos procesados en transcodificador",
    model: "Modelo",
    status: "Estatus",
    date: "Fecha",
    size: "Tamaño",
    "The data is being processed to test": "Se estan procesando los datos para testar",
    "The file could not be processed": "El archivo no pudo ser procesado",
    "hide names": "ocultar nombres names",
    "open text editor": "Abrir el editor de texto",
    "You want to continue with the action": "¿Desea continuar con la acción?",
    "Process files": "Procesar archivo",
    "reprocess file": "¿Reprocesar archivo?",
    "Reprocess file": "Reprocesar archivo",
    "Reprocess file no names": "No se encontraron nombres para testar. ¿Reprocesar archivo?",
};
