export const sign = {
    "Document signed": "Document signed",
    "Document not found": "Document not found",
    "Sign Document": "Sign Document",
    "Are you sure you want to sign this document? Your document will be overwritten": "Are you sure you want to sign this document? Your document will be overwritten",
    "No element was added to the document": "No element was added to the document",
    "New Signature": "New Signature",
    Signature: "Signature",
    "Half Signature": "Half Signature",
    Initials: "Initials",
    Date: "Date",
    "Signature created": "Signature created",
    "Ocurrio un error al crear la firma": "An error occurred while creating the signature",
    "Create signature": "Create signature",
    "Draw your new signature without leaving the box": "Draw your new signature without leaving the box",
    "Save Signature": "Save Signature",
    "Are you sure you want to save this signature?": "Are you sure you want to save this signature?",
    "Your previous signature will be deleted": "Your previous signature will be deleted",
    "It was not possible to sign the document": "It was not possible to sign the document",
    "It was not possible to obtain the file": "It was not possible to obtain the file",
};
