import React from "react";

export default function InputDragAndDrop({
    label,
    name,
    onChangeMethod,
    onBlurMethod,
    accepts,
    errors,
    touched,
    reff,
    value,
    required,
    listType,
    renderFileInfo,
    renderThumbnail,
}: {
    label?: string;
    name: string;
    onChangeMethod: any;
    onBlurMethod?: any;
    accepts?: string;
    errors?: string;
    touched?: boolean;
    reff?: any;
    value: any; // Cambiar el tipo según lo que acepte Reactstrap
    required?: boolean;
    listType?: "picture" | "text" | "picture-text";
    renderFileInfo?: any;
    renderThumbnail?: any;
}) {
    return (
        <>
            {
                <label htmlFor={name}>
                    {label} {required && <span className="text-danger">*</span>}
                </label>
            }
            <input id={name} type="file" className="form-control" accept={accepts} onChange={(e) => onChangeMethod({ target: { value: e.target.files ? e.target.files[0] : null, name } })} onBlur={onBlurMethod} ref={reff} />
            <div style={{ minHeight: "10px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span className={errors !== undefined && touched !== undefined ? "text-danger fw-bold" : ""}>{!label && required && <span className="text-danger">*</span>}</span>
            </div>
            {touched && errors && <span className="text-danger fw-bold">{errors}</span>}
        </>
    );
}
