import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Dispatch } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { OnChangeParams } from "../../../inputs/InputProps";
import peticionBack from "../../../../helpers/peticiones";
import InputBasic from "../../../inputs/InputBasic";

const defaultForm = {
    name: "",
    folder_id: "",
};



export default function CreateFolder({ t, currentFolder, openCreateFolder, setOpenCreateFolder, setRefreshContent, setIsFolderRoot }: {t: (text: string) => string;  currentFolder: any; openCreateFolder: boolean; setOpenCreateFolder: Dispatch<boolean>; setRefreshContent: Dispatch<boolean>; setIsFolderRoot: Dispatch<boolean> }) {

    const formSchema = Yup.object().shape({
        name: Yup.string().required(t("Enter a name for the folder")),
    });
    
    const { handleSubmit, handleReset, handleBlur, values, errors, touched, setFieldValue } = useFormik({
        initialValues: defaultForm,
        validationSchema: formSchema,
        onSubmit: () => {
            create();
        },
        onReset: () => {
            setOpenCreateFolder(false);
        },
    });

    async function create() {
        const params = { ...values, folder_id: currentFolder };
        const res = await peticionBack(params, "/folders", "POST", true);
        if (res) {
            setRefreshContent(true);
            setIsFolderRoot(res.folder_id ? false : true);
        }
        setOpenCreateFolder(false);
        handleReset(null);
    }

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    return (
        <Modal id="createFolderModal" isOpen={openCreateFolder} modalClassName="zoomIn" centered tabIndex={1}>
            <ModalHeader className="p-3 bg-success-subtle">{t("create-folder")}</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit} onReset={handleReset}>
                    <InputBasic name="name" value={values.name} label={t("folder-name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                    <div className="mt-4">
                        <div className="hstack gap-2 justify-content-end">
                            <button type="reset" className="btn btn-ghost-success" onClick={() => setOpenCreateFolder(false)}>
                                <i className="ri-close-line align-bottom"></i> {t("cancel")}
                            </button>
                            <button type="submit" className="btn btn-primary">
                                {t("create-folder")}
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}
