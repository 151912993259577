import { Link, ScrollRestoration } from "react-router-dom";
import PrivacyView from "../../components/pages/privacidad/Main";

import LandingFooter from "../../components/pages/landing/footer/Index";

export default function Privacy({ padding }: { padding: string }) {
    return (
        <div>
            <div className="m-5">
                <ScrollRestoration />
                <nav className={"navbar   fixed-top d-flex justify-content-start align-items-end p-3 "} id="navbar">
                    <div>
                        <Link to="/index">
                            <Link to="/" className="btn btn-info" style={{ background: "#4452EE" }}>
                                Regresar
                            </Link>
                        </Link>
                    </div>
                </nav>
                <div className={padding.length > 0 ? padding : ""}>
                    <PrivacyView />
                </div>
            </div>
            <LandingFooter />
        </div>
    );
}
