export const landingUS = {
    Start: "Start",
    Public: "Public",
    Solutions: "Solutions",
    Organizations: "Organizations",
    Contact: "Contact",
    Login: "Login",
    "Welcome to the era of artificial intelligence in the judicial branch with": "Welcome to the era of artificial intelligence in the judicial branch with",
    "Our suite of digital tools contains services for 2 sectors": "Our suite of digital tools contains services for 2 sectors",
    "Trial lawyers and educational sector of the branch of law": "Trial lawyers and educational sector of the branch of law",
    "Online text editor tested with AI to generate": "Online text editor tested with AI to generate",
    Promotions: "Promotions",
    Protections: "Protections",
    "Converting video audiences to text": "Converting video audiences to text",
    "Conversion of sentences, jurisprudence and agreements to text": "Conversion of sentences, jurisprudence and agreements to text",
    "Converting text to audio": "Converting text to audio",
    "AI Legal Assistant": "AI Legal Assistant",
    "Electronic file": "Electronic file",
    "Electronic agenda": "Electronic agenda",
    "Judicial powers": "Judicial powers",
    "Public statement generator": "Public statement generator",
    "Conversion of sentences, jurisprudence and agreements in PDF format to text": "Conversion of sentences, jurisprudence and agreements in PDF format to text",
    "It allows the Judiciary and law firms to share the same wallet with different users.": "It allows the Judiciary and law firms to share the same wallet with different users.",
    "Our suite helps you control expenses through a dashboard and reports in HTML and Excel to have better control of expenses and identify which user has used the tools and how much credit from your wallet has been consumed.":
        "Our suite helps you control expenses through a dashboard and reports in HTML and Excel to have better control of expenses and identify which user has used the tools and how much credit from your wallet has been consumed.",
    "What are you waiting for to use AI to your advantage?": "What are you waiting for to use AI to your advantage?",
    "Start your free trial at": "Start your free trial at",
    "right now and discover how AI can help you be the best lawyer in Mexico": "right now and discover how AI can help you be the best lawyer in Mexico",
    Register: "Register",
    "All rights reserved for www.miabogadoenlinea.mx, consult our privacy notice for more information about privacy and data confidentiality policies": "All rights reserved for www.miabogadoenlinea.mx, consult our privacy notice for more information about privacy and data confidentiality policies.",
    "Privacy notice": "Privacy notice",
    footerPolicy: "Policies",
    "PUBLIC SENTENCE GENERATOR": "PUBLIC SENTENCE GENERATOR",
    "Through the use of AI, we help judiciaries convert their private sentences into public sentences under intelligent testing of their document.": "Through the use of AI, we help judiciaries convert their private sentences into public sentences under intelligent testing of their document.",
    "VIDEO TO TEXT CONVERSION": "VIDEO TO TEXT CONVERSION",
    "Agilely manipulate a hearing of 2 hours or more, transforming it into text and requesting an intelligent legal point of view with our AI assistant.":
        "Agilely manipulate a hearing of 2 hours or more, transforming it into text and requesting an intelligent legal point of view with our AI assistant.",
    "PDF/IMAGE TO TEXT CONVERSION": "PDF/IMAGE TO TEXT CONVERSION",
    "Convert your promotions, demands or any other legal writing to text and rely on our AI legal assistant trained in Mexican laws.": "Convert your promotions, demands or any other legal writing to text and rely on our AI legal assistant trained in Mexican laws.",
    "Our chatbot is an AI assistant that allows you to connect our video to text and PDF/image to text conversion services to manipulate content and request legal advice or content summaries in a single click.":
        "Our chatbot is an AI assistant that allows you to connect our video to text and PDF/image to text conversion services to manipulate content and request legal advice or content summaries in a single click.",
    "TEXT TO AUDIO CONVERSION": "TEXT TO AUDIO CONVERSION",
    "Being inclusive, our text-to-audio tool allows visually impaired people to listen to any plain text extracted from hearings or lawsuits in PDF, etc.":
        "Being inclusive, our text-to-audio tool allows visually impaired people to listen to any plain text extracted from hearings or lawsuits in PDF, etc.",
    "ELECTRONIC FILE": "ELECTRONIC FILE",
    "Keep an organized and accessible electronic file, register your clients and keep an electronic agenda for each of your next hearings; as well as being able to manipulate the stages in which each case is found.":
        "Keep an organized and accessible electronic file, register your clients and keep an electronic agenda for each of your next hearings; as well as being able to manipulate the stages in which each case is found.",
    free: "Free",
};
