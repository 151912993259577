import { useState, useRef, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataItem } from "../../../../pages/ocr/dto/OcrDto";

export default function ModalFileViewer({ row }: { row: DataItem }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [fileType, setFileType] = useState<string | null>(null);
    const fileRef = useRef(null);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const getFileType = (name: string): string | null => {
        const extension = name.split(".").pop()?.toLowerCase();
        if (extension === "pdf") return "pdf";
        if (["jpg", "jpeg", "png", "gif"].includes(extension as string)) return "image";
        return null;
    };

    useEffect(() => {
        //const url = JSON.stringify(row.result[3]);
        const type = getFileType(row.name);
        if (type) {
            setFileType(type);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openFile = () => {
        //const url = JSON.stringify(row.result[3]);
        const type = getFileType(row.name);
        if (type) {
            setModalOpen(true);
        }
    };

    return (
        <div>
            <FontAwesomeIcon id="tooltipFile" icon={fileType === "pdf" ? faFilePdf : faFileImage} size="xl" className="text-info px-1 cursor-pointer" onClick={openFile} />
            <UncontrolledTooltip placement="top" target="tooltipFile">
                {/* {fileType === "pdf" ? "Abrir PDF" : "Abrir Imagen"} */} Abrir Archivo
            </UncontrolledTooltip>
            <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
                <ModalHeader toggle={toggleModal}>{row.name}</ModalHeader>
                <ModalBody>
                    {fileType === "pdf" && <iframe ref={fileRef} src={row.url} title="PDF Viewer" style={{ width: "100%", height: "500px", border: "none" }} />}
                    {fileType === "image" && <img src={row.url} alt="Imagen" style={{ maxWidth: "100%" }} />}
                </ModalBody>
            </Modal>
        </div>
    );
}
