import SimpleBar from "simplebar-react";
import { chatHistory } from "../types/Chat";
import { formatRelative } from "date-fns/formatRelative";
import { es } from "date-fns/locale";
import { useEffect } from "react";

export const ConversationView = ({ chatRef, messages }: { chatRef: any; messages: any }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <SimpleBar ref={chatRef} className="h-100">
                <ul className="list-unstyled chat-conversation-list" id="users-conversation">
                    {messages
                        .flatMap((message: chatHistory) => [
                            { value: message.human, id: `${message._id}-human`, reply: false, date: message.human_date },
                            { value: message.ia === "..." ? <div className="animate-typing"></div> : message.ia, id: `${message._id}-ia`, reply: true, date: message.ia_date },
                        ])
                        .map((elmt: any) => (
                            <li className={elmt.reply ? "chat-list left" : "chat-list right"} key={elmt.id}>
                                <div className="conversation-list">
                                    <div className="user-chat-content">
                                        <div className="ctext-wrap">
                                            {elmt.reply ? (
                                                <>
                                                    <div className="replymessage-block mb-0 d-flex align-items-start">
                                                        <div className="flex-grow-1">
                                                            <h5 className="conversation-name">IA</h5>
                                                            <p className="mb-0">{elmt.value}</p>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <button type="button" className="btn btn-sm btn-link mt-n2 me-n3 font-size-18"></button>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="ctext-wrap-content">
                                                    <p className="mb-0 ctext-content">{elmt.value}</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="conversation-name">
                                            <small className="text-muted time">{formatRelative(elmt.date !== null ? elmt.date : new Date(), new Date(), { locale: es })}</small>
                                            <span className="text-success check-message-icon">
                                                <i className="ri-check-double-line align-bottom"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
            </SimpleBar>
        </div>
    );
};
