import { useEffect, useState } from "react";
import {} from "date-fns";
import { Collapse, Spinner } from "reactstrap";
import CardContainer from "../../../cardContainer/CardContainer";
import { FormReporte } from "./Form";

export default function ReportesMain({ t, reportes }: { t: (f: string) => string; reportes: any[] }) {
    const [loading, setLoading] = useState<any>({ state: false, msg: "" });
    const [reportPDF, setReportPDF] = useState<string>("");
    const [showPDF, setshowPDF] = useState<boolean>(false);
    const [reportHTML, setReportHTML] = useState<any>();
    const [showHTML, setshowHTML] = useState<boolean>(false);

    useEffect(() => {
        if (reportPDF) setshowPDF(true);
    }, [reportPDF]);

    useEffect(() => {
        if (!showPDF) setReportPDF("");
    }, [showPDF]);

    useEffect(() => {
        if (reportHTML) setshowHTML(true);
    }, [reportHTML]); 
    

    return (
        <>
            <CardContainer title="Reportes" tutorial="REPORTS">
                <FormReporte t={t} reportes={reportes} setLoading={setLoading} setReportePDF={setReportPDF} setReportHTML={setReportHTML} tabla={[showHTML, setshowHTML]} />
                <Collapse isOpen={showHTML}>
                    <div className="mt-4 mx-md-3">{reportHTML}</div>
                </Collapse>  
            </CardContainer>
            {loading.state && (
                <>
                    <div className="d-flex justify-content-center">
                        <Spinner color="primary">{t("Getting information")}</Spinner>
                    </div>
                    <div className="d-flex justify-content-center">
                        <h3>{t("Getting information")}</h3>
                    </div>
                </>
            )}
            {loading.state && <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999 }}></div>}
        </>
    );
}
