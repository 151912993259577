import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

export default function LandingFooter() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <footer className="custom-footer bg-dark py-5 position-relative poppings" id="contact">
                <Container>
                    <Row>
                        <Col lg={4} className="mt-4">
                            <div>
                                <div className="mt-4 fs-13">
                                    <p>{t("All rights reserved for www.miabogadoenlinea.mx, consult our privacy notice for more information about privacy and data confidentiality policies")}</p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={7} className="ms-lg-auto">
                            <Row>
                                <Col sm={4} className="mt-4"></Col>
                                <Col sm={4} className="mt-4">
                                    <h5 className="text-white mb-0">{t("Contact")}</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li>
                                                <p>+52 729 146 8319</p>
                                            </li>
                                            <li>
                                                <p>contacto@miabogadoenlinea.mx</p>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col sm={4} className="mt-4">
                                    <h5 className="text-white mb-0">{t("footerPolicy")}</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li>
                                                <p
                                                    onClick={() => {
                                                        navigate("/privacidad", { replace: true });
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {t("Privacy notice")}
                                                </p>
                                            </li>
                                            <li>
                                                <p
                                                    onClick={() => {
                                                        navigate("/privacidad", { replace: true });
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {t("footerPolicy")}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="text-center text-sm-start align-items-center mt-5">
                        <Col sm={6}>
                            <div>
                                <p className="copy-rights mb-0">{new Date().getFullYear()} &copy; MIAbogado Suite</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end mt-3 mt-sm-0">
                                <ul className="list-inline mb-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <Link to="https://www.facebook.com/profile.php?id=61561875013482&mibextid=LQQJ4d" target="_blank" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-facebook-fill"></i>
                                            </div>
                                        </Link>
                                    </li>

                                    <li className="list-inline-item">
                                        <Link target="_blank" to="https://www.instagram.com/miabogado_en_linea?igsh=MnZpNTRtOTNuZWRs" className="avatar-xs d-block">
                                            <div className="avatar-title rounded-circle">
                                                <i className=" ri-instagram-line"></i>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
}
