export const dashboardEN = {
    Dashboard: "Dashboard",
    "schedule report": "Scheduled Clients Report",
    "status report": "Status Clients Report",
    "stage report": "Stage Clients Report",
    "Transcoding Report": "Transcoding Report",
    "Chatbot Report": "Chatbot Report",
    "ingress Report": "Ingress Report",
    "use of tools": "Use of Solutions and Ingress",
    "Income graph": "Income Graph",
    "Clients report": "Clients Report",
    AllTimeUsers: "Total Users",
    "access date": "Access Date",
    "Income report": "Income Report",
    "ocr Report": "OCR Report",
    "pdf Report": "PDF Files Report",
    "video Report": "Video Files Report",
    "image Report": "Image Files Report",
    "folder Report": "Folder Report",
    "Generate report": "Generate Report",
    Active: "Active",
    Inactive: "Inactive",
    "Files Processed in ocr": "Files Processed in OCR",
    "Folders created": "Folders Created",
    "file name": "File Name",
    "chat interaction per file": "Chat Interaction per File",
    total_chat_history: "Total Chat History",
    "user name": "User Name",
    "file uploded": "Files Uploaded",
    report: "Report",
    format: "Format",
    "Choose a report": "Choose a Report",
    "Choose a date e": "Choose an End Date",
    "Choose a date s": "Choose a Start Date",
    "Choose a option": "Choose an Option",
    "Choose a format": "Choose a Format",
    "file type": "File Type",
    "Getting information": "Getting information...",
    "The selected report does not exist": "The selected report does not exist",
};
