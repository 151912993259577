export const tts = {
    title: "Conversor de Texto a Audio",
    "The browser cannot play this type of audio": "El navegador no puede reproducir este tipo de audio",
    table: {
        file: "Archivo",
        status: "Estatus",
        "Last Update": "Última Actualización",
        Play: "Reproducir",
    },
    "Converting text to audio": "Conviertiendo texto en audio",
    "It was not possible to convert the text to audio": "No fue posible conviertir el texto en audio",
    "Word processing": "Procesar texto",
    "Convert text to audio": "Convertir texto en audio",
};
