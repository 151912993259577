import { withTranslation } from "react-i18next";
import MainView from "../../../components/pages/modulos-reportes-admin/views/MainView";
import { solutions } from "../../../core/enums/enums";

function ReportChatbot({ t }: { t: (f: string) => string }) {
    const columns = [{ data: "file.name" }, { data: "size" }, { data: "total_chat_history" }, { data: "userName" }, { data: "file.mimetype" }, { data: "createdAt", format: "date" }, { data: "updatedAt", format: "date" }, { data: "file.createdAt", format: "date" }];
    const headers = [`${t("file name")}`, `${t("size")}`, `${t("chat interaction per file")}`, `${t("user name")}`, `${t("file type")}`, `${t("date")}`, `${t("lastUpdateDate")}`, `${t("File date")}`];
    return (
        <>
            <MainView t=    {t} task={solutions.CHATBOT} columns={columns} headers={headers} />
        </>
    );
}
export default withTranslation()(ReportChatbot);
