/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import * as Yup from "yup";
import peticionBack from "../../../../helpers/peticiones";
import { Col, Collapse, Row } from "reactstrap";
import Button from "../../../buttons/Button";
import getReportExcel from "../components/excel/getReports";
import InputSelect from "../../../inputs/InputSelect";
import { thirdValue, thirdValueStatus, tiposReporte } from "../../../../core/enums/enums";
import { OnChangeParams } from "../../../inputs/InputProps";
import toastMessage from "../../../../helpers/toastMessage";
import { convertirAMegas, formatDate, obtenerNombrePaisPorCodigo } from "../../../../helpers/formats";
import { useEffect, useState } from "react";
import InputDate from "../../../inputs/InputDate";
import createURL from "../../../../helpers/url";
import getReportHTML from "../components/htmlRep/getReport";
import { paramsCFA } from "../types/reportesTypes";

export function FormReporte({ t, reportes, setLoading, setReportePDF, setReportHTML, tabla }: { t: (text: string) => string; reportes: any[]; setLoading: any; setReportePDF: any; setReportHTML: any; tabla: [any, any] }) {
    const [typeReport, setTypeReport] = useState<any>();
    const [repo, setRepo] = useState<any[]>([]);
    useEffect(() => {
        setRepo(reportes);
    }, []);

    const TypeReporte = [
        { value: tiposReporte.HTML, name: tiposReporte.HTML },
        { value: tiposReporte.EXCEL, name: tiposReporte.EXCEL },
    ];

    const thirdValueT = [
        { value: thirdValue.Amparo, label: t("protection") },
        { value: thirdValue.Enespera, label: t("Waiting") },
        { value: thirdValue.Promocion, label: t("Promotion") },
    ];

    const thirdValueStatusData = [
        { value: thirdValueStatus.PorIniciar, label: t("ByStart") },
        { value: thirdValueStatus.EnProceso, label: t("InProcess") },
        { value: thirdValueStatus.Finalizado, label: t("Finished") },
    ];

    const activeInactive = [
        { value: thirdValueStatus.Activo, label: t("Active") },
        { value: thirdValueStatus.Inactivo, label: t("Inactive") },
    ];

    const filterTranscoOcr = [
        { value: thirdValueStatus.PENDING, label: t("PENDING") },
        { value: thirdValueStatus.STARTED, label: t("STARTED") },
        { value: thirdValueStatus.RETRY, label: t("RETRY") },
        { value: thirdValueStatus.FAILURE, label: t("FAILURE") },
        { value: thirdValueStatus.SUCCESS, label: t("SUCCESS") },
    ];

    const formSchema = Yup.object().shape({
        id: Yup.string().required(t("Choose a report")),
        type: Yup.string().required(t("Choose a format")),
        sdate:
            typeReport?.value === 11
                ? Yup.date()
                .test("date-range", t("sdate"), function (value) {
                    const { edate } = this.parent;
                    const today = new Date();
                    if (!value) return true;
                    const sdate = new Date(value);
                    if (sdate > today) { 
                        throw new Yup.ValidationError(
                            t("Start date cannot be greater than today"),
                            value,
                            "sdate" 
                        );
                    }
                    if (!edate) return true;
                    const endDate = new Date(edate);
                    return sdate < endDate; 
                })
                .required(t("Choose a date s"))
                : Yup.date()
                .test("date-range", t("sdate"), function (value) {
                    const { edate } = this.parent;
                    const today = new Date();
                    if (!value) return true;
                    const sdate = new Date(value);
                    if (sdate > today) { 
                        throw new Yup.ValidationError(
                            t("Start date cannot be greater than today"),
                            value,
                            "sdate" 
                        );
                    }
                    if (!edate) return true;
                    const endDate = new Date(edate);
                    return sdate < endDate; 
                }),
        edate:
            typeReport?.value === 11
                ? Yup.date()
                .test("date-range", t("edate"), function (value) {
                    const { sdate } = this.parent;
                    const today = new Date();
                    if (!value) return true; 
                    const endDate = new Date(value);
                    if (endDate > today) { 
                        throw new Yup.ValidationError(
                            t("End date cannot be greater than today"),
                            value,
                            "edate"
                        );
                    }
                    if (!sdate) return true;
                    const startDate = new Date(sdate);
                    return endDate > startDate; 
                })
                .required(t("Choose a date e"))
                : Yup.date()
                .test("date-range", t("edate"), function (value) {
                    const { sdate } = this.parent;
                    const today = new Date();
                    if (!value) return true; 
                    const endDate = new Date(value);
                    if (endDate > today) { 
                        throw new Yup.ValidationError(
                            t("End date cannot be greater than today"),
                            value,
                            "edate"
                        );
                    }
                    if (!sdate) return true;
                    const startDate = new Date(sdate);
                    return endDate > startDate; 
                })
    });

    const { handleSubmit, handleReset, handleBlur, setFieldValue, values, errors, touched } = useFormik({
        initialValues: { id: "", type: "", third: "", sdate: "", edate: "" },
        validationSchema: formSchema,
        onSubmit: (values) => {
            getDataReport();
        },
        onReset: () => {},
    });

    useEffect(() => {
        setTypeReport(obtenerObjetoPorId(parseInt(values.id)));
        setFieldValue("third", "");
    }, [values.id]);

    function obtenerObjetoPorId(id: number) {
        return repo.find((item) => item.value === id);
    }

    async function getDataReport() {
        setLoading({ state: true, msg: "Obteniendo información..." });
        const task = obtenerObjetoPorId(parseInt(values.id));
        if (parseInt(values.id) === 1 || parseInt(values.id) === 2 || parseInt(values.id) === 12) {
            const params: any = {
                pathname: `/reports/iterations/${task?.name}`,
                searchParams: [{ name: "status", value: values.third ? values.third : "" }],
            };
            const url = createURL(params);
            const res = await peticionBack({}, url, "GET", false);
            if (res) {
                res.data.forEach((element: any) => {
                    element.size = convertirAMegas(element.size);
                    if (element.costo) element.costo.value = element.costo.value.toString();
                });
                generateReport(res);
            }
            setLoading({ state: false, msg: "" });
        } else if (parseInt(values.id) === 3 || parseInt(values.id) === 4 || parseInt(values.id) === 5 || parseInt(values.id) === 6) {
            const res = await peticionBack({}, `/reports/expedients/${task?.name}`, "GET", false);
            if (res) {
                res.data.forEach((element: any) => {
                    if (element.costo) element.costo.value = element.costo.value.toString();
                });
                generateReport(res);
            }
            setLoading({ state: false, msg: "" });
        } else if (parseInt(values.id) === 10) {
            const params: paramsCFA = {};
            if (values.sdate && values.sdate[0] !== "") {
                params.startDate = formatDate(values.sdate[0]);
            }

            if (values.edate && values.edate[0] !== "") {
                params.endDate = formatDate(values.edate[0]);
            }

            if (values.third && values.third !== "") {
                params.disable = values.third === "Inactivo" ? true : false;
            }
            const res = await peticionBack(params, "/usuarios/report", "POST", false);
            if (res) {
                const objetos = res.rows.filter((objeto: any) => objeto.country_code !== null);
                objetos.forEach((element: any) => {
                    element.country_code = obtenerNombrePaisPorCodigo(t, element.country_code);
                    element.fullname = element.fullname.trim() === "" ? `${t("NoFullData")}` : element.fullname;
                    if (element.wallet && element.wallet.transacciones.length > 0) {
                        element.wallet.value = element.wallet.value.toString();
                        element.wallet.transacciones[0].value = element.wallet.transacciones[0].value.toString();
                    }
                });
                generateReport(objetos);
            }
            setLoading({ state: false, msg: "" });
        } else if (parseInt(values.id) === 11) {
            const params: paramsCFA = {};
            if (values.sdate && values.sdate[0] !== "") {
                params.startDate = formatDate(values.sdate[0]);
            }

            if (values.edate && values.edate[0] !== "") {
                params.endDate = formatDate(values.edate[0]);
            }

            if (values.third && values.third !== "") {
                params.disable = values.third === "Inactivo" ? true : false;
            }
            const res = await peticionBack(params, "/reports/ingress", "POST", false);
            if (res) {
                res.data.forEach((element: any) => {
                    element.ingress = element.ingress + `${t("credits")}`;
                    element.tool_use = element.tool_use + `${t("use")}`;
                });
                generateReport(res);
            }
            setLoading({ state: false, msg: "" });
        } else {
            const params: any = {
                pathname: `/reports/clients/${task?.name}`,
                searchParams: [
                    { name: "stage", value: parseInt(values.id) === 8 ? values.third : "" },
                    { name: "status", value: parseInt(values.id) === 7 ? values.third : "" },
                    { name: "startDate", value: parseInt(values.id) === 9 ? formatDate(values.sdate[0]) : "" },
                    { name: "endDate", value: parseInt(values.id) === 9 ? formatDate(values.edate[0]) : "" },
                ],
            };
            const url = createURL(params);
            const res = await peticionBack({}, url, "GET", false);
            if (res) {
                generateReport(res);
            }
            setLoading({ state: false, msg: "" });
        }
    }

    async function generateReport(data: any) {
        setLoading({ state: true, msg: "Generando reporte..." });
        try {
            switch (values.type) {
                case "EXCEL":
                    getReportExcel(t, parseInt(values.id), data);
                    break;
                case "HTML":
                    const cmpHtml = getReportHTML(t, data, parseInt(values.id), true);
                    setReportHTML(cmpHtml);
                    break;
            }
        } catch (err: any) {
            console.error(err);
            toastMessage("warning", "Ocurrio un problema al generar el reporte");
        }
    }

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
        if (tabla[0]) tabla[1](false);
    }
    return (
        <form onSubmit={handleSubmit} onReset={handleReset}>
            <Row>
                <Col xs={12} md={6}>
                    <InputSelect opLabel="label" name={"id"} label={t("report")} options={repo} opValue="value" onChange={onChange} value={values.id} error={errors.id} touched={touched.id} onBlur={handleBlur} />
                </Col>
                <Col xs={12} md={6}>
                    <InputSelect opLabel="name" name={"type"} label={t("format")} options={TypeReporte} opValue="value" onChange={onChange} value={values.type} error={errors.type} touched={touched.type} onBlur={handleBlur} />
                </Col>
                <Collapse isOpen={typeReport?.name === "TRANSCRIPCION" || typeReport?.name === "OCR"}>
                    <Col xs={12} md={12}>
                        <InputSelect opLabel="label" name={"third"} label={t("filter")} options={filterTranscoOcr} opValue="value" onChange={onChange} value={values.third} error={errors.third} touched={touched.third} onBlur={handleBlur} clearable />
                    </Col>
                </Collapse>
                <Collapse isOpen={typeReport?.name === "STAGE"}>
                    <Col xs={12} md={12}>
                        <InputSelect opLabel="label" name={"third"} label={t("filter")} options={thirdValueT} opValue="value" onChange={onChange} value={values.third} error={errors.third} touched={touched.third} onBlur={handleBlur} clearable />
                    </Col>
                </Collapse>
                <Collapse isOpen={typeReport?.name === "STATUS"}>
                    <Col xs={12} md={12}>
                        <InputSelect opLabel="label" name={"third"} label={t("filter")} options={thirdValueStatusData} opValue="value" onChange={onChange} value={values.third} error={errors.third} touched={touched.third} onBlur={handleBlur} clearable />
                    </Col>
                </Collapse>
                <Collapse isOpen={typeReport?.name === "CLIENTES"}>
                    <Col xs={12} md={12}>
                        <InputSelect opLabel="label" name={"third"} label={t("filter")} options={activeInactive} opValue="value" onChange={onChange} value={values.third} error={errors.third} touched={touched.third} onBlur={handleBlur} clearable />
                    </Col>
                </Collapse>
                <Collapse isOpen={typeReport?.name === "SCHEDULE" || typeReport?.name === "CLIENTES" || typeReport?.name === "INGRESOS"}>
                    <Row className="d-flex justify-content-around">
                        <Col lg="4">
                            <InputDate name="sdate" value={values.sdate} label={t("sdatelabel")} touched={touched.sdate} error={errors.sdate} onChange={onChange} onBlur={handleBlur} type="single" wTime={false} clereable />
                        </Col>
                        <Col lg="4">
                            <InputDate name="edate" value={values.edate} label={t("edatelabel")} touched={touched.edate} error={errors.edate} onChange={onChange} onBlur={handleBlur} type="single" wTime={false} clereable />
                        </Col>
                    </Row>
                </Collapse>
            </Row>
            <div className="d-grid d-md-block text-center mt-2">
                <Button color="primary" type="submit">
                    {t("Generate report")}
                </Button>
            </div>
        </form>
    );
}
