import { saveAs } from "file-saver";
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { TranscoIterInter } from "../../types/reportesTypes";
import { ConfigTable, generaTabla } from "./generateTable";
import header from "./Header";

export default async function CustomerBySchedule(t: (text: string) => string, data: TranscoIterInter[]) {
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("schedule")}`, 6);
    let row = 3;
    if (true) {
        const configA: ConfigTable = {
            columns: [
                {
                    data: "file.client.name",
                    title: `${t("user name")}`,
                    width: 20,
                },
                {
                    data: "file.client.lastname",
                    title: `${t("user lastname")}`,
                    width: 20,
                },
                {
                    data: "name",
                    title: `${t("visit")}`,
                    width: 25,
                },
                {
                    data: "dateAssigned",
                    title: `${t("dateAssigned")}`,
                    width: 20,
                    align: "tCenter",
                    format: "date",
                },
                { data: "createdAt", title: `${t("date")}`, width: 20, align: "tCenter", format: "date" },
                {
                    data: "file.file_number",
                    title: `${t("file_number")}`,
                    width: 25,
                },
                {
                    data: "file.status",
                    title: `${t("state")}`,
                    width: 10,
                },
            ],
        };

        const transcoIter = data;
        vtCabecera(sheet, row, t("schedule report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("schedule report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string) {
    sheet.mergeCells(row, 1, row, 6);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
