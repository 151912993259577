import { withTranslation } from "react-i18next";
import MainView from "../../../components/pages/modulos-reportes-admin/views/MainView";
import { solutions } from "../../../core/enums/enums";

function ReportOCR({ t }: { t: (f: string) => string }) {
    const columns = [{ data: "userName"}, { data: "file.name" }, { data: "size" },{ data: "file.mimetype" }, { data: "status" }, { data: "task" }, { data: "costo.value" }, { data: "createdAt", format: "date" }];
    const headers = [`${t("user name")}`, `${t("file name")}`, `${t("size")}`, `${t("file type")}`, `${t("state")}`, `${t("model")}`, `${t("Cost")}`, `${t("date")}`];
    return (
        <>
            <MainView t={t} task={solutions.OCR} columns={columns} headers={headers} />
        </>
    );
}
export default withTranslation()(ReportOCR);
