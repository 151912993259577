import React from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";

export const HeaderChatView = ({ backToUserChat, Chat_Box_Username }: { backToUserChat: any; Chat_Box_Username: any }) => {
    return (
        <Row className="">
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0 d-block  me-3">
                    <Link to="#" onClick={backToUserChat} className="user-chat-remove fs-18 p-1 ">
                        <i className="ri-arrow-left-s-line align-bottom"></i>
                    </Link>
                </div>
                <div className="w-100 overflow-hidden ">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1 overflow-hidden text-center">
                            <h6 className=" mt-3 fs-14">{Chat_Box_Username}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    );
};
