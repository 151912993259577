import { Col, Container, Row } from "reactstrap";
import ChatBot from "../../../pages/test/ChatBot/ChatBot";
import "../../../styles/components/layout/footer.scss";

const Footer = ({ disableChatbot = false }: { disableChatbot?: boolean }) => {
    return (
        <footer className={disableChatbot ? "footerMiPerfil" : "footer"}>
            <Container fluid>
                {!disableChatbot && (
                    <Row>
                        <Col>
                            <ChatBot />
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col sm={6}>{new Date().getFullYear()} &copy; MIAbogado Suite</Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
