import { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import peticionBack from "../../../helpers/peticiones";
import React from "react";
import { ChatListView } from "../../../components/pages/chatbot/views/ChatListView";
import { chatHistory } from "../../../components/pages/chatbot/types/Chat";
import { SendMessageView } from "../../../components/pages/chatbot/views/SendMessageView";
import { ConversationView } from "../../../components/pages/chatbot/views/ConversationView";
import { HeaderChatView } from "../../../components/pages/chatbot/views/HeaderChatView";
import { Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { chatBotData, changeChatList, changeCurrentChat, addMessageHistory, changeHistoryMessages } from "../../../core/slices/chatbot/ChatbotSlice";
import { useSelector } from "react-redux";

// Establecer la autenticación por defecto en un objeto vacío en caso de JSON nulo o no válido
const auth = localStorage.getItem("persist:auth_zeeIAB") || "{}";

const jsonAuth = JSON.parse(auth);
const token = jsonAuth.accessToken || "";
const formattedToken = token ? token.substring(1, token.length - 1) : "";
const backUri = process.env.REACT_APP_BACK_URI?.substring(0, process.env.REACT_APP_BACK_URI.length - 4) || "";

// Inicializando el socket con la URL y los encabezados
const socket = io(backUri + "/chat", {
    path: "/api/socket.io",
    extraHeaders: {
        Authorization: `Bearer ${formattedToken}`,
    },
    auth: {
        // Agregando un token a la inicialización del socket
        token: formattedToken,
    },
});

// Conexión y desconexión
socket.on("connect", () => {});

socket.on("connect_error", (error) => {});

socket.on("disconnect", (reason) => {});

const Chat = ({ t }: { t: (text: string) => string }) => {
    const chatInfo = useSelector(chatBotData);
    const dispatch = useDispatch();
    const userChatShow: any = useRef();
    // const [chatId, setChatId] = useState<string | null>(null);

    const [curMessage, setcurMessage] = useState<string>("");
    // const [messages, setMessages] = useState<chatHistory[]>([]);
    const [isLoading, setIsLoading] = useState(Boolean);

    const getChats = async () => {
        const res = await peticionBack({}, "/chat", "GET", false);
        if (res) {
            dispatch(changeChatList(res));
        }
    };

    useEffect(() => {
        getChats();

        if (chatInfo.currentChat._id) {
            userChatShow.current.classList.add("user-chat-show");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Use For Chat Box
    const userChatOpen = (chats: any) => {
        getChatId(chats._id);
        // setChatId(chats._id);
        userChatShow.current.classList.add("user-chat-show");
    };

    const getChatId = async (id: string) => {
        const res = await peticionBack({}, `/chat/${encodeURIComponent(id)}`, "GET", false);
        if (res) {
            dispatch(changeCurrentChat(res));
            // setMessages(res.chat_history);
        }
    };

    const backToUserChat = () => {
        userChatShow.current.classList.remove("user-chat-show");
    };

    // add messages
    const addMessage = () => {
        if (curMessage !== "") {
            const message: any = {
                chat_id: chatInfo.currentChat._id,
                question: curMessage,
            };

            const valoresActuales = [...(chatInfo.currentChat.chat_history || [])];
            const nuevoMensaje: chatHistory = {
                ia: "...",
                ia_date: null,
                human: curMessage,
                human_date: new Date(),
                _id: "chatId",
            };

            dispatch(addMessageHistory(nuevoMensaje));
            socket.emit("message", message, (response: any) => {
                valoresActuales.push(response);
                dispatch(changeHistoryMessages(valoresActuales));
            });
        }
        setcurMessage("");
    };

    const chatRef = useRef<any>(null);
    useEffect(() => {
        if (chatRef.current?.el) {
            chatRef.current.getScrollElement().scrollTop = chatRef.current.getScrollElement().scrollHeight;
        }
    }, [chatInfo.currentChat.chat_history]);

    const onKeyPress = (e: any) => {
        const { key, value } = e;
        if (key === "Enter") {
            e.preventDefault();
            setcurMessage(value);
            addMessage();
        }
    };

    //serach recent user

    const searchUsers = () => {
        const input = document.getElementById("search-user") as HTMLInputElement;
        const filter = input.value.toUpperCase();
        const userList = document.getElementsByClassName("users-list");

        Array.prototype.forEach.call(userList, (el: HTMLElement) => {
            const li = el.getElementsByTagName("li");

            for (let i = 0; i < li.length; i++) {
                const a = li[i].getElementsByTagName("a")[0];
                const txtValue = a.textContent || a.innerText;

                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                } else {
                    li[i].style.display = "none";
                }
            }
        });
    };

    return (
        <React.Fragment>
            <div className="chat-wrapper d-lg-flex gap-1  p-1" style={{ height: "94%" }}>
                <div className="chat-leftsidebar h-100">
                    <ChatListView searchUsers={searchUsers} isLoading={isLoading} setIsLoading={setIsLoading} chats={chatInfo.chatList} userChatOpen={userChatOpen} />
                </div>

                <div className="user-chat w-100 overflow-hidden " ref={userChatShow}>
                    <div className="chat-content  d-lg-flex">
                        <div className="w-100 overflow-hidden position-relative">
                            <div className="position-relative vh-100">
                                <Container>
                                    <Row className="p-3 user-chat-topbar " style={{ minHeight: "100px" }}>
                                        <HeaderChatView Chat_Box_Username={chatInfo.currentChat.name} backToUserChat={backToUserChat} />
                                    </Row>
                                    <Row className="">
                                        <div className="chat-conversation overflow-y-scroll ">
                                            <ConversationView chatRef={chatRef} messages={chatInfo.currentChat.chat_history} />
                                        </div>
                                    </Row>

                                    <Row className="bg-white h-100 ">
                                        <div className="mr-3 chat-input-section w-100   pt-2 ">
                                            <SendMessageView addMessage={addMessage} curMessage={curMessage} onKeyPress={onKeyPress} setcurMessage={setcurMessage} />
                                        </div>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Chat;
