import React from "react";
import HeaderLanding from "../../components/pages/landing/header/HeaderLanding";
import Home from "../../components/pages/landing/home/Index";
import Solutions from "../../components/pages/landing/Solutions/Index";
import Footer from "../../components/pages/landing/footer/Index";
import OrganizationsLanding from "../../components/pages/landing/organizations/Index";
import LandingIntroduction from "../../components/pages/landing/introduction/Index";
import InvitantionLanding from "../../components/pages/landing/invitation/Index";
import "./landingGeneral.scss";

export default function LandingPage() {
    return (
        <div style={{ background: "#ffffff" }}>
            <HeaderLanding />
            <Home />

            <LandingIntroduction />

            <Solutions />

            <OrganizationsLanding />

            <InvitantionLanding />
            <Footer />
        </div>
    );
}
