import { useTranslation } from "react-i18next";
import Button from "../../../components/buttons/Button";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import peticionBack from "../../../helpers/peticiones";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/redux/store";
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { changeOrganization, changeUserType } from "../../../core/slices/auth/AuthSlicer";
import toastMessage from "../../../helpers/toastMessage";
import { user_type } from "../../../core/enums/enums";

export function DeleteOrg() {
    const { t } = useTranslation();
    const orgInfo = useSelector((state: RootState) => state.auth.userInformation?.organization);
    const userType = useSelector((state: RootState) => state.auth.userInformation);

    const dispatch = useDispatch();

    async function deleteOrg() {
        const res = await peticionBack({}, `/organizaciones`, "DELETE", false);
        if (res) {
            dispatch(changeUserType(user_type.NORMAL_USER));
            toastMessage("success", "Organización eliminada");
           
        } else {
            toastMessage("error", "Algo salió mal");
        }
    }
    
    async function deleteMyselef() {
        const res = await peticionBack({}, `/me/organization/leave`, "PUT", false);
        if (res) {
            toastMessage("success", "Has salido de la organización");
            dispatch(changeOrganization(null));
        } else {
            toastMessage("error", "Algo salió mal");
        }
    }

    function showAlert() {
        alerConfirmation({
            handleAction: deleteOrg,
            title: `${t("organization.Delete your organization")}`,
            content: `${t("Are you sure you want to delete")} ${orgInfo?.name}`,
            buttonOkMsg: `${t("common.accept")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            icon: "warning",
        });
    }

    function leaveOrganiztion() {
        alerConfirmation({
            handleAction: deleteMyselef,
            title: `${t("organization.Leave organization")}`,
            content: `${t("organization.Are you sure you want to leave this organization?")}`,
            buttonOkMsg: `${t("common.accept")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            icon: "warning",
        });
    }

    if (userType?.user_type === "2") {
        return (
            <Row className="justify-content-end">
                <Col lg="4" className="justify-content-end"></Col>
                <Card
                    className="my-4"
                    color="danger"
                    outline
                    style={{
                        width: "50%",
                        borderColor: "red",
                        margin: "35px",
                    }}
                >
                    <CardBody>
                        <CardTitle tag="h4" className="text-danger">
                            {t("organization.Leave organization")}
                        </CardTitle>
                        <CardText>
                            {t("organization.You will leave this organization, only the administrator can invite you again.")} <b>{t("organization.This action can't be undone")}</b>
                        </CardText>
                        <Button onClick={() => leaveOrganiztion()} color="danger" className="justify-content-end">
                            {t("organization.Leave organization")}
                        </Button>
                    </CardBody>
                </Card>
            </Row>
        );
    } else
        return (
            <div>
                <Container>
                    <CardBody>
                        {/* Card DELTE */}
                        <Row className="justify-content-end">
                            <Col lg="4" className="justify-content-end"></Col>
                            <Card
                                className="my-5"
                                color="danger"
                                outline
                                style={{
                                    width: "100%",
                                    borderColor: "red",
                                }}
                            >
                                <CardBody>
                                    <CardTitle tag="h4" className="text-danger">
                                        {t("organization.Delete your organization")}
                                    </CardTitle>
                                    <CardText>
                                        {t("organization.Your organization will be delete and you can't recover it. All the users will be unliked and you will need to create a new organization.")} <b>{t("organization.This action can't be undone")}</b>
                                    </CardText>
                                    <Button onClick={() => showAlert()} color="danger" className="justify-content-end">
                                        {t("organization.Delete my organization")}
                                    </Button>
                                </CardBody>
                            </Card>
                        </Row>
                    </CardBody>
                </Container>
            </div>
        );
}
