import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import { BalanceType } from "../pages/dashboard/types/chartType";

export default function CardBalance({ datag, loading }: { datag: BalanceType; loading?: boolean }) {
    return (
        <>
            <Row>
                <Col xl={12} md={12}>
                    <Card className={"card-animate "}>
                        <CardBody>
                            {loading && (
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <Spinner color="primary" />
                                </div>
                            )}
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <p className={" fw-medium mb-0 text-"}>{datag.label}</p>
                                </div>
                                <div className="flex-shrink-0">
                                    <h5 className={"fs-14 mb-0 text-" + datag.percentageClass}>
                                        <i className={"fs-13 align-middle " + datag.percentageIcon}></i> {datag.percentage}
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className={"fs-22 fw-semibold ff-secondary mb-4 "}>
                                        <span className="counter-value" data-target="559.25">
                                            <h1>{datag.counter}</h1>
                                        </span>
                                    </h4>
                                    <Link to="/wallet" className={"text-decoration-underline "}>
                                        {datag.caption}
                                    </Link>
                                </div>
                                <div className="avatar-sm flex-shrink-0 shadow">
                                    <span className={"avatar-title rounded fs-3 bg-" + datag.iconClass}>
                                        <i className={datag.icon + " text-" + datag.color}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
