import { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import InputCheck from "../../inputs/InputCheck";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loaded, loading } from "../../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../../core/types/TypeLoader";
import ModalCharge from "../../modals/modalCharge";
import { getCostSolution } from "../../../helpers/getCostsSolutions";
import { solutions } from "../../../core/enums/enums";

export const ModalNames = ({ state, testarNames, getUrlEditor, statusNer }: { state: [boolean, any]; testarNames: (n: string[]) => void; getUrlEditor: Function; statusNer: any }) => {
    const [selected, setSelected] = useState<string[]>([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [modalChargeIn, setModalChargeIn] = useState<boolean>(false);
    const [costSolution, setCostSolution] = useState<number | null>();

    function startChange() {
        if (!selected) return;
        testarNames(selected);
        state[1]();
    }

    useEffect(() => {
        setSelected([]);
        if (state[0]) getSolutionCost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state[0]]);

    function selectName(name: string) {
        if (selected.includes(name)) setSelected(selected.filter((s) => s !== name));
        else setSelected([...selected, name]);
    }

    async function getSolutionCost() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const costSolution = await getCostSolution(solutions.EDITOR);
        if (costSolution) {
            setCostSolution(costSolution);
        }
        dispatch(loaded());
    }

    return (
        <Modal size="lg" onClose={() => state[1]()} isOpen={state[0]} title="Testar nombres" centered withCloseButton overlayProps={{ backgroundOpacity: 0 }}>
            <div className="text-center my-3">
                <h3>Testar nombres</h3>
            </div>
            <div className="d-flex justify-content-around mb-3" style={{ overflowY: "auto" }}>
                <div className="d-flex flex-column">
                    {statusNer.result.slice(0, Math.ceil(statusNer.result.length / 2)).map((name: any, ix: any) => (
                        <InputCheck key={"name" + ix} label={name} type="checkbox" name={name} value={selected.includes(name)} onChange={() => selectName(name)} />
                    ))}
                </div>
                <div className="d-flex flex-column">
                    {statusNer.result.slice(Math.ceil(statusNer.result.length / 2), statusNer.result.length).map((name: any, ix: any) => (
                        <InputCheck key={"name" + ix} label={name} type="checkbox" name={name} value={selected.includes(name)} onChange={() => selectName(name)} />
                    ))}
                </div>
            </div>

            <div className="d-flex justify-content-around mb-3">
                {costSolution && (
                    <div className="ml-3">
                        <Button color="info" type="reset" me={{ base: "none", md: "auto" }} onClick={() => setModalChargeIn(true)}>
                            <i className="ri-money-dollar-circle-line" style={{ fontSize: "1em" }} />
                            {`${costSolution} ${t("Reprocess file")}`}
                        </Button>
                    </div>
                )}
                <div className="d-flex justify-content-md-start">
                    <Button className="me-4" color="danger" type="reset" onClick={() => state[1]()}>
                        {t("Cancel")}
                    </Button>
                    <Button color="success" type="submit" onClick={() => startChange()}>
                        {t("Save")}
                    </Button>
                </div>
            </div>
            <ModalCharge t={t} functionToExecut={getUrlEditor} message={t("reprocess file")} modalChargeIn={modalChargeIn} setModalChargeIn={setModalChargeIn} iconRemix="ri-file-edit-line" />
        </Modal>
    );
};
