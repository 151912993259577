import { Modal } from "reactstrap";
import ViewOcr from "../pages/ocrText/views/VistaOcr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SimpleBar from "simplebar-react";

export default function ModalOCR({ open, row, setIsVideo, setViewFile, setIsProcessed }: { open: boolean; row: any; setIsVideo?: any; setViewFile?: any; setIsProcessed?: any }) {
    const handleOnClose = () => {
        setIsProcessed(false);
        setIsVideo(false);
        setViewFile(false);
    };
    return (
        <>
            <Modal isOpen={open} onClosed={handleOnClose} size="xl" fullscreen={row?.mimetype !=="application/pdf"?false:true} centered scrollable={false} keyboard>
                <div className="mt-3 d-flex justify-content-end mx-3">
                    <FontAwesomeIcon
                        className="text-danger"
                        style={{ cursor: "pointer" }}
                        size="2x"
                        icon={faXmark}
                        onClick={() => {
                            handleOnClose();
                        }}
                    />
                </div>
                <SimpleBar autoHide={false} style={{ maxHeight: "100vh" }} className="px-3 t-justify">
                    <ViewOcr row={row} />
                </SimpleBar> 
            </Modal>
        </>
    );
}
