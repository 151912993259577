import {Dropdown, DropdownItem, DropdownMenu } from "reactstrap";
import { alerConfirmation } from "../../../../helpers/alertConfirmation";
import peticionBack from "../../../../helpers/peticiones";
import { useEffect, useRef, useState } from "react";
import '../../../../styles/components/file-manager/recursive-folder.scss'

const MiniMenu = ({t, idCarpeta, setRefreshContent, setIsFolderRoot, setCurrentFolder, setMenuVisible, menuVisible,setModalRename}: {t: (text: string) => string; idCarpeta: any; setRefreshContent: any; setIsFolderRoot: any; setCurrentFolder:any; setMenuVisible:any; menuVisible:Boolean; setModalRename: any}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const menuRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
    const handleClickOutside = (event:MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, []);

    async function Delete() {
        const res = await peticionBack({}, `/folders/${idCarpeta._id}`, "DELETE", true);
        if (res) {
            if(res.folder_id){
                setRefreshContent(true);
                setCurrentFolder(res.folder_id)
            }
            setIsFolderRoot(res.folder_id? false : true);
            setDropdownOpen(false);
        }
    }

    function showAler() {
        alerConfirmation({
            handleAction: Delete,
            title: `${t("Delete-folder")}`,
            content: `${t("Are-you-sure-folder")} ${idCarpeta.name}`,
            buttonOkMsg: `${t("yes-delete-it")}`,
            buttonCancelMsg: `${t("cancel")}`
        });
    }

const handleRenombrar= ()=>{
        setModalRename(true);
        setMenuVisible(false)
      }
    return (
        <div className="context-menu">  
            <Dropdown className={"background"}isOpen={idCarpeta ? true : false && menuVisible} toggle={() => setDropdownOpen(!dropdownOpen)}direction="up">
                <DropdownMenu>
                    <DropdownItem onClick={()=>{handleRenombrar()}}>{t("Rename")}</DropdownItem>
                    <DropdownItem
                        onClick={() => {
                            setDropdownOpen(false)
                            showAler();
                            setMenuVisible(false)
                        }}
                    >
                        {t("Delete")}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            
        </div>
    );
};

export default MiniMenu;
