/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from "react";

import { Modal, ModalBody } from "reactstrap";
import PrivacyView from "../pages/privacidad/Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "../buttons/Button";

export default function ModalPrivacy({ open, setOpen, accepted }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>>; accepted: Function }) {
    return (
        <>
            <Modal isOpen={open} size="lg" toggle={() => setOpen(false)} centered>
                <ModalBody>
                    <div className="d-flex justify-content-end">
                        <FontAwesomeIcon
                            className="text-danger"
                            style={{ cursor: "pointer" }}
                            size="2x"
                            icon={faXmark}
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                    </div>

                    <div className="mb-5" style={{ overflowY: "scroll", height: "400px" }}>
                        <PrivacyView />
                    </div>

                    <div className="text-center">
                        <Button
                            color="success"
                            onClick={() => {
                                accepted();
                            }}
                        >
                            Aceptar
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}
