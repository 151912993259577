import { useState } from "react";
import ModalDatatable from "../../../modals/ModalDatatable";
import { Usuario } from "../../../../pages/admin_user/Usuarios/dto/Usuario.dt";
import Button from "../../../buttons/Button";
import { useTranslation } from "react-i18next";
import { TablePropsrops } from "../../../modals/ModalProps";

function ModalUsuarios({ setSelected }: { setSelected: Function }) {
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation();
    const stateModal = () => {
        setModalOpen(!modalOpen);
    };

    const tableProps: TablePropsrops = {
        endpoint: "/Usuarios",
        columns: [{ data: "name" }, { data: "lastname" }],
        headers: [t("Name"), t("Last name")],
        petitionProps: { filters: [{ name: "rows", value: "id,name,lastname" }] },
        columnDefs: [{ className: "text-center", targets: [0, 1] }],
        control: "back" as "back",
        onSelect: selectModalRow,
        autoClose: true,
        onClick: true,
        order: { col: 0, opt: "asc" as "asc" },
    };

    async function selectModalRow(row: Usuario) {
        setSelected(row);
    }

    return (
        <div className="justify-content-around">
           
                    <Button onClick={stateModal} color="success" className="justify-content-end text-center mb-3">
                       { t("Seleccionar usuario")}
                    </Button>
            
            <ModalDatatable state={[modalOpen, setModalOpen]} title="Usuarios" tableProps={tableProps} />
        </div>
    );
}

export { ModalUsuarios };
