export const organizationsEN = {
    organization: {
        "Create your own organization!": "Create your own organization!",
        "Add Over": "Add Over",
        Users: "Users",
        "Why to create an": "Why to create an",
        Organization: "Organization",
        An: "An",
        "streamlines access management, ensuring that only authorized users can interact with specific modules, thereby enhancing security and data integrity within the application. Additionally, it simplifies user administration by centralizing access control and wallet control under the organization admin's view.":
            "streamlines access management, ensuring that only authorized users can interact with specific modules, thereby enhancing security and data integrity within the application. Additionally, it simplifies user administration by centralizing access control and wallet control under the organization admin's view.",
        "Centralized administration": "Centralized administration",
        "Add users to your organization": "Add users to your organization",
        "Shared wallet": "Shared wallet",
        "My organization": "My organization",
        "This is your new organization, here you can administrate the basic information and features.": "This is your new organization, here you can administrate the basic information and features.",
        "Edit my organization": "Edit my organization",
        "Delete your organization": "Delete your organization",
        "Your organization will be delete and you can't recover it. All the users will be unliked and you will need to create a new organization.": "Your organization will be delete and you can't recover it. All the users will be unliked and you will need to create a new organization.",
        "This action can't be undone": "This action can't be undone",
        "Delete my organization": "Delete my organization",
        "Organization users": "Organization users",
        "To start sharing all the solutions with your users, you should first add them to your organization. Just enter an email of an existan user and it will be automatically form part of your organization.":
            "To start sharing all the solutions with your users, you should first add them to your organization. Just enter an email of an existan user and it will be automatically form part of your organization.",
        "Don't make them wait!": "Don't make them wait!",
        "Add user": "Add user",
        "The organization has been created": "The organization has been created",
        "The organization already exist, please choose another name": "The organization already exist, please choose another name",
        "Exclude user": "Exclude user",
        "Are you sure you want to take out": "Are you sure you want to take out",
        "This user will no longer be linked to your organization": "This user will no longer be linked to your organization",
        "Are you sure you want add this user? This user will have access to all the modules": "Are you sure you want add this user? This user will have access to all the modules",
        "This is your organization, here you can visualize general information about it and leve the organization.": "This is your organization, here you can visualize general information about it and leve the organization.",
        "Leave organization": "Leave organization",
        "Are you sure you want to leave this organization?": "Are you sure you want to leave this organization?",
        "You will leave this organization, only the administrator can invite you again.": "You will leave this organization, only the administrator can invite you again.",
        STOP:"ALTO",
        "You can't delete yourself":"No puedes eliminarte a ti mismo",
        Accept:"Aceptar"
    },

    User_Types: {
        "User type": "User type",
        "Normal user": "Normal user",
        "Organization administrator": "Organization administrator",
        Administrator: "Administrator",
        Root: "Root",
    },
};
