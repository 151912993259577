import { Card, Col, Container, Row } from "reactstrap";
import ChartPDF from "../../components/pages/dashboard/views/ChartPDF";
import ChartFolders from "../../components/pages/dashboard/views/ChartFolder";
import ChartVideo from "../../components/pages/dashboard/views/ChartVideos";
import { withTranslation } from "react-i18next";
import ChartOCRIteraciones from "../../components/pages/dashboard/views/ChartOCRIteraciones";
import ChartTranscoIteraciones from "../../components/pages/dashboard/views/ChartTranscoIteraciones";
import ChartChatIteraciones from "../../components/pages/dashboard/views/ChartChatIteraciones";
import Balance from "../../components/pages/dashboard/views/Balance";
import ChartExpense from "../../components/pages/dashboard/views/ChartExpense";
import ChartImages from "../../components/pages/dashboard/views/ChartImages";
import ChartEXpenseInteractions from "../../components/pages/dashboard/views/ChartEXpenseInteractions";
import ChartStage from "../../components/pages/dashboard/views/ChartStage";
import ChartState from "../../components/pages/dashboard/views/ChartStatus";
import ChartSchedule from "../../components/pages/dashboard/views/ChartSchedule";
import Clients from "../../components/pages/dashboard/views/Clients";
import FoldersCount from "../../components/pages/dashboard/views/FoldersCount";
import BtnTutorial from "../../components/buttons/ButtonTutorial";
import { tutorials } from "../../core/constants/tutorials";

function Dashboard({ t }: { t: (f: string) => string }) {
    return (
        <div>
            <div className="d-flex flex-row justify-content-between">
                <h2 className="m-3">{t("Dashboard")}</h2>
                <BtnTutorial url={tutorials.DASHBOARD} />
            </div>
            <Container fluid>
                <Row className="lign-content-center ">
                    <Col color="danger" lg={5}>
                    <div className="row">
                            <div className="col-12">
                                
                                <Balance />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Clients />
                            </div>
                            <div className="col-6">
                            <FoldersCount />
                            </div>
                        </div>

                    </Col>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-success"}>
                            <ChartExpense />
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className={"card-animate border card-border-success"}>
                            <ChartEXpenseInteractions />
                        </Card>
                    </Col>
                </Row>
                <h3 className="mt-3 mb-3 text-dark">{t("Clients")}</h3>
                <Row>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-info"}>
                            <ChartSchedule />
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-info"}>
                            <ChartStage />
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-info"}>
                            <ChartState />
                        </Card>
                    </Col>
                </Row>
                <h3 className="mt-3 mb-3 text-dark">{t("Files")}</h3>
                <Row>
                    <Col lg={6}>
                        <Card className={"card-animate border card-border-primary"}>
                            <ChartFolders />
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className={"card-animate border card-border-primary"}>
                            <ChartVideo />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Card className={"card-animate border card-border-primary"}>
                            <ChartPDF />
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className={"card-animate border card-border-primary"}>
                            <ChartImages />
                        </Card>
                    </Col>
                </Row>
                <h3 className="mt-3 mb-3 text-dark">{t("interactions")}</h3>
                <Row>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-info"}>
                            <ChartTranscoIteraciones />
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-info"}>
                            <ChartOCRIteraciones />
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className={"card-animate border card-border-info"}>
                            <ChartChatIteraciones />
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default withTranslation()(Dashboard);
