export const formsEN = {
    Send: "Send",
    Actions: "Actions",
    "Delete user": "Delete user",
    "Are you sure you want to delete": "Are you sure you want to delete",
    Edit: "Edit",
    "- Edit": "- Edit",
    "- Add": "- Add",
    Delete: "Delete",
    Cancel: "Cancel",
    Save: "Save",
    ClientName: "Client Name",
    nationality: "Nationality",
    cancel: "Cancel",
    Search: "Search",
    Add: "Add",
    edit: "edit",
    save: "save",
    Name: "Name",
    Clean: "Clean",
    Date: "Date of Birth",
    Phone: "Phone",
    CURP: "CURP",
    "Last name": "Last Name",
    "Date born": "Date of Birth",
    Gender: "Gender",
    Career: "Career",
    MALE: "MALE",
    FEMALE: "FEMALE",
    LGBT: "LGBT",
    UNDEFINED: "UNDEFINED",
    Next: "Next",
    Back: "Back",
    "Passwords must match": "Passwords must match",
    NoFullData: "Incomplete record",
    "Country not found or not assigned": "Country not found or not assigned",
    "Do you want to": "Do you want to",
    "this user?": "this user?",
    "Enter an email": "Enter an email",
    "Invalid email": "Invalid email",
    "Enter a password": "Enter a password",
    "Phone is not valid": "Phone is not valid",
    "The password must have at least one uppercase letter, one special character, one number and be at least 6 characters": "The password must have at least one uppercase letter, one special character, one number and be at least 6 characters",
    "Sign Up with": "Sign Up with",
    "Waiting for email confirmation": "Waiting for email confirmation",
    "In moments you will receive an email with a link to verify your email": "In moments you will receive an email with a link to verify your email",
    "Have you not received the email?": "Have you not received the email?",
    "Send again": "Send again",
    "Don't have an account?": "Don't have an account?",
    "Repeat password": "Repeat password",
    "must contain 10 digits": "must contain 10 digits",
    "Enter your name": "Enter your name",
    "Enter your last name": "Enter your last name",
    "Choose your date born": "Choose your date of birth",
    "Enter your phone number": "Enter your phone number",
    "Invalid phone": "Invalid phone",
    "Choose your gender": "Choose your gender",
    "Enter your CP": "Enter your CP",
    "Max 5 digits": "Max 5 digits",
    "CURP must be 18 characters": "CURP must be 18 characters",
    "RFC must be 13 characters": "RFC must be 13 characters",
    ADMINISTRATOR: "ADMINISTRATOR",
    "ORGANIZATION ADMINISTRATOR": "ORGANIZATION ADMINISTRATOR",
    "NORMAL USER": "NORMAL USER",
    "Choose a role": "Choose a role",
    "Min 8 characters": "Min 8 characters",
    "en-US": "en-US",
    "credits have been credited to your account": "credits have been credited to your account",
    "Payment method": "Payment method",
    "Add credits": "Add credits",
    "Select a month": "Select a month",
    "Select a year": "Select a year",
    "The password must have at least one uppercase letter, one lowercase letter, one special character, one number and be at least 6 characters": "The password must have at least one uppercase letter, one lowercase letter, one special character, one number and be at least 6 characters",
    sdate: "The start date must be before the end date",
    edate: "The end date must be after the start date",
    "Start date cannot be greater than today": "Start date cannot be greater than today",
    "End date cannot be greater than today": "End date cannot be greater than today",
    sdatelabel: "Start date",
    edatelabel: "End date",
    filter: "Filter",
    dateAssigned: "Date Assigned",
    file_number: "File Number",
    stage: "Stage",
    state: "State",
    schedule: "Schedule",
    visit: "Visit Name",
    "user lastname": "User Last Name",
    protection: "Protection",
    Promotion: "Promotion",
    Waiting: "Waiting",
    title: "Title",
    description: "Description",
    startDate: "start date",
    endDate: "end date",
    startTime: "start time",
    endTime: "end time",
    "form-validation": {
        common: {
            text: {
                requeired: "Enter a {{field}}",
                "min-length": "The {{field}} must contain at least {{minLength}} characters",
                "max-length": "The {{field}} must contain less than {{maxLength}} characters",
                invalid: "{{field}} invalid",
            },
            quantity: {
                requeired: "Enter a quantity",
                "min-value": "The {{field}} must be equal to or greater than {{minValue}}",
                "max-value": "The {{field}} must be equal to or less than {{maxValue}}",
                integer: "The {{field}} must be an integer",
                float: "The {{field}} must be a decimal number",
                positive: "The {{field}} must be a positive number",
                "min-length": "The {{field}} must contain at least {{minLength}} digits",
                "max-length": "The {{field}} must contain less than {{maxLength}} digits",
                invalid: "Invalid quantity",
            },
            phone: {
                requeired: "Enter a phone number",
                invalid: "Invalid phone number",
            },
            date: {
                requeired: "Enter a {{field}} date",
                "min-value": "The {{field}} date must be equal to or after {{minValue}}",
                "max-value": "The {{field}} date must be equal to or before {{maxValue}}",
                invalid: "Invalid date",
            },
        },
    },
};
