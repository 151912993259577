import { useEffect, useRef, useState } from "react";
import MiniMenu from "./DeleteFolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "../../../../styles/components/file-manager/recursive-folder.scss";
import RenameFolders from "./RenemeFolder";

const RecursiveFolders = ({ t, folder, setCurrentFolder, currentFolder, setRefreshContent, setIsFolderRoot }: { t: (text: string) => string; folder: any; setCurrentFolder: (folderId: string | undefined) => void; currentFolder: string; setRefreshContent: any; setIsFolderRoot: any }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [modalRename, setModalRename] = useState(false);

    useEffect(() => {
        if (folder._id === currentFolder) {
            setIsExpanded(true);
        }
        if (!currentFolder) setIsExpanded(false);

        const handleClickOutside = (event: MouseEvent) => {
            const menuContainer = document.getElementById("menu-container45");
            const renameModal = document.getElementById("RenameFolderModal");

            if (menuRef.current && !menuRef.current.contains(event.target as Node) && menuContainer && menuContainer.contains(event.target as Node) && !renameModal?.contains(event.target as Node)) {
                setMenuVisible(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [folder._id, currentFolder]);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        setMenuVisible(false);
    };

    const handleFolderClick = () => {
        setCurrentFolder(folder._id);
    };

    const handleContextMenu = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setMenuVisible(true);
    };

    const handleMenuItemClick = (idCarpeta: any) => {};

    return (
        <section className="">
            <div className="mb-2" onContextMenu={handleContextMenu}>
                <a href="#!" onClick={handleToggle} style={{ textDecoration: "none" }}>
                    <i className={`ri-folder-${isExpanded ? "open" : "3"}-fill ${isExpanded ? "text-secondary" : ""}`}></i>
                    <span className={`${isExpanded ? "text-secondary" : ""}`} onClick={handleFolderClick}>
                        {" " + folder.name + "  "}
                        {isExpanded ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
                    </span>
                </a>

                {menuVisible && (
                    <div ref={menuRef} className="context-menu" style={{ height: "80px" }}>
                        <div className="" onClick={() => handleMenuItemClick(folder._id)}>
                            <MiniMenu t={t} idCarpeta={folder} setRefreshContent={setRefreshContent} setIsFolderRoot={setIsFolderRoot} setCurrentFolder={setCurrentFolder} setMenuVisible={setMenuVisible} menuVisible={menuVisible} setModalRename={setModalRename} />
                        </div>
                    </div>
                )}

                {isExpanded &&
                    folder.subFolders &&
                    folder.subFolders.map((folders: any, index: number) => (
                        <div style={{ marginLeft: 20 }} key={`folder-${index}`}>
                            <RecursiveFolders folder={folders} setCurrentFolder={setCurrentFolder} currentFolder={currentFolder} setRefreshContent={setRefreshContent} setIsFolderRoot={setIsFolderRoot} t={t} />
                        </div>
                    ))}
            </div>

            {modalRename && <RenameFolders t={t} folder={folder} openRenameFolder={modalRename} setOpenRenameFolder={setModalRename} setRefreshContent={setRefreshContent} setIsFolderRoot={setIsFolderRoot} setCurrentFolder={setCurrentFolder} />}
        </section>
    );
};

export default RecursiveFolders;
