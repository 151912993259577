export enum Gender {
    MASCULINO = "MASCULINO",
    FEMENINO = "FEMENINO",
    LGBT = "LGBT",
    // UNDEFINED = "UNDEFINED",
}

export enum tiposReporte {
    EXCEL = "EXCEL",
    HTML = "HTML",
}
export enum thirdValue {
    Amparo = "Amparo",
    Promocion = "Promoción",
    Enespera = "En espera",
}
export enum thirdValueStatus {
    PorIniciar = "Por iniciar",
    EnProceso = "En proceso",
    Finalizado = "Finalizado",
    Activo = "Activo",
    Inactivo = "Inactivo",
    PENDING = "PENDING",
    STARTED = "STARTED",
    RETRY = "RETRY",
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
}
export enum Reportes {
    TRANSCRIPCION = "TRANSCRIPCION",
    OCR = "OCR",
    FOLDER = "FOLDER",
    PDF = "PDF",
    VIDEO = "VIDEO",
    IMAGE = "IMAGE",
    STATUS = "STATUS",
    STAGE = "STAGE",
    SCHEDULE = "SCHEDULE",
    CLIENTES = "CLIENTES",
    INGRESOS = "INGRESOS",
    CHATBOT = "CHATBOT",
}
export enum users {
    ADMINISTRATOR = "0",
    ORGANIZATION_ADMINISTRATOR = "1",
    NORMAL_USER = "2",
}

export enum UserTypes {
    ROOT = "-1",
    ADMINISTRATOR = "0",
    ORGANIZATION_ADMINISTRATOR = "1",
    NORMAL_USER = "2",
}
export enum status {
    POR_INICIAR = "Por iniciar",
    EN_PROCESO = "En proceso",
    FINALIZADO = "Finalizado",
}

export enum user_type {
    ROOT = "-1",
    ADMINISTRATOR = "0",
    ORGANIZATION_ADMINISTRATOR = "1",
    NORMAL_USER = "2",
}

export enum solutions {
    TRANSCRIPCION = "TRANSCRIPCION",
    OCR = "OCR",
    CHATBOT = "CHATBOT",
    FILEMANAGER = "FILEMANAGER",
    TRANSCODIFICADOR = "TRANSCODIFICADOR",
    ALMACENAMIENTO = "ALMACENAMIENTO",
    EDITOR = "EDITOR",
    TEXTO_VOZ = "TEXTO-VOZ",
}

export enum StausQueue {
    PENDING = "PENDING",
    STARTED = "STARTED",
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE",
    RETRY = "RETRY",
}

export enum QueueWork {
    TRANSCRIPCION = "TRANSCRIPCION",
    OCR = "OCR",
    TTS = "TTS",
}
