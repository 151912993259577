import React, { useState, useEffect } from "react";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeaderLanding = () => {
    const { t } = useTranslation();
    const [isOpenMenu, setisOpenMenu] = useState<boolean>(false);
    const [navClass, setnavClass] = useState<any>("");

    const toggle = () => setisOpenMenu(!isOpenMenu);

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    const [activeLink, setActiveLink] = useState<any>();

    useEffect(() => {
        const activation = (event: any) => {
            const target = event.target;
            if (target) {
                target.classList.add("active");
                setActiveLink(target);
                if (activeLink && activeLink !== target) {
                    activeLink.classList.remove("active");
                }
            }
        };
        const defaultLink = document.querySelector(".navbar li a.active");
        if (defaultLink) {
            defaultLink?.classList.add("active");
            setActiveLink(defaultLink);
        }
        const links = document.querySelectorAll(".navbar a");
        links.forEach((link) => {
            link.addEventListener("click", activation);
        });
        return () => {
            links.forEach((link) => {
                link.removeEventListener("click", activation);
            });
        };
    }, [activeLink]);

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("is-sticky");
        } else {
            setnavClass("");
        }
    }

    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar">
                <Container>
                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse isOpen={isOpenMenu} className="navbar-collapse" id="navbarSupportedContent">
                        {/* <Scrollspy offset={-18} items={["hero", "services", "features", "plans", "reviews", "team", "contact"]} currentClassName="active" className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example"> */}
                        <div className="navbar-nav mx-auto mt-2 mt-lg-0" id="navbar-example">
                            <li className="nav-item">
                                <NavLink className="fs-14 active" href="#start">
                                    {t("Start")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-14" href="#public">
                                    {t("Public")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-14" href="#solutions">
                                    {t("Solutions")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-14" href="#organizations">
                                    {t("Organizations")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="fs-14" href="/contacto">
                                    {t("Contact")}
                                </NavLink>
                            </li>
                        </div>

                        <div className="">
                            <Link to="/login" className="btn btn-info" style={{ background: "#4452EE" }}>
                                {t("Login")}
                            </Link>
                        </div>
                    </Collapse>
                </Container>
            </nav>
        </React.Fragment>
    );
};

export default HeaderLanding;
