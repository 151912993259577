import AdminAlmacenamiento from "../../../modulos-reportes-admin/components/adminRepots/AdminAlmacenamiento";
import AdminChatbot from "../../../modulos-reportes-admin/components/adminRepots/AdminChtabot";
import AdminOCR from "../../../modulos-reportes-admin/components/adminRepots/AdminOcr";
import AdminTransco from "../../../modulos-reportes-admin/components/adminRepots/AdminTransco";




export default async function getReportAdmin(t: (text: string) => string, report:string, data:any){
    switch(report){    

        case "TRANSCRIPCION": return AdminTransco(t, data);
        case "OCR": return AdminOCR(t, data);
        case "CHATBOT": return AdminChatbot(t, data);
        case "FILEMANAGER": return AdminAlmacenamiento(t, data);
        default: throw new Error(t("The selected report does not exist"), {cause: 404});
    }
}