export const organizationsES = {
    organization: {
        "Create your own organization!": "Crea tu propia organización!",
        "Add Over": "Agrega más de",
        Users: "Usuarios",
        "Why to create an": "Porqué crear una",
        Organization: "Organización",
        An: "Una",
        "streamlines access management, ensuring that only authorized users can interact with specific modules, thereby enhancing security and data integrity within the application. Additionally, it simplifies user administration by centralizing access control and wallet control under the organization admin's view.":
            "agiliza la gestión de acceso, garantizando que sólo los usuarios autorizados puedan interactuar con módulos específicos, mejorando así la seguridad y la integridad de los datos dentro de la aplicación. Además, simplifica la administración de usuarios al centralizar el control de acceso y el control de la cartera bajo la vista del administrador de la organización.",
        "Centralized administration": "Administración centralizada",
        "Add users to your organization": "Agrega usuarios a tu organización",
        "Shared wallet": "Cartera compartida",
        "My organization": "Mi organización",
        "This is your new organization, here you can administrate the basic information and features.": "En esta sección pudes administar administrar a los usuarios, cambiar el noombre y la descripción de tu organización, e incluso eliminarla.",
        "Edit my organization": "Editar mi organización",
        "Delete your organization": "Eliminar tu organización",
        "Your organization will be delete and you can't recover it. All the users will be unliked and you will need to create a new organization.": "Tu organización será eliminada no la podrás recuperar. Todos los usuarios serán excluidos y tendrás que crear una nueva organización",
        "This action can't be undone": "Esta acción no puede ser deshecha",
        "Delete my organization": "Eliminar mi organización",
        "Organization users": "Usuarios de tu organización",
        "To start sharing all the solutions with your users, you should first add them to your organization. Just enter an email of an existan user and it will be automatically form part of your organization.":
            "Para empezar a compartir todas las soluciones con tus usuarios, primero debes añadirlos a tu organización. Solo ingresa el email de un usuario y automáticamente formará parte de tu organización",
        "Don't make them wait!": "No los hagas esperar!",
        "Add user": "Agregar usario",
        "The organization has been created": "La organización ha sido creada",
        "The organization already exist, please choose another name": "La organización ya existe, por favor escoja otro nombre",
        "Exclude user": "Excluir usuario",
        "Are you sure you want to take out": "Desea excluir a",
        "This user will no longer be linked to your organization": "Este usuario ya no estará ligado a tu organización y perderá el acceso a todos los módulos",
        "Are you sure you want add this user? This user will have access to all the modules": "Desea agregar a este usuario? Tendrá acceso a todos los modulos",
        "This is your organization, here you can visualize general information about it and leve the organization.": "Esta es tu organización, aqui puedes visualzar información general y abandonar la organización.",
        "Leave organization": "Abandonar organización",
        "Are you sure you want to leave this organization?": "¿Estás seguro de que deseas abandonar esta organización?",
        "You will leave this organization, only the administrator can invite you again.": "Dejarás de pertencer a esta administración, no tendrás acceso a los módulos y tu cartera regresará a ser privada. Solo el administrador te podrá añadir de nuevo.",
    },

    User_Types: {
        "User type": "Tipo de usuario",
        "Normal user": "Usuario conmún",
        "Organization administrator": "Administrador de organización",
        Administrator: "Administrador",
        Root: "Super administrador",
    },
};
