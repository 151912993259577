import { useEffect, useState } from "react";
import Datatable from "../../../../datatable/Datatable";
import { FechaEstandar } from "../../../../../helpers/formats";
import { Card, Row } from "reactstrap";
import { StackedColumn } from "../../../dashboard/components/BasicColumnChart";

export default function IngresForAdminsHTML({ t, data, openT }: { t: (text: string) => string; data: any; openT: boolean }) {
    const [refresh, setRefresh] = useState<boolean>(false);
    const groupedData: any = {};
    data.forEach((item: any) => {
        const solutionName = item.solution.name;
        const date = item.date;
        if (!groupedData[solutionName]) {
            groupedData[solutionName] = {};
        }
        if (!groupedData[solutionName][date]) {
            groupedData[solutionName][date] = 0;
        }
        groupedData[solutionName][date] += parseFloat(item.ingress.split(" ")[0]);
    });

    const chartData: any = [];

    Object.keys(groupedData).forEach((solution) => {
        Object.keys(groupedData[solution]).forEach((date) => {
            chartData.push({
                label: date,
                solution: solution,
                total: groupedData[solution][date],
            });
        });
    });

    useEffect(() => {
        setRefresh(true);
    }, [data]);

    /* TABLA */
    const columns = [{ data: "date", format: FechaEstandar }, { data: "ingress" }, { data: "solution.name" }, { data: "tool_use" }];

    const columnDefs = [{ className: "text-center", targets: [0, 1, 2, 3, 4, 5, 6] }];
    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
                <h4 className="">{t("ingress Report")}</h4>
            </div>
            <Row>
                <Datatable columns={columns} headers={[`${t("date")}`, `${t("Ingress")}`, `${t("Solution")}`, `${t("tool_use")}`]} columnDefs={columnDefs} data={data} control="front" stateRefresh={[refresh, setRefresh]} className="p-0" />

                <Card className={"card-animate border card-border-primary"}>
                    <h3>{t("Income graph")}</h3>
                    <StackedColumn data={chartData} t={t} />
                </Card>
            </Row>
        </>
    );
}
