import { Spinner, TabContent, TabPane } from "reactstrap";
import SimpleBar from "simplebar-react";
import { chat } from "../types/Chat";
import { ContentList } from "./ContentList";

export const TableChats = ({ isLoading, setIsLoading, chats, userChatOpen }: { isLoading: boolean; setIsLoading: any; chats: Array<any>; userChatOpen: any }) => {
    return (
        <div>
            <TabContent activeTab={"1"} className="text-muted">
                <TabPane tabId="1" id="chats">
                    {isLoading ? (
                        <Spinner setLoading={setIsLoading} />
                    ) : (
                        <SimpleBar className="chat-room-list pt-3 m-3 scr" style={{ margin: "-16px 0px 0px" }}>
                            <div className="chat-message-list">
                                <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                                    {chats.map((chat: chat) => (
                                        <li key={chat._id}>
                                            <ContentList chat={chat} userChatOpen={userChatOpen} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </SimpleBar>
                    )}
                </TabPane>
            </TabContent>
        </div>
    );
};
