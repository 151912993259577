import { Button, Card, CardBody, Col, Container, Row, UncontrolledCollapse } from "reactstrap";
import CardContainer from "../../../components/cardContainer/CardContainer";
import { useTranslation } from "react-i18next";
import FormOrganizacion from "../Components/FormOrganizacion";
import { useState } from "react";
import { useSelector } from "react-redux";
import { userAuth, userInformation } from "../../../core/slices/auth/interface/userinterface";
import About from "../../../assets/images/xD.jpeg";

export default function NonUserView() {
    const { t } = useTranslation();
    const userInfo = useSelector(({ auth }: { auth: userAuth }) => auth.userInformation) as userInformation;


     // {BOTONES COLOR Y TEXT}
     const [buttonText, setButtonText] = useState(t("organization.Create your own organization!"));
     const [buttonColor, setButtonColor] = useState("primary");
 
     const toggleButtonText = () => {
         if (buttonText === t("organization.Create your own organization!")) {
             setButtonText(t("Cancel"));
             setButtonColor("danger"); // Cambia el color a rojo
         } else {
             setButtonText(t("organization.Create your own organization!"));
             setButtonColor("primary"); // Cambia el color de vuelta a azul
         }
     };

     
    return (
        <CardContainer>
            <div>
                <section className="section">
                    <Container>
                        <Row className="align-items-center justify-content-lg-between justify-content-center gy-4">
                            <Col lg={5} sm={7}>
                                <div className="about-img-section mb-5 mb-lg-0 text-center">
                                    <Card className="rounded shadow-lg inquiry-box d-none d-lg-block">
                                        <CardBody className="d-flex align-items-center">
                                            <div className="avatar-sm flex-shrink-0 me-3">
                                                <div className="avatar-title bg-secondary-subtle text-secondary rounded-circle fs-18">
                                                    <i className="ri-user-2-fill"></i>
                                                </div>
                                            </div>

                                            <h5 className="fs-15 lh-base mb-0">
                                                {t("organization.Add Over")} <span className="text-secondary fw-semibold">100</span> {t("organization.Users")}
                                            </h5>
                                        </CardBody>
                                    </Card>

                                    <img src={About} alt="" className="img-fluid mx-auto rounded-3" />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="text-muted">
                                    <h1 className="mb-3 lh-base fw-semibold">
                                        {t("organization.Why to create an")} <span className="text-primary">{t("organization.Organization")}</span>?
                                    </h1>
                                    <p className="ff-secondary fs-16 mb-2">
                                        {t("organization.An")} <b>{t("organization.Organization")} </b>{" "}
                                        {t(
                                            "organization.streamlines access management, ensuring that only authorized users can interact with specific modules, thereby enhancing security and data integrity within the application. Additionally, it simplifies user administration by centralizing access control and wallet control under the organization admin's view."
                                        )}
                                    </p>

                                    <div className="vstack gap-2 mb-4 pb-1">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-2">
                                                <div className="avatar-xs icon-effect">
                                                    <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                        <i className="ri-check-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="mb-0">{t("organization.Centralized administration")}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-2">
                                                <div className="avatar-xs icon-effect">
                                                    <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                        <i className="ri-check-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="mb-0">{t("organization.Add users to your organization")}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0 me-2">
                                                <div className="avatar-xs icon-effect">
                                                    <div className="avatar-title bg-transparent text-success rounded-circle h2">
                                                        <i className="ri-check-fill"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <p className="mb-0">{t("organization.Shared wallet")}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <UncontrolledCollapse toggler="#toggler">
                                        <Card>
                                            <CardBody>
                                                <FormOrganizacion selected={userInfo.organization} />
                                            </CardBody>
                                        </Card>
                                    </UncontrolledCollapse>

                                    <div>
                                        <Col lg="12" className="d-flex justify-content-end" toggler="#toggler">
                                            <Button className="my-1 my-md-0" id="toggler" onClick={toggleButtonText} color={buttonColor}>
                                                {buttonText}
                                            </Button>
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </CardContainer>
    );
}
