import { useEffect, useState } from "react";
import Datatable from "../../../../datatable/Datatable";
import { convertirFormatoFecha } from "../../../../../helpers/formats";

export default function CustomerByStatusHTML({ t, data, openT }: { t: (text: string) => string; data: any; openT: boolean }) {
    const [refresh, setRefresh] = useState<boolean>(false);

    useEffect(() => {
        setRefresh(true);
    }, [data]);

    /* TABLA */
    const columns = [{ data: "cliente.name" }, { data: "cliente.lastname" }, { data: "cliente.phone" }, { data: "cliente.email" }, { data: "createdAt", format: convertirFormatoFecha }, { data: "file_number" }, { data: "status" }];

    const columnDefs = [{ className: "text-center", targets: [0, 1, 2, 3, 4, 5, 6] }];

    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
                <h4 className="">{t("status report")}</h4>
            </div>
            <Datatable columns={columns} headers={[`${t("user name")}`, `${t("user lastname")}`, `${t("Phone")}`, `${t("Email")}`, `${t("date")}`, `${t("file_number")}`, `${t("state")}`]} columnDefs={columnDefs} data={data} control="front" stateRefresh={[refresh, setRefresh]} className="p-0" />
        </>
    );
}
