import ReactApexChart from "react-apexcharts";
import { getChartColorsArrayf } from "./ChartsDynamicColor";

export const SimpleRadar = ({ dataColors, data } :{ dataColors:any; data:any}) => {
    var chartRadarBasicColors = getChartColorsArrayf(dataColors);

    const parsedData = data.map((item: any) => ({
        x: item.label,
        y: item.total
    }));

    const series = [{
        name: "Total",
        data: parsedData,
    }]
    var options : any = {

        chart: {
            height: 350,
            type: 'radar',
            toolbar: {
                show: !1
            }
        },
        colors: chartRadarBasicColors,
        xaxis: {
            categories: data.map((item: any) => item.label),
        }
    };
    return (
        <ReactApexChart 
            dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="radar"
            height={350}
        />
    )
}