import { Card, CardBody, CardHeader } from "reactstrap";
import ViewSection from "../../../../pages/landing/components/ViewSection";
import { useTranslation } from "react-i18next";

export default function OrganizationsLanding() {
    const { t } = useTranslation();
    return (
        <ViewSection id="organizations">
            {/* <div className="mt-5 mb-5">
                <div className="text-center mb-5 ">
                    <h1 className="display-6">{t("Organizations")}</h1>
                </div>
                <Row className="d-flex h-100 justify-content-center align-content-center ">
                    <Col className="d-flex justify-content-center align-items-start">
                        <img src="/images/organizaciones.svg" style={{ maxHeight: "275px" }} alt="organizaciones" />
                    </Col>
                    <Col>
                        <div className="p-3 texts">
                            <p>
                                MIABOGADO EN LINEA{" "}
                                {t(
                                    "It allows the Judiciary and law firms to share the same wallet with different users. Our suite helps you control expenses through a dashboard and reports in HTML and Excel to have better control of expenses and identify which user has used the tools and how much credit from your wallet has been consumed."
                                )}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div> */}

            <Card className="p-3">
                <CardHeader>
                    <img className="card-img rounded-0 img-fluid" src="/images/organizaciones.svg" style={{ maxHeight: "275px" }} alt="Card cap" />
                </CardHeader>

                <div className="text-center my-3 texts">
                    <h1 className="display-6">{t("Organizations")}</h1>
                </div>

                <CardBody>
                    <div className="p-3 texts   mb-0 fs-5" style={{ textAlign: "justify" }}>
                        <p>MIABOGADO EN LINEA {t("It allows the Judiciary and law firms to share the same wallet with different users.")}</p>
                        <p>{t("Our suite helps you control expenses through a dashboard and reports in HTML and Excel to have better control of expenses and identify which user has used the tools and how much credit from your wallet has been consumed.")}</p>
                    </div>
                </CardBody>
            </Card>
        </ViewSection>
    );
}
