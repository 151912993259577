import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./start.scss";
import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const startPage = document.querySelector(".start-page") as HTMLElement | null;

        if (startPage) {
            const lazyLoadBgImage = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        startPage.classList.add("loaded");
                        observer.disconnect();
                    }
                });
            };

            const observer = new IntersectionObserver(lazyLoadBgImage, {
                root: null,
                rootMargin: "0px",
                threshold: 0.1,
            });

            observer.observe(startPage);
            return () => observer.disconnect();
        }
    }, []);

    return (
        <React.Fragment>
            <section className="section start-page poppings" id="start">
                <div className="bg-overlay"></div>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} sm={10}>
                            <div className="text-center">
                                <h1 className="display-5 fw-medium mb-4 lh-base primary">
                                    {t("Welcome to the era of artificial intelligence in the judicial branch with")}
                                    <span style={{ color: "#4452EE" }}> MIABOGADO EN LÍNEA</span>
                                </h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Home;
