import { Form } from "react-router-dom";
import InputBasic from "../../../components/inputs/InputBasic";
import { Button, Col, Row } from "reactstrap";
import InputPhone from "../../../components/inputs/InputPhoneNumber";
import { Gender, UserTypes } from "../../../core/enums/enums";
import * as Yup from "yup";
import { useFormik } from "formik";
import peticionBack from "../../../helpers/peticiones";
import { useTranslation } from "react-i18next";
import { OnChangeParams } from "../../../components/inputs/InputProps";
import InputSelect from "../../../components/inputs/InputSelect";
import { Usuario } from "./dto/Usuario.dt";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import { useEffect } from "react";
import InputPassword from "../../../components/inputs/InputPassword";

export default function FormUser({ setOpen, selected }: { setOpen: any; selected?: Usuario }) {
    const { t } = useTranslation();

    const generosArray = [
        { value: Gender.MASCULINO, label: t(Gender.MASCULINO) },
        { value: Gender.FEMENINO, label: t(Gender.FEMENINO) },
        { value: Gender.LGBT, label: t(Gender.LGBT) },
    ];

    const userType = [
        { value: UserTypes.ROOT, label: t("ROOT") },
        { value: UserTypes.ADMINISTRATOR, label: t("ADMINISTRATOR") },
    ];

    //VALIDACIÓN FORM
    const defaultForm: Usuario = {
        name: "",
        lastname: "",
        user_type: "",
        gender: "",
        email: "",
        phone: "",
        password: "",
        country_code: "+52",
        id: "",
        user_id: "",
    };

    const formValidations = Yup.object().shape({
        name: Yup.string().required(t("Enter your name")),
        lastname: Yup.string().required(t("Enter your last name")),
        email: selected ? Yup.string() : Yup.string().required(t("Enter an email")),
        phone: Yup.string().required(t("Enter your phone")).min(8, t("Min 8 characters")),
        password: selected
            ? Yup.string()
            : Yup.string()
                  .required(t("Enter a password"))
                  .matches(/^(?=.*[!@#$%^&*()\-_=+{};:,<.>/?`~])(?=.*[0-9])(?=.*[A-Z]).{6,}$/, t("The password must have at least one uppercase letter, one special character, one number and be at least 6 characters")),
        user_type: Yup.string().required("Select an user type"),
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    const { handleSubmit, handleReset, handleBlur, setFieldValue, setValues, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async (values) => {
            alerConfirmation({
                handleAction: save,
                title: `${selected ? t("Edit") : t("Save")}`,
                content: `${t("¿Desea")} ${selected ? t("edit") : t("guardar")} ${t("este usuario?")}`,
                buttonOkMsg: `${t("Save")}`,
                buttonCancelMsg: `${t("Cancel")}`,
                values: values,
            });
        },

        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (selected) {
            setValues(
                {
                    name: selected.name,
                    lastname: selected.lastname,
                    email: selected.email,
                    phone: selected.phone.substring(selected.phone.length - 10),
                    user_id: selected.user_id,
                    gender: selected.gender,
                    user_type: selected.user_type,
                    password: selected.password,
                    country_code: selected.country_code,
                    id: selected.id,
                },
                true
            );

            setFieldValue("user_type", selected.user_type);
        }
        // eslint-disable-next-line
    }, []);

    async function save() {
        const method = selected ? "PUT" : "POST";
        const rute = selected ? `/${selected.id}` : "";
        const params = selected
            ? {
                  name: values.name,
                  lastname: values.lastname,
                  email: values.email,
                  gender: values.gender,
                  phone: `${values.country_code || "+52"} ${values.phone}`,
                  user_type: values.user_type,
              }
            : {
                  name: values.name,
                  lastname: values.lastname,
                  email: values.email,
                  password: values.password,
                  gender: values.gender,
                  phone: `${values.country_code || "+52"} ${values.phone}`,
                  user_type: values.user_type,
              };

        const res = await peticionBack(params, `/usuarios` + rute, method, true);
        if (res) setOpen(false);
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                <Row className="d-flex justify-content-around">
                    <Col lg="5">
                        <InputBasic name="name" type="text" value={values.name} label={t("Name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                    <Col lg="5">
                        <InputBasic name="lastname" type="text" value={values.lastname} label={t("Last name")} touched={touched.lastname} error={errors.lastname} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                    <Col lg="5" style={{ zIndex: 9 }}>
                        <InputSelect name="gender" options={generosArray} value={values.gender} label={t("Gender")} touched={touched.gender} error={errors.gender} onChange={onChange} onBlur={handleBlur} />
                    </Col>
                    <Col lg="5" style={{ zIndex: 8 }}>
                        <InputPhone name="phone" nameCountryCode="country_code" value={values.phone} label={t("Phone")} touched={touched.phone} error={errors.phone} onChange={onChange} onBlur={handleBlur} valueCountry={values.country_code} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-around">
                    {!selected && (
                        <Col lg="5">
                            <InputBasic name="email" type="text" value={values.email} label={t("Email")} touched={touched.email} error={errors.email} onChange={onChange} onBlur={handleBlur} required />
                        </Col>
                    )}

                    {!selected && (
                        <Col lg="5">
                            <InputPassword name="password" value={values.password} label={t("Password")} touched={touched.password} error={errors.password} onChange={onChange} onBlur={handleBlur} required />
                        </Col>
                    )}
                </Row>
                <Row className="d-flex justify-content-around m-3">
                    <Col lg="6" xl="6">
                        <InputSelect name="user_type" options={userType} value={values.user_type} label={t("User type")} touched={touched.user_type} error={errors.user_type} onChange={onChange} onBlur={handleBlur} />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between ">
                    <Col xl="5" lg="4" className="d-flex justify-content-center my-2">
                        <Button color="danger" type="reset">
                            {t("Cancel")}
                        </Button>
                    </Col>
                    <Col xl="5" lg="4" className="d-flex justify-content-center my-1">
                        <Button color="success" type="submit">
                            {t("Save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
