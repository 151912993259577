/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import peticionBack from "../../../../helpers/peticiones";
import { withTranslation } from "react-i18next";
import CardBalance from "../../../cardContainer/CardMini";
import { BalanceType } from "../types/chartType";

function FoldersCount({ t }: { t: (f: string) => string }) {
    const [data, setData] = useState<BalanceType | undefined>({
        id: "3",
        label: `${t("totalFolders")}`,
        labelClass: "muted",
        percentage: `${t("Folders")}`,
        percentageClass: "secondary",
        percentageIcon: "ri-arrow-right-up-line",
        counter: "",
        caption: ``,
        icon: "bx bx-dollar-circle",
        iconClass: "secondary-subtle",
        decimals: 2,
        prefix: "$",
        color: "secondary",
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getBalance();
    }, []);

    async function getBalance() {
        setLoading(true);
        const res = await peticionBack({}, `/folders/count`, "GET", false);
        if (res) {
            const BalanceObj = {
                id: "3",
                label: `${t("totalFolders")}`,
                labelClass: "muted",
                percentage: `${t("Folders")}`,
                percentageClass: "secondary",
                percentageIcon: "ri-arrow-right-up-line",
                counter: res.folders,
                caption: ``,
                icon: "bx bxs-folder-open",
                iconClass: "secondary-subtle",
                decimals: 2,
                prefix: "$",
                color: "secondary",
            };
            setData(BalanceObj);
        }
        setLoading(false);
    }
    return (
        <>
            {data && <CardBalance datag={data} loading={loading}/>}
        </>
    );
}

export default withTranslation()(FoldersCount);
