import { io } from "socket.io-client";

const auth = localStorage.getItem("persist:auth_zeeIAB") || "{}";

const jsonAuth = JSON.parse(auth);
const token = jsonAuth.accessToken || "";
const formattedToken = token ? token.substring(1, token.length - 1) : "";
const backUri = process.env.REACT_APP_BACK_URI?.substring(0, process.env.REACT_APP_BACK_URI.length - 4) || "";

export const socket = io(backUri + "/ner", {
    path: "/api/socket.io",
    extraHeaders: {
        Authorization: `Bearer ${formattedToken}`,
    },
    auth: {
        token: formattedToken,
    },
});

socket.on("connect", () => {});

socket.on("connect_error", (error) => {});

socket.on("disconnect", (reason) => {});
