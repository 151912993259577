import React from "react";
import { withTranslation } from "react-i18next";
import Started from "../../components/pages/started/Started";

export const MainStarted = ({ t }: { t: (text: string) => string }) => {
    
    return (
        <>
           <Started/>
        </>
    );
};

export default withTranslation()(MainStarted);
