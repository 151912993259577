/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useNavigate } from "react-router-dom";
import Layout from "../components/layout/protected/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toastMessage from "../helpers/toastMessage";
import { loginData, loginSuccess, clear, fillUserInformation, expirationToken } from "../core/slices/auth/AuthSlicer";
import peticionBack from "../helpers/peticiones";
import { useLocation } from "react-router-dom";
import { RootState } from "../core/redux/store";
import { user_type } from "../core/enums/enums";
import { Helmet } from "react-helmet-async";
import { isUserInformationValidated } from "../helpers/userValidations";
import { MainValidate } from "../pages/user/Main";
import { useTranslation } from "react-i18next";
import Timer from "../helpers/timer/Timer";
import ParseJwt from "../helpers/timer/parseJWT";
import { loaded, loading } from "../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../core/types/TypeLoader";

export function ProtectedRoutes(props: any) {
    const loginInformation = useSelector(loginData);
    let modulesRedux = useSelector((state: RootState) => state.modules.modules);
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const userInformation = useSelector(loginData);
    const { t } = useTranslation();
    const show = !isUserInformationValidated(userInformation);

    useEffect(() => {
        if (isUserInformationValidated(loginInformation)) {
            navigate("/user/validation", { replace: true });
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!loginInformation?.accessToken && loginInformation.accessToken === "") {
            logout(true, "Token no valido");
        }
        getLoginInformation();
        // eslint-disable-next-line
    }, []);

    async function getLoginInformation() {
        if (loginInformation.accessToken) {
            dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
            const token = { Authorization: `bearer ${loginInformation.accessToken}` };
            const res = await peticionBack({}, "/me", "GET", false, token);
            if (res) {
                if (res.verified === false) {
                    logout(true, "Valide su correo electronico");
                }

                dispatch(
                    loginSuccess({
                        ...loginInformation,
                        verified: Boolean(res.verified) || false,
                        completed: Boolean(res.completed) || false,
                    })
                );
                dispatch(fillUserInformation(res));
                if (!res.completed && res.user_type !== user_type.ROOT && res.user_type !== user_type.ADMINISTRATOR) {
                    navigate("/user/validation");
                }

                if (modulesRedux && modulesRedux.some((module: any) => module.permission.r === false)) {
                    navigate("/explorador");
                }
            } else {
                logout(true, "Hubo un problema al obtener sus datos");
            }

            dispatch(expirationToken(ParseJwt(loginInformation.accessToken)));
            dispatch(loaded());
        }
    }
    function logout(withWarning: boolean, textWarning: string) {
        dispatch(clear());
        if (withWarning) {
            toastMessage("warning", textWarning || "Vuelva pronto");
        }
        navigate("/login", { replace: true });
    }
    return (
        <>
            {show ? (
                <Layout>
                    <Helmet>
                        <title> MIAbogado Suite</title>
                    </Helmet>
                    <Timer onFinished={logout} loginInformation={loginInformation}>
                        <Outlet />
                    </Timer>
                </Layout>
            ) : (
                <MainValidate t={t} />
            )}
        </>
    );
}
