import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import TreeFile from "../views/TreeFile";
import UploadFileView from "../views/UploadFileView";
import { useTranslation } from "react-i18next";
import { QueueWork } from "../../core/enums/enums";

export default function SingleModalUpload({ open, setOpen, typeProcess, setRefresh }: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>>; typeProcess: QueueWork; setRefresh: Dispatch<SetStateAction<boolean>> }) {
    const [currentFolder, setCurrentFolder] = useState<string | undefined>();
    const { t } = useTranslation();
    function toggle() {
        setOpen(!open);
    }

    useEffect(() => {
        if (open) {
            setCurrentFolder(undefined);
        }
    }, [open]);

    function refreshContent() {
        toggle();
        setRefresh(true);
        setCurrentFolder(undefined);
    }

    function cancel() {
        setCurrentFolder(undefined);
        toggle();
    }
    return (
        <div>
            {open === true && (
                <Modal isOpen={open} toggle={toggle} centered size="lg">
                    <div className="mt-3 d-flex justify-content-end mx-3">
                        <FontAwesomeIcon
                            className="text-danger"
                            style={{ cursor: "pointer" }}
                            size="2x"
                            icon={faXmark}
                            onClick={() => {
                                toggle();
                            }}
                        />
                    </div>
                    <div className="text-center">
                        <h1>{t("Upload-file")}</h1>
                    </div>

                    <ModalBody>
                        <Row className="p-3">
                            <Col xs={4} md={4}>
                                <div>
                                    <TreeFile currentFolder={currentFolder} setCurrentFolder={setCurrentFolder} />
                                    {!currentFolder && <span className="text-danger">Seleccione un folder</span>}
                                </div>
                            </Col>
                            <Col xs={8} md={8}>
                                {<UploadFileView t={t} currentFolder={currentFolder as string} cancelFunction={cancel} refreshContent={refreshContent} typeProcess={typeProcess} accepts={typeProcess === QueueWork.TRANSCRIPCION ? "video/*" : "application/pdf,image/*"} />}
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
}
