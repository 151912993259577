
export const SearchInputChats = ({ searchUsers }: { searchUsers: any }) => {
    return (
        <div className="px-4 pt-4 mb-3">
            <div className="search-box">
                <input onKeyUp={searchUsers} id="search-user" type="text" className="form-control bg-light border-light" placeholder="Busca aquí..." />
                <i className="ri-search-2-line search-icon"></i>
            </div>
        </div>
    );
};
