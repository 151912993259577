/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Datatable from "../../../datatable/Datatable";
import createURL from "../../../../helpers/url";
import peticionBack from "../../../../helpers/peticiones";
import ChartGlobal from "./ChartGlobal";
import { Card, Col, Row, Spinner } from "reactstrap";
import { solutions } from "../../../../core/enums/enums";
import { convertirAMegas } from "../../../../helpers/formats";
import { FormReporte } from "./FormReporte";
import getReportAdmin from "../../reportes/components/excel/getReportAdmin";

export default function MainView({ t, task, columns, headers }: { t: (f: string) => string; task: string; columns: any; headers: any }) {
    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

const dataColorsCharQuantity ='["--vz-secondary"]';
const dataColorsCharAmount ='["--vz-success"]';

    async function getData(status?: any, startDate?: string, endDate?: string, isDeleted?: boolean) {
        const params: any = {
            pathname: `/administrative/reports/iterations/${task}`,
            searchParams: [
                { name: "status", value: status },
                { name: "startDate", value: startDate },
                { name: "endDate", value: endDate },
                { name: "isDeleted", value: isDeleted },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res) {
            res.data.forEach((element: any) => {
                if (element.file) {
                    element.size = convertirAMegas(element.file.size);
                } else {
                    element.size = convertirAMegas(element.size);
                }
                if (element.costo) element.costo.value = element.costo.value.toString();
            });
            setData(res.data);
        }
        setRefresh(true);
        
    }
    /* TABLA */

    const columnDefs = [{ className: "text-center", targets: [0, 1, 2, 3, 4, 5, 6] }];

    function handleExcelReport() {
        setLoading(true);
        getReportAdmin(t, task, data);
        setLoading(false);
    }
    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2">
                <h4>{`${t("Report preview")} - ${task}`}</h4>
            </div>
            <FormReporte t={t} petition={getData} task={task} handleExcelReport={handleExcelReport} />
            <Card className={"border card-border-secondary p-3"}>
                <Datatable columns={columns} headers={headers} columnDefs={columnDefs} data={data} control="front" stateRefresh={[refresh, setRefresh]} className="p-0" />
            </Card>
            <Row>
                <Col xs={12} md={6}>
                    <Card className={"card-animate border card-border-primary"}>
                        <ChartGlobal t={t} task={task === solutions.TRANSCRIPCION ? solutions.TRANSCODIFICADOR : task === solutions.FILEMANAGER ? solutions.ALMACENAMIENTO : task} type={true} dataColors ={dataColorsCharQuantity}  />
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className={"card-animate border card-border-primary"}>
                        <ChartGlobal t={t} task={task === solutions.TRANSCRIPCION ? solutions.TRANSCODIFICADOR : task === solutions.FILEMANAGER ? solutions.ALMACENAMIENTO : task} type={false} dataColors ={dataColorsCharAmount} />
                    </Card>
                </Col>
            </Row>
            {loading && (
                <>
                    <div className="d-flex justify-content-center">
                        <Spinner color="primary">{t("Getting information")}</Spinner>
                    </div>
                    <div className="d-flex justify-content-center">
                        <h3>{t("Getting information")}</h3>
                    </div>
                </>
            )}
            {loading && <div style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999 }}></div>}
        </>
    );
}
