export const landingEs = {
    Start: "Inicio",
    Public: "Publico",
    Solutions: "Soluciones",
    Organizations: "Organizaciones",
    Contact: "Contacto",
    Login: "Iniciar",
    "Welcome to the era of artificial intelligence in the judicial branch with": "Bienvenido a la era de la inteligencia artificial en la rama judicial con",
    "Our suite of digital tools contains services for 2 sectors": "Nuestras suite de herramientas digitales contiene servicios para 2 sectores",
    "Trial lawyers and educational sector of the branch of law": "Abogados litigantes y sector educativo de la rama del derecho",
    "Online text editor tested with AI to generate": "Editor de texto en linea y testado con IA para generar",
    Promotions: "Promociones",
    Protections: "Amparos",
    "Converting video audiences to text": "Conversión de audiencias en video a texto",
    "Conversion of sentences, jurisprudence and agreements to text": "Conversión de sentencias, jurisprudencias y acuerdos a texto",
    "Electronic agenda": "Agenda electronica",
    "Converting text to audio": "Conversion texto a audio",
    "AI Legal Assistant": "Asistente Legal de IA",
    "Electronic file": "Expediente electrónico",
    "Judicial powers": "Poderes judiciales",
    "Public statement generator": "Generador de sentencias publicas",
    "Conversion of sentences, jurisprudence and agreements in PDF format to text": "Conversión de sentencias, jurisprudencias y acuerdos en formato PDF a texto",
    "It allows the Judiciary and law firms to share the same wallet with different users.": "permite que los Poderes Judiciales y buffetes de abogados, compartan una misma wallet con diferentes usuarios.",
    "Our suite helps you control expenses through a dashboard and reports in HTML and Excel to have better control of expenses and identify which user has used the tools and how much credit from your wallet has been consumed.":
        "Nuestra suite te ayuda a tener un control de gastos a través de un dashboard y reportes en html y excel para tener un mejor control de gastos e identificar que usuario a utilizado las herramientas y cuánto crédito de tu wallet a consumido.",
    "What are you waiting for to use AI to your advantage?": "¿Qué esperas para usar la IA a tu favor?",
    "Start your free trial at": "Inicia tu prueba gratuita en",
    "right now and discover how AI can help you be the best lawyer in Mexico": "ahora mismo y descubre cómo la IA puede ayudarte a ser el mejor abogado de México",
    Register: "Registrarme",
    "All rights reserved for www.miabogadoenlinea.mx, consult our privacy notice for more information about privacy and data confidentiality policies":
        "Todos los derechos reservados para www.miabogadoenlinea.mx, consulta nuestro aviso de privacidad para mayor información a cerca de las políticas de privacidad y confidencialidad de los datos.",
    "Privacy notice": "Aviso de privacidad",
    footerPolicy: "Politicas",
    "PUBLIC SENTENCE GENERATOR": "GENERADOR DE SENTENCIAS PÚBLICAS",
    "Through the use of AI, we help judiciaries convert their private sentences into public sentences under intelligent testing of their document.":
        "A través del uso de IA, ayudamos a los poderes judiciales a convertir sus sentencias privadas en sentencias públicas bajo un testado inteligente de su documento.",
    "VIDEO TO TEXT CONVERSION": "CONVERSIÓN DE VIDEO A TEXTO",
    "Agilely manipulate a hearing of 2 hours or more, transforming it into text and requesting an intelligent legal point of view with our AI assistant.":
        "Manipula de manera ágil una audiencia de 2 horas o más, transformándola a texto y solicitando un punto de vista jurídico inteligente con nuestro asistente de IA.",
    "PDF/IMAGE TO TEXT CONVERSION": "CONVERSIÓN DE  PDF/IMAGEN A TEXTO",
    "Convert your promotions, demands or any other legal writing to text and rely on our AI legal assistant trained in Mexican laws.": "Convierte tus promociones, demandas o cualquier otro escrito jurídico a texto y apóyate de nuestro asistente jurídico de IA entrenado con las leyes mexicanas.",
    "INTELLIGENT ASSISTANT": "ASISTENTE INTELIGENTE",
    "Our chatbot is an AI assistant that allows you to connect our video to text and PDF/image to text conversion services to manipulate content and request legal advice or content summaries in a single click.":
        "Nuestro chatbot es un asistente de IA que permite conectar nuestros servicios de conversión de video a texto y de PDF/imagen a texto para manipular el contenido y solicitar asesoría jurídica o resúmenes del contenido en un solo click.",
    "TEXT TO AUDIO CONVERSION": "CONVERSIÓN DE  TEXTO A AUDIO",
    "Being inclusive, our text-to-audio tool allows visually impaired people to listen to any plain text extracted from hearings or lawsuits in PDF, etc.":
        "Al ser inclusiva, nuestra herramienta de conversión de texto a audio permite a las personas con discapacidad visual escuchar cualquier texto sin formato extraído de audiencias o demandas en PDF, etc.",
    "ELECTRONIC FILE": "EXPEDIENTE ELECTRONICO",
    "Keep an organized and accessible electronic file, register your clients and keep an electronic agenda for each of your next hearings; as well as being able to manipulate the stages in which each case is found.":
        "Lleva un expediente electrónico organizado y accesible, da de alta a tus clientes y lleva una agenda electrónica de cada una de tus siguientes audiencias; asi como también poder manipular las etapas en las que se) encuentra cada caso.",
    free: "Gratis",
};
