import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { getCostSolution } from "../../helpers/getCostsSolutions";
import { solutions } from "../../core/enums/enums";
import { EnumLoader } from "../../core/types/TypeLoader";

export default function ModalCharge({
    t,
    functionToExecut,
    showCost = true,
    modalChargeIn,
    setModalChargeIn,
    message,
    iconRemix,
}: {
    t: (text: string) => string;
    functionToExecut: Function;
    modalChargeIn: boolean;
    setModalChargeIn: Function;
    message?: string;
    iconRemix?: string;
    showCost?: boolean;
}) {
    const toggle = () => setModalChargeIn(!modalChargeIn);

    const dispatch = useDispatch();
    const [costSolution, setCostSolution] = useState<number | null>();

    const handleButtonClick = () => {
        functionToExecut();
        setModalChargeIn(!modalChargeIn);
    };

    useEffect(() => {
        if (modalChargeIn) {
            getSolutionCost();
        }
        // eslint-disable-next-line
    }, [modalChargeIn]);

    async function getSolutionCost() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const costSolution = await getCostSolution(solutions.EDITOR);
        if (costSolution) {
            setCostSolution(costSolution);
        }
        dispatch(loaded());
    }
    return (
        <Modal isOpen={modalChargeIn} toggle={toggle} centered modalClassName="zoomIn">
            <ModalBody>
                <div className="mt-2 text-center">
                    <i className={`${iconRemix ? iconRemix : "ri-file-search-line"} display-1 text-info`}></i>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h5>{message ? message : t("Process files")}</h5>
                        <p className="text-muted mx-4 mb-0">
                            {t("You want to continue with the action")}
                            <strong>{}</strong>
                        </p>
                    </div>
                </div>
                <div>
                    {costSolution ? (
                        <div className="d-flex gap-2 justify-content-around mt-4 mb-2">
                            <button type="reset" className="btn w-sm btn-danger" onClick={toggle} data-bs-dismiss="modal">
                                <i className="ri-close-line align-bottom"></i> {t("cancel")}
                            </button>
                            <button type="submit" className="btn w-sm btn-success " id="delete-record" onClick={handleButtonClick}>
                                {showCost && <i className="ri-money-dollar-circle-line" style={{ fontSize: "1em" }} />}
                                {`${showCost ? costSolution : ""} ${t("common.Continue")}`}
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center p-2">
                            <h4>${t("The price could not be consulted")}</h4>
                        </div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
}
