import { UncontrolledTooltip } from "reactstrap";
import CardContainer from "../../components/cardContainer/CardContainer";
import { useState } from "react";
import Datatable from "../../components/datatable/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faEye, faRedo } from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/buttons/Button";
import peticionBack from "../../helpers/peticiones";
import { DataItem } from "./dto/OcrDto";
import createURL from "../../helpers/url";
import { getColor } from "../../helpers/formats";
import ViewOcr from "../../components/pages/ocrText/views/VistaOcr";
import ModalFileViewer from "../../components/pages/ocrText/components/ModalViewOcr";
import { useTranslation } from "react-i18next";
import { checkStatus } from "../../helpers/checkStatusOperation";
import { StausQueue } from "../../core/enums/enums";
import { alerConfirmation } from "../../helpers/alertConfirmation";
import toastMessage from "../../helpers/toastMessage";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";
import { useDispatch } from "react-redux";
import Badge from "../../components/badge/Badge";
import SingleModalUpload from "../../components/modals/SingleModalUpload";
import { QueueWork } from "../../core/enums/enums";

export default function PrincipalOcr() {
    const [refresh, setRefresh] = useState(false);
    const [isOpen, setIsOpen] = useState<any>(null);
    const [isOpenModalUpload, setIsOpenModalUpload] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: "/queues/OCR",
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        if (res) return res || [];
    }

    const ReproccessFile = async (row: any) => {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const res = await peticionBack({ file_id: row._id, replace: true }, `/ocr`, "POST", true);
        dispatch(loaded());
        if (res) {
            if (res.is_free) toastMessage("success", t("This processing has been free of charge by") + " MIAbogadoEnLinea");
        }
    };

    function showAlert(row: any) {
        alerConfirmation({
            handleAction: ReproccessFile,
            title: `${t("reprocess")}`,
            content: `${t("would you want convert to audio")} ${row.name}`,
            buttonOkMsg: `${t("common.accept")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    const columnsDef = [
        { orderable: false, targets: [4] },
        { className: "text-center", targets: [0, 1, 2, 3] },
    ];

    const headers: any[] = ["Nombre", "Tipo de tarea", "Estatus", "Última Actualización", "Acciones"];
    const columns = [
        { data: "name" },
        { data: "task" },
        {
            data: "status",
            render: (d: string) => (
                <Badge color={getColor(d)} soft>
                    {d}
                </Badge>
            ),
        },
        { data: "taskLastUpdate", format: "datetime" as "datetime" },
        {
            data: null,
            render: (row: DataItem) => (
                <div className={`d-flex justify-content-center`}>
                    <ModalFileViewer row={row} />
                    <FontAwesomeIcon id="tooltipVconv" icon={faEye} size="xl" className="text-primary px-1 cursor-pointer" onClick={() => checkStatus({ row: row, t: t, funtionSuccess: setIsOpen })} />
                    <UncontrolledTooltip placement="top" target="tooltipVconv">
                        Ver contenido convertido
                    </UncontrolledTooltip>
                    {row.status === StausQueue.FAILURE && (
                        <>
                            <FontAwesomeIcon id="tooltipReporcess" icon={faRedo} size="xl" className="text-primary px-1 cursor-pointer" onClick={() => showAlert(row)} />{" "}
                            <UncontrolledTooltip placement="top" target="tooltipReporcess">
                                {t("reprocess")}{" "}
                            </UncontrolledTooltip>{" "}
                        </>
                    )}
                </div>
            ),
        },
    ];

    return (
        <CardContainer title="Reconocimiento Óptico de Caracteres" tutorial="OCR" defaultOpne={true}>
            <div className="mb-3">
                {isOpen ? (
                    <Button color="danger" onClick={() => setIsOpen(null)} className="my-2 mx-2 d-flex justify-content-md-between flex-column-reverse flex-md-row d-grid gap-2">
                        {" "}
                        Regresar{" "}
                    </Button>
                ) : (
                    <div className="d-flex justify-content-between">
                        <Button onClick={() => setRefresh(true)}>
                            <FontAwesomeIcon icon={faArrowsRotate} />
                        </Button>
                        <Button onClick={() => setIsOpenModalUpload(true)}>{t("Add")}</Button>
                    </div>
                )}
            </div>
            {isOpen ? <ViewOcr row={isOpen} /> : <Datatable headers={headers} petition={getData} control="back" columns={columns} columnDefs={columnsDef} stateRefresh={[refresh, setRefresh]} />}

            <SingleModalUpload open={isOpenModalUpload} setOpen={setIsOpenModalUpload} typeProcess={QueueWork.OCR} setRefresh={setRefresh} />
        </CardContainer>
    );
}
