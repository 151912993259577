import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import peticionBack from "../../../../helpers/peticiones";
import { Session } from "../../../../core/types/Sessions";
import Datatable from "../../../datatable/Datatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { alerConfirmation } from "../../../../helpers/alertConfirmation";
import { loaded, loading } from "../../../../core/slices/loader/LoaderSlicer";
import { useDispatch } from "react-redux";
import { EnumLoader } from "../../../../core/types/TypeLoader";

export default function ModalCloseSessions({ modalOpen, setModalOpen, recoveryToken, t }: { modalOpen: boolean; setModalOpen: Dispatch<SetStateAction<boolean>>; recoveryToken: string; t: (text: string) => string }) {
    const [sessions, setSessions] = useState<Array<Session>>([]);

    const [refresh, setRefresh] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        getSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getSessions() {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const token = { Authorization: `Bearer ${recoveryToken}` };
        const res = await peticionBack({}, `/me/help/sessions/all`, "GET", false, token);

        if (res) {
            setSessions(res);
            setRefresh(true);
        }
        dispatch(loaded());
    }

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const headers = ["Navegador", "Sistema Operativo", "Ultima vez activo", "Cerrar sesion"];

    const columns = [
        { data: "browser_family" },
        { data: "browser_family" },
        { data: "updatedAt", format: "datetime" as "datetime" },
        {
            data: null,
            render: (row: Session) => <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />,
        },
    ];
    const columnsDef = [
        { orderable: false, targets: [0, 1, 2, 3] },
        { className: "text-center", targets: [0, 1, 2, 3] },
    ];
    function showAlert(row: Session) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("Close session")}`,
            content: `${t("Are you sure you want to close the session?")} `,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    async function deleteRow(session: Session) {
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const token = { Authorization: `Bearer ${recoveryToken}` };
        const res = await peticionBack({}, `/me/help/sessions/close/${encodeURIComponent(session.id)}`, "DELETE", false, token);
        if (res) {
            getSessions();
        }
        dispatch(loaded);
    }

    return (
        <div>
            <Modal isOpen={modalOpen} toggle={toggleModal} className="responsive-modal" centered>
                <ModalHeader toggle={toggleModal}>{t("Open sessions")}</ModalHeader>

                <ModalBody>
                    {" "}
                    <Datatable columns={columns} headers={headers} data={sessions} control="front" columnDefs={columnsDef} stateRefresh={[refresh, setRefresh]} searching={false} />{" "}
                </ModalBody>
            </Modal>
        </div>
    );
}
