import { saveAs } from 'file-saver';
import { Workbook, Worksheet } from "exceljs";
import { styles as sg } from "../../../../../styles/components/reportes/styles";
import { TranscoIterInter } from '../../types/reportesTypes';
import { ConfigTable, generaTabla } from './generateTable';
import header from './Header';

export default async function chatBotIter(t: (text: string) => string, data: TranscoIterInter[]){
    const workbook = new Workbook();
    const sheet = header(workbook, `${t("chat interaction per file")}`, 3);
    let row = 3;
    if(true){
        const configA: ConfigTable = {
            columns: [
                { data: "name", title: `${t("file name")}`, width: 40, align: "tCenter" },
                { data: "total_chat_history", title: `${t("total_chat_history")}`, width: 20, align: "tCenter" },
                { data: "createdAt", title: `${t("date")}`, width: 15, align: "tCenter", format: "date" },
            ]
        }

        const transcoIter = data;
        vtCabecera(sheet, row, t("Chatbot Report"));
        row = generaTabla(transcoIter, configA, sheet, row + 3);
    }

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${t("Chatbot Report")}-${new Date().getFullYear()}.xlsx`);
}

function vtCabecera(sheet: Worksheet, row: number, title: string){
    sheet.mergeCells(row, 1, row, 3);
    const cellA = sheet.getRow(row).getCell(1);
    cellA.value = title;
    cellA.font = sg.font.tBold;
    cellA.alignment = sg.alignment.tCenter;
}
