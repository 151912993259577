import { useTranslation } from "react-i18next";
import RecursiveFolders from "../pages/explorador-archivos/views/Recursive-capretas";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { loginData } from "../../core/slices/auth/AuthSlicer";
import peticionBack from "../../helpers/peticiones";
import { loaded, loading } from "../../core/slices/loader/LoaderSlicer";
import { EnumLoader } from "../../core/types/TypeLoader";

export default function TreeFile({ currentFolder, setCurrentFolder }: { currentFolder: string | undefined; setCurrentFolder: Dispatch<SetStateAction<string | undefined>> }) {
    const { t } = useTranslation();
    const [showFiles, setShowFiles] = useState<Array<any>>([]);
    const [peticionComplete, setPeticionComplete] = useState(false);

    const loginInformation = useSelector(loginData);
    const [isFolderRoot, setIsFolderRoot] = useState<boolean>(false);
    const [refreshContent, setRefreshContent] = useState<boolean>(false);

    useEffect(() => {
        getFolders();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isFolderRoot) {
            getFolders();
        }
        // eslint-disable-next-line
    }, [isFolderRoot]);

    useEffect(() => {
        if (currentFolder) {
            if (refreshContent) {
                getContent(currentFolder);
            }
        }
        // eslint-disable-next-line
    }, [refreshContent]);
    useEffect(() => {
        if (currentFolder) {
            getContent(currentFolder);
        }
        // eslint-disable-next-line
    }, [currentFolder]);

    async function getFolders() {
        if (loginInformation.accessToken) {
            setPeticionComplete(false);
            loading(EnumLoader.FULLSCREEN_SUBTLE);
            const token = { Authorization: `bearer ${loginInformation.accessToken}` };
            const res = await peticionBack({}, `/folders/init-folders`, "GET", false, token);
            if (res) {
                if (res.length > 0) {
                    res.forEach((fol: any) => {
                        fol.isDir = true;
                        fol.id = fol._id;
                    });
                    setShowFiles(res);
                } else {
                    setShowFiles([]);
                    setCurrentFolder(undefined);
                }
            } else {
            }
            if (isFolderRoot) {
                setIsFolderRoot(false);
            }
            loaded();
            setPeticionComplete(true);
        }
    }

    async function getContent(currentFolder: string) {
        loading(EnumLoader.FULLSCREEN_SUBTLE);
        const res = await peticionBack({}, `/folders/content/${encodeURIComponent(currentFolder)}`, "GET", false);
        if (res) {
            if (res.length > 0) {
                res.forEach((fol: any) => {
                    fol.id = fol._id;
                });
            }
            const newContent = res.map((folder: any) => ({
                ...folder,
                id: folder._id,
                subFolders: [],
            }));

            setShowFiles(updateShowFilesWithContent(showFiles, newContent));
        }
        loaded();
        if (refreshContent) {
            setRefreshContent(false);
        }
    }

    function updateShowFilesWithContent(showFiles: any[], content: any): any[] {
        const updatedShowFiles = showFiles.map((folder: any) => {
            const matchedContent = content.find((c: any) => c.id === folder._id);
            if (matchedContent) {
                return {
                    ...folder,
                    subFolders: matchedContent.folders.map((subfolder: any) => ({
                        ...subfolder,
                        id: subfolder._id,
                    })),
                };
            } else if (folder.subFolders) {
                return {
                    ...folder,
                    subFolders: updateShowFilesWithContent(folder.subFolders, content),
                };
            } else {
                return folder;
            }
        });

        return updatedShowFiles;
    }

    return (
        <div>
            <span></span>
            {peticionComplete && showFiles?.map((m: any, ix: number) => <RecursiveFolders t={t} folder={m} setCurrentFolder={setCurrentFolder} currentFolder={currentFolder as string} key={"mr" + m.name + ix} setRefreshContent={setRefreshContent} setIsFolderRoot={setIsFolderRoot} />)}
        </div>
    );
}
