import { FabricObjectProps } from "fabric";

export function objectConfig(cornerSize: number = 10): FabricObjectProps {
    return {
        hasBorders: true,
        borderDashArray: [1, 0, 1],
        cornerColor: "#1154d195",
        borderColor: "#1666fa",
        cornerSize,
        transparentCorners: false,
        minScaleLimit: 0.3,
        hasControls: true,
        originX: "left",
        originY: "bottom",
        centeredRotation: false,
    } as FabricObjectProps;
}
