import { Form } from "react-router-dom";
import InputBasic from "../../../components/inputs/InputBasic";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import peticionBack from "../../../helpers/peticiones";
import { useTranslation } from "react-i18next";
import { OnChangeParams } from "../../../components/inputs/InputProps";

import { alerConfirmation } from "../../../helpers/alertConfirmation";
import { useEffect } from "react";
import { useDispatch} from "react-redux";
import toastMessage from "../../../helpers/toastMessage";
import { changeOrganization, changeUserType } from "../../../core/slices/auth/AuthSlicer";
import { Organizacion as Org } from "../dto/organization.dto";
import { user_type } from "../../../core/enums/enums";

export default function FormOrganizacion({ selected }: { selected?: Org | null }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //VALIDACIÓN FORM
    const defaultForm: Org = {
        name: "",
        description: "",
        id: "",
    };

    const formValidations = Yup.object().shape({
        name: Yup.string().required(t("Enter your name")),
        description: Yup.string().required("Enter a description"),
    });

    const { handleSubmit, handleReset, handleBlur, setFieldValue, setValues, values, errors, touched } = useFormik({
        initialValues: defaultForm,
        validationSchema: formValidations,
        onSubmit: async (values) => {
            alerConfirmation({
                handleAction: save,
                title: `${selected ? "Editar" : "Guardar"}`,
                content: `¿Desea ${selected ? "editar" : "guardar"} esta organización?`,
                buttonOkMsg: `${"Guardar"}`,
                buttonCancelMsg: `${"Cancelar"}`,
                values: values,
            });
        },
    });

    function onChange({ name, value }: OnChangeParams) {
        setFieldValue(name, value);
    }

    useEffect(() => {
        if (selected) {
            setValues(
                {
                    name: selected.name,
                    description: selected.description,
                    id: selected.id,
                },
                true
            );
        }
    }, [setValues, selected]);

    async function save() {
        const method = selected ? "PUT" : "POST";

        const params = {
            name: values.name,
            description: values.description,
        };

        if (selected) {
            await peticionBack(params, `/me/own-organization`, method, true);
        } else {
            //Si crea una organización, se cambia su tipo de usaurio y muestra otra vista
            const res = await peticionBack(params, `/me/own-organization`, method, false);
            if (res) {
                toastMessage("success", t("organization.The organization has been created"));
                const res2 = await peticionBack(params, `/me`, "GET", true);

                dispatch(changeOrganization(res2.organization));
                dispatch(changeUserType(user_type.ORGANIZATION_ADMINISTRATOR));
            } else {
                toastMessage("error", t("organization.The organization already exist, please choose another name"));
            }
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} onReset={handleReset} className="p-3">
                <h3>{t("organization.My organization")}</h3>
                <Row className="d-flex justify-content-around">
                    <Col xl="12" lg="12" md="12" sm="12">
                        <InputBasic name="name" type="text" value={values.name} label={t("Name")} touched={touched.name} error={errors.name} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                    <Col xl="12" lg="12" md="12" sm="12">
                        <InputBasic name="description" type="text" value={values.description} label={t("Descripción")} touched={touched.description} error={errors.description} onChange={onChange} onBlur={handleBlur} required />
                    </Col>
                </Row>

                <Row className="d-flex justify-content-between ">
                    <Col xl="12" lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-center my-1">
                        <Button color="success" type="submit">
                            {t("Save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
