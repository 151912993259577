import React from "react";
import { useNavigate } from "react-router-dom";

export const RecoverPassword = ({ t, messageLink, messageLabel }: { t: (text: string) => string; messageLink: string; messageLabel: string }) => {
    const navigate = useNavigate();
    async function sendMailView() {
        navigate("/recoverPassword", { replace: true });
    }

    return (
        <div className=" text-center">
            <span className="mb-0">
                {t(messageLabel)}
                <p className="fw-semibold text-primary text-decoration-underline ms-2" style={{ cursor: "pointer" }} onClick={sendMailView}>
                    {t(messageLink)}
                </p>
            </span>
        </div>
    );
};
