import axios, { AxiosResponse } from "axios";
import toastMessage from "./toastMessage";

interface PetitionType {
    query?: any;
    rows?: string;
    page?: number;
    records?: number;
    order?: string;
    search?: string;
    join?: any;
}

interface SaveFilePetition {
    endpoint: string;
    method: "POST" | "PUT";
    show?: boolean;
    file: any;
    keyBody?: string;
    okMessage?: string;
    errorMessage?: string;
}

function midAuth(headers?: any): object {
    if (Object.hasOwn(headers || {}, "Authorization")) {
        return { headers };
    }
    const auth = localStorage.getItem("persist:auth_zeeIAB");
    const fHeaders = { ...headers };
    if (auth) {
        const jsonAuth = JSON.parse(auth);
        if (jsonAuth.accessToken) {
            const token: string = jsonAuth.accessToken;
            fHeaders.headers = {
                ...fHeaders.headers,
                Authorization: `bearer ${token.substring(1, token.length - 1)}`,
            };
        }
    }
    return fHeaders;
}

export async function getTypePetition(endpoint: string, finalData: any, tipo: string, headers?: any, env?: string): Promise<AxiosResponse<any, any>> {
    let url = " ";

    if (process.env.REACT_APP_BACK_URI && endpoint.includes(process.env.REACT_APP_BACK_URI)) {
        url = endpoint;
    } else if (env) {
        url = env + endpoint;
    } else {
        url = process.env.REACT_APP_BACK_URI + endpoint;
    }
    const fHeaders = midAuth(headers);
    if (tipo === "GET") {
        const res = await axios.get(url, { ...fHeaders, params: finalData });
        return res;
    } else if (tipo === "PUT") {
        const res = await axios.put(url, finalData, fHeaders);
        return res;
    } else if (tipo === "DELETE") {
        const res = await axios.delete(url, fHeaders);
        return res;
    } else {
        const res = await axios.post(url, finalData, fHeaders);
        return res;
    }
}

export function getType(cause: any): "success" | "info" | "warning" | "error" {
    if (typeof cause === "number") {
        switch (cause) {
            case 200:
            case 201:
                return "success";
            case 204:
            case 208:
                return "info";
            case 404:
            case 405:
            case 412:
                return "warning";
            case 400:
            case 401:
            case 409:
            case 500:
            case 501:
            case 502:
            case 503:
            case 504:
            case 505:
            case 506:
            case 507:
            case 508:
            case 509:
            case 510:
            case 511:
            case 512:
                return "error";
            default:
                return "error";
        }
    } else {
        return "error";
    }
}

export default async function peticionBack(params: PetitionType | any, endpoint: string, tipo: string, show: boolean | string, headers?: any, full?: boolean, env?: string) {
    try {
        const finalData = params ? params : "";
        const res = await getTypePetition(endpoint, finalData, tipo, headers, env);
        if (full) return res;
        const data = res.data;
        if (res.status >= 200 && res.status <= 299) {
            if (show) {
                toastMessage("success", getTypeMessage(data, tipo));
            }
            if (res.status === 200 || res.status === 201) {
                return data;
            }
            return null;
        }

        throw new Error(data.message);
    } catch (err: any) {
        const message = err.response ? (typeof err.response.data.message === "string" ? err.response.data.message : err.response.data.message[0] || err.response.data) : err.message;
        const status = err.response ? err.response.request.status : 500;

        if (show) {
            toastMessage(getType(status), typeof show === "string" ? show : message);
        }
        if (full) return err.response.data;
        return null;
    }
}

function getTypeMessage(data: any, tipo: string) {
    if (data.message) return data.message;
    if (typeof data === "string") return data;
    switch (tipo) {
        case "GET":
            return "Información consultada correctamente";
        case "PUT":
            return "Registro actualizado";
        case "DELETE":
            return "Registro eliminado";
        default:
            return "Registro guardado";
    }
}

export async function uploadFile(petition: SaveFilePetition) {
    try {
        const url = process.env.REACT_APP_BACK_URI + petition.endpoint;
        const headers = midAuth();
        const formData = new FormData();
        formData.append(petition.keyBody || "file", petition.file);
        const res = await getTypePetition(url, formData, petition.method, headers);
        if (res.status >= 200 && res.status <= 299) {
            if (petition.show ?? true) toastMessage("success", petition.okMessage || "Documento guardado");
            if ([200, 201].includes(res.status)) return res.data;
        }
        throw new Error(res.data);
    } catch (error: any) {
        toastMessage(getType(error.code || 400), petition.errorMessage || error.message);
    }

    return null;
}
