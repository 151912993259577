import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ButtonGroup, Col, Form, Input, InputGroup, Row } from "reactstrap";
import Button from "../../../buttons/Button";
import toastMessage from "../../../../helpers/toastMessage";
import { isValid, secondsToMinutes } from "../../../../helpers/formats";
import peticionBack from "../../../../helpers/peticiones";
import { DataItem } from "../../../../pages/transcodificador/dto/TranscoderDto";
import AudioPlayer from "../../../audio-player/AudioPlayer";
import { StausQueue } from "../../../../core/enums/enums";
import SimpleBar from "simplebar-react";

type Content = {
    id: number;
    start: number;
    end: number;
    text: string;
    show?: string;
};

export default function ViewTranscoder({ row }: { row: DataItem }) {
    const [content, setContent] = useState<Content[] | null>(null); //Todo el contenido separado
    const [descripction, setDescription] = useState<string | null>(null); //Todo el contenido junto
    const [fieldValue, setFieldValue] = useState<string>("");
    const [coincidencias, setCoincidencias] = useState<Content[]>([]); // coincidencias de filtro
    const [coincidenciaActual, setCoincidenciaActual] = useState<number>(0); // indice marca actual
    const [currTimeVideo, setCurrTimeVideo] = useState<number>(0);
    const videoRef = useRef(null);

    const [isPc, setIsPc] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsPc(window.innerWidth >= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        DowJson();
        // eslint-disable-next-line
    }, []);

    const DowJson = async () => {
        const fileJson = row.result.find((f: any) => f.type === "json");
        if (!fileJson) {
            return;
        }
        try {
            const res = await axios.get(fileJson.url, { responseType: "text" });
            const json = JSON.parse(res.data);
            let concatContent = "";
            const jsonClean = json.segments.map((s: Content) => {
                concatContent += s.text;
                return { id: s.id, start: s.start, end: s.end, text: s.text };
            });
            setContent(jsonClean);
            setDescription(concatContent);
        } catch (error) {
            console.error("Error al descargar el archivo:", error);
        }
    };

    /* =================== Boton copiar ==================== */
    async function copyClipboard() {
        try {
            if (descripction) {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(descripction).then((a) => {});
                } else {
                    commandCopyClipboard();
                }
                toastMessage("info", "Contenido copiado correctamente");
            }
        } catch (error) {
            toastMessage("error", "No se puede copiar al portapapeles");
        }
    }

    function commandCopyClipboard() {
        const textArea = document.createElement("textarea");
        textArea.value = descripction as string;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }

    function onChange({ target }: { target: any }) {
        setFieldValue(target.value);
    }

    //Realizar busqueda
    async function search(evento: any) {
        evento.preventDefault();
        if (!isValid(fieldValue)) return;
        const res = await peticionBack({}, `/queues/TRANSCRIPCION/search/${[row.queue_id]}?search=${fieldValue}`, "GET", false);

        if (res && res.concidences && res.concidences.length > 0) {
            const data: Content[] = res.concidences.map((el: Content) => {
                return {
                    id: el.id,
                    start: el.start,
                    end: el.end,
                    text: el.text,
                    show: `${secondsToMinutes(el.start)} - ${secondsToMinutes(el.end)}`,
                };
            });
            data.sort((a: Content, b: Content) => {
                return a.start - b.start;
            });
            setCoincidencias(data);
        } else {
            setCoincidencias([]);
            toastMessage("info", "No se encontraron concidencias");
        }
    }

    /* Anterior/Siguiente concidiencias de busqueda */
    const onNext = () => setCoincidenciaActual(coincidenciaActual + 1);
    const onPrev = () => setCoincidenciaActual(coincidenciaActual - 1);

    /* Establecer tiempo actual del reproductor */
    function setTimeVideo(time: number) {
        if (videoRef.current) (videoRef.current as any).currentTime = time;
    }

    useEffect(() => {
        const video: any = videoRef.current;

        if (video) {
            const updateTime = () => {
                if (Math.floor(video.currentTime) !== Math.floor(currTimeVideo)) {
                    setCurrTimeVideo(video.currentTime);
                }
            };

            video.addEventListener("timeupdate", updateTime);

            return () => {
                video.removeEventListener("timeupdate", updateTime);
            };
        }
    }, [currTimeVideo]);

    /* Establecel el elemento actual de las conicdencias de busqueda */
    useEffect(() => {
        if (coincidencias.length > 0) setTimeVideo(coincidencias[coincidenciaActual].start);
    }, [coincidencias, coincidenciaActual]);

    function getClass(s: Content) {
        let clase = "";
        if (coincidencias.find((c: Content) => c.start === s.start)) {
            clase += "search ";
        }

        if (currTimeVideo >= s.start && currTimeVideo < s.end) {
            clase += "current ";
        }
        return clase;
    }

    return (
        <div className="mx-3 text-center mt-3">
            <Row>
                <h4 className="mb-2">{row.name}</h4>
                <Col xs="12" md="6">
                    <div style={{ display: "flex", flexDirection: "column", height: isPc ? "80vh" : "auto", minHeight: isPc ? "45vh" : "20vh", justifyContent: "center" }}>
                        <Row>
                            <div className="my-2" style={{ display: "flex", flexDirection: "column", height: "40%", justifyContent: "center", minHeight: isPc ? "45vh" : "auto" }}>
                                <video ref={videoRef} style={{ width: "100%" }} controls controlsList="nodownload" crossOrigin="anonymous" id="video">
                                    <source src={row.url} type="video/mp4" />
                                    <track src={row.result[0].url} kind="subtitles" label="Subtitulos" srcLang="es" />
                                    <p>Su navegador no admite el elemento de vídeo</p>
                                </video>
                            </div>
                            {coincidencias.length > 0 && (
                                <div className="text-center">
                                    <ButtonGroup>
                                        <Button color="primary" onClick={onPrev} disabled={coincidenciaActual < 1}>
                                            {"<"}
                                        </Button>
                                        <Button color="dark" className="bg-transparent text-dark" onClick={() => setTimeVideo(coincidencias[coincidenciaActual].start)}>
                                            {coincidencias[coincidenciaActual].show}
                                        </Button>
                                        <Button color="primary" onClick={onNext} disabled={coincidenciaActual === coincidencias.length - 1}>
                                            {">"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            )}
                        </Row>
                    </div>
                </Col>
                <Col xs="12" md="6">
                    <div style={{ height: "100%" }}>
                        <Form role="search" onSubmit={search}>
                            <InputGroup className="mt-5 mb-3">
                                <Input name="busqueda" onChange={onChange} placeholder="Buscar..." onCopy={(e) => e.preventDefault()} value={fieldValue} />
                                <Button outline color="primary" type="submit">
                                    <i className="ri-search-line" />
                                </Button>
                                <Button outline color="secondary" type="button" onClick={() => copyClipboard()}>
                                    <i className="ri-file-copy-2-line" />
                                </Button>
                            </InputGroup>
                        </Form>
                        {row.status === StausQueue.SUCCESS && <AudioPlayer queue={row} />}
                        <div className="text-justify mt-3 border border-2 rounded py-1 px-2" style={{ display: "flex", flexDirection: "column", height: "75vh", minHeight: isPc ? "75vh" : "75vh" }}>
                            <strong>Contenido:</strong>
                            <SimpleBar autoHide={false} style={{ maxHeight: "70vh" }} className="px-3 t-justify">
                                {content &&
                                    content.map((s: Content) => (
                                        <span key={s.id} className={"parrafo " + getClass(s)} onClick={() => setTimeVideo(s.start)}>
                                            {s.text}
                                        </span>
                                    ))}
                            </SimpleBar>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
