import { cloneElement, Fragment, isValidElement, ReactElement, ReactNode, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Button from "../../../components/buttons/Button";
import { ButtonProps } from "../../../components/buttons/ButtonProps";
import { loaded, loading } from "../../../core/slices/loader/LoaderSlicer";
import { useDispatch } from "react-redux";
import { EnumLoader } from "../../../core/types/TypeLoader";
import { uploadFile } from "../../../helpers/peticiones";
import toastMessage from "../../../helpers/toastMessage";
import { base64ToBlob } from "../../../helpers/image";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import ReactSignatureCanvas from "react-signature-canvas";

export function ModalSign({ children, setSign, exist, t }: { children: ReactNode; setSign: (val: string) => void; exist: boolean; t: Function }) {
    const [open, setOpen] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    let refff: any = {};

    const dispatch = useDispatch();

    useEffect(() => {
        if (!open) setError(false);
    }, [open]);

    const toggle = () => setOpen(!open);

    const childrenWithClick = isValidElement(children) ? cloneElement(children as ReactElement, { onClick: toggle }) : children;

    const clearSign = () => {
        refff.clear();
    };

    const save = async () => {
        if (!refff) return;
        if (refff.isEmpty()) {
            setError(true);
            return;
        }
        dispatch(loading(EnumLoader.FULLSCREEN_SUBTLE));
        const img64 = refff.getTrimmedCanvas().toDataURL("image/png");
        const file = base64ToBlob(img64, "image/png");
        const res = await uploadFile({ file, endpoint: "/me/sign", method: "POST", keyBody: "file", show: false });
        if (res.sign) {
            setSign(res.sign);
            toastMessage("success", t("sign.Signature created"));
            toggle();
        } else {
            toastMessage("error", t("sign.An error occurred while creating the signature"));
        }
        dispatch(loaded());
        setError(false);
    };

    function getAlert(exist: boolean) {
        alerConfirmation({
            title: t("sign.Save Signature"),
            content: t("sign.Are you sure you want to keep this signature?") + " " + (exist ? t("sign.Your previous signature will be deleted") : ""),
            buttonOkMsg: t("common.save"),
            handleAction: save,
        });
    }

    return (
        <Fragment>
            <Modal isOpen={open} toggle={toggle} size="lg" centered fullscreen="md" backdrop="static">
                <ModalBody>
                    <h5 className="mb-3">
                        {t("sign.Create signature")}
                        <i className="mdi mdi-signature-freehand ms-2" />
                    </h5>
                    <h6 className={error ? "text-danger" : "text-muted"}>{t("sign.Draw your new signature without exiting the box")}</h6>
                    <div className="signature-container">
                        <div className="signature-pad">
                            <ReactSignatureCanvas ref={(ref) => (refff = ref)} penColor="black" canvasProps={{ width: 400, height: 250 }} />
                        </div>
                    </div>
                    <div className="d-flex flex-column-reverse flex-md-row justify-content-center justify-content-md-between mt-2">
                        <IconBtn icon="bx bx-exit" text={t("common.close")} color="dark" ghost onClick={toggle} className="border" />
                        <IconBtn icon="bx bxs-eraser" text={t("common.clean")} color="danger" onClick={clearSign} soft />
                        <IconBtn icon="bx bx-save" text={t("sign.Save Signature")} color="success" onClick={() => getAlert(exist)} />
                    </div>
                </ModalBody>
            </Modal>
            {childrenWithClick}
        </Fragment>
    );
}

function IconBtn(props: ButtonProps & { icon: string; text: string }) {
    const { icon, text, onClick, className, ...buttonProps } = props;
    return (
        <Button onClick={onClick} className={(className || "") + " mb-3 mt-md-2 mb-md-0"} {...buttonProps}>
            <i className={icon + " me-1 align-middle fs-4"} />
            {text}
        </Button>
    );
}
