/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import peticionBack from "../../../helpers/peticiones";
import { Button, Card, CardBody, CardHeader, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { EnumLoader } from "../../../core/types/TypeLoader";
import { useRef, useState } from "react";
import img from "../../../assets/images/backGroundabogados.jpeg";
import createURL from "../../../helpers/url";
import { BannerD } from "./dto/banner.dto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { alerConfirmation } from "../../../helpers/alertConfirmation";
import Datatable from "../../../components/datatable/Datatable";
import { LazyLoadImage } from "react-lazy-load-image-component";
import loadImg from "../../../../src/assets/images/load-img.webp";
import toastMessage from "../../../helpers/toastMessage";

export function Banner() {
    const [loader, setLoader] = useState<EnumLoader | undefined>();
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [modal, setModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState<any>([]);
    const toggle = () => setModal(!modal);
    const headers = [t("Name"), t("Actions")];
    const [res, setRes] = useState(undefined);
    const inputRef = useRef<HTMLInputElement>(null);

    const columnsDef = [
        { orderable: false, targets: [1] },
        { className: "text-center", targets: [0, 1, 2] },
    ];

    const columns = [
        { data: "name" },
        {
            data: null,
            render: (row: BannerD) => (
                <div className="d-flex justify-content-evenly">
                    <FontAwesomeIcon icon={faTrashCan} className="text-danger cursor-pointer" onClick={() => showAlert(row)} />
                    <FontAwesomeIcon icon={faEye} className="text-warning cursor-pointer" onClick={() => Image(row)} />
                </div>
            ),
        },
    ];

    async function upload(value: FileList | null) {
        if (value === null || value.length < 1) {
            return;
        }

        setLoader(EnumLoader.SOLID);
        const params = new FormData();
        const archivo = value[0];
        if (value[0].type === "image/png" || value[0].type === "image/jpeg") {
            params.append("files", archivo);
            const res = await peticionBack(params, "/banners/upload", "POST", true);
            if (res) {
                if (inputRef.current) inputRef.current.value = "";
                setRefresh(true);
            }
            setLoader(undefined);
        } else {
            toastMessage("error", "Select a .jpg or .png file");
            setLoader(undefined);
            setRes(undefined);
        }
    }

    async function getData(order: string, records: number, page: number, search: string) {
        const params: any = {
            pathname: `/banners`,
            searchParams: [
                { name: "order", value: order },
                { name: "records", value: records },
                { name: "page", value: page },
                { name: "search", value: search },
                { name: "rows", value: "name,url" },
            ],
        };
        const url = createURL(params);
        const res = await peticionBack({}, url, "GET", false);
        return { data: res };
    }

    async function deleteRow(row: BannerD) {
        setLoader(EnumLoader.SOLID);
        await peticionBack({}, `/banners/${row.name}`, "DELETE", true);
        setLoader(undefined);
        setRefresh(true);
    }

    function showAlert(row: BannerD) {
        alerConfirmation({
            handleAction: deleteRow,
            title: `${t("Delete image")}`,
            content: `${t("Are you sure you want to delete this image?")}`,
            buttonOkMsg: `${t("Delete")}`,
            buttonCancelMsg: `${t("Cancel")}`,
            values: row,
        });
    }

    function Image(row: BannerD) {
        setSelectedImage(row);
        toggle();
    }

    return (
        <div>
            <div className="text-center m-3">
                <Card>
                    <img className="card-img img-fluid" src={img} alt="Card" style={{ filter: "brightness(80%) blur(20px)", height: "200px", objectFit: "cover" }} />

                    <div className="card-img-overlay p-0 d-flex flex-column">
                        <CardHeader className="bg-transparent">
                            <h4 className="card-title  mb-0" style={{ fontSize: "5em", fontWeight: "bold", fontStyle: "normal" }}>
                                Banner
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <p className="card-text mb-2" style={{ fontSize: "1.5em" }}>
                                {t("Add images to customize your banner")}
                            </p>
                        </CardBody>
                    </div>

                    <div className=" position-relative d-inline-block mx-auto">
                        {loader && (
                            <div className="loader-container">
                                <Spinner color="success" />
                                {t("Cargando...")}
                            </div>
                        )}
                        <div>
                            <Input
                                id="profile-img-file-input"
                                type="file"
                                accept=".jpg, .png"
                                className="profile-img-file-input mb-4 me-5"
                                onChange={({ target }) => {
                                    upload(target.files), res ? (target.value = "") : null;
                                }}
                                innerRef={inputRef}
                            />
                        </div>
                    </div>
                </Card>
            </div>

            <h3 className="m-3">Mi imagenes</h3>
            <Datatable columns={columns} columnDefs={columnsDef} headers={headers} control="back" stateRefresh={[refresh, setRefresh]} petition={getData} />

            <Modal isOpen={modal} toggle={toggle} size="md">
                <ModalHeader toggle={toggle}>{selectedImage.name}</ModalHeader>
                <ModalBody className="justify-content-center">{selectedImage ? <LazyLoadImage src={selectedImage.url} effect="blur" placeholderSrc={loadImg} style={{ width: "100%", height: "auto" }} className="object-fit-fill" /> : ""}</ModalBody>

                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Aceptar
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
